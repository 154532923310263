<template>
  <div>
      <b-row>
        <b-col cols="12" class="d-print-none">
          <hr class="border-dark" />
        </b-col>
      </b-row>
      <b-row no-gutters class="page-break-print">
        <b-col cols="12" md="8">
          <b-row no-gutters>
            <b-col>
              <h4 class="text-primary fw-600">
                {{ getTranslatedName(product) }}
                <b-button v-b-modal="`${modalid}`" variant="outline-primary" id="productEbModalLink" :data-artnr="product.artnr" class="ml-2 d-print-none show-info-button" :title="$t('text.order.visa-produktdata-title')">{{ $t("text.order.visa-produktdata") }}</b-button>
              </h4>
            </b-col>
          </b-row>
          <b-row v-for="(opt, index) in options" :key="index" no-gutters>
            <b-col cols="12" md="5">
              <div class="row-title bg-primary text-white m-0 mr-md-2 mb-md-2">{{ $t(opt.name) }}</div>
            </b-col>
            <b-col cols="12" md="7">
              <div class="row-value bg-secondary text-dark mr-0 mr-md-2">
                {{ displaySpec(_self.intersection(opt.slugs, _self.selection)) }}
              </div>
            </b-col>
          </b-row>
          <div class="d-none d-md-block">
            <slot></slot>
          </div>
        </b-col>
        <b-col cols="12" md="4" align="center">
          <ProductImages :artnr="product.artnr" :key="product.artnr"></ProductImages>
        </b-col>
      </b-row>
      <div class="d-md-none">
        <slot></slot>
      </div>
  </div>
</template>
<script>
  import _ from "lodash";
  import ProductImages from "@/components/products/ProductImages";
  export default {
    name: "PrintOrderProduct",
    components: {
      ProductImages
    },
    props: {
      selection: {
          type: Array,
          required: true
      },
      product: {
          type: Object,
          required: true
      },
      options: {
          type: Array,
          required: true
      },
      modalid: {
          type: String,
          required: true
      }
    },
    data() {
      return {

      }
    },
    methods: {
      displaySpec(specs) {
        let displaySpecs = [];
        specs.forEach((spec) => {
          // Convert float values to a readable format
          if (spec.indexOf("p-") == 0) {
            let dslug = spec.replace("p-", "").replace("-", ",");
            displaySpecs.push(dslug);
          } else if (spec.indexOf("b-") == 0) {
            let dslug = spec.replace("b-", "").replace("-", ",");
            displaySpecs.push(dslug);
          } else if (spec.indexOf("h-") == 0) {
            let dslug = spec.replace("h-", "").replace("-", ",");
            displaySpecs.push(dslug);
          } else if (spec.indexOf("vd-") == 0) {
            let dslug = spec.replace("vd-", "").replace("-", ",");
            displaySpecs.push(dslug);
          } else {
            displaySpecs.push(this.$t(spec));
          }
        });
        return _.map(displaySpecs, (spec) => this.$t(spec)).join(", ");
      },
      getTranslatedName(item) {
        if (item.translations && item.translations[this.$i18n.locale]) {
          const translatedName = item.translations[this.$i18n.locale].name;
          if (this.$i18n.locale != "sv" && translatedName != "") {
            return translatedName;
          }
        }
        return item.name;
      },
      intersection(a, b) {
        return _.intersection(a, b);
      },
    }    
  }
</script>
<style lang="scss" scoped>
  @import "/scss/custom.scss";
  @import "/node_modules/bootstrap/scss/mixins/_breakpoints.scss";

  @include media-breakpoint-down(md) {
    .ingress .btn.float-right {
      float: none !important;
      width: 100%;
    }
  }

  .show-info-button {
    width: 100%;
    margin: 10px 0 0 0 !important;
    @include media-breakpoint-up(md) {
      margin: 0 0 0 10px !important;
      width: auto;
    }
  }

  .row-title,
  .row-value {
    line-height: 32px;
    padding: 0 9px;
    font-size: 14px;
  }
  .row-title {
    margin: 0 9px 9px 0;
    font-weight: bold;
  }
  .row-value {
    margin: 0 9px 9px 0;
  }
  h4 {
    margin-top: 10px;
    margin-bottom: 32px;
  }
  hr {
    margin: 16px 0;
  }
  .page-break-print {
    page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-inside: avoid; /* or 'auto' */
  }
  .carousel-indicators {
    display: none;
  }
  .carousel {
    padding: 0 !important;
  }
  .product-aside-image {
    max-height: 394px !important;
  }
</style>
<style lang="css">
  .product-aside-image {
    max-height: 394px !important;
  }
</style>