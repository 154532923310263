<template>
  <b-col :cols="cols" :md="md" class="py-3">
    <h3 v-if="h3" v-text="h3" />
    <b-img v-if="image" fluid :src="image" class="w-100" />
    <h4 v-if="h4" v-text="h4" />
    <p v-if="body" v-text="body" />
    <b-button v-if="readmore" variant="dark" size="sm" class="px-5 rounded stretched-link" :to="readmore">{{ $t("text.startsida.read-more") }}</b-button>
  </b-col>
</template>

<script>
  export default {
    name: "RowContentPuff",
    props: {
      cols: { type: String },
      md: { type: String, default: "" },
      h3: { type: String },
      image: { type: String },
      h4: { type: String },
      body: { type: String },
      readmore: { type: Object },
    },
  };
</script>

<style lang="scss" scoped>
  h3 {
    margin-bottom: 31px;
    font-weight: 350;
    font-size: 30px;
  }
  h4 {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 20px;
  }
  img {
    margin-bottom: 21px;
  }
  p {
    margin-bottom: 29px;
  }
</style>
