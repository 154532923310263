<template>
    <div>
        <AdminNbList :data="products" @on-deleted-item="fetchNb"></AdminNbList>
    </div>
</template>

<script>
    import AdminNbList from "@/components/admin/AdminNbList";
    import ApiServiceAdmin from "@/services/ApiServiceAdmin";
    export default {
        components: {
            AdminNbList,
            ApiServiceAdmin
        }, 
        data() {
            return {
                products: [],
                totalForSlug: {},
                activetab: 1
            }
        },
        methods: {
            fetchNb() {
                ApiServiceAdmin.getNb().then((response) => {
                    this.products = response.data;
                });
            },
        },
        mounted() {
            this.fetchNb();
        },
    }

</script>