var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.show}},[(_vm.filter)?_c('form',{on:{"change":function($event){return _vm.$emit('on-change-slug', _vm.filter)}}},[_c('table',{staticClass:"table-header-rotated table-hover"},[(_vm.options)?_c('thead',{staticClass:"bg-light"},[_c('tr',[(_vm.development)?_c('th'):_vm._e(),_vm._l((_vm.options),function(o,index){return _c('th',{key:index,staticClass:"rotate p-0",attrs:{"scope":"col"}},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t(o)))])])])}),_c('th',{staticClass:"rotate p-0",attrs:{"scope":"col"}},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t("Nödutrymningsbeslag")))])])]),_c('th',{staticClass:"rotate p-0",attrs:{"scope":"col"}},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t("Slutbleck")))])])]),_c('th',{staticClass:"rotate p-0",attrs:{"scope":"col"}},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t("Elslutbleck")))])])])],2)]):_vm._e(),(_vm.filter)?_c('tbody',_vm._l((_vm.filter),function(f,row){return _c('tr',{key:`row-${row}`,class:{ 'bg-warning': row.unsynced }},[(_vm.development)?_c('th',{staticClass:"text-right th-sticky-left",attrs:{"scope":"row","width":"200"}},[_vm._v(" "+_vm._s(row)+" "),_c('b-form-input',{ref:`rowHelper-${row}`,refInFor:true,on:{"change":function($event){return _vm.fillRowHelper(row)}},model:{value:(_vm.fillRowHelperValues[row]),callback:function ($$v) {_vm.$set(_vm.fillRowHelperValues, row, $$v)},expression:"fillRowHelperValues[row]"}})],1):_vm._e(),_vm._l((_vm.options),function(o,col){return _c('td',{key:`col-${col}`,attrs:{"align":"center"}},[_c('b-form-checkbox',{attrs:{"disabled":_vm.access_level > 1,"name":o,"value":o,"unchecked-value":[]},on:{"change":function($event){return _vm.$emit('on-update-row', f)}},model:{value:(f.slugs),callback:function ($$v) {_vm.$set(f, "slugs", $$v)},expression:"f.slugs"}})],1)}),_c('td',{staticClass:"py-0"},[_c('b-form-select',{class:{
                  'bg-success text-light': f.nodutrymningsbeslag && f.nodutrymningsbeslag.length > 0,
                },attrs:{"size":"sm","options":_vm.nbsSelectOptions},on:{"change":function($event){return _vm.$emit('on-update-row', f)}},model:{value:(f.nodutrymningsbeslag),callback:function ($$v) {_vm.$set(f, "nodutrymningsbeslag", $$v)},expression:"f.nodutrymningsbeslag"}})],1),_c('td',{staticClass:"py-0"},[_c('b-form-select',{class:{
                  'bg-success text-light': f.slutbleck && f.slutbleck.length > 0,
                },attrs:{"disabled":f.elslutbleck && f.elslutbleck.length > 0,"size":"sm","options":_vm.sbsSelectOptions},on:{"change":function($event){return _vm.$emit('on-update-row', f)}},model:{value:(f.slutbleck),callback:function ($$v) {_vm.$set(f, "slutbleck", $$v)},expression:"f.slutbleck"}})],1),_c('td',{staticClass:"py-0"},[_c('b-form-select',{class:{
                  'bg-success text-light': f.elslutbleck && f.elslutbleck.length > 0,
                },attrs:{"disabled":f.slutbleck && f.slutbleck.length > 0,"size":"sm","options":_vm.ebsSelectOptions},on:{"change":function($event){return _vm.$emit('on-update-row', f)}},model:{value:(f.elslutbleck),callback:function ($$v) {_vm.$set(f, "elslutbleck", $$v)},expression:"f.elslutbleck"}})],1)],2)}),0):_vm._e(),_c('tfoot',[_c('tr',[_c('td',{attrs:{"colspan":`${_vm.options.length + 3}`}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$emit('on-add-row', _vm.filter)}}},[_vm._v("Add row")])],1)])])])]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.debug),expression:"debug"}],staticStyle:{"position":"fixed","bottom":"0","left":"0","background":"#fff","padding":"10px","z-index":"1000","max-height":"100vh","overflow":"scroll","text-align":"left"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$emit('on-update-selected-products', {UISelectedEb: _vm.UISelectedEb,UISelectedSb: _vm.UISelectedSb})}}},[_vm._v("updateSelectedProducts")]),_c('hr'),_c('pre',[_vm._v("UISelectedEb = "+_vm._s(_vm.UISelectedEb))]),_c('hr'),_c('pre',[_vm._v("UISelectedSb = "+_vm._s(_vm.UISelectedSb))]),_c('hr'),_c('pre',[_vm._v("selectedSb = "+_vm._s(_vm.selectedSb))]),_c('hr'),_c('pre',[_vm._v("selectedEb = "+_vm._s(_vm.selectedEb))]),_c('hr'),_c('pre',[_vm._v("factsSb = "+_vm._s(_vm.factsSb))]),_c('hr'),_c('pre',[_vm._v("factsEb = "+_vm._s(_vm.factsEb))]),_c('hr')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }