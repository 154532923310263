<template>
  <form enctype="multipart/form-data" @submit.prevent="saveProductImages">
    <input :disabled="access_level > 1" :multiple="multiple" type="file" ref="productImages" @change="selectProductImages" />
  </form>
</template>

<script>
  import ApiServiceUpload from "@/services/ApiServiceUpload";
  import AdminMessagesMixin from "@/mixins/AdminMessagesMixin";
  import _ from "lodash";
  import { MAX_SIZE, ALLOWED_IMAGE_TYPES } from "@/config";
  export default {
    name: "UploadProductImages",
    mixins: [AdminMessagesMixin],
    props: {
      forProductArt: { type: String, required: true },
      multiple: { type: Boolean, default: false },
      language: { type: String },
    },
    data() {
      return {
        files: [],
        productImages: [],
      };
    },
    computed: {
      access_level() {
        // return 2;
        return this.$store.state.user.access_level;
      },
    },
    methods: {
      async selectProductImages() {
        const filesFromInput = this.$refs.productImages.files;

        this.files = [...this.files, ...filesFromInput];
        this.productImages = [
          ...this.productImages,
          ..._.map(filesFromInput, (file) => ({
            name: file.name,
            src: URL.createObjectURL(file),
            size: file.size,
            validateMessage: this.validate(file),
          })),
        ];
        this.$emit("on-select-product-image", this.productImages[0], this.files);
        this.saveProductImages();
      },
      async saveProductImages() {
        // Time to save those new images
        let formData = new FormData();
        this.files.forEach((file) => {
          formData.append("file", file);
        });
        try {
          await ApiServiceUpload.uploadFilesToArt(this.forProductArt, "images", this.language, formData)
            .then((response) => {
              this.handleSuccess(response);
            })
            .catch(this.handleError);
          this.$emit("on-uploaded-image", this.files);
        } catch (error) {
          this.handleError(error);
        }
      },
      validate(file) {
        if (MAX_SIZE < file.size) {
          return "File is to big!";
        }

        if (!ALLOWED_IMAGE_TYPES.includes(file.type)) {
          return "Wrong format";
        }
      },
    },
  };
</script>

<style lang="scss" scoped></style>
