<template>
  <div>
    <table class="table">
      <thead class="thead-light">
        <tr>
          <th>{{ $t("text.admin.eltryckeslas_motorlas") }}</th>
          <th v-if="showTranslation"></th>
          <th>{{ $t("text.admin.artnr") }}</th>
          <th>{{ $t("text.admin.skapad") }}</th>
          <th>{{ $t("text.admin.andrad") }}</th>
          <th>{{ $t("Filer") }}</th>
          <th v-if="showAddAndDelete"><country-flag country="se" size="small" /></th>
          <th><country-flag country="no" size="small" /></th>
          <th align="right" class="py-1 text-nowrap text-right">
            <b-button v-show="showAddAndDelete" variant="success" @click.prevent="exportListToCsv('em')"><b-icon-download></b-icon-download></b-button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in data" v-bind:key="item.id">
          <td>{{ item.name }}</td>
          <td v-if="showTranslation">
            <small>{{ item.translations.no.name }}</small>
          </td>
          <td>
            <small>{{ showTranslation ? (item.translations ? item.translations.no.artnr : "") : item.artnr }}</small>
          </td>
          <td>
            <small v-if="access_level < 2">{{ item.date | sv_date }}</small>
            <small v-if="access_level == 2">{{ item.date | no_date }}</small>
          </td>
          <td>
            <span v-if="item.log">
              <small v-if="access_level < 2">
                {{ item.log.changed | sv_date }}
                <small
                  ><strong>({{ item.log.user.name }})</strong></small
                >
              </small>
              <small v-if="access_level == 2">
                {{ item.log.changed | no_date }}
                <small
                  ><strong>({{ item.log.user.name }})</strong></small
                >
              </small>
            </span>
          </td>
          <td>
            <AdminAssetsList :artnr="item.artnr" type="docs"></AdminAssetsList>
          </td>
          <td v-if="showAddAndDelete">
            <b-icon-check variant="success" font-scale="1.5" v-if="item.published"></b-icon-check>
          </td>
          <td>
            <b-icon-check variant="success" font-scale="1.5" v-if="item.translations && item.translations.no.published"></b-icon-check>
          </td>
          <td align="right" class="py-1 text-nowrap">
            <b-button variant="primary" :to="{ name: 'adm-em-edit', params: { id: item._id } }"><b-icon-pencil-fill></b-icon-pencil-fill></b-button>
            <b-button v-show="showAddAndDelete" variant="danger" @click.prevent="deleteById(item._id, 'eltryckeslas-motorlas')"><b-icon-trash-fill></b-icon-trash-fill></b-button>
            <b-button v-show="showAddAndDelete" variant="success" @click.prevent="exportToCsv(item._id, 'em')"><b-icon-download></b-icon-download></b-button>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th></th>
          <th v-if="showTranslation"></th>
          <th></th>
          <th></th>
          <th></th>
          <th colspan="5" class="text-right">
            <b-button v-show="showAddAndDelete" variant="success" :to="{ name: 'adm-em-add' }"><b-icon-plus></b-icon-plus> {{ $t("text.admin.lagg-till-em") }}</b-button>
          </th>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
  import AdminListMixin from "@/mixins/AdminListMixin";
  import AdminAccessMixin from "@/mixins/AdminAccessMixin";
  import AdminAssetsList from "@/components/admin/AdminAssetsList";
  export default {
    mixins: [AdminListMixin, AdminAccessMixin],
    components: {
      AdminAssetsList,
    },
    name: "AdminEmList",
    props: {
      data: {
        type: Array,
      },
    },
  };
</script>

<style lang="scss" scoped></style>
