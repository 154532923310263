<template>
  <div>
    <b-container>
      <transition name="fade">
        <ProductsList
          scope="nb"
          :total="getTotal"
          :selected-product-data="getProduct"
          :selected-product-docs="getDocs"
          :products="getProducts"
          @on-product-select="showProduct(...arguments)"
          @on-click-load-more="loadMore"
        >
          <template #ingress>
            <h2 class="d-print-none">
              {{ products_nodutrymningsbeslag_title }}
            </h2>
            <p class="d-print-none">{{ products_nodutrymningsbeslag_text }}</p>
          </template>
        </ProductsList>
      </transition>
    </b-container>
  </div>
</template>

<script>
import store from "@/store/store";
import { mapGetters, mapActions } from "vuex";
import productsListMixin from "@/mixins/productsListMixin";
import contentMixin from "@/mixins/contentMixin";
export default {
  name: "ProductsListViewNB",
  mixins: [productsListMixin, contentMixin],
  computed: {
    ...mapGetters("productsNB", ["getProducts", "getCount", "getTotal"]),
    ...mapGetters("productNB", ["getProduct", "getDocs"]),
  },
  methods: {
    ...mapActions("productsNB", ["fetchProducts"]),
    ...mapActions("productNB", ["fetchProduct", "fetchDocsForArt"]),
    loadMore() {
      this.$store.dispatch("productsNB/updateSkip");
    },
  },
  beforeRouteEnter(to, from, next) {
    // Reset pagination
    // store.dispatch("productsNB/resetSkip");
    // Reset selected product
    // store.dispatch("productNB/resetProduct");
    store
      .dispatch("productsNB/fetchProducts")
      .then(() => next())
      .catch((error) => {
        if (error.response && error.response.status == 404) {
          next({ name: "404", params: { resource: "event" } });
        } else {
          next({ name: "network-issue" });
        }
      });
  },
  beforeRouteUpdate(to, from, next) {
    store.dispatch("productsNB/fetchProducts").then(() => {
      next();
    });
  },
};
</script>

<style lang="scss" scoped></style>
