<template>
  <div class="container">
    <div class="d-flex w-50 h-100 p-3 mx-auto flex-column p-5 my-5 bg-light rounded shadow-sm">
      <h1>Oops! 🤔</h1>
      <h3>Denna sidan finns ej. Prova att <router-link :to="{ name: 'guide' }">gå till guiden.</router-link></h3>
    </div>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped></style>
