import _ from "lodash";
export default {
  computed: {
    translatedArtnr() {
      console.log('translations', this.productData.translations)
      if (this.productData.translations && this.productData.translations[this.$i18n.locale] && this.productData.translations[this.$i18n.locale].artnr) {
        const translatedArtnr = this.productData.translations[this.$i18n.locale].artnr;
        if (this.$i18n.locale != "sv" && translatedArtnr != "") {
          return translatedArtnr;
        }
      }
      return this.productData.artnr;
    },
    translatedName() {
      if (this.productData.translations && this.productData.translations[this.$i18n.locale]) {
        const translatedName = this.productData.translations[this.$i18n.locale].name;
        if (this.$i18n.locale != "sv" && translatedName != "") {
          return translatedName;
        }
      }
      return this.productData.name;
    },
    translatedInfo() {
      if (this.productData.translations && this.productData.translations[this.$i18n.locale]) {
        const translatedInfo = this.productData.translations[this.$i18n.locale].info;
        if (this.$i18n.locale != "sv") {
          return translatedInfo;
        }
      }
      return this.productData.info;
    },
    translatedVariants() {
      if (this.productData.translations && this.productData.translations[this.$i18n.locale]) {
        const translatedVariants = this.productData.translations[this.$i18n.locale].variants;
        let translatedVariantsNotEmpty = _.filter(translatedVariants, (v) => {
          return v.title != ""
        })
        if (this.$i18n.locale != "sv") {
          return translatedVariantsNotEmpty;
        }
      }
      return this.productData.variants;
    },
  }
}