<template>
    <div>
        <AdminLhList :data="products" @on-deleted-item="fetchLh"></AdminLhList>
        <!-- <b-tabs v-model="activetab" pills content-class="m-0" card>
            <b-tab :title="$t('Produkter')" no-body>
                <AdminLhList :data="products" @on-deleted-item="fetchLh"></AdminLhList>
            </b-tab>
            <b-tab :title="$t('Kriterier')" no-body>
                <AdminLhOptionsList></AdminLhOptionsList>
            </b-tab>
        </b-tabs> -->
    </div>
</template>

<script>
    import AdminLhList from "@/components/admin/AdminLhList";
    import ApiServiceAdmin from "@/services/ApiServiceAdmin";
    export default {
        components: {
            AdminLhList,
            ApiServiceAdmin
        }, 
        data() {
            return {
                products: [],
                totalForSlug: {},
                activetab: 1
            }
        },
        methods: {
            fetchLh() {
                ApiServiceAdmin.getLh().then((response) => {
                    this.products = response.data;
                });
            },
        },
        mounted() {
            this.fetchLh();
        },
    }

</script>