import ApiServiceAdmin from "@/services/ApiServiceAdmin"
import AdminMessagesMixin from "@/mixins/AdminMessagesMixin";
export default {
  mixins: [AdminMessagesMixin],
  methods: {
    async deleteById(id, scope) {
      this.loading = true;
      if (confirm("Är du säker?")) {
        await ApiServiceAdmin.deleteById(id, scope)
          .then((response) => {
            this.handleSuccess(response)
            this.$emit('on-deleted-item')
          })
          .catch(this.handleError);
      }
    },
    deleteSlug(slug,opts,scope) {
      console.log('deleteSlug', slug, opts, scope)
      const opt = opts.find((o) => o.slugs.includes(slug));
      opt.slugs = opt.slugs.filter((s) => s !== slug);
      if ( !scope || opt.length == 0 || opt.slugs.length == 0 || !confirm("Är du säker?") ) {
        return;
      }
      this.loading = true;
      // Delete slug from opt array
      const payload = _.pick(opt, ['name', 'slugs']);
      console.log('payload', payload)
      let func
      switch (scope) {
        case "eb":
          func = 'updateEbOptionsById';
          break;
        case "ms":
          func = 'updateMsOptionsById';
          break;
        case "em":
          func = 'updateEmOptionsById';
          break;
        case "sb":
          func = 'updateSbOptionsById';
          break;
      
        default:
          break;
      }

      if (func) {
        ApiServiceAdmin[func](opt._id, payload)
        .then(this.handleSuccess)
        .then(this.fetchOptions)
        .catch(this.handleError);
      }
    },
    exportToCsv(id, scope) {
      this.loading = true;
      ApiServiceAdmin.exportToCsv(id, scope)
        .then((response) => {
          this.handleSuccess(response)
          this.loading = false;
        })
        .catch(this.handleError);
    },
    exportListToCsv(scope) {
      this.loading = true;
      ApiServiceAdmin.exportListToCsv(scope)
        .then((response) => {
          this.handleSuccess(response)
          this.loading = false;
        })
        .catch(this.handleError);
    },
    onUpdatedOptionsListTable() {
      this.loading = false;
    },
  },
  filters: {
    sv_date(datestr) {
      const d = new Date(datestr);
      const date = d.toLocaleDateString("sv-SE");
      const time = d.toLocaleTimeString("sv-SE");
      return date + " " + time;
    },
    no_date(datestr) {
      const d = new Date(datestr);
      const date = d.toLocaleDateString("no-NO");
      const time = d.toLocaleTimeString("no-NO");
      return date + " " + time;
    },
  },
}