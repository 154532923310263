<template>
  <b-container id="product-category" class="px-0 px-md-4">
    <b-list-group horizontal class="justify-content-start py-2">
      <span class="list-group-item border-0 p-0 align-items-center d-none d-md-flex justify-content-start cat-title mr-3">{{ title }}:</span>
      <OptionsListSlug class="border-0 slug px-0 px-md-2 w-auto bg-white" :slug="slug" :selected="selection.includes(slug)" :disabled="disabled.includes(slug)" v-for="slug in categories" v-bind:key="`${slug}-group-item`" id="`${slug}-group-item`" @toggle-slug="$emit('toggle-slug', [...arguments])" />
    </b-list-group>
  </b-container>
</template>

<script>
  import OptionsListSlug from "./OptionsListSlug";
  export default {
    components: {
      OptionsListSlug,
    },
    data() {
      return {
        selected: [],
      };
    },
    props: ["categories", "selection", "disabled", "title"],
  };
</script>

<style lang="scss" scoped>
  .slug {
    min-width: 140px;
    background-color: none;
  }
  .cat-title {
    font-size: 14px !important;
    font-weight: 700;
  }
</style>
