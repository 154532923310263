<template>
  <div>
    <b-card no-body class="mb-1 border-0" :class="{'none-selected': selection.length === 0}">
      <b-button
        block
        v-b-toggle="`${name}-body`"
        class="options-head"
        role="tab"
        variant="primary"
      >
        <div class="p-0">
          <div>{{ $t(name) }}</div>
          <em class="fw-400 d-lg-none" v-html="selectedOptionsInfo"></em>
        </div>
        <div cols="1" align="right" class="d-md-none">
          <b-icon-chevron-right :ref="`${name}-body`"></b-icon-chevron-right>
        </div>
      </b-button>
      <b-collapse
        :id="`${name}-body`"
        class="options-body"
        accordion="filter-options"
      >
        <b-overlay :show="isPe(name)" spinner-variant="none">
          <template #overlay>
            <b-button
              variant="link"
              v-b-tooltip.hover
              :title="tooltipText(name)"
              @click="clearSelected"
            >
              <b-icon-eye-slash-fill font-scale="2"></b-icon-eye-slash-fill
              ><br /><small class="text-nowrap">{{
                translatedSelectedEb
              }}</small>
            </b-button>
          </template>
          <b-card no-body class="border-0" :class="{ eb: scope == 'eb' }">
            <b-list-group class="border-0 list-group-flush">
              <OptionsListSlug
                :disabled="disabled.includes(slug)"
                :selected="selection.includes(slug)"
                :translation="
                  translations ? translations[slugsSorted.indexOf(slug)] : ''
                "
                :slug="slug"
                v-for="slug in slugsSorted"
                v-bind:key="`${slug}-item`"
                @toggle-slug="$emit('toggle-slug', [...arguments])"
              />
            </b-list-group>
            <b-card-footer
              class="p-0"
              v-if="hasYScroll(name) && slugs.length > 15"
            >
              <b-icon
                icon="chevron-down"
                scale="1.6"
                variant="primary"
                animation="cylon-vertical"
                class="scroll-indicator"
              ></b-icon>
            </b-card-footer>
          </b-card>
        </b-overlay>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import OptionsListSlug from "./OptionsListSlug";
import _ from "lodash";
export default {
  name: "OptionsListColumn",
  props: ["name", "slugs", "translations", "scope", "disabled", "selection"],
  data() {
    return {
      scrolling: [
        "passande-elslutbleck",
        "profilsystems-tillverkare",
        "plosmatt",
        "bredd",
      ],
      selectedOptionsCount: 0,
    };
  },
  components: {
    OptionsListSlug,
  },
  computed: {
    numSelInSlugs() {
      return _.intersection(this.selection, this.slugs).length;
    },
    selectedOptionsInfo() {
      return `<strong>${this.numSelInSlugs}</strong> val gjorda`;
    },
    translatedSelectedEb() {
      if (this.$i18n.locale == "no") {
        return this._self.$store.state.selectedEb.translations.no.name;
      } else {
        return this._self.$store.state.selectedEb.name;
      }
    },
    slugsSorted() {
      if (
        this.name == "plosmatt" ||
        this.name == "hojd" ||
        this.name == "bredd" ||
        this.name == "vinkeldjup"
      ) {
        let origSlugs = this.slugs;
        let sortedSlugs = origSlugs.sort(function (a, b) {
          // a as float
          let aNumeric = a.match(/\d/g);
          aNumeric = aNumeric.join("");
          aNumeric =
            aNumeric.slice(0, aNumeric.length - 1) +
            "." +
            aNumeric.slice(aNumeric.length - 1);
          aNumeric = parseFloat(aNumeric);
          // b as float
          let bNumeric = b.match(/\d/g);
          bNumeric = bNumeric.join("");
          bNumeric =
            bNumeric.slice(0, bNumeric.length - 1) +
            "." +
            bNumeric.slice(bNumeric.length - 1);
          bNumeric = parseFloat(bNumeric);
          return aNumeric - bNumeric;
        });
        return sortedSlugs;
      }
      if (this.name == "profilsystems-tillverkare" || this.name == "lastyp") {
        // Sort alphabetically
        return _.sortBy(this.slugs, (slug) => slug);
      }
      return this.slugs;
    },
  },
  methods: {
    clearSelected() {
      this.$emit("on-reset");
    },
    tooltipText(name) {
      return this.$t("help.tooltip.eye['" + name + "']");
    },
    isPe(is) {
      return is === "passande-elslutbleck" && this.$store.state.selectedEb
        ? true
        : false;
    },
    hasYScroll(name) {
      return this.scrolling.filter((s) => s == name).length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../scss/custom.scss";
@import "../../../../node_modules/bootstrap/scss/mixins/_breakpoints.scss";
.options-head {
  min-height: 56px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  > div {
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    padding: 8px;
  }
  > em {
    display: block;
    text-align: left;
    font-size: 14px;
  }
}

.card-footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 1;
}

.btn.options-head[aria-expanded="true"] .bi-chevron-right {
  transform: rotate(90deg);
  transition: all 100ms ease-in-out;
}

@include media-breakpoint-down(lg) {
  .options-body {
    .card {
      height: auto;
    }
  }
}
@include media-breakpoint-up(lg) {
  .options-body {
    .card {
      height: auto;
      max-height: 360px;
      &.eb {
        max-height: 279px;
      }
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    display: block !important;
  }
  .options-head > div {
    .bi-chevron-down {
      display: none;
    }
  }

  .none-selected .list-group-item.disabled {
    display: none;
  }
}
</style>
