<template>
  <div>
    <GuideInfoSection
      :title="guideTitle"
      :text="guide_montagestolpe_text"
      :text2="guide_friskrivning"
      :step1="$t('text.guideMS.step1')"
      @on-reset-slugs="resetSlugs"
      class="d-print-none"
    >
      <FilterSelection
        :selection="this['optionsEM/getSelection']"
        :options="this['optionsEM/getOptions']"
        :show="showFilterSelectionEM"
        :text="$t('text.guideEM.criterion')"
        scope="em"
      ></FilterSelection>
      <FilterSelection
        :selection="this['optionsEB/getSelection']"
        :options="this['optionsEB/getOptions']"
        :show="showFilterSelectionEB"
        :text="$t('text.guideEB.criterion')"
        scope="eb"
      ></FilterSelection>
      <!-- <template #bottom>
        <OptionsCategory :categories="getCategories" :disabled="getDisabled" :selection="getSelection" :title="$t('produktkategori')" @toggle-slug="toggleSlug" />
      </template> -->
    </GuideInfoSection>
    <b-overlay
      no-fade
      spinner-variant="primary"
      :show="loading"
      opacity="0.95"
      bg-color="white"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon
            icon="stopwatch"
            font-scale="3"
            animation="cylon"
            variant="primary"
          ></b-icon>
          <p class="mt-2">
            <strong>{{ $t("text.guideMS.loading") }}</strong>
          </p>
        </div>
      </template>
      <OptionsList
        :options="skip([getEsbs, ...getOptions])"
        :disabled="getDisabled"
        :selection="getSelection"
        @toggle-slug="toggleSlug"
        @reset-slugs="resetSlugs"
        @on-reset="resetSelectedEb"
        product-type="elslutbleck"
        class="d-print-none"
      />
    </b-overlay>
    <b-container>
      <transition name="fade">
        <ProductsList
          :total="getTotal"
          :selected-product-data="getProduct"
          :selected-product-docs="getDocs"
          :products="getProducts"
          @on-product-select="showProduct(...arguments, 'ms')"
          @on-click-load-more="loadMore"
          scope="ms"
        >
          <template #ingress>
            <h3 class="d-print-none">{{ $t("text.guideMS.step2") }}</h3>
            <p class="d-print-none">{{ $t("text.guideMS.step2-text") }}</p>
          </template>
        </ProductsList>
      </transition>
    </b-container>
  </div>
</template>

<script>
import store from "@/store/store";
import GuideInfoSection from "@/components/guide/options/GuideInfoSection";
import FilterSelection from "@/components/guide/options/FilterSelection";
// import OptionsCategory from "@/components/guide/options/OptionsCategory.vue";
import OptionsList from "@/components/guide/options/OptionsList.vue";
import { mapGetters, mapActions } from "vuex";
import productsListMixin from "@/mixins/productsListMixin";
import contentMixin from "@/mixins/contentMixin";
export default {
  name: "ProductsListViewMS",
  mixins: [productsListMixin, contentMixin],
  components: {
    GuideInfoSection,
    FilterSelection,
    // OptionsCategory,
    OptionsList,
  },
  computed: {
    ...mapGetters("optionsMS", [
      "getOptions",
      "getSelection",
      "getDisabled",
      "getCategories",
      "getEsbs",
    ]),
    ...mapGetters("productsMS", [
      "getProducts",
      "getCount",
      "getTotal",
      "getUi",
    ]),
    ...mapGetters("productMS", ["getProduct", "getDocs"]),
    // ...mapGetters("productEB", ["getProduct", "getDocs"]),
    ...mapGetters(["optionsEM/getSelection", "optionsEM/getOptions"]),
    ...mapGetters(["optionsEB/getSelection", "optionsEB/getOptions"]),

    showFilterSelectionEM() {
      let options = this['optionsEM/getOptions']
      let selection = this['optionsEM/getSelection']
      return options.filter((option) => {
        if (option.slugs.filter((s) => selection.indexOf(s) != -1).length > 0) {
          return true; 
        }
      }).length > 0;
    },
    showFilterSelectionEB() {
      let options = this['optionsEB/getOptions']
      let selection = this['optionsEB/getSelection']

      return options.filter((option) => {
        if (option.slugs.filter((s) => selection.indexOf(s) != -1).length > 0) {
          return true; 
        }
      }).length > 0;
    },
    loading: {
      get() {
        return this.$store.state.optionsMS.loading;
      },
      set(loading) {
        this.$store.state.optionsMS.loading = loading;
      },
    },
    ebInfo() {
      let eb = this.$store.state.productEB.product;

      if (this.$i18n.locale == "no" && eb && eb.translations) {
        eb = eb.translations.no;
      }
      return eb ? ` - passande ${eb.name}` : "";
    },
    guideTitle() {
      return this.guide_montagestolpe_title + this.ebInfo;
    },
  },
  methods: {
    ...mapActions("optionsMS", [
      "fetchOptions",
      "fetchDisabled",
      "updDisabled",
      "resetSlugs",
      "addSlug",
      "rmSlug",
    ]),
    ...mapActions("productsMS", ["fetchProducts"]),
    ...mapActions("productMS", ["fetchProduct", "fetchDocsForArt"]),
    ...mapActions(["clearAllData"]),
    toggleSlug(payload) {
      this.loading = true;
      const { slug, selected } = payload[0];
      if (selected) {
        this.addSlug(slug);
      } else {
        this.rmSlug(slug);
      }
    },
    skip(o) {
      return o.filter((o) => {
        return o.name != "produktkategori" && o.name != "rattvand-omvand";
      });
    },
    resetSelectedEb() {
      console.log("resetSelectedEb");
      this.$store.dispatch("setSelectedEb", null);
      this.resetSlugs();
    },
    loadMore() {
      this.$store.dispatch("productsMS/updateSkip");
    },
  },
  // beforeRouteEnter(to, from, next) {
  //   // Reset selected product
  //   if (to.name != "guide-filter-ms-edit") {
  //     store.dispatch("optionsMS/resetSlugs");
  //     store.dispatch("productMS/resetProduct").then(() => next());
  //     store
  //       .dispatch("productsMS/fetchProducts")
  //       .then(() => next())
  //       .catch((error) => {
  //         if (error.response && error.response.status == 404) {
  //           next({ name: "404", params: { resource: "event" } });
  //         } else {
  //           next({ name: "network-issue" });
  //         }
  //       });
  //   } else next();
  // },
  // beforeRouteUpdate(to, from, next) {
  //   store.dispatch("productsMS/fetchProducts").then(() => {
  //     next();
  //   });
  // },
  beforeRouteEnter(to, from, next) {
    // store.dispatch("optionsMS/resetSlugs");
    if ( store.getters["optionsMS/getDidFirstTimeCleanup"] === false ) {
      store.dispatch("optionsMS/fetchOptions", to.name)
    }
    // let numProducts = store.getters["productsMS/getProducts"].length
    // if (numProducts == 0 || to.name == "guide-filter-ms-start") {
    //   store.dispatch("productsMS/fetchProducts", store.state.optionsMS.selection)
    // }
    store.dispatch("productsMS/fetchProducts", store.state.optionsMS.selection)
    next();
  },
};
</script>

<style lang="scss" scoped></style>
