<template>
    <div>
        <b-tabs v-model="activetab" pills content-class="m-0" card>
            <b-tab :title="$t('Produkter')" no-body lazy>
                <AdminMsList :data="products" @on-deleted-item="fetchMs"></AdminMsList>
            </b-tab>
            <b-tab :title="$t('Kriterier')" no-body lazy>
                <AdminMsOptionsList></AdminMsOptionsList>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
    import AdminMsList from "@/components/admin/AdminMsList";
    import AdminMsOptionsList from "@/components/admin/AdminMsOptionsList";
    import ApiServiceAdmin from "@/services/ApiServiceAdmin";
    export default {
        components: {
            AdminMsList,
            AdminMsOptionsList,
            ApiServiceAdmin
        }, 
        data() {
            return {
                products: [],
                totalForSlug: {},
                activetab: 0
            }
        },
        methods: {
            fetchMs() {
                ApiServiceAdmin.getMs().then((response) => {
                    this.products = response.data;
                });
            },
        },
        mounted() {
            this.fetchMs();
        },
    }

</script>