<template>
  <form enctype="multipart/form-data" @submit.prevent="saveProductDocs">
    <country-flag :country="language == 'no' ? 'no' : 'se'" size="normal" class="ml-n5 mt-n1 float-right" />
    <ProductsListAsideDocs :product-docs="productDocs" @on-delete-image="deleteDoc(...arguments)"></ProductsListAsideDocs>
    <div class="my-3"><input :multiple="multiple" type="file" ref="productDocs" @change="selectProductDocs" /></div>
  </form>
</template>

<script>
  import ApiServiceUpload from "@/services/ApiServiceUpload";
  import ApiServiceAdmin from "../../../services/ApiServiceAdmin";
  import { MAX_SIZE, ALLOWED_DOC_TYPES } from "@/config";
  import ProductsListAsideDocs from "@/components/products/ProductsListAsideDocs";
  import AdminMessagesMixin from "../../../mixins/AdminMessagesMixin";

  export default {
    name: "UploadProductDocs",
    mixins: [AdminMessagesMixin],
    components: {
      ProductsListAsideDocs,
    },
    props: {
      forProductArt: { type: String, required: true },
      multiple: { type: Boolean, default: false },
      language: { type: String },
    },
    data() {
      return {
        files: [],
        productDocs: [],
      };
    },
    mounted() {
      this.getDocs(this.language);
    },
    methods: {
      deleteDoc(name, i) {

        ApiServiceAdmin.deleteDocByArt(this.forProductArt, name, this.language).then(async (response) => {
          console.log('this.language', this.language)
          this.$emit("on-deleted-image");
          this.productDocs.splice(i, 1);
          this.handleSuccess(response);
        });
      },
      async getDocs() {
        ApiServiceAdmin.getDocsForArt(this.forProductArt, this.language)
          .then(async (response) => {
            const data = await response.data;
            this.productDocs = data;
          })
          .catch((error) => console.error(error));
      },
      async selectProductDocs() {
        const filesFromInput = this.$refs.productDocs.files;
        this.files = await [...this.files, ...filesFromInput];
        this.saveProductDocs();
      },
      async saveProductDocs() {
        // Time to save those new images
        let formData = new FormData();
        this.files.forEach((file) => {
          formData.append("file", file);
        });
        try {
          await ApiServiceUpload.uploadFilesToArt(this.forProductArt, "docs", this.language, formData).then(async (response) => {
            // const data = await response.data.files;
            await this.getDocs(this.language);
            // this.$store.commit("ALERT", { msg: data.msg, variant: data.variant });
            this.handleSuccess(response);
          });
        } catch (error) {
          console.error(error);
        }
      },
      validate(file) {
        if (MAX_SIZE < file.size) {
          return "File is to big!";
        }
        if (!ALLOWED_DOC_TYPES.includes(file.type)) {
          return "Wrong format";
        }
        return "";
      },
    },
    watch: {
      language() {
        this.getDocs(this.language);
      },
    },
  };
</script>

<style lang="scss" scoped></style>
