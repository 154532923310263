<template>
  <b-container fluid class="bg-secondary py-4 px-2 mb-4 px-md-3 px-lg-4" v-if="show">
    <b-row no-gutters>
      <b-col>
        <b-container>
          <b-row no-gutters class="mt-0 mb-4 mt-md-0 mb-md-0">
            <b-col cols="12" md="auto">
              <h5 class="text-primary m-0 fw-600">{{ text ? text : $t("text.guideEB.criterion") }}</h5>  
            </b-col>
            <b-col cols="12" md="auto" class="mt-2 mt-md-0 align-items-center d-flex ml-auto mr-0">
              <b-button size="sm" class="mr-0 ml-auto w-100 w-md-auto rounded" variant="dark" :to="{ name: `guide-filter-${scope}-edit` }">Redigera</b-button>  
            </b-col>
          </b-row>
          <!-- <div class="d-flex pb-3 px-0">
            <h5 class="text-primary m-0 fw-600">{{ text ? text : $t("text.guideEB.criterion") }}</h5>
            <b-button size="sm" class="mr-0 ml-auto rounded" variant="dark" :to="{ name: `guide-filter-${scope}-edit` }">Redigera</b-button>
          </div> -->
        </b-container>
      </b-col>
    </b-row>
    <b-row no-gutters class="mx-lg-n1 mt-3">
      <b-col cols="12" md="6" lg="3" class="px-1 py-0 option-column" v-for="option in options" v-bind:key="option.name">
        <b-card no-body class="mb-1 border-0">
          <div block class="options-head" role="tab">{{ $t(option.name) }}</div>
          <div class="options-body">
            <b-card no-body class="border-0">
              <b-list-group class="border-0 list-group-flush">
                <b-list-group-item class="list-group-item slug px-2 py-1 text-dark" v-for="(slug, index) in option.slugs.filter((s) => selection.indexOf(s) != -1)" :key="index"> <b-icon-check-square-fill color="#C3C4BE" class="mr-1"></b-icon-check-square-fill> {{ $t(slug) }} </b-list-group-item>
              </b-list-group>
            </b-card>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
  name: "FilterSelection",
  props: {
    selection: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    show: {
      type: Boolean,
      default: true,
    },
    text: {
      type: String,
      default: "",
    },
    scope: {
      type: String,
      default: "em",
    },
    }
  };
</script>

<style lang="scss" scoped>
  @import "../../../../scss/custom.scss";
  h4 {
    font-weight: 600;
  }
  /* .disabled {
      opacity: 0.5;
      transition: background 400ms ease-in-out;
    } */
  .options-head {
    background-color: #c3c4be;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    min-height: 28px;
    display: flex;
    justify-content: space-between;
    padding: 8px;
  }
  .card {
    background-color: transparent;
  }
  .options-body {
    .card,
    .list-group-item {
      background-color: transparent;
      font-size: 14px;
    }
  }
  @include media-breakpoint-up(lg) {
    .option-column:not(:first-child) {
      border-left: $dark solid 1px !important;
    }
    .options-body {
      .card {
        height: auto;
        // max-height: 84px;
        overflow-y: auto;
      }
      display: block !important;
    }
    .options-head {
      .bi-chevron-down {
        display: none;
      }
    }
  }
</style>
