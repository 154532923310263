<template>
  <div class="py-5">
    <b-form novalidate id="edit" @submit="onSubmit" @reset="onReset" @change="onChange">
      <b-overlay :show="loading" rounded opacity="0.8" spinner-small spinner-variant="primary" @hidden="onOverlayHidden">
        <b-container>
          <h2>{{ title }}</h2>
          <b-row>
            <b-col cols="8">
              <b-form-row>
                <b-col>
                  <b-form-group :label="$t('form.namn')">
                    <b-form-input v-if="showOriginal" required id="sb-name" v-model="form.name"></b-form-input>
                    <b-input-group v-if="showTranslation">
                      <b-form-input required id="sb-name-no" v-model="form.translations.no.name"></b-form-input>
                      <template #append>
                        <country-flag country="no" size="normal" class="ml-n5" />
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group :label="$t('form.artnr')">
                    <input type="hidden" name="old_artnr" v-model="old.artnr" />
                    <b-form-input v-if="showOriginal" required id="sb-artnr" v-model="form.artnr"></b-form-input>
                    <b-input-group v-if="showTranslation">
                      <b-form-input required id="sb-artnr-no" v-model="form.translations.no.artnr"></b-form-input>
                      <template #append>
                        <country-flag country="no" size="normal" class="ml-n5" />
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group v-if="showOriginal" :label="$t('form.enr')" :description="$t('form.enr-help')">
                    <b-form-input required id="eb-enr" v-model="form.enr"></b-form-input>
                  </b-form-group>
                  <b-form-group v-if="showTranslation" :label="$t('form.nobnr')" :description="$t('form.enr-help')">
                    <b-input-group>
                      <b-form-input required id="eb-nobnr" v-model="form.nobnr"></b-form-input>
                      <template #append>
                        <country-flag country="no" size="normal" class="ml-n5" />
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row v-for="(info, i) in form.info" v-bind:key="`info-${i}`">
                <b-col>
                  <b-form-group :label="$t(info.title)">
                    <b-form-textarea v-if="showOriginal" rows="5" wrap="soft" :formatter="removeHtml" id="`info-${i}`" v-model="info.body"></b-form-textarea>
                    <b-input-group v-if="showTranslation">
                      <b-form-textarea rows="5" wrap="soft" :formatter="removeHtml" id="`no-info-${i}`" v-model="form.translations.no.info[0].body"></b-form-textarea>
                      <template #append>
                        <country-flag country="no" size="normal" class="ml-n5" />
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row v-if="showOriginal">
                <b-col>
                  <b-form-checkbox :disabled="!canPublishSE" v-model="form.published" switch>
                    {{ $t("text.admin.published") }} <small>{{ $t("text.admin.published-help") }}</small>
                  </b-form-checkbox>
                </b-col>
              </b-form-row>

              <div v-if="showTranslation">
                <b-form-row>
                  <b-col>
                    <b-form-checkbox :disabled="!canPublishNO" v-model="form.translations.no.published" switch>
                      {{ $t("text.admin.published") }} <small>{{ $t("text.admin.published-help") }}</small>
                    </b-form-checkbox>
                  </b-col>
                </b-form-row>
                <div class="mt-5" v-if="showTranslation">
                  <label>{{ $t("text.admin.sampletext-orig") }}</label>
                  <b-card class="shadow-sm" no-body bg-variant="light">
                    <div class="d-flex" style="justify-content: space-between">
                      <h3 class="m-3">{{ form.name }}</h3>
                      <div class="my-3 ml-auto">{{ form.artnr }}</div>
                      <div class="mt-2">
                        <country-flag country="se" size="normal" class="mr-0 ml-0" />
                      </div>
                    </div>
                    <div class="px-3">
                      <dl v-for="(info, i) in form.info" v-bind:key="`product-info-${i}`">
                        <div v-if="info.body">
                          <dt>{{ info.title }}</dt>
                          <dd v-html="info.body" style="white-space: pre-wrap"></dd>
                        </div>
                      </dl>
                    </div>
                  </b-card>
                </div>
              </div>

              <!-- Edit facts -->
              <div v-if="showOriginal" class="mt-5">
                <h3>{{ $t("text.fakta") }}</h3>
                <table class="table facts">
                  <tbody v-if="form.facts">
                    <tr>
                      <th>{{ $t("form.artnr") }}</th>
                      <td>{{ form.artnr }}</td>
                    </tr>
                    <tr v-for="(fact, i) in form.facts.filter((fact) => fact.name != 'passar-eltryckeslas-motorlas')" :key="`fact-${i}`">
                      <th width="40%">
                        {{ $t(fact.title) }}
                      </th>
                      <td>
                        <div v-if="fact.options">
                          <div v-if="Array.isArray(fact.value)">
                            <b-form-checkbox-group v-model="fact.value" :options="factsOptions[fact.title].options" :name="`fact-options-${i}`" @change="updateOld(fact)"></b-form-checkbox-group>
                          </div>
                          <div v-else>
                            <b-form-radio-group v-model="fact.value" :options="fact.options" :name="`fact-options-${i}`" @change="updateOld(fact)"></b-form-radio-group>
                          </div>
                        </div>
                        <div v-else>
                          <b-input-group>
                            <b-form-input v-show="fact.update && fact.update.pattern" style="max-width: 85px; text-align: right; padding-right: 2px" type="text" v-model="fact.value" @change="updateOld(fact)"></b-form-input>
                            <!-- <b-form-input v-show="fact.update && fact.update.pattern"  style="max-width: 85px; text-align: right; padding-right: 2px" type="text" :list="`${fact.name}-dropdown`" v-model="fact.value" @change="updateOld(fact)"></b-form-input> -->
                            <b-form-input v-show="fact.update && !fact.update.pattern" style="max-width: 270px" :list="`${fact.name}-dropdown`" v-model="fact.value" @change="updateOld(fact)"></b-form-input>
                            <div v-if="fact.dropdown">
                              <datalist :id="`${fact.name}-dropdown`" v-if="options[fact.name] && options[fact.name].slugs">
                                <option>...</option>
                                <option v-for="(opt, index) in options[fact.name].slugs" :key="index">{{ opt }}</option>
                              </datalist>
                              <b-button
                                variant="link"
                                class="text-danger"
                                v-show="fact.dropdown.indexOf(fact.value) != -1 && fact.value.length > 0"
                                @click="
                                  fact.dropdown.splice(fact.dropdown.indexOf(fact.value), 1);
                                  fact.value = '';
                                "
                                ><b-icon icon="dash-square"></b-icon
                              ></b-button>
                            </div>
                            <b-input-group-text v-if="fact.suffix">
                              {{ fact.suffix }}
                            </b-input-group-text>
                          </b-input-group>
                        </div>
                        <b-form-input v-if="fact.value == 'JA'" placeholder="fritext" v-model="fact.text" @change="updateOld(fact)"></b-form-input>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
            <b-col cols="4">
              <b-alert :show="form.artnr == ''" variant="warning"><b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> {{ $t("text.admin.alert-no-artnr") }}</b-alert>
              <b-form-group :label="$t('text.produktbild')" v-if="form.artnr">
                <b-form-row>
                  <b-col class="shadow-sm w-100 border">
                    <ProductImages :artnr="form.artnr" :key="uselessNumber" @on-deleted-image="uselessNumber--"></ProductImages>
                  </b-col>
                </b-form-row>
                <b-form-row class="mt-3" v-if="form.artnr && showOriginal">
                  <b-col>
                    <upload-product-images multiple :for-product-art="form.artnr" @on-select-product-image="setProductImageData" @on-uploaded-image="uploadDone(...arguments)"></upload-product-images>
                  </b-col>
                </b-form-row>
              </b-form-group>
              <!-- <div class="accordion" role="tablist" v-if="showOriginal">
                <b-card no-body border-variant="primary" header-bg-variant="primary" header-text-variant="white">
                  <b-card-header header-tag="header" class="p-0 bg-white" role="tab">
                    <b-button block v-b-toggle.eltryckeslas_motorlas variant="outline-primary">
                      {{ $t("text.admin.passar-till-eltryckeslas-motorlas") }}
                    </b-button>
                  </b-card-header>
                  <b-collapse id="eltryckeslas_motorlas" accordion="eltryckeslas_motorlas" role="tabpanel">
                    <b-card-body class="px-2 py-1">
                      {{ selectedEm }}
                      <div v-for="(em, i) in eltryckeslas_motorlas" :key="`ms-${i}`">
                        asd <b-form-checkbox v-model="selectedEm" :value="em.name" unchecked-value="">{{ em.name }}</b-form-checkbox>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div> -->
            </b-col>
          </b-row>
          <hr class="my-5" />
          <b-form-row v-if="form.artnr" class="border rounded p-3 mt-3 mb-4">
            <b-col>
              <b-form-group>
                <upload-product-docs :language="access_level == 2 ? 'no' : 'se'" v-if="form.artnr" multiple :docs="form.docs" :key="uselessNumber" :for-product-art="form.artnr" @on-select-product-docs="setProductDocs" @on-deleted-image="uselessNumber--"></upload-product-docs>
                <small>{{ $t("text.admin.ladda-upp-bilagor-max") }} {{ maxSize | fsize }}</small>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-row class="mt-4">
            <b-col>
              <b-button size="lg" type="button" :to="{ name: 'adm-dashboard' }" variant="outline-primary"><b-icon icon="chevron-left"></b-icon> {{ $t("text.admin.ga-till-lista") }}</b-button>
            </b-col>
            <b-col align="right">
              <b-button size="lg" type="submit" variant="primary">{{ $t("text.admin.spara") }}</b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-overlay>
    </b-form>
  </div>
</template>

<script>
  import ApiServiceAdmin from "@/services/ApiServiceAdmin";
  import AdminEditMixin from "@/mixins/AdminEditMixin";
  import AdminAccessMixin from "@/mixins/AdminAccessMixin";
  import _ from "lodash";

  let factsBaseArray = [
    { name: "passar-eltryckeslas-motorlas", title: "Passar eltryckeslås & motorlås", value: [] },
    { name: "sb-langd", title: "Längd", value: "", suffix: "mm", update: { name: "sb-langd", pattern: "sb-l-" } },
    { name: "sb-bredd", title: "Bredd", value: "", suffix: "mm", update: { name: "sb-bredd", pattern: "sb-b-" } },
    { name: "sb-plosmatt", title: "Plösmått", value: "", suffix: "mm", update: { name: "sb-plosmatt", pattern: "sb-p-" } },
    { name: "sb-plan-vinklad", title: "Funktion", value: [], options: ["sb-plan", "sb-vinklad"] },
    { name: "", title: "SSF 3522", value: "", options: ["JA", "NEJ"], text: "" },
  ];

  export default {
    name: "AdminSbEdit",
    mixins: [AdminEditMixin, AdminAccessMixin],
    components: {},
    data() {
      return {
        scope: "sb",
        language: process.env.VUE_APP_I18N_LOCALE,
        selectedEm: [],
        saveMsSpec: null,
        changedEm: [],
        selected: {
          "passande-eltryckeslas-motorlas": [],
        },
        factsOptions: {
          Funktion: {
            options: [
              { text: this.$t("sb-plan"), value: "sb-plan" },
              { text: this.$t("sb-vinklad"), value: "sb-vinklad" },
            ],
          },
        },
        facts: factsBaseArray,
        old: {
          name: "",
          artnr: "",
        },
        form: {
          name: "",
          artnr: "",
          enr: "",
          nobnr: "",
          published: false,
          spec: {},
          facts: factsBaseArray,
          info: [
            {
              title: "Användningsområde",
              body: "",
            },
          ],
        },
        options: [],
        eltryckeslas_motorlas: [],
        lhs: null,
        show: true,
        productsNB: [],
        optionsEM: [],
      };
    },
    computed: {
      canPublishSE() {
        return this.form.name && this.form.artnr && this.form.info[0].body;
      },
      canPublishNO() {
        return this.form.translations && this.form.translations.no.name && this.form.translations.no.artnr && this.form.translations.no.info[0].body;
      },
      title: {
        get() {
          return this.form.name ? this.form.name : this.$t("text.admin.add-eb");
        },
      },
      lockids() {
        // Return array with unique IDs concatinated from spec arrays
        let concatArray = [];
        for (const name in this.form.spec) {
          concatArray = [...new Set([...concatArray, ...this.form.spec[name]])];
        }
        return concatArray;
      },
      slugs() {
        return Object.keys(this.form.spec);
      },
      allSelected() {
        return this.selected;
      },
    },
    methods: {
      updateRelatedEmSpec(em, checked) {
        
        const ebname = this.form.name;

        if (em.spec) {
          // Set or remove ebname from em.spec
          if (checked && em.spec[ebname] === undefined) {
            // Add ebname to em.spec
            em.spec[ebname] = [];
          } else {
            // Remove ebname from em.spec
            if (em.spec[ebname]) {
              delete em.spec[ebname];
            }
          }
        } else {
          // em.spec could be null because of an old error, but should NEVER be null now
          console.log(`[spec] is null for "${em.name}""`);
          console.log("ACTIVITY shold be written here");
        }

        // Update facts value
        const passarEms = this.getFactByName("passar-eltryckeslas-motorlas")[0].value;
        if (checked && passarEms.indexOf(em.name) === -1) {
          passarEms.push(em.name);
        } else if (!checked && passarEms.indexOf(em.name) !== -1) {
          passarEms.splice(passarEms.indexOf(em.name), 1);
        }

        // Fetch monteringstolpar or update spec value in object
        // this.fetchMs();

        // Update ms in db

        // Add MS to list of items should be updated on save
        this.changedEm.push(em);

        // ApiServiceAdmin.updateMsById(ms._id, _.pick(ms, ["artnr", "name", "spec", "published", "facts", "info", "translations"]))
        //   .then((response) => {
        //     this.fetchMs();
        //     this.updateEb();
        //     this.handleSuccess(response);
        //   })
        //   .catch(this.handleError);
      },
      updateOld(fact) {
        if (fact.update && fact.update.name) {
          if (Array.isArray(fact.value)) {
            // Value is array, need to handle separate values
            this.selected[fact.update.name] = [this.form.artnr];
            fact.value.forEach((val) => {
              this.selected[fact.update.name].push(this.getTokenizedValue(val, fact.update, fact.update.pattern));
            });
          } else if (this.selected[fact.update.name]) {
            this.selected[fact.update.name][0] = this.getTokenizedValue(fact.value, fact.update, fact.update.pattern);
          }
        }
      },
      uploadDone() {
        this.uselessNumber++;
      },
      updateSbSpecObject(spec) {
        let merge = _.mergeWith(this.form.spec, spec, (objValue, srcValue) => {
          if (_.isArray(objValue)) {
            return objValue.concat(srcValue);
          }
        });
        this.form.spec = merge;
      },
      async updateRelatedEms() {
        // Update all related ebs
        if (this.updatedEbs.length) {
          this.updatedEbs.forEach((eb) => {
            ApiServiceAdmin.updateEbById(eb._id, { name: eb.name, artnr: eb.artnr, spec: eb.spec, facts: eb.facts, info: eb.info, published: eb.published, translations: eb.translations });
          });
        }
      },
      async initFormData() {
        if (this.$route.params.id != "add") {
          try {
            await ApiServiceAdmin.getSbDataById(this.id, []).then((response) => {
              this.form = _.pick(response.data, ["name", "artnr", "enr", "nobnr", "spec", "info", "facts", "published", "translations", "ems"]);

              // Clean spec object from empty arrays
              this.form.spec = _.omitBy(this.form.spec, _.isEmpty)

              // spec should never be null
              // console.log("check spec", !_.some(this.form.spec, _.isEmpty));
              // if (!_.some(this.form.spec, _.isEmpty)) {
              //   this.form.spec = this.getDefaultSpecObjFor(this.scope, this.form.spec);
              //   console.log("Set default spec", this.form.spec);
              // }

              // Set old.artnr to keep track of changes
              this.old.artnr = this.form.artnr;

              // Merge static facts defined in this document with facts fetched from db
              // let formFacts = JSON.parse(JSON.stringify(this.form.facts));
              // const staticFacts = JSON.parse(JSON.stringify(this.facts));
              // this.form.facts = _.unionBy(formFacts, staticFacts, "title");
            });
            // Set old.name to keep track of changes
            this.old.name = this.form.name;
            // init selected ms
            const passarEms = this.getFactByName("passar-eltryckeslas-motorlas")[0];
            this.selectedEm = passarEms.value;
          } catch (error) {
            console.log(error);
          }
          try {
            ApiServiceAdmin.getSbOptions().then(async (response) => {
              const data = await response.data;
              data.forEach((option) => {
                this.options[option.name] = {
                  _id: option._id,
                  name: option.name,
                  slugs: option.slugs,
                };
              });
              ApiServiceAdmin.getEm() // Passande eltryckeslås & motorlås
                .then(async (response) => {
                  const data = await response.data;
                  this.options["passande-eltryckeslas-motorlas"] = _.map(data, (em) => em.name);
                  this.eltryckeslas_motorlas = data;
                  this.initSelected();
                })
                .catch((error) => console.error("getEm", error));
            });

            // const arrWithEbNames = _.map(ebs.data, (eb) => eb.name);
            // Get options
          } catch (error) {
            console.error(error);
          }
        }
      },
      onChange() {
        const allValuesArray = _.filter(this.allSelected, (x) => x.length > 0);
        const allValuesFlattened = _.compact(_.flatten(allValuesArray));
        let emptyValues = new Array();
        for (let i = 0; i < allValuesFlattened.length; i++) {
          emptyValues.push([this.form.artnr]);
        }
        const newSpec = _.zipObject(allValuesFlattened, emptyValues);
        this.form.spec = newSpec;
      },
      saveNewSb() {
        if (!_.some(this.form.spec, _.isEmpty)) {
          this.form.spec = this.getDefaultSpecObjFor(this.scope, this.form.spec);
          console.log("Set default spec", this.form.spec);
        }
        // const payload = JSON.stringify(this.form);
        const payload = _.pick(this.form, ["artnr", "enr", "nobnr", "name", "spec", "published", "facts", "info", "translations"]);

        ApiServiceAdmin.addSb(payload)
          .then((response) => {
            this.$router.push(`/admin/sb/${response.data.result._id}`);
            this.id = response.data.result._id;
            this.handleSuccess(response);
          })
          .catch(this.handleError);
      },
      getTokenizedValue(value, update, pattern) {
        const val = String(value);
        if (val && update && pattern) {
          let tokenizedValue = pattern + val.replace(".", "-") + (val.indexOf(".") == -1 ? "-0" : "");
          return tokenizedValue;
        }
        return val;
      },
      updateSb() {
        ApiServiceAdmin.updateSbById(this.id, JSON.stringify(this.form), 1) // [id], [payload], [loglevel]
          .then((response) => {
            // Make sure that spec is not null
            // if (!_.some(this.form.spec, _.isEmpty)) {
            //   this.form.spec = this.getDefaultSpecObjFor(this.scope, this.form.spec);
            //   console.log("Set default spec", this.form.spec);
            // }
            // Update folder name in GCS
            if (this.form.artnr != this.old.artnr) {
              ApiServiceAdmin.updateFolderByArt(this.form.artnr, this.old.artnr)
                .then(() => {
                  this.old.artnr = this.form.artnr;
                  // this.makeToast({ variant: "info", title: this.$t("messages.post-update-artnr"), text: this.$t("messages.post-save") });
                })
                .catch(this.handleError);
            }
            // Use list of updated related MS to update them
            if (this.changedEm.length) {
              this.changedEm.forEach((em) => {
                ApiServiceAdmin.updateEmById(em._id, _.pick(em, ["artnr", "name", "spec", "published", "facts", "info", "translations", "elslutbleck", "slutbleck", "nodutrymningsbeslag"]))
                  .then((response) => {
                    this.changedEm = [];
                    this.handleSuccess(response);
                  })
                  .catch(this.handleError);
              });
            }
            this.old.name = this.form.name;
            this.handleSuccess(response);
          })
          .catch(this.handleError);
      },
      async onSubmit(event) {
        event.preventDefault();

        const unique_enr = await this.isUnique('enr', this.form.enr, this.id)
        const unique_nobnr = await this.isUnique('nobnr', this.form.nobnr, this.id)
        if ( !unique_enr || !unique_nobnr ) {
          // Prevent saving if not unique
          this.handleError('E11000_DUPLICATE_KEY')
          return false;
        }
        this.loading = true;
        if (!this.id) {
          this.saveNewSb();
        } else {
          this.updateSb();
        }
        // If new value does not exist in options, we need to add it
        this.form.facts
          .filter((fact) => fact.update)
          .forEach((fact) => {
            // console.log("this.options[fact.name].indexOf(this.getTokenizedValue(fact))", this.options[fact.name].indexOf(this.getTokenizedValue(fact)));
            if (fact.value && this.options[fact.name] && this.options[fact.name].slugs.indexOf(this.getTokenizedValue(fact.value, fact.update, fact.update.pattern)) == -1 && this.getTokenizedValue(fact.value, fact.update, fact.update.pattern) != "") {
              this.options[fact.name].slugs.push(this.getTokenizedValue(fact.value, fact.update, fact.update.pattern));
              ApiServiceAdmin.updateSbOptionsById(this.options[fact.name]._id, _.pick(this.options[fact.name], ["name", "slugs"]))
                .then(this.handleSuccess)
                .catch(this.handleError);
            }
          });
      },
      cleanSpecObjFrom(spec, startstr) {
        if (!startstr || !spec) return {};
        return _.omitBy(spec, (s, key) => {
          return key.indexOf(startstr) === 0;
        });
      },
      async initSelected() {
        let optionsKeys = await _.keysIn(this.options);

        for (let i = 0; i < optionsKeys.length; i++) {
          const key = optionsKeys[i];
          const factWithName = _.filter(this.form.facts, (f) => f.name == key)[0];
          if (factWithName !== undefined) {
            if (Array.isArray(factWithName.value)) {
              this.selected[key] = [];
              factWithName.value.forEach((slug) => {
                this.form.spec[slug] = [this.form.artnr];
                this.selected[key].push(slug);
              });
            } else {
              // Numbers
              const tokenizedKey = this.getTokenizedValue(factWithName.value, factWithName.update, factWithName.update.pattern);
              this.form.spec[tokenizedKey] = [this.form.artnr];
              this.selected[key] = [tokenizedKey];
            }
          }
          if (key == "passande-eltryckeslas-motorlas") {
            let passandeElslutblecks = _.intersection(this.options["passande-eltryckeslas-motorlas"], _.keysIn(this.form.spec));
            this.selected[key] = passandeElslutblecks;
          }
        }
      },
      getFactByName(name) {
        return this.form.facts ? this.form.facts.filter((fact) => fact.name == name) : false;
      },
      fetchEm() {
        ApiServiceAdmin.getEm()
          .then(async (response) => {
            this.eltryckeslas_motorlas = response.data;
            this.handleSuccess(response);
            // this.eltryckeslas_motorlas = await _.map(response.data, (ms) => {
            //   // if (fact != null && fact.value.indexOf(ms.name) != -1) {
            //   //   this.selectedEm.push(ms);
            //   // }
            //   console.log("return MS");
            //   return ms;
            // });
          })
          .catch((err) => console.error(err));
      },
      async fetchNb() {
        this.productsNB = await ApiServiceAdmin.getNb().then((response) => response.data);
      },
      async fetchEmOptions() {
        const emOptions = await ApiServiceAdmin.getEmOptions().then((response) => response.data);
        this.optionsEM = _.flatten(_.valuesIn(_.map(emOptions, (o) => o.slugs)));
      },
    },
    mounted() {
      const fact = this.getFactByName("passar-eltryckeslas-motorlas");
      console.log("fact", fact);
      this.fetchEm();
      this.fetchNb();
      this.fetchEmOptions();
      // this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
      //   if (isJustShown) {
      //     // this.selectedEm = [];
      //   }
      // });
    },
    watch: {
      "form.name": function (newname, oldname) {
        console.log(newname, oldname);

        // this.selectedEm = val;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../../scss/_colors.scss";
  #eltryckeslas_motorlas {
    max-height: 400px;
    overflow: auto;
  }
  table {
    th,
    td {
      border-width: 2px;
      border-color: white;
    }
    th {
      background-color: $primary;
      color: $white;
    }
    td {
      background-color: $gray-100;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        white-space: nowrap;
      }
    }
  }
</style>
