<template>
  <footer class="bg-white d-print-none" style="z-index: 1">
    <!-- <b-row>
      <b-col cols="2" class="mx-auto d-flex justify-content-between" align="center">
        <b-link class="text-dark"><b-icon-linkedin scale="1.6"></b-icon-linkedin></b-link>
        <b-link class="text-dark"><b-icon-facebook scale="1.6"></b-icon-facebook></b-link>
        <b-link class="text-dark"><b-icon-instagram scale="1.6"></b-icon-instagram></b-link>
        <b-link class="text-dark"><b-icon-youtube scale="1.6"></b-icon-youtube></b-link>
      </b-col>
    </b-row>
    <hr class="border-dark" />
    <b-row>
      <b-col cols="3" class="mx-auto d-flex justify-content-between" align="center">
        <b-button variant="secondary">Om oss</b-button>
        <b-button variant="secondary">Press</b-button>
        <b-button variant="secondary">Kontakt</b-button>
      </b-col>
    </b-row> -->
    <hr class="border-dark" />
    <b-row>
      <b-col align="center">
        <!-- <span class="legal text-dark">© ASSA ABLOY<span class="dot">•</span><b-link>Legal</b-link><span class="dot">•</span><b-link>Hantering av personuppgifter</b-link></span> -->
        <span class="legal text-dark">© ASSA ABLOY</span>
      </b-col>
    </b-row>
  </footer>
</template>

<script>
export default {
  name: "footer-section",
};
</script>

<style lang="scss" scoped>
footer {
  // padding-bottom: 75px;
  padding-bottom: 0;
  max-width: 100vw;
  overflow: hidden;
  > .row {
    padding: 40px 0;
  }
  > hr {
    margin: 0 80px;
  }
  button {
    font-weight: 700;
    padding: 0 24px;
    border: 0;
    line-height: 48px;
    white-space: nowrap;
  }
  .legal {
    font-size: 16px;
    font-weight: 700;
    a {
      color: inherit;
    }
    .dot {
      margin: 0 20px;
    }
  }
}
</style>
