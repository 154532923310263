<template>
  <div id="products">
    <slot name="ingress"></slot>
    <!-- We have results -->
    <div class="accordion" role="tablist">
      <b-row>
        <b-col cols="12" lg="5" class="d-print-none">
          <div v-for="(product, index) in products" v-bind:key="`${product.artnr}-${index}`">
            <ProductsListItem v-bind="product" :ref="`product-list-item-${product._id}`" :active="selectedProductData != null && product._id == selectedProductData._id" @on-product-select="$emit('on-product-select', product._id)"></ProductsListItem>
          </div>
          <b-button v-if="hasMore" block variant="outline-primary" size="lg" style="margin-bottom: 10px" class="load-more bg-white text-primary justify-content-between align-items-center d-flex" @click="$emit('on-click-load-more')" v-html="$t('text.ui.loadmore', { total, count: products.length })"></b-button>
          <ProductInfoModal id="selectedProductInfoModal" v-bind="selectedProductData" :product-docs="selectedProductDocs" :scope="scope"></ProductInfoModal>
        </b-col>
        <b-col cols="12" md="7" class="pl-md-0 mb-2 d-none d-lg-block bg-white product-info-container p-0 d-print-block">
          <b-collapse visible id="productInfo" class="sticky-to-header">
            <b-button block v-show="!selectedProductData" size="lg" icon="chevron-left" variant="primary" style="font-size: 30px; font-weight: normal" class="py-3 pr-5 text-left w-100 w-md-auto text-center mb-2">
              <b-icon icon="chevron-left" class="mr-3 d-none d-md-inline"></b-icon>
              <b-icon icon="chevron-up" class="mr-3 d-block w-100 d-md-none"></b-icon>
              {{ $t("text.ui.select-product") }}
            </b-button>
            <transition name="fade">
              <ProductsListAside v-if="!!selectedProductData" :product-data="selectedProductData" :product-docs="selectedProductDocs" :scope="scope"></ProductsListAside>
            </transition>
          </b-collapse>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import ProductsListItem from "./ProductsListItem";
  import ProductsListAside from "./ProductsListAside";
  import ProductInfoModal from "@/components/products/ProductInfoModal";
  export default {
    name: "ProductsList",
    props: {
      selectedProductData: { type: Object },
      selectedProductDocs: { type: Array },
      products: { type: Array },
      scope: { type: String },
      ui: { type: Object },
      total: { type: Number },
    },
    computed: {
      hasMore() {
        return this.total > this.products.length && this.products.length >= 20;
      },
    },
    components: {
      ProductsListItem,
      ProductsListAside,
      ProductInfoModal,
    },
    data() {
      return {
        API_HOST: process.env.VUE_APP_DBHOST,
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../../scss/custom.scss";
  .product-info-container {
    z-index: 1020;
  }
  p {
    margin-bottom: 34px;
  }
  .load-more {
  }

  .sticky-to-header {
    position: sticky;
    top: 87px;
  }
</style>
