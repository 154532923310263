<template>
  <div>
    <form @change.prevent="$emit('on-change-slug', ebs)">
      <table class="table table-sm table-hover table-bordered table-responsive shadow rounded">
        <thead class="thead-dark">
          <tr>
            <th scope="col" class="text-nowrap th-sticky-left">{{ $t("text.admin.elslutbleck") }}</th>
            <th scope="col" class="text-nowrap" v-for="o in options" :key="o">{{ $t(o) }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(eb, index) in ebs" :key="`row${index}`">
            <th scope="row" class="text-left text-nowrap th-sticky-left px-2">{{ eb.name }}</th>
            <td v-for="(o, j) in options" :key="`opt${j}`" align="center">
              <b-form-checkbox :name="o" :value="lockid" v-if="eb.spec" v-model="eb.spec[o]"></b-form-checkbox>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</template>

<script>
  import ApiServiceAdmin from "@/services/ApiServiceAdmin";
  const _ = require("lodash");
  export default {
    name: "AdminLhOptionsEdit",
    props: ["lhid", "lhebs"],
    data() {
      return {
        lockid: null,
        spec: null,
        ebs: [],
        options: [],
      };
    },
    computed: {
      tableRows() {
        const populatedTr = _.map(this.ebs, (eb) => {
          if (this.lhebs && this.lhebs.filter((_lhebs) => _lhebs.artnr == eb.artnr).length) {
            return { _id: eb._id, name: eb.name, artnr: eb.artnr, spec: eb.spec };
          }
          return { _id: eb._id, name: eb.name, artnr: eb.artnr, spec: {} };
        });
        return populatedTr;
      },
    },
    mounted() {
      this.fetchLh();
    },
    methods: {
      async fetchLh() {
        try {
          this.ebs = await ApiServiceAdmin.getEb().then((response) => {
            return response.data;
          });
          this.options = await ApiServiceAdmin.getEbOptions().then((response) => {
            return _.flatten(_.valuesIn(_.map(response.data, (o) => o.slugs)));
          });

          if (this.lhid !== null) {
            const lh = await ApiServiceAdmin.getLhDataById(this.lhid);
            this.lockid = lh.data.lockid;
            this.spec = _.valuesIn(_.map(this.lhebs, (e) => e.spec));
          } else {
            this.spec = {};
            this.options.forEach((o) => (this.spec[o] = []));
          }
        } catch (error) {
          console.error(error);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  thead > tr > th,
  tbody > tr > th {
    font-size: 14px;
    padding: 0.25em 1em;
  }
  .th-sticky-left {
    position: sticky;
    left: 0;
    box-shadow: inset -1px 0 1px rgb(0 0 0 / 10%);
  }
  tbody .th-sticky-left {
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 999;
  }
</style>
