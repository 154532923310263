<template>
  <div>
    <b-form-group>
      <legend class="col-form-label font-weight-bold mb-2">{{ $t("form.documents") }}</legend>
      <b-row cols="2">
        <b-col cols="12" sm="auto" v-for="(doc, index) in productDocs" :key="index">
          <b-media class="mb-3">
            <template #aside>
              <b-img :src="doc.icon" width="36" alt="Document type"></b-img>
            </template>
            <a :href="encodeURI(doc.url)">
              <h6 class="m-0 text-primary text-truncate" style="max-width: 50%">{{ decodeURIComponent(doc.name) }}</h6>
            </a>
            <small>({{ doc.ext }}, {{ doc.size | fsize }})</small>
            <b-link v-if="$store.state.user.user !== null" variant="link" class="text-danger ml-5" @click="$emit('on-delete-image', doc.name, index)"><small>Ta bort</small></b-link>
            <!-- <small class="badge badge-info ml-2">{{ doc.validateMessage }}</small> -->
          </b-media>
        </b-col>
      </b-row>
    </b-form-group>
  </div>
</template>

<script>
  import futils from "@/mixins/futils";
  export default {
    name: "ProductListAsideDocs",
    mixins: [futils],
    props: ["productDocs"],
  };
</script>

<style lang="scss" scoped></style>
