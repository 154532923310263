import { render, staticRenderFns } from "./AdminLhList.vue?vue&type=template&id=04258d1d&scoped=true"
import script from "./AdminLhList.vue?vue&type=script&lang=js"
export * from "./AdminLhList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04258d1d",
  null
  
)

export default component.exports