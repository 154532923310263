<template>
  <div class="py-5">
    <b-form
      novalidate
      id="edit"
      @submit="onSubmit"
      @reset="onReset"
    >
      <b-overlay
        :show="loading"
        rounded
        opacity="0.8"
        spinner-small
        spinner-variant="primary"
        @hidden="onOverlayHidden"
      >
        <b-container>
          <h2>{{ title }}</h2>
          <b-row>
            <b-col cols="8">
              <b-form-row>
                <b-col>
                  <b-form-group :label="$t('form.namn')">
                    <b-form-input
                      v-if="showOriginal"
                      required
                      id="em-name"
                      v-model="form.name"
                    ></b-form-input>
                    <b-input-group v-if="showTranslation">
                      <b-form-input
                        required
                        id="em-name-no"
                        v-model="form.translations.no.name"
                      ></b-form-input>
                      <template #append>
                        <country-flag
                          country="no"
                          size="normal"
                          class="ml-n5"
                        />
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group :label="$t('form.artnr')">
                    <input type="hidden" name="old_artnr" v-model="old.artnr" />
                    <b-form-input v-if="showOriginal" required id="em-artnr" v-model="form.artnr"></b-form-input>
                    <b-input-group v-if="showTranslation">
                      <b-form-input
                        required
                        id="em-artnr-no"
                        v-model="form.translations.no.artnr"
                      ></b-form-input>
                      <template #append>
                        <country-flag
                          country="no"
                          size="normal"
                          class="ml-n5"
                        />
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group v-if="showOriginal" :label="$t('form.enr')" :description="$t('form.enr-help')">
                    <b-form-input required id="eb-enr" v-model="form.enr"></b-form-input>
                  </b-form-group>
                  <b-form-group v-if="showTranslation" :label="$t('form.nobnr')" :description="$t('form.nobnr-help')">
                    <b-input-group>
                      <b-form-input required id="eb-nobnr" v-model="form.nobnr"></b-form-input>
                      <template #append>
                        <country-flag country="no" size="normal" class="ml-n5" />
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row
                v-for="(info, i) in form.info"
                v-bind:key="`info-${i}`"
              >
                <b-col>
                  
                  <b-form-group :label="$t(info.title)">
                    <b-form-textarea
                      v-if="showOriginal"
                      rows="5"
                      wrap="soft"
                      :formatter="removeHtml"
                      :id="`info-${i}`"
                      v-model="info.body"
                    ></b-form-textarea>
                    <b-input-group v-if="showTranslation">
                      <b-form-textarea
                        rows="5"
                        wrap="soft"
                        :formatter="removeHtml"
                        :id="`no-info-${i}`"
                        v-model="form.translations.no.info[i].body"
                      ></b-form-textarea>
                      <template #append>
                        <country-flag
                          country="no"
                          size="normal"
                          class="ml-n5"
                        />
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row v-if="showOriginal">
                <b-col>
                  <b-form-checkbox
                    :disabled="!canPublishSE"
                    v-model="form.published"
                    switch
                  >
                    {{ $t("text.admin.published") }}
                    <small>{{ $t("text.admin.published-help") }}</small>
                  </b-form-checkbox>
                </b-col>
              </b-form-row>

              <div v-if="showTranslation">
                <b-form-row>
                  <b-col>
                    <b-form-checkbox
                      :disabled="!canPublishNO"
                      v-model="form.translations.no.published"
                      switch
                    >
                      {{ $t("text.admin.published") }}
                      <small>{{ $t("text.admin.published-help") }}</small>
                    </b-form-checkbox>
                  </b-col>
                </b-form-row>
                <div class="mt-5" v-if="showTranslation">
                  <label>{{ $t("text.admin.sampletext-orig") }}</label>
                  <b-card class="shadow-sm" no-body bg-variant="light">
                    <div class="d-flex" style="justify-content: space-between">
                      <h3 class="m-3">{{ form.name }}</h3>
                      <div class="my-3 ml-auto">{{ form.artnr }}</div>
                      <div class="mt-2">
                        <country-flag
                          country="se"
                          size="normal"
                          class="mr-0 ml-0"
                        />
                      </div>
                    </div>
                    <div class="px-3">
                      <dl
                        v-for="(info, i) in form.info"
                        v-bind:key="`product-info-${i}`"
                      >
                        <div v-if="form.info[i].body">
                          <dt>{{ form.info[i].title }}</dt>
                          <dd
                            v-html="form.info[i].body"
                            style="white-space: pre-wrap"
                          ></dd>
                        </div>
                      </dl>
                    </div>
                  </b-card>
                </div>
              </div>

              <!-- Edit facts -->
              <div v-if="showOriginal" class="mt-5">
                <h3>{{ $t("text.fakta") }}</h3>
                <table class="table facts">
                  <tbody v-if="form.facts">
                    <tr
                      v-for="(fact, i) in form.facts.filter(
                        (fact) =>
                          fact.name != 'passar-slutbleck' &&
                          fact.name != 'passar-elslutbleck'
                      )"
                      :key="`fact-${i}`"
                    >
                      <th>{{ $t(fact.title) }}</th>
                      <td>
                        <div v-if="fact.options">
                          <div v-if="Array.isArray(fact.value)">
                            <b-form-checkbox-group
                              v-model="fact.value"
                              :options="getOptionsObj(fact.options)"
                              :name="`fact-options-${i}`"
                            ></b-form-checkbox-group>
                          </div>
                          <div v-else>
                            <b-form-radio-group
                              v-model="fact.value"
                              :options="fact.options"
                              :name="`fact-options-${i}`"
                            ></b-form-radio-group>
                          </div>
                        </div>
                        <div v-else>
                          <b-input-group :append="fact.suffix">
                            <b-form-input v-model="fact.value"></b-form-input>
                          </b-input-group>
                        </div>
                        <b-form-input
                          v-if="fact.value == 'JA'"
                          placeholder="fritext"
                          v-model="fact.text"
                        ></b-form-input>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
            <b-col cols="4">
              <b-alert :show="form.artnr == ''" variant="warning"
                ><b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill>
                {{ $t("text.admin.alert-no-artnr") }}</b-alert
              >
              <b-form-group :label="$t('text.produktbild')" v-if="form.artnr">
                <b-form-row>
                  <b-col class="shadow-sm w-100 border">
                    <ProductImages
                      :artnr="form.artnr"
                      :key="uselessNumber"
                      @on-deleted-image="uselessNumber--"
                    ></ProductImages>
                  </b-col>
                </b-form-row>
                <b-form-row class="mt-3" v-if="form.artnr && showOriginal">
                  <b-col>
                    <upload-product-images
                      multiple
                      :for-product-art="form.artnr"
                      @on-select-product-image="setProductImageData"
                      @on-uploaded-image="uploadDone(...arguments)"
                    ></upload-product-images>
                  </b-col>
                </b-form-row>
              </b-form-group>
            </b-col>
          </b-row>
          <hr class="my-5" />
          <b-form-row v-if="form.artnr" class="border rounded p-3 mt-3 mb-4">
            <b-col>
              <b-form-group>
                <upload-product-docs
                  :language="access_level == 2 ? 'no' : 'se'"
                  v-if="form.artnr"
                  multiple
                  :docs="form.docs"
                  :key="uselessNumber"
                  :for-product-art="form.artnr"
                  @on-select-product-docs="setProductDocs"
                  @on-deleted-image="uselessNumber--"
                ></upload-product-docs>
                <small
                  >{{ $t("text.admin.ladda-upp-bilagor-max") }}
                  {{ maxSize | fsize }}</small
                >
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-row class="mt-4">
            <b-col>
              <b-button
                size="lg"
                type="button"
                :to="{ name: 'adm-dashboard' }"
                variant="outline-primary"
                ><b-icon icon="chevron-left"></b-icon>
                {{ $t("text.admin.ga-till-lista") }}</b-button
              >
            </b-col>
            <b-col align="right">
              <b-button size="lg" type="submit" variant="primary">{{
                $t("text.admin.spara")
              }}</b-button>
            </b-col>
          </b-row>
        </b-container>
        <div
          v-if="showOriginal && id && optionsEM.length && form.filter.length"
        >
          <hr class="mt-5 mb-0" />
          <b-container fluid class="bg-light p-md-5 px-sm-1 py-sm-3" style="overflow-x: auto;">
            <b-row>
              <b-col class="px-0 px-0 col-auto mx-auto text-center">
                <h2 class="mb-5">
                  {{ $t("text.admin.kriterier-for") }} {{ form.name }}
                </h2>
                <AdminFilterEditTable
                  :filter="form.filter"
                  :options="optionsEM"
                  :selectedSb="selectedSb"
                  :selectedEb="selectedEb"
                  :factsSb="factsSb"
                  :factsEb="factsEb"
                  @on-add-row="filterAddRow()"
                  @on-update-row="filterUpdateRow(...arguments);"
                  @on-change-slug="updateEmFilterArray(...arguments)"
                  @on-update-selected-products="updateSelectedProducts(...arguments)"
                ></AdminFilterEditTable>
                <pre v-show="debug">{{ this.form.facts }}</pre>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-overlay>
    </b-form>
  </div>
</template>

<script>
import ApiServiceAdmin from "@/services/ApiServiceAdmin";
import AdminFilterEditTable from "@/components/admin/AdminFilterEditTable.vue";
// import AdminOptionsEditTable from "@/components/admin/AdminOptionsEditTable.vue";
import AdminEditMixin from "@/mixins/AdminEditMixin";
import AdminAccessMixin from "@/mixins/AdminAccessMixin";
import ProductImages from "@/components/products/ProductImages.vue";
import _ from "lodash";

export default {
  name: "AdminEmEdit",
  mixins: [AdminEditMixin, AdminAccessMixin],
  components: {
    AdminFilterEditTable,
    ProductImages
  },
  data() {
    return {
      debug: false,
      language: process.env.VUE_APP_I18N_LOCALE,
      selectedSb: [],
      selectedEb: [],
      saveSbSpec: null,
      saveEbSpec: null,
      changedSb: [],
      changedEb: [],
      updatedSbs: [],
      updatedEbs: [],
      updatedNbs: [],
      old: {
        name: "",
        artnr: "",
      },
      form: {
        name: "",
        artnr: "",
        enr: "",
        nobnr: "",
        published: false,
        filter: [],
        elslutbleck: [],
        slutbleck: [],
        nodutrymningsbeslag: [],
        info: [
          { title: "Användningsområde", body: "" },
          { title: "Funktion", body: "" },
          { title: "Egenskaper", body: "" },
          { title: "Inkoppling", body: "" },
          { title: "Leveransalternativ", body: "" },
        ],
        facts: [
          { name: "passar-slutbleck", title: "Passar slutbleck", value: [] },
          { title: "Dorndjup (mm)", value: "", suffix: "mm" },
          {
            title: "Rättvänd eller omvänd funktion",
            value: [],
            options: ["em-oro-omstallbar", "em-oro-rattvand", "em-oro-omvand"],
          },
          {
            title: "Split spindel eller enkel funktion",
            value: [],
            options: [
              "em-sse-split-spindel",
              "em-sse-enkel",
              "em-sse-omstallbar",
            ],
          },
          {
            title: "CE-märkt för branddörr",
            value: "",
            options: ["JA", "NEJ"],
          },
          { title: "Spänning", value: "" },
          { title: "Strömförbrukning vid drift 24V:", value: "", suffix: "mA" },
          { title: "Strömförbrukning vid drift 12V:", value: "", suffix: "mA" },
          { title: "Strömförbrukning stand by 24V", value: "", suffix: "mA" },
          { title: "Strömförbrukning stand by 12V", value: "", suffix: "mA" },
          { title: "Hi-O", value: "", options: ["JA", "NEJ"] },
          {
            title: "Durability enligt SS-EN 14846",
            value: "",
            options: ["JA", "NEJ"],
            text: "",
          },
          {
            title: "Corrosion enligt SS-EN 14846",
            value: "",
            options: ["JA", "NEJ"],
            text: "",
          },
          {
            title: "Security enligt SS-EN 14846",
            value: "",
            options: ["JA", "NEJ"],
            text: "",
          },
          {
            title: "Security electrical manipulation enligt SS-EN 14846",
            value: "",
            options: ["JA", "NEJ"],
            text: "",
          },
          { title: "Låsklass SSF 3522", value: "" },
          {
            title: "Certifiering Larmklass",
            value: "",
            options: ["JA", "NEJ"],
          },
          { title: "SS-EN 179", value: "", options: ["JA", "NEJ"] },
          { title: "SS-EN 1125", value: "", options: ["JA", "NEJ"] },
        ],
      },
      slutbleck: [],
      elslutbleck: [],
      nodutrymningsbeslag: [],
      options: [],
      show: true,
      productsNB: [],
      productsSB: [],
      productsEB: [],
      optionsEM: [],
    };
  },
  computed: {
    canPublishSE() {
      return this.form.name && this.form.artnr && this.form.info[0].body;
    },
    canPublishNO() {
      return (
        this.form.translations &&
        this.form.translations.no.name &&
        this.form.translations.no.artnr &&
        this.form.translations.no.info[0].body
      );
    },
    allEb: {
      get() {
        return this.elslutbleck.map((eb) => eb.name);
      },
    },
    allSb: {
      get() {
        return this.slutbleck.map((sb) => sb.name);
      },
    },
    title: {
      get() {
        return this.form.name ? this.form.name : this.$t("text.admin.add-em");
      },
    },
    lockids() {
      // Return array with unique IDs concatinated from spec arrays
      let concatArray = [];
      for (const name in this.form.spec) {
        concatArray = [...new Set([...concatArray, ...this.form.spec[name]])];
      }
      return concatArray;
    },
    slugs() {
      return Object.keys(this.form.spec);
    },
    checkedProductsSB() {
      return this.productsSB.filter(
        (sb) => this.selectedSb.indexOf(sb.name) > -1
      );
    },
    checkedProductsEB() {
      return this.productsEB.filter(
        (eb) => this.selectedEb.indexOf(eb.name) > -1
      );
    },
    factsSb() {
      let sbfact = (this.getFactByName('passar-slutbleck') && this.getFactByName('passar-slutbleck')[0] && this.getFactByName('passar-slutbleck')[0].value ? this.getFactByName('passar-slutbleck')[0].value : [])
      return sbfact
    },
    factsEb() {
      let ebfact = (this.getFactByName('passar-elslutbleck') && this.getFactByName('passar-elslutbleck')[0] && this.getFactByName('passar-elslutbleck')[0].value ? this.getFactByName('passar-elslutbleck')[0].value : [])
      return ebfact
    }
  },
  methods: {
    getOptionsObj(options) {
      return _.map(options, (o) => {
        return {text: this.$t(o), value: o}
      });
    },
    cleanSpecObjFrom(spec, startstr) {
      if (!startstr || !spec) return {};
      return _.omitBy(spec, (s, key) => {
        return key.indexOf(startstr) === 0;
      });
    },
    filterAddRow() {
      // Add new empty row to filter
      this.form.filter.push({ slugs: [], nodutrymningsbeslag: [] });
    },
    getMergedSlugsFromRowsWithSameProduct(product) {
      // Get all rows in filter with same slutbleck/elslutbleck
      let rowsWithSameProduct = [];
      if (product.length > 0) {
        rowsWithSameProduct = this.form.filter.filter(
          (r) =>
            r.slutbleck[0] === product[0] || r.elslutbleck[0] === product[0]
        );
        const mapped = _.map(rowsWithSameProduct, (r) => r.slugs);
        const flattened = _.flatten(mapped);
        const uniq = _.uniq(flattened);
        return uniq;
      }
      return [];
    },
    addToUpdatedSbs(localFormSb) {
      // Update [updatedSbs] - update on submit later on
      let sbInUpdatedSb = this.updatedSbs.find(
        (sb) => sb._id === localFormSb._id
      );
      if (sbInUpdatedSb === undefined) {
        // Update existing or create new
        this.updatedSbs.push(localFormSb);
      } else {
        sbInUpdatedSb = localFormSb;
      }
    },
    addToUpdatedEbs(localFormEb) {
      // Update [updatedSbs] - update on submit later on
      let sbInUpdatedEb = this.updatedEbs.find(
        (sb) => sb._id === localFormEb._id
      );
      if (sbInUpdatedEb === undefined) {
        // Update existing or create new
        this.updatedEbs.push(localFormEb);
      } else {
        sbInUpdatedEb = localFormEb;
      }
    },
    updateSelectedProducts({UISelectedSb, UISelectedEb}) {
      console.log('updateSelectedProducts')
      // Update local facts
      let passandeSlutbleckNames = UISelectedSb.map((sb) => {
        return this.slutbleck.find((s) => s._id === sb).name;
      });
      const passarSlutbleckObj = this.getFactByName("passar-slutbleck")[0];
      passarSlutbleckObj.value = passandeSlutbleckNames

      let passandeElslutbleckNames = UISelectedEb.map((eb) => {
        return this.elslutbleck.find((s) => s._id === eb).name;
      });
      const passarElslutbleckObj = this.getFactByName("passar-elslutbleck")[0];
      passarElslutbleckObj.value = passandeElslutbleckNames
      // this.updateRelatedEbs();
      // this.updateRelatedSbs();
      
    },
    filterUpdateRow(row) {
      // row = changed row
      // May be other rows with same slutbleck/elslutbleck, slugs should be merged from all those
      if (row.slutbleck.length == 0 && row.elslutbleck.length == 0) {
        // Handle empty slutbleck/elslutbleck
        return;
      }
      if (row.slutbleck.length > 0) {
        // Handle slutbleck
        const mergedSlugsFromRowsWithSameSB = this.getMergedSlugsFromRowsWithSameProduct(row.slutbleck);
        
        let localFormSb = this.getSbFromLocalFormById(row.slutbleck[0]);

        let sbSpecAdditionsFromRow = _.mapValues(
          _.zipObject(mergedSlugsFromRowsWithSameSB),
          () => [this.form.artnr]
        );

        let cleanLocalSpec = this.cleanSpecObjFrom(localFormSb.spec, "em-"); // Clean first since there can be old slugs in spec
        let updatedSpec = this.getMergedSpecObject(
          cleanLocalSpec,
          sbSpecAdditionsFromRow
        );
        // Also add the local product name to related product [spec]
        if (
          localFormSb.spec[this.form.name] === undefined &&
          mergedSlugsFromRowsWithSameSB.length > 0
        ) {
          updatedSpec[this.form.name] = [localFormSb.artnr];
        }

        localFormSb.spec = updatedSpec;

        // row.nodutrymningsbeslag = _.uniq(row.nodutrymningsbeslag)
        // row.slutbleck = _.uniq(row.slutbleck)
        // row.elslutbleck = _.uniq(row.elslutbleck)

        this.addToUpdatedSbs(localFormSb);
      }

      if (row.elslutbleck.length > 0) {
        // Handle elslutbleck
        const mergedSlugsFromRowsWithSameEB =
          this.getMergedSlugsFromRowsWithSameProduct(row.elslutbleck);
        let localFormEb = this.getEbFromLocalFormById(row.elslutbleck[0]);
        let ebSpecAdditionsFromRow = _.mapValues(
          _.zipObject(mergedSlugsFromRowsWithSameEB),
          () => [this.form.artnr]
        );
        let cleanLocalSpec = this.cleanSpecObjFrom(localFormEb.spec, "em-"); // Clean first since there can be old slugs in spec
        let updatedSpec = this.getMergedSpecObject(
          cleanLocalSpec,
          ebSpecAdditionsFromRow
        );

        // Also add the local product name to related product [spec]
        if (
          localFormEb.spec[this.form.name] === undefined &&
          mergedSlugsFromRowsWithSameEB.length > 0
        ) {
          updatedSpec[this.form.name] = [localFormEb.artnr];
        }
        localFormEb.spec = updatedSpec;
        this.addToUpdatedEbs(localFormEb);
      }
    },
    updateRelatedSbSpec(sb, checked) {
      console.log("checked", checked);
      console.log("spec", sb.spec);

      const emname = this.form.name;
      console.log("emname", emname);

      if (sb.spec) {
        // Set or remove emname from sb.spec
        if (checked && sb.spec[emname] === undefined) {
          sb.spec[emname] = [sb.artnr];
          // Add emname to sb.spec
        } else {
          // Remove emname from sb.spec
          if (sb.spec[emname]) {
            delete sb.spec[emname];
          }
        }
        console.log("new spec", sb.spec);
      } else {
        // sb.spec could be null because of an old error, but should NEVER be null now
        console.log(`[spec] is null for "${sb.name}""`);
        console.log("ACTIVITY shold be written here");
      }
      // Update facts value
      const passarSlutbleck = this.getFactByName("passar-slutbleck")[0].value;
      if (checked && passarSlutbleck.indexOf(sb.name) === -1) {
        passarSlutbleck.push(sb.name);
      } else if (!checked && passarSlutbleck.indexOf(sb.name) !== -1) {
        passarSlutbleck.splice(passarSlutbleck.indexOf(sb.name), 1);
      }

      console.log("passarSlutbleck", passarSlutbleck);
      console.log("EM spec", this.form.spec[this.form.artnr]);
      console.log("SB spec", sb.spec);

      // Add MS to list of items should be updated on save
      this.changedSb = this.changedSb.filter((item) => item.artnr !== sb.artnr); // Remove duplicate and save last
      this.changedSb.push(sb);
    },
    updateRelatedEbSpec(eb, checked) {
      console.log("checked", checked);
      console.log("spec", eb.spec);

      const emname = this.form.name;
      console.log("emname", emname);

      if (eb.spec) {
        // Set or remove emname from eb.spec
        if (checked && eb.spec[emname] === undefined) {
          // Add emname to eb.spec
          console.log("Add emname to eb.spec", eb.spec);
          eb.spec[emname] = [eb.artnr];
        } else {
          // Remove emname from eb.spec
          if (eb.spec[emname]) {
            delete eb.spec[emname];
          }
        }
        console.log("new spec", eb.spec);
      } else {
        // sb.spec could be null because of an old error, but should NEVER be null now
        console.log(`[spec] is null for "${eb.name}""`);
        console.log("ACTIVITY shold be written here");
      }
      // Update facts value
      const passarElslutbleck =
        this.getFactByName("passar-elslutbleck")[0].value;
      if (checked && passarElslutbleck.indexOf(eb.name) === -1) {
        passarElslutbleck.push(eb.name);
      } else if (!checked && passarElslutbleck.indexOf(eb.name) !== -1) {
        passarElslutbleck.splice(passarElslutbleck.indexOf(eb.name), 1);
      }

      // Fetch monteringstolpar or update spec value in object
      // this.fetchMs();

      // Update ms in db
      console.log("eb", eb);

      // Add MS to list of items should be updated on save
      this.changedEb.push(eb);
    },
    uploadDone(files) {
      console.log("Upload done", files);
      this.uselessNumber++;
    },
    async updateChangedNbs(nbsFromTable) {
      console.log("nbsFromTable", nbsFromTable);
      console.log("updateChangedNbs", nbsFromTable);
      this.form.nodutrymningsbeslag = await _.map(nbsFromTable, (nb) => ({
        _id: nb._id,
      }));
      this.updatedNbs = await _.map(nbsFromTable, (nb) => nb);
    },
    updateEmSpecObject(spec, products) {
      console.log("debug", spec, products);
      let merge = _.mergeWith(this.form.spec, spec, (objValue, srcValue) => {
        if (_.isArray(objValue)) {
          return objValue.concat(srcValue);
        }
      });

      this.form.spec = merge;
    },
    updateEmFilterArray(filter) {
      console.log("filter", filter);
      this.form.filter = filter.filter((f) => f.slugs.length > 0);
    },
    async updateChangedSbs(spec, sbsFromTable) {
      console.log("updateChangedSbs", sbsFromTable);
      this.form.slutbleck = await _.map(sbsFromTable, (sb) => ({
        _id: sb._id,
      }));
      this.updatedSbs = await _.map(sbsFromTable, (sb) => sb);
    },
    async updateChangedEbs(spec, ebsFromTable) {
      console.log("updateChangedEbs", ebsFromTable);
      this.form.elslutbleck = await _.map(ebsFromTable, (eb) => ({
        _id: eb._id,
      }));
      this.updatedEbs = await _.map(ebsFromTable, (eb) => eb);
    },
    async updateRelatedEbs() {
      // Update all related ebs
      console.log("updateRelatedEbs");
      if (this.updatedEbs.length) {
        this.updatedEbs.forEach((eb) => {
          console.log("eb", eb);
          ApiServiceAdmin.updateEbById(eb._id, {
            name: eb.name,
            artnr: eb.artnr,
            spec: eb.spec,
            facts: eb.facts,
            info: eb.info,
            published: eb.published,
            translations: eb.translations,
          });
        });
      }
    },
    async updateRelatedSbs() {
      // Update all related ebs
      console.log("updateRelatedSbs");
      if (this.updatedSbs.length) {
        this.updatedSbs.forEach((sb) => {
          console.log("sb", sb);
          ApiServiceAdmin.updateSbById(sb._id, {
            name: sb.name,
            artnr: sb.artnr,
            spec: sb.spec,
            facts: sb.facts,
            info: sb.info,
            published: sb.published,
            translations: sb.translations,
          });
        });
      }
    },
    async initFormData() {
      if (!this.id) {
        this.filterAddRow();
        return;
      }
      try {
        await ApiServiceAdmin.getEmDataById(this.id, []).then((response) => {
          this.form = _.pick(response.data, [
            "name",
            "artnr",
            "enr",
            "nobnr",
            "filter",
            "info",
            "facts",
            "published",
            "translations",
            "elslutbleck",
            "slutbleck",
            "nodutrymningsbeslag",
          ]);

          // Make sure length of info is same
          this.form.info.forEach((info,i) => {
            let noInfo = this.form.translations.no.info
            if ( !noInfo[i] ) {
              noInfo.push({title: info.title, body: ""})
            }
          });

          if (!this.form.filter || this.form.filter.length == 0) {
            this.filterAddRow();
          }

          // Set old.artnr to keep track of changes
          this.old.artnr = this.form.artnr;

          // Merge info start object with db-data
          // let formInfo = JSON.parse(JSON.stringify(this.form.info));
          // const staticInfo = JSON.parse(JSON.stringify(this.info));
          // this.form.info = _.unionBy(formInfo, staticInfo, "title");

          // Merge static facts defined in this document with facts fetched from db
          // let formFacts = JSON.parse(JSON.stringify(this.form.facts));
          // const staticFacts = JSON.parse(JSON.stringify(this.facts));
          // this.form.facts = _.unionBy(formFacts, staticFacts, "title");

          // Passande slutbleck och elslutbleck
          const passarSlutbleck = this.getFactByName("passar-slutbleck")[0];
          this.selectedSb = passarSlutbleck.value;
          const passarElslutbleck = this.getFactByName("passar-elslutbleck")[0];
          this.selectedEb = passarElslutbleck.value;
        });
        // Set old.name to keep track of changes
        this.old.name = this.form.name;
      } catch (error) {
        console.log(error);
      }
    },
    saveNewEm() {
      // const payload = JSON.stringify(this.form);
      const payload = _.pick(this.form, [
        "artnr",
        "enr",
        "nobnr",
        "name",
        "spec",
        "published",
        "facts",
        "info",
        "translations",
      ]);

      ApiServiceAdmin.addEm(payload)
        .then((response) => {
          this.$router.push(`/admin/em/${response.data.result._id}`);
          this.id = response.data.result._id;
          this.handleSuccess(response);
        })
        .catch(this.handleError);
    },
    updateEm() {
      ApiServiceAdmin.updateEmById(
        this.id,
        _.pick(this.form, [
          "artnr",
          "enr",
          "nobnr",
          "name",
          "spec",
          "filter",
          "published",
          "facts",
          "info",
          "translations",
          "elslutbleck",
          "slutbleck",
          "nodutrymningsbeslag",
        ]),
        1
      ) // [id], [payload], [loglevel]
        .then((response) => {
          console.log("updateEmById", "then");
          // Make sure that spec is not null
          if (this.form.spec == null) {
            this.form.spec = this.getDefaultSpecObjFor(
              this.scope,
              this.form.spec
            );
          }

          // Update folder name in GCS
          if (this.form.artnr != this.old.artnr) {
            console.log("Update folder name in S3");
            ApiServiceAdmin.updateFolderByArt(this.form.artnr, this.old.artnr)
              .then(() => {
                this.old.artnr = this.form.artnr;
                // this.makeToast({ variant: "info", title: this.$t("messages.post-update-artnr"), text: this.$t("messages.post-save") });
              })
              .catch(this.handleError);
          }
          // Use list of updated related SB to update them
          if (this.changedSb.length) {
            console.log("list of changed sb", this.changedSb);
            this.changedSb.forEach((sb) => {
              ApiServiceAdmin.updateSbById(
                sb._id,
                _.pick(sb, [
                  "artnr",
                  "name",
                  "spec",
                  "published",
                  "facts",
                  "info",
                  "translations",
                  "ems",
                ])
              )
                .then((response) => {
                  this.changedSb = [];
                  this.handleSuccess(response);
                })
                .catch(this.handleError);
            });
          }

          if (this.changedEb.length) {
            console.log("list of changed eb", this.changedEb);
            this.changedEb.forEach((sb) => {
              ApiServiceAdmin.updateEbById(
                sb._id,
                _.pick(sb, [
                  "artnr",
                  "name",
                  "spec",
                  "published",
                  "facts",
                  "info",
                  "translations",
                  "ems",
                ])
              )
                .then((response) => {
                  this.changedEb = [];
                  this.handleSuccess(response);
                })
                .catch(this.handleError);
            });
          }

          this.old.name = this.form.name;
          this.handleSuccess(response);
        })
        .catch(this.handleError);
    },
    getSbFromLocalFormById(id) {
      return this.slutbleck.find((sb) => sb._id === id);
    },
    getEbFromLocalFormById(id) {
      return this.elslutbleck.find((eb) => eb._id === id);
    },
    getMergedSpecObject(spec1, spec2) {
      // Input multiple spec object and get merged version
      let merged = _.mergeWith(spec1, spec2, (objValue, srcValue) => {
        if (_.isArray(objValue)) {
          return objValue.concat(srcValue);
        }
      });
      merged = _.mapValues(merged, (v) => _.uniq(v)); // make unique
      return merged;
    },
    // updateRelatedProductsFromFilter() {
    //   // Loop filter rows and update related product spec with slugs
    //   this.form.filter.forEach((row) => {
    //     if (row.slutbleck.length) {
    //       var sbSpecAdditionsFromRow = _.mapValues(_.zipObject(row.slugs), () => [this.form.artnr])
    //       row.slutbleck.forEach(async (sb) => { // For now there is only one, but could be more in the future
    //         let sbPreSave = this.getSbFromLocalFormById(sb);
    //         await ApiServiceAdmin.updateSbById(sb, { name: sbPreSave.name, artnr: sbPreSave.artnr, spec: this.getMergedSpecObject(sbPreSave.spec, sbSpecAdditionsFromRow) })
    //           .then((response) => {
    //             this.handleSuccess(response);
    //           })
    //           .catch(this.handleError);
    //       });
    //     }
    //   });
    // },
    async onSubmit(event) {
      event.preventDefault();

      const unique_enr = await this.isUnique('enr', this.form.enr, this.id)
      const unique_nobnr = await this.isUnique('nobnr', this.form.nobnr, this.id)
      if ( !unique_enr || !unique_nobnr ) {
        // Prevent saving if not unique
        this.handleError('E11000_DUPLICATE_KEY')
        return false;
      }
      this.loading = true;
      if (!this.id) {
        ApiServiceAdmin.addEm(JSON.stringify(this.form))
          .then((response) => {
            console.log("response", response);
            this.$router.push(`/admin/em/${response.data.result._id}`);
            this.id = response.data.result._id;
            this.handleSuccess(response);
          })
          .catch(this.handleError);
      } else {
        ApiServiceAdmin.updateEmById(this.id, JSON.stringify(this.form))
          .then((response) => {
            // Update folder name in S3
            if (this.form.artnr != this.old.artnr) {
              console.log("Update folder name in S3");
              ApiServiceAdmin.updateFolderByArt(this.form.artnr, this.old.artnr)
                .then(() => {
                  this.old.artnr = this.form.artnr;
                  // this.makeToast({ variant: "info", title: this.$t("messages.post-update-artnr"), text: this.$t("messages.post-save") });
                })
                .catch(this.handleError);
            }
            // Update related ebs
            this.updateRelatedEbs();
            this.updateRelatedSbs();
            this.handleSuccess(response);
          })
          .catch(this.handleError);
      }
      // this.updateRelatedProductsFromFilter()
    },
    initSelected() {
      console.log(this.monteringsstolpar);

      // this.selectedMs = ["MONTERINGSSTOLPE 930-17 ,5"];
    },
    getFactByName(name) {
      return this.form.facts
        ? this.form.facts.filter((fact) => fact.name == name)
        : false;
    },
    fetchSb() {
      ApiServiceAdmin.getSb()
        .then(async (response) => {
          this.slutbleck = await response.data;
          this.productsSB = this.slutbleck;
          this.handleSuccess(response);
        })
        .catch((err) => console.error(err));
    },
    fetchEb() {
      ApiServiceAdmin.getEb()
        .then(async (response) => {
          this.elslutbleck = await response.data;
          this.productsEB = this.elslutbleck;
          this.handleSuccess(response);
        })
        .catch((err) => console.error(err));
    },
    async fetchNb() {
      this.productsNB = await ApiServiceAdmin.getNb().then(
        (response) => response.data
      );
    },
    async fetchEmOptions() {
      const emOptions = await ApiServiceAdmin.getEmOptions().then(
        (response) => response.data
      );
      this.optionsEM = _.flatten(_.valuesIn(_.map(emOptions, (o) => o.slugs)));
    },
  },
  mounted() {
    const fact = this.getFactByName("passar-slutbleck");
    console.log("fact", fact);
    this.fetchSb();
    this.fetchEb();
    this.fetchNb();
    this.fetchEmOptions();
  },
  watch: {
    "form.name": function (newname, oldname) {
      console.log(newname, oldname);

      // this.selectedMs = val;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../scss/_colors.scss";
#slutbleck,
#elslutbleck {
  max-height: 400px;
  overflow: auto;
}
table {
  th,
  td {
    border-width: 2px;
    border-color: white;
  }
  th {
    background-color: $primary;
    color: $white;
  }
  td {
    background-color: $gray-100;
    display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        white-space: nowrap;
      }
  }
}
</style>
