<template>
  <b-container id="options" fluid class="px-2 px-md-3 px-lg-4">
    <div id="accordion-options" role="tablist">
      <b-row no-gutters class="accordion mt-3" role="tablist">
        <b-col
          cols="12"
          md="6"
          lg="3"
          xl=""
          class="px-1 py-0 option-column"
          v-for="option in options"
          v-bind:key="option.name"
        >
          <OptionsListColumn
            v-bind="option"
            :scope="scope"
            :disabled="disabled"
            :selection="selection"
            @toggle-slug="toggleSlug(...arguments)"
            @on-reset="reset"
          ></OptionsListColumn>
        </b-col>
        <hr
          class="border-primary w-100"
          style="border-width: 5px; margin: 30px 0 0"
        />
      </b-row>
    </div>
  </b-container>
</template>

<script>
import OptionsListColumn from "./OptionsListColumn";
export default {
  name: "OptionsList",
  props: ["options", "disabled", "selection", "scope"],
  data() {
    return {
      hidecolumn: ["passande-eltryckeslas-motorlas"],
    };
  },
  components: {
    OptionsListColumn,
  },
  methods: {
    toggleSlug(args) {
      this.$emit("toggle-slug", args);
    },
    reset() {
      this.$emit("on-reset");
    },
  },
};
</script>

<style lang="scss">
@import "../../../../scss/custom.scss";
@import "../../../../node_modules/bootstrap/scss/mixins/_breakpoints.scss";

h4 {
  font-weight: 600;
}

@include media-breakpoint-up(lg) {
  .option-column:not(:first-child) {
    border-left: $dark solid 1px !important;
  }
}
</style>
