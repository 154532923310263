import toast from "@/mixins/toasts.js";
import ApiServiceAdmin from "../services/ApiServiceAdmin";
export default {
  components: { ApiServiceAdmin },
  mixins: [toast],
  methods: {
    handleError(error) {
      this.loading = false;
      console.log('error', error)
      console.log('error.response', error.response)
      let errormsg;
      if (error.response) {
        errormsg = this.$t(error.response.data.error);
      }
      if ( typeof error == "string" ) {
        errormsg = this.$t(error);
      }
      if (errormsg) {
        this.makeToast({ variant: "danger", title: 'Fel', text: errormsg })
      } else if (error) {
        console.log('error', error)
      }
    },
    handleMessage(msg) {
      this.makeToast({ variant: "info", title: "", text: msg });
    },
    handleSuccess(response) {
      this.loading = false;
      if (response.config && response.config.method == "get") {
        return
      }

      if (response && response.config && response.config.method) {
        console.log('METHOD', response.config.method)
        const messages = {
          "put": response.data.message,
          "post": response.data.message,
          "delete": response.data.message,
          "get": `Hämtar data`,
        }
        var message = messages[response.config.method]
      }
      if (response) {
        if (response.status == 200) {
          // OK
          this.makeToast({ variant: "success", title: "Success", text: message });
          let datastr = (typeof response.config.data !== 'string' ? JSON.stringify(response.config.data) : response.config.data)
          ApiServiceAdmin.logActivity({
            lang: this.$i18n.locale,
            page: response.data.body ? {
              href: location.pathname,
              title: response.data.body.name,
            } : {},
            status: response.status,
            method: response.config.method,
            type: response.config.url.replace('/api/', '').replace(/\/.*/, ''),
            datastr: datastr,
            message: message
          })
        } else {
          this.makeToast({ variant: "danger", title: "Danger", text: `<b>${response.status}:</b> ${response}` });
          // this.$store.commit("ALERT", { variant: "danger", msg: `<b>${response.status}:</b> ${response}` });
        }
      } else {
        this.makeToast({ variant: "warning", title: "Success", text: "Successful but no response (?)" });
        console.log('Successful but no response (?)')
      }
    },
  }
}