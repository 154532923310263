<template>
  <div>
    <GuideInfoSection
      :title="guideTitle"
      :text="guide_slutbleck_text"
      :text2="guide_friskrivning"
      :step1="$t('text.guideSB.step1')"
      @on-reset-slugs="resetSlugs"
      class="d-print-none"
    >
      <FilterSelection
        :selection="this['optionsEM/getSelection']"
        :text="$t('text.guideEM.criterion')"
        :options="this['optionsEM/getOptions']"
        :show="this['optionsEM/getSelection'].length > 0"
      ></FilterSelection>
      <!-- <template #bottom>
        <OptionsCategory :categories="getCategories" :disabled="getDisabled" :selection="getSelection" :title="$t('omstallbar-rattvand-omvand')" @toggle-slug="toggleSlug" />
      </template> -->
    </GuideInfoSection>
    <b-overlay
      no-fade
      spinner-variant="primary"
      :show="loading"
      opacity="0.95"
      bg-color="white"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon
            icon="stopwatch"
            font-scale="3"
            animation="cylon"
            variant="primary"
          ></b-icon>
          <p class="mt-2">
            <strong>{{ $t("text.guideSB.loading") }}</strong>
          </p>
        </div>
      </template>
      <OptionsList
        :options="this['optionsSB/getOptions']"
        :disabled="this['optionsSB/getDisabled']"
        :selection="this['optionsSB/getSelection']"
        @toggle-slug="toggleSlug"
        @reset-slugs="resetSlugs"
        @on-reset="resetSelectedEm"
        product-type="slutbleck"
        class="d-print-none"
      />
    </b-overlay>
    <b-container>
      <transition name="fade">
        <ProductsList
          :total="getTotal"
          :selected-product-data="getProduct"
          :selected-product-docs="getDocs"
          :products="getProducts"
          @on-product-select="showProduct(...arguments, 'sb')"
          @on-click-load-more="loadMore"
          scope="sb"
        >
          <template #ingress>
            <h3 class="d-print-none">{{ $t("text.guideSB.step2") }}</h3>
            <p class="d-print-none">{{ $t("text.guideSB.step2-text") }}</p>
          </template>
        </ProductsList>
      </transition>
    </b-container>
  </div>
</template>

<script>
import store from "@/store/store";
import GuideInfoSection from "@/components/guide/options/GuideInfoSection";
import FilterSelection from "@/components/guide/options/FilterSelection";
// import OptionsCategory from "@/components/guide/options/OptionsCategory.vue";
import OptionsList from "@/components/guide/options/OptionsList.vue";
import { mapGetters, mapActions } from "vuex";
import productsListMixin from "@/mixins/productsListMixin";
import contentMixin from "@/mixins/contentMixin";
export default {
  name: "GuideFilterViewSB",
  mixins: [productsListMixin, contentMixin],
  components: {
    GuideInfoSection,
    FilterSelection,
    OptionsList,
  },
  computed: {
    ...mapGetters([
      "optionsSB/getSelection",
      "optionsSB/getOptions",
      "optionsSB/getDisabled",
      "optionsSB/getCategories",
      "optionsSB/getEms",
      "optionsSB/getDidFirstTimeCleanup"
    ]),
    ...mapGetters("productsSB", [
      "getProducts",
      "getCount",
      "getTotal",
      "getUi",
    ]),
    ...mapGetters("productSB", ["getProduct", "getDocs"]),
    ...mapGetters(["optionsEM/getSelection", "optionsEM/getOptions"]),
    loading: {
      get() {
        return this.$store.state.optionsSB.loading;
      },
      set(loading) {
        this.$store.state.optionsSB.loading = loading;
      },
    },
    emInfo() {
      let em = this.$store.state.productEM.product;

      if (this.$i18n.locale == "no" && em && em.translations) {
        em = em.translations.no;
      }
      return em ? ` - passande ${em.name}` : "";
    },
    guideTitle() {
      return this.guide_slutbleck_title + this.emInfo;
    },
  },
  methods: {
    ...mapActions("optionsSB", [
      "fetchOptions",
      "fetchDisabled",
      "updDisabled",
      "resetSlugs",
      "addSlug",
      "rmSlug",
    ]),
    ...mapActions("productsSB", ["fetchProducts"]),
    ...mapActions("productSB", ["fetchProduct", "fetchDocsForArt"]),
    ...mapActions(["clearAllData"]),
    toggleSlug(payload) {
      this.loading = true;
      const { slug, selected } = payload[0];
      if (selected) {
        this.addSlug(slug);
      } else {
        this.rmSlug(slug);
      }
    },
    skip(o) {
      return o.filter((o) => {
        console.log("name", o.name);
        return (
          o.name != "produktkategori" &&
          o.name != "omstallbar-rattvand-omvand" &&
          o.name != "passande-eltryckeslas-motorlas"
        );
      });
    },
    resetSelectedEm() {
      console.log("resetSelectedEm");
      this.$store.dispatch("setSelectedEm", null);
      this.resetSlugs();
    },
    loadMore() {
      this.$store.dispatch("productsSB/updateSkip");
    },
  },
  // beforeRouteEnter(to, from, next) {
  //   // Reset selected product
  //   if (to.name != "guide-filter-sb-edit") {
  //     store.dispatch("optionsSB/resetSlugs");
  //     store.dispatch("productSB/resetProduct").then(() => next());
  //     store
  //       .dispatch("productsSB/fetchProducts")
  //       .then(() => next())
  //       .catch((error) => {
  //         if (error.response && error.response.status == 404) {
  //           next({ name: "404", params: { resource: "event" } });
  //         } else {
  //           next({ name: "network-issue" });
  //         }
  //       });
  //   } else next();
  // },
  // beforeRouteUpdate(to, from, next) {
  //   store.dispatch("productsSB/fetchProducts").then(() => {
  //     next();
  //   });
  // },
  beforeRouteEnter(to, from, next) {
    // If page is reloaded, fetch products
    // guide-filter-sb-start = via menu
    // guide-filter-sb-with-em = via EM
    // guide-filter-sb-edit = via edit button
    // guide-filter-sb = direct access
    
    

    switch (to.name) {
      case "guide-filter-sb":
      case "guide-filter-sb-start":
        console.log('fetch products for SB')
        store.dispatch('optionsSB/setDidFirstTimeCleanup', false)
        store.dispatch("productsSB/fetchProducts", store.state.optionsSB.selection);
        break;
      default:
        break;
    }
    
    next();
  },
  mounted() {
    store.dispatch("optionsSB/fetchOptions")
  },
};
</script>

<style lang="scss" scoped></style>
