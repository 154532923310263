<template>
  <div>
    <b-container>
      <transition name="fade">
        <ProductsListAside v-if="!!product" :product-data="product" product-docs="" scope=""></ProductsListAside>
      </transition>
    </b-container>
  </div>
</template>

<script>
  import ApiService from "@/services/ApiService";
  export default {
    name: "Product",
    props: ["id"],
    data() {
      return {
        product: null,
      };
    },
    mounted() {
      ApiService.getEbDataById(this.id).then(async (response) => {
        this.product = await response.data;
      });
    },
  };
</script>

<style></style>
