<template>
  <div id="PrintOrderView">
    <GuideInfoSection
      :title="sammanstallning_title"
      :text="sammanstallning_text"
    >
      <template #ingress-prepend>
        <div class="float-right" style="margin-top: 40px">
          <b-button
            variant="outline-primary"
            size="sm"
            @click="printOrder"
            class="mr-2 print-button"
            ><b-icon-printer-fill></b-icon-printer-fill>
            {{ $t("text.order.skriv-ut") }}</b-button
          >
          <b-button
            variant="outline-primary"
            size="sm"
            class="edit-button"
            @click="$router.go(-1)"
            ><b-icon-chevron-left></b-icon-chevron-left>
            {{ $t("text.order.back-to-guide") }}</b-button
          >
        </div>
      </template>
    </GuideInfoSection>
    <b-container>
      <!-- Eltryckeslås & motorlås -->
      <PrintOrderProduct v-if="productEM"
        :product="productEM"
        :options="selectedEMOptions()"
        :selection="productEMSelection"
        modalid="productEmInfoModal"
      >
      <div v-if="matchedNbs && matchedNbs.length" class="mt-3">
        <b-row no-gutters class="page-break-print">
          <b-col cols="12">
            <ProductsGridNB
              :products="matchedNbs"
              :title="$t('Kräver nödutrumningsbeslag')"
              no-rulers
            ></ProductsGridNB>
          </b-col>
        </b-row>
      </div>
    </PrintOrderProduct>
      <!-- Elslutbleck -->
      <PrintOrderProduct v-if="productEB"
        :product="productEB"
        :options="selectedEBOptions()"
        :selection="productEBSelection"
        modalid="productEbInfoModal"
      ></PrintOrderProduct>
      <!-- Slutbleck -->
      <PrintOrderProduct v-if="productSB"
        :product="productSB"
        :options="selectedSBOptions()"
        :selection="productSBSelection"
        modalid="productSbInfoModal"
      ></PrintOrderProduct>
      <!-- Monteringsstolpe -->
      <PrintOrderProduct v-if="productMS"
        :product="productMS"
        :options="selectedMSOptions()"
        :selection="productMSSelection"
        modalid="productMsInfoModal"
      ></PrintOrderProduct>
      <!-- Låshus -->
      <div v-if="productHousings && productHousings.length && !productEM">
        <b-row class="d-print-none">
          <b-col>
            <hr class="border-dark" />
          </b-col>
        </b-row>
        <b-row no-gutters class="page-break-print">
          <b-col cols="12">
            <ProductsGrid
              :products="productHousings"
              size="lg"
              cols="5"
              :title="$t('Passar följande låshus')"
            >
              <template #title>
                <h4 class="text-primary fw-600">
                  {{ $t("Passar följande låshus") }}
                </h4>
              </template>
            </ProductsGrid>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <ProductInfoModal
      ref="productEbModal"
      id="productEbInfoModal"
      v-bind="this.productEB"
      :product-docs="[]"
      scope="eb-info"
      modal-name="factsonly"
    ></ProductInfoModal>
    <ProductInfoModal
      ref="productMsModal"
      id="productMsInfoModal"
      v-bind="this.productMS"
      :product-docs="[]"
      scope="ms-info"
      modal-name="factsonly"
    ></ProductInfoModal>
    <ProductInfoModal
      ref="productEmModal"
      id="productEmInfoModal"
      v-bind="this.productEM"
      :product-docs="[]"
      scope="em-info"
      modal-name="factsonly"
    ></ProductInfoModal>
    <ProductInfoModal
      ref="productSbModal"
      id="productSbInfoModal"
      v-bind="this.productSB"
      :product-docs="[]"
      scope="sb-info"
      modal-name="factsonly"
    ></ProductInfoModal>
  </div>
</template>

<script>
import store from "@/store/store";
import _ from "lodash";
// import ProductImages from "@/components/products/ProductImages";
import ProductsGrid from "@/components/products/ProductsGrid";
import ProductsGridNB from "@/components/products/ProductsGridNB";
import GuideInfoSection from "@/components/guide/options/GuideInfoSection";
import PrintOrderProduct from "@/components/order/PrintOrderProduct";
import contentMixin from "@/mixins/contentMixin";
import { mapGetters } from "vuex";
import ApiService from "../services/ApiService";
export default {
  name: "PrintOrderView",
  mixins: [contentMixin],
  data() {
    return {
      productsNB: [],
      API_HOST: process.env.VUE_APP_DBHOST,
    };
  },
  components: {
    GuideInfoSection,
    PrintOrderProduct,
    // ProductImages,
    ProductsGrid,
    ProductsGridNB,
    ProductInfoModal: () => import("@/components/products/ProductInfoModal"),
  },
  computed: {
    ...mapGetters("optionsEB", ["getSelection", "getOptions"]),
    ...mapGetters("optionsMS", ["getSelection", "getOptions"]),
    ...mapGetters("optionsEM", ["allRequiredOptionsSelectedToShowNB"]),
    ...mapGetters("productEM", ["getProduct"]),
    productEB() {
      return store.state.productEB.product;
    },
    productMS() {
      return store.state.productMS.product;
    },
    productEM() {
      return store.state.productEM.product;
    },
    productSB() {
      return store.state.productSB.product;
    },
    productEBSelection() {
      return store.state.optionsEB.selection;
    },
    productMSSelection() {
      return store.state.optionsMS.selection;
    },
    productEMSelection() {
      return store.state.optionsEM.selection;
    },
    productSBSelection() {
      return store.state.optionsSB.selection;
    },
    productHousings() {
      if (!this.productEB) return null;
      // Return array with unique IDs concatinated from spec arrays
      const spec = this.productEB.spec;
      // console.log("valuesIn", _.valuesIn(spec));
      // console.log("valuesIn", _.intersection(..._.valuesIn(spec)));

      // console.log("spec", spec);
      // let concatArray = [];
      // for (const name in this.selectedEb.spec) {
      //   concatArray = [...new Set([...concatArray, ...this.selectedEb.spec[name]])];
      // }
      return _.intersection(..._.valuesIn(spec));
    },
    matchedFilters() {
      let filters = this.productEM.filter;
      let matched = [];
      const selection = Array.from(this.$store.state.optionsEM.selection);

      matched = filters.filter((f) => {
        let sel = Array.from(selection);
        let slugs = Array.from(f.slugs);
        let diff = _.difference(sel, slugs);
        return diff.length == 0;
      });
      // console.log("matched", matched);
      return matched;
    },
    nbsFromMatchedFilters() {
      let nbs = this.matchedFilters
        .map((f) => {
          return Array.from(f.nodutrymningsbeslag);
        })
        .flat();

      nbs = _.uniq(nbs);
      return nbs;
    },
    matchedNbs() {
      // If NOT all required options are selected, we should never show any NBS
      if (!this.$store.getters["optionsEM/allRequiredOptionsSelectedToShowNB"]) {
        return [];
      }
  
      // Get NBS from store
      const nbsFromMatchedFilters = this.nbsFromMatchedFilters;
      const matched = this.productsNB.filter((nb) => {
        return nbsFromMatchedFilters.indexOf(nb._id) > -1;
      });

      return _.map(matched, (nb) => nb.artnr);
    },
  },
  mounted() {
    console.log('route', this.$route)
    ApiService.getNb().then(
      async (res) => {
        this.productsNB = await res.data;
      }
    );
  },
  methods: {
    displaySpec(specs) {
      // console.log("SPECS", specs);
      let displaySpecs = [];
      specs.forEach((spec) => {
        // Convert float values to a readable format
        if (spec.indexOf("p-") == 0) {
          let dslug = spec.replace("p-", "").replace("-", ",");
          displaySpecs.push(dslug);
        } else if (spec.indexOf("b-") == 0) {
          let dslug = spec.replace("b-", "").replace("-", ",");
          displaySpecs.push(dslug);
        } else if (spec.indexOf("h-") == 0) {
          let dslug = spec.replace("h-", "").replace("-", ",");
          displaySpecs.push(dslug);
        } else if (spec.indexOf("vd-") == 0) {
          let dslug = spec.replace("vd-", "").replace("-", ",");
          displaySpecs.push(dslug);
        } else {
          displaySpecs.push(this.$t(spec));
        }
      });
      return _.map(displaySpecs, (spec) => this.$t(spec)).join(", ");
    },
    async print() {
      // Pass the element id here
      await window.print();
    },
    selectedEBOptions() {
      return this.$store.state.optionsEB.options.filter((o) => {
        return o.slugs.some((s) => this.productEBSelection.indexOf(s) >= 0);
      });
    },
    selectedMSOptions() {
      return this.$store.state.optionsMS.options.filter((o) => {
        return o.slugs.some((s) => this.productMSSelection.indexOf(s) >= 0);
      });
    },
    selectedEMOptions() {
      return this.$store.state.optionsEM.options.filter((o) => {
        return o.slugs.some((s) => this.productEMSelection.indexOf(s) >= 0);
      });
    },
    selectedSBOptions() {
      return this.$store.state.optionsSB.options.filter((o) => {
        return o.slugs.some((s) => this.productSBSelection.indexOf(s) >= 0);
      });
    },
    intersection(a, b) {
      return _.intersection(a, b);
    },
    getTranslatedName(item) {
      if (item.translations && item.translations[this.$i18n.locale]) {
        const translatedName = item.translations[this.$i18n.locale].name;
        if (this.$i18n.locale != "sv" && translatedName != "") {
          return translatedName;
        }
      }
      return item.name;
    },
    printOrder() {
      window.print();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/scss/custom.scss";
@import "/node_modules/bootstrap/scss/mixins/_breakpoints.scss";

@include media-breakpoint-down(md) {
  .ingress .float-right {
    float: none !important;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
    .print-button, .edit-button {
      width: 100%;
      display: block;

    }
  }
}

@include media-breakpoint-down(xs) {
  .ingress .float-right {
    float: none !important;
    width: 100%;
    display: grid;
    grid-template-columns: auto;
    gap: 20px;
    .print-button, .edit-button {
      width: 100%;
      display: block;

    }
  }
}

.show-info-button {
  width: 100%;
  margin: 10px 0 0 0 !important;
  @include media-breakpoint-up(md) {
    margin: 0 0 0 10px !important;
    width: auto;
  }
}

.row-title,
.row-value {
  line-height: 32px;
  padding: 0 9px;
  font-size: 14px;
}
.row-title {
  margin: 0 9px 9px 0;
  font-weight: bold;
}
.row-value {
  margin: 0 9px 9px 0;
}
h4 {
  margin-top: 10px;
  margin-bottom: 32px;
}
hr {
  margin: 16px 0;
}
.page-break-print {
  page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
  page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
  page-break-inside: avoid; /* or 'auto' */
}
.carousel-indicators {
  display: none;
}
.carousel {
  padding: 0 !important;
}
.product-aside-image {
  max-height: 394px !important;
}
</style>
