<template>
  <div class="py-5">
    <b-form novalidate id="edit" @submit="onSubmit" @reset="onReset" @change="onChange">
      <b-overlay :show="loading" rounded opacity="0.8" spinner-small spinner-variant="primary" @hidden="onOverlayHidden">
        <b-container>
          <h2>{{ title }}</h2>
          <b-row>
            <b-col cols="8">
              <b-form-row>
                <b-col>
                  <b-form-group :label="$t('form.namn')">
                    <b-form-input v-if="showOriginal" required id="ms-name" v-model="form.name"></b-form-input>
                    <b-input-group v-if="showTranslation">
                      <b-form-input required id="ms-name-no" v-model="form.translations.no.name"></b-form-input>
                      <template #append>
                        <country-flag country="no" size="normal" class="ml-n5" />
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group :label="$t('form.artnr')">
                    <input type="hidden" name="old_artnr" v-model="old.artnr" />
                    <b-form-input v-if="showOriginal" required id="ms-artnr" v-model="form.artnr"></b-form-input>
                    <b-input-group v-if="showTranslation">
                      <b-form-input required id="ms-artnr-no" v-model="form.translations.no.artnr"></b-form-input>
                      <template #append>
                        <country-flag country="no" size="normal" class="ml-n5" />
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group v-if="showOriginal" :label="$t('form.enr')" :description="$t('form.enr-help')">
                    <b-form-input required id="eb-enr" v-model="form.enr"></b-form-input>
                  </b-form-group>
                  <b-form-group v-if="showTranslation" :label="$t('form.nobnr')" :description="$t('form.nobnr-help')">
                    <b-input-group>
                      <b-form-input required id="eb-nobnr" v-model="form.nobnr"></b-form-input>
                      <template #append>
                        <country-flag country="no" size="normal" class="ml-n5" />
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row v-for="(info, i) in form.info" v-bind:key="`info-${i}`">
                <b-col>
                  <b-form-group :label="$t(info.title)">
                    <b-form-textarea v-if="showOriginal" rows="5" wrap="soft" :formatter="removeHtml" id="`info-${i}`" v-model="info.body"></b-form-textarea>
                    <b-input-group v-if="showTranslation">
                      <b-form-textarea rows="5" wrap="soft" :formatter="removeHtml" id="`no-info-${i}`" v-model="form.translations.no.info[0].body"></b-form-textarea>
                      <template #append>
                        <country-flag country="no" size="normal" class="ml-n5" />
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row v-if="showOriginal">
                <b-col>
                  <b-form-checkbox :disabled="!canPublishSE" v-model="form.published" switch>
                    {{ $t("text.admin.published") }} <small>{{ $t("text.admin.published-help") }}</small>
                  </b-form-checkbox>
                </b-col>
              </b-form-row>

              <div v-if="showTranslation">
                <b-form-row>
                  <b-col>
                    <b-form-checkbox :disabled="!canPublishNO" v-model="form.translations.no.published" switch>
                      {{ $t("text.admin.published") }} <small>{{ $t("text.admin.published-help") }}</small>
                    </b-form-checkbox>
                  </b-col>
                </b-form-row>
                <div class="mt-5" v-if="showTranslation">
                  <label>{{ $t("text.admin.sampletext-orig") }}</label>
                  <b-card class="shadow-sm" no-body bg-variant="light">
                    <div class="d-flex" style="justify-content: space-between">
                      <h3 class="m-3">{{ form.name }}</h3>
                      <div class="my-3 ml-auto">{{ form.artnr }}</div>
                      <div class="mt-2">
                        <country-flag country="se" size="normal" class="mr-0 ml-0" />
                      </div>
                    </div>
                    <div class="px-3">
                      <dl v-for="(info, i) in form.info" v-bind:key="`product-info-${i}`">
                        <div v-if="info.body">
                          <dt>{{ info.title }}</dt>
                          <dd v-html="info.body" style="white-space: pre-wrap"></dd>
                        </div>
                      </dl>
                    </div>
                  </b-card>
                </div>
              </div>

              <!-- Edit facts -->
              <div v-if="showOriginal" class="mt-5">
                <h3>{{ $t("text.fakta") }}</h3>
                <table class="table facts">
                  <tbody>
                    <tr>
                      <th>{{ $t("form.artnr") }}</th>
                      <td>{{ form.artnr }}</td>
                    </tr>
                    <tr v-for="(fact, i) in form.facts" :key="`fact-${i}`">
                      <th width="40%">
                        {{ $t(fact.title) }}
                      </th>
                      <td>
                        <div v-if="factsOptions[fact.title] && factsOptions[fact.title].options">
                          <div v-if="Array.isArray(fact.value)">
                            <b-form-checkbox-group v-model="fact.value" :options="factsOptions[fact.title].options" :name="`fact-options-${i}`" @change="updateOld(fact)"></b-form-checkbox-group>
                          </div>
                          <div v-else>
                            <b-form-radio-group v-model="fact.value" :options="factsOptions[fact.title].options" :name="`fact-options-${i}`" @change="updateOld(fact)"></b-form-radio-group>
                          </div>
                        </div>
                        <div v-else>
                          <b-input-group>
                            <b-form-input v-show="fact.update.pattern" style="max-width: 85px; text-align: right; padding-right: 2px" step="0.1" type="number" :list="`${fact.name}-dropdown`" v-model="fact.value" @change="updateOld(fact)"></b-form-input>
                            <b-form-input v-show="!fact.update.pattern" style="max-width: 270px" :list="`${fact.name}-dropdown`" v-model="fact.value" @change="updateOld(fact)"></b-form-input>
                            <div v-if="fact.dropdown">
                              <datalist :id="`${fact.name}-dropdown`" v-if="options[fact.name] && options[fact.name].slugs">
                                <option>...</option>
                                <option v-for="(opt, index) in options[fact.name].slugs" :key="index">{{ opt }}</option>
                              </datalist>
                              <b-button
                                variant="link"
                                class="text-danger"
                                v-show="fact.dropdown.indexOf(fact.value) != -1 && fact.value.length > 0"
                                @click="
                                  fact.dropdown.splice(fact.dropdown.indexOf(fact.value), 1);
                                  fact.value = '';
                                "
                                ><b-icon icon="dash-square"></b-icon
                              ></b-button>
                            </div>
                            <b-input-group-text v-if="fact.suffix">
                              {{ fact.suffix }}
                            </b-input-group-text>
                          </b-input-group>
                        </div>
                        <b-form-input v-if="fact.value == 'JA'" placeholder="fritext" v-model="fact.text" @change="updateOld(fact)"></b-form-input>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
            <b-col cols="4">
              <b-alert :show="form.artnr == ''" variant="warning"><b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> {{ $t("text.admin.alert-no-artnr") }}</b-alert>
              <b-form-group :label="$t('text.produktbild')" v-if="form.artnr">
                <b-form-row>
                  <div class="shadow-sm w-100 border">
                    <ProductImages :artnr="form.artnr" :key="uselessNumber" @on-deleted-image="uselessNumber--"></ProductImages>
                  </div>
                </b-form-row>
                <b-form-row class="mt-3" v-if="form.artnr && showOriginal">
                  <b-col>
                    <upload-product-images multiple :for-product-art="form.artnr" @on-select-product-image="setProductImageData" @on-uploaded-image="uploadDone(...arguments)"></upload-product-images>
                  </b-col>
                </b-form-row>
              </b-form-group>
              <div class="accordion" role="tablist" v-if="showOriginal">
                <b-card no-body border-variant="primary" header-bg-variant="primary" header-text-variant="white">
                  <b-card-header header-tag="header" class="p-0 bg-white" role="tab">
                    <b-button block v-b-toggle.montagestolpar variant="outline-primary">{{ $t("text.admin.passar-till-elslutbleck") }}</b-button>
                  </b-card-header>
                  <b-collapse id="montagestolpar" accordion="montagestolpar" role="tabpanel">
                    <b-card-body class="px-2 py-1">
                      <div v-for="(eb, i) in elslutbleck" :key="`eb-${i}`">
                        <b-form-checkbox v-model="selected['passande-elslutbleck']" :value="eb.name" unchecked-value="" @change="updateRelatedEbSpec(eb, selected['passande-elslutbleck'].indexOf(eb.name) > -1)">{{ eb.name }}</b-form-checkbox>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </b-col>
          </b-row>
          <!-- <hr class="my-3" /> -->
          <b-form-row v-if="form.artnr" class="border rounded p-3 mt-3 mb-4">
            <b-col>
              <b-form-group v-if="form.artnr">
                <upload-product-docs :language="access_level == 2 ? 'no' : 'se'" v-if="form.artnr" multiple :docs="form.docs" :for-product-art="form.artnr" @on-select-product-docs="setProductDocs"></upload-product-docs>
                <small>{{ $t("text.admin.ladda-upp-bilagor-max") }} {{ maxSize | fsize }}</small>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-row class="mt-4">
            <b-col>
              <b-button size="lg" type="button" :to="{ name: 'adm-dashboard' }" variant="outline-primary"><b-icon icon="chevron-left"></b-icon> {{ $t("text.admin.ga-till-lista") }}</b-button>
            </b-col>
            <b-col align="right">
              <b-button size="lg" type="submit" variant="primary">{{ $t("text.admin.spara") }}</b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-overlay>
    </b-form>
  </div>
</template>

<script>
  import ApiServiceAdmin from "@/services/ApiServiceAdmin";
  // import FormDataList from "@/components/admin/form/FormDataList";
  import AdminEditMixin from "@/mixins/AdminEditMixin";
  import AdminAccessMixin from "@/mixins/AdminAccessMixin";
  import _ from "lodash";

  export default {
    name: "AdminMsEdit",
    mixins: [AdminEditMixin, AdminAccessMixin],
    components: {
      // FormDataList,
    },
    data() {
      return {
        language: process.env.VUE_APP_I18N_LOCALE,
        changedEb: [],
        selected: {
          "passande-elslutbleck": [],
        },
        factsOptions: {
          "Passar låshustyp": {
            options: [
              { text: this.$t("dubbelfall"), value: "dubbelfall" },
              { text: this.$t("enkelfall"), value: "enkelfall" },
              { text: this.$t("cylinderfall"), value: "cylinderfall" },
              { text: this.$t("tryckesfall"), value: "tryckesfall" },
            ],
          },
          Material: {
            options: [
              { text: this.$t("rostfritt"), value: "rostfritt" },
              { text: this.$t("stal-forzinkat"), value: "stal-forzinkat" },
            ],
          },
          "Plan / Vinklad": {
            options: [
              { text: this.$t("plan"), value: "plan" },
              { text: this.$t("vinklad"), value: "vinklad" },
            ],
          },
          "Material karm": {
            options: [
              { text: this.$t("aluminium"), value: "aluminium" },
              { text: this.$t("stal"), value: "stal" },
              { text: this.$t("tra"), value: "tra" },
            ],
          },
          Hängning: {
            options: [
              { text: this.$t("symmetrisk"), value: "symmetrisk" },
              { text: this.$t("hoger"), value: "hoger" },
              { text: this.$t("vanster"), value: "vanster" },
            ],
          },
          Hörn: {
            options: [
              { text: this.$t("rak"), value: "rak" },
              { text: this.$t("rund"), value: "rund" },
            ],
          },
        },
        old: {
          name: "",
          artnr: "",
        },
        form: {
          name: "",
          artnr: "",
          enr: "",
          nobnr: "",
          spec: {},
          info: [
            {
              title: "Användningsområde",
              body: "",
            },
          ],
          facts: [
            {
              name: "passar-lastyp",
              title: "Passar låshustyp",
              value: [],
              options: [
                { text: "Dubbelfall", value: "dubbelfall" },
                { text: "Enkelfall", value: "enkelfall" },
                { text: "Cylinderfall", value: "cylinderfall" },
                { text: "Tryckesfall", value: "tryckesfall" },
              ],
            },
            {
              name: "material",
              title: "Material",
              value: [],
              options: [
                { text: "Rostfritt", value: "rostfritt" },
                { text: "Stål förzinkat", value: "stal-forzinkat" },
              ],
            },
            {
              name: "plan-vinklad",
              title: "Plan / Vinklad",
              value: [],
              options: [
                { text: "Plan", value: "plan" },
                { text: "Vinklad", value: "vinklad" },
              ],
              update: { name: "plan-vinklad" },
            },
            { name: "profilsystems-tillverkare", title: "Profilsystem", value: "", dropdown: [], update: { name: "profilsystems-tillverkare" } },
            {
              name: "material-karm",
              title: "Material karm",
              value: [],
              options: [
                { text: "Aluminium", value: "aluminium" },
                { text: "Stål", value: "stal" },
                { text: "Trä", value: "tra" },
              ],
              update: { name: "material-karm" },
            },
            {
              name: "hangning",
              title: "Hängning",
              value: [],
              options: [
                { text: "Symmetrisk", value: "symmetrisk" },
                { text: "Höger", value: "hoger" },
                { text: "Vänster", value: "vanster" },
              ],
              update: { name: "hangning" },
            },
            { name: "hojd", title: "Höjd", value: null, suffix: "mm", update: { name: "hojd", pattern: "h-" } },
            { name: "bredd", title: "Bredd", value: null, suffix: "mm", update: { name: "bredd", pattern: "b-" } },
            { name: "plosmatt", title: "Plösmått", value: null, suffix: "mm", update: { name: "plosmatt", pattern: "p-" } },
            { name: "vinkeldjup", title: "Vinkeldjup", value: null, suffix: "mm", update: { name: "vinkeldjup", pattern: "vd-" } },
            {
              name: "horn",
              title: "Hörn",
              value: [],
              options: [
                { text: "Rak", value: "rak" },
                { text: "Rund", value: "rund" },
              ],
              update: { name: "horn" },
            },
          ],
          published: false,
        },
        factsKeys: ["passar-lastyp", "material", "plan-vinklad", "profilsystems-tillverkare", "material-karm", "hangning", "hojd", "bredd", "plosmatt", "vinkeldjup", "horn"],
        options: {
          "passande-elslutbleck": [],
        },
        lhs: null,
        show: true,
        tableData: [{ a: "_a" }, { a: "_b" }],
        elslutbleck: [],
      };
    },
    computed: {
      canPublishSE() {
        return this.form.name && this.form.artnr && this.form.info[0].body;
      },
      canPublishNO() {
        return this.form.translations && this.form.translations.no.name && this.form.translations.no.artnr && this.form.translations.no.info[0].body;
      },
      title: {
        get() {
          return this.form.name ? this.form.name : this.$t("text.admin.add-ms");
        },
      },
      allSelected() {
        return this.selected;
      },
      lockids() {
        // Return array with unique IDs concatinated from spec arrays
        let concatArray = [];
        for (const name in this.form.spec) {
          concatArray = [...new Set([...concatArray, ...this.form.spec[name]])];
        }
        return concatArray;
      },
      slugs() {
        return Object.keys(this.form.spec);
      },
    },
    methods: {
      updateRelatedEbSpec(eb, checked) {
        console.log("checked", checked);
        console.log("spec", eb.spec);

        const msname = this.form.name;
        console.log("msname", msname);

        if (!checked) {
          // If MS is uncheckded we should remove the spec attribute
          if (eb.facts[13].value.indexOf(this.form.name) != -1) {
            eb.facts[13].value.splice(eb.facts[13].value.indexOf(this.form.name), 1);
          }
        } else {
          // If OLD spec attribute exists, remove it
          if (eb.facts[13].value.indexOf(this.old.name) != -1) {
            eb.facts[13].value.splice(eb.facts[13].value.indexOf(this.old.name), 1);
          }
          // If MS IS checked we shold add it and update name if needed
          eb.facts[13].value.push(this.form.name); // Add spec attribute

          // Make sure there are no duplicates before updating
          eb.facts[13].value = _.uniq(eb.facts[13].value);
        }
        // Add EB to list of items should be updated on save
        this.changedEb.push(eb);
      },
      getTokenizedValue(value, update, pattern) {
        const val = String(value);
        if (val && update && pattern) {
          let tokenizedValue = pattern + val.replace(".", "-") + (val.indexOf(".") == -1 ? "-0" : "");
          return tokenizedValue;
        }
        return val;
      },
      updateEb(ms) {
        ApiServiceAdmin.updateEbById(ms._id, { name: ms.name, artnr: ms.artnr, spec: ms.spec, facts: ms.facts, info: ms.info, published: ms.published, translations: ms.translations }).then(this.handleSuccess).then(this.handleError);
      },
      updateOld(fact) {
        if (fact.update && fact.update.name) {
          if (Array.isArray(fact.value)) {
            // Value is array, need to handle separate values
            this.selected[fact.update.name] = [];
            fact.value.forEach((val) => {
              this.selected[fact.update.name].push(this.getTokenizedValue(val, fact.update, fact.update.pattern));
            });
          } else if (this.selected[fact.update.name]) {
            this.selected[fact.update.name][0] = this.getTokenizedValue(fact.value, fact.update, fact.update.pattern);
          }
        }
      },
      uploadDone() {
        this.uselessNumber++;
      },
      updateMsSpecObj(spec) {
        this.form.spec = spec;
      },
      getFactByName(name) {
        return this.form.facts ? this.form.facts.filter((fact) => fact.name == name) : false;
      },
      async initFormData() {
        console.log("initFormData");
        if (this.$route.params.id != "add") {
          // Only execute when there is an ID to get data from
          try {
            await ApiServiceAdmin.getMsDataById(this.$route.params.id, []).then(async (response) => {
              this.form = await _.pick(response.data, ["name", "artnr", "enr", "nobnr", "spec", "info", "facts", "published", "translations"]);
              if (this.form.published === null) {
                this.form.published = true;
              }
              if (this.form.translations === null) {
                this.form.translations = { no: { name: "", info: [{ title: "Bruksområde", body: "" }] } };
              }
              this.old.artnr = this.form.artnr;
            });
          } catch (error) {
            console.error("getMsDataById", error);
          }
        }
        try {
          ApiServiceAdmin.getMsOptions().then(async (response) => {
            const data = await response.data;
            console.log("data", data);
            data.forEach((option) => {
              this.options[option.name] = {
                _id: option._id,
                name: option.name,
                slugs: option.slugs,
              };
            });
            ApiServiceAdmin.getEb() // Passande elslutbleck
              .then(async (response) => {
                const data = await response.data;
                this.options["passande-elslutbleck"] = _.map(data, (eb) => eb.name);
                this.elslutbleck = data;
                this.initSelected();
              })
              .catch((error) => console.error("getEb", error));
          });

          // const arrWithEbNames = _.map(ebs.data, (eb) => eb.name);
          // Get options
        } catch (error) {
          console.error(error);
        }
      },
      async initSelected() {
        let optionsKeys = await _.keysIn(this.options);

        console.log("this.form.facts", this.form.facts);
        for (let i = 0; i < optionsKeys.length; i++) {
          const key = optionsKeys[i];
          const factWithName = _.filter(this.form.facts, (f) => f.name == key)[0];
          if (factWithName !== undefined) {
            if (Array.isArray(factWithName.value)) {
              this.selected[key] = [];
              factWithName.value.forEach((slug) => {
                this.form.spec[slug] = [];
                this.selected[key].push(slug);
              });
            } else {
              // Numbers
              const tokenizedKey = this.getTokenizedValue(factWithName.value, factWithName.update, factWithName.update.pattern);
              this.form.spec[tokenizedKey] = [];
              this.selected[key] = [tokenizedKey];
            }
          }
          if (key == "passande-elslutbleck") {
            let passandeElslutblecks = _.intersection(this.options["passande-elslutbleck"], _.keysIn(this.form.spec));
            console.log("KEY", key);
            console.log("optionSlugs", this.options);
            console.log("formSpecKeys", this.form.spec);
            this.selected[key] = passandeElslutblecks;
          }
        }
      },
      onChange() {
        console.log("pre-update", this.form.spec);
        console.log('this.allSelected',this.allSelected)
        const allValuesArray = _.filter(this.allSelected, (x) => x.length > 0);
        console.log('allValuesArray',allValuesArray)
        const allValuesFlattened = _.flatten(allValuesArray);
        console.log('allValuesFlattened',allValuesFlattened)
        let emptyValues = [];
        for (let i = 0; i < allValuesFlattened.length; i++) {
          emptyValues.push([]);
        }
        this.form.spec = _.zipObject(allValuesFlattened, emptyValues);
      },
      saveNewMs() {
        // Add new MS
        ApiServiceAdmin.addMs(JSON.stringify(this.form))
          .then((response) => {
            this.$router.push(`/admin/ms/${response.data.result._id}`);
            this.id = response.data.result._id;
            this.handleSuccess(response);
          })
          .catch(this.handleError);
      },
      updateMs() {
        ApiServiceAdmin.updateMsById(this.id, JSON.stringify(this.form), 1) // [id], [payload], [loglevel]
          .then((response) => {
            // Update folder name in S3
            if (this.form.artnr != this.old.artnr) {
              console.log("Update folder name in S3");
              ApiServiceAdmin.updateFolderByArt(this.form.artnr, this.old.artnr)
                .then(() => {
                  this.old.artnr = this.form.artnr;
                  // this.makeToast({ variant: "info", title: this.$t("messages.post-update-artnr"), text: this.$t("messages.post-save") });
                })
                .catch(this.handleError);
            }
            // Use list of updated related MS to update them
            console.log("list of changed ms", this.changedEb);
            this.changedEb.forEach((eb) => {
              ApiServiceAdmin.updateEbById(eb._id, _.pick(eb, ["artnr", "name", "spec", "published", "facts", "info", "translations"]))
                .then((response) => {
                  this.changedEb = [];
                  this.handleSuccess(response);
                })
                .catch(this.handleError);
            });
            // A list of all ms that should be updated = this.selectedMs
            // console.log("passande elslutbleck", this.options["passande-elslutbleck"]);
            // this.options["passande-elslutbleck"].forEach((eb) => {
            //   let ebObj = _.filter(this.elslutbleck, (_ebObj) => _ebObj.name == eb)[0];
            //   let ebChecked = this.selected["passande-elslutbleck"].indexOf(ebObj.name) != -1;

            //   if (!ebChecked) {
            //     // If MS is uncheckded we should remove the spec attribute
            //     if (ebObj.facts[13].value.indexOf(this.form.name) != -1) {
            //       ebObj.facts[13].value.splice(ebObj.facts[13].value.indexOf(this.form.name), 1);
            //     }
            //   } else {
            //     // If OLD spec attribute exists, remove it
            //     if (ebObj.facts[13].value.indexOf(this.old.name) != -1) {
            //       ebObj.facts[13].value.splice(ebObj.facts[13].value.indexOf(this.old.name), 1);
            //     }
            //     // If MS IS checked we shold add it and update name if needed
            //     ebObj.facts[13].value.push(this.form.name); // Add spec attribute

            //     // Make sure there are no duplicates before updating
            //     ebObj.facts[13].value = _.uniq(ebObj.facts[13].value);
            //   }
            //   ApiServiceAdmin.updateEbById(ebObj._id, _.pick(ebObj, ["artnr", "name", "spec", "published", "facts", "info", "translations"])).catch(this.handleError);
            // });
            this.handleSuccess(response);
          })
          .catch(this.handleError);
      },
      async onSubmit(event) {
        event.preventDefault();
        
        const unique_enr = await this.isUnique('enr', this.form.enr, this.id)
        const unique_nobnr = await this.isUnique('nobnr', this.form.nobnr, this.id)
        if ( !unique_enr || !unique_nobnr ) {
          // Prevent saving if not unique
          this.handleError('E11000_DUPLICATE_KEY')
          return false;
        }
        this.loading = true;
        if (!this.id) {
          this.saveNewMs();
        } else {
          this.updateMs();
        }
        // If new value does not exist in options, we need to add it
        this.form.facts
          .filter((fact) => fact.update)
          .forEach((fact) => {
            // console.log("this.options[fact.name].indexOf(this.getTokenizedValue(fact))", this.options[fact.name].indexOf(this.getTokenizedValue(fact)));
            if (fact.value && this.options[fact.name] && this.options[fact.name].slugs.indexOf(this.getTokenizedValue(fact.value, fact.update, fact.update.pattern)) == -1 && this.getTokenizedValue(fact.value, fact.update, fact.update.pattern) != "") {
              this.options[fact.name].slugs.push(this.getTokenizedValue(fact.value, fact.update, fact.update.pattern));
              ApiServiceAdmin.updateMsOptionsById(this.options[fact.name]._id, _.pick(this.options[fact.name], ["name", "slugs"]))
                .then(this.handleSuccess)
                .catch(this.handleError);
            }
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../../scss/_colors.scss";
  table {
    th,
    td {
      border-width: 2px;
      border-color: white;
    }
    th {
      background-color: $primary;
      color: $white;
      vertical-align: middle;
    }
    td {
      background-color: $gray-100;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        white-space: nowrap;
      }
    }
  }
</style>
