import UploadProductImages from "@/components/admin/form/UploadProductImages";
import UploadProductDocs from "@/components/admin/form/UploadProductDocs";
import ProductImages from "@/components/products/ProductImages";
export default {
  components: {
    UploadProductImages,
    UploadProductDocs,
    ProductImages
  },
  data() {
    return {
      productImageData: {
        src: "",
        center: true,
        // width: 350,
        // height: 468,
        fluid: true,
        // fluidGrow: true,
        blank: true,
        thumbnail: false,
        blankColor: "transparent",
      },

    }
  },
  methods: {
    async initProductImageData() {
      console.log('initProductImageData')
      // this.productImageData.src = await `${process.env.VUE_APP_S3_BASE_URL}${this.form.artnr}/images/${this.form.artnr}`;
      // this.productImageData.blank = false;
    },
    setProductImageData(img, files) {
      console.log('setProductImageData', files)
      // img.blank = false;
      // this.productImageData = { ...this.productImageData, ...img };
      // this.files = files;
      this.makeToast({ variant: null, title: "Ok!", text: this.$t('messages.post-upload') });
    },
    setProductDocs(docs) {
      console.log("set product docs", docs);
    },
  },
}