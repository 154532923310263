<template>
    <div>
        <div v-for="(asset, i) in assets" :key="`asset-${i}`" class="mb-2" style="max-width: 300px;">
            <small><b-icon-file-earmark></b-icon-file-earmark> <span class="">{{decodeURI(asset.name)}}</span></small><br>
        </div>
    </div>
</template>
<script>
import ApiServiceAdmin from '../../services/ApiServiceAdmin';
import futils from "@/mixins/futils";
export default {
    name: "AdminAssetsList",
    mixins: [futils],
    props: {
        artnr: String,
        type: String
    },
    data() {
        return {
            assets: []
        };
    },
    methods: {
        fetchAssets() {
            this.assets = [];
        }
    },
    created() {
        ApiServiceAdmin.getAssetsByArt(this.artnr, this.type).then((response) => {
            this.assets = response.data;
        });
    }
}
</script>
<style lang="">
    
</style>