<template>
    <div>
        <b-tabs v-model="activetab" pills content-class="m-0" card>
            <b-tab :title="$t('Produkter')" no-body lazy>
                <AdminEmList :data="products" @on-deleted-item="fetchEm"></AdminEmList>
            </b-tab>
            <b-tab :title="$t('Kriterier')" no-body lazy>
                <AdminEmOptionsList></AdminEmOptionsList>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
    import AdminEmList from "@/components/admin/AdminEmList";
    import AdminEmOptionsList from "@/components/admin/AdminEmOptionsList";
    import ApiServiceAdmin from "@/services/ApiServiceAdmin";
    export default {
        components: {
            AdminEmList,
            AdminEmOptionsList,
            ApiServiceAdmin
        }, 
        data() {
            return {
                products: [],
                totalForSlug: {},
                activetab: 0
            }
        },
        methods: {
            fetchEm() {
                ApiServiceAdmin.getEm().then((response) => {
                    this.products = response.data;
                });
            },
        },
        mounted() {
            this.fetchEm();
        },
    }

</script>