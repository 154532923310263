export default {
  computed: {
    access_level() {
      // return 2;
      return this.$store.state.user.access_level;
    },
    showOriginal() {
      return this.access_level < 2;
    },
    showTranslation() {
      return this.access_level == 2;
    },
    showAddAndDelete() {
      return this.access_level < 2;
    },
  },
}