<template>
  <div class="py-5">
    <b-form novalidate @submit="onSubmit" @change="onChange" @reset="onReset">
      <b-overlay :show="loading" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block w-100" @hidden="onOverlayHidden">
        <b-container>
          <h2>{{ title }}</h2>
          <b-row>
            <b-col>
              <b-form-row>
                <b-col>
                  <b-form-group :label="$t('form.namn')">
                    <b-form-input v-if="showOriginal" required id="lh-name" v-model="form.name"></b-form-input>
                    <b-input-group v-if="showTranslation">
                      <b-form-input required id="lh-name-no" v-model="form.translations.no.name"></b-form-input>
                      <template #append>
                        <country-flag country="no" size="normal" class="ml-n5" />
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <!-- <b-col cols="2">
                  <input type="hidden" name="old_artnr" v-model="old.artnr" />
                  <b-form-group :label="$t('form.artnr')">
                    <b-form-input v-if="showOriginal" required id="lh-artnr" v-model="form.artnr"></b-form-input>
                    <b-input-group v-if="showTranslation">
                      <b-form-input required id="lh-artnr-no" v-model="form.translations.no.artnr"></b-form-input>
                      <template #append>
                        <country-flag country="no" size="normal" class="ml-n5" />
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col> -->
                <b-col cols="2">
                  <b-form-group :label="$t('form.lockid')">
                    <b-form-input id="lh-lockid" v-model="form.lockid" @change="form.artnr = form.lockid"></b-form-input>
                  </b-form-group>
                </b-col>
                <!-- <b-col cols="3">
                  <b-form-group v-if="showOriginal" :label="$t('form.enr')">
                    <b-form-input required id="eb-enr" v-model="form.enr"></b-form-input>
                  </b-form-group>
                  <b-form-group v-if="showTranslation" :label="$t('form.nobnr')">
                    <b-input-group>
                      <b-form-input required id="eb-nobnr" v-model="form.nobnr"></b-form-input>
                      <template #append>
                        <country-flag country="no" size="normal" class="ml-n5" />
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col> -->
              </b-form-row>
              <b-form-row v-for="(info, i) in form.info" v-bind:key="`info-${i}`">
                <b-col>
                  <b-form-group :label="$t(info.title)">
                    <b-form-textarea v-if="showOriginal" rows="5" wrap="soft" :formatter="removeHtml" id="`info-${i}`" v-model="info.body"></b-form-textarea>
                    <b-input-group v-if="showTranslation">
                      <b-form-textarea rows="5" wrap="soft" :formatter="removeHtml" id="`no-info-${i}`" v-model="form.translations.no.info[i].body"></b-form-textarea>
                      <template #append>
                        <country-flag country="no" size="normal" class="ml-n5" />
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row v-if="showOriginal">
                <b-col>
                  <b-form-checkbox :disabled="!canPublishSE" v-model="form.published" switch>
                    {{ $t("text.admin.published") }} <small>{{ $t("text.admin.published-help") }}</small>
                  </b-form-checkbox>
                </b-col>
              </b-form-row>

              <div v-if="showTranslation">
                <b-form-row>
                  <b-col>
                    <b-form-checkbox :disabled="!canPublishNO" v-model="form.translations.no.published" switch>
                      {{ $t("text.admin.published") }} <small>{{ $t("text.admin.published-help") }}</small>
                    </b-form-checkbox>
                  </b-col>
                </b-form-row>
              </div>

              <!-- Edit facts -->
              <div v-if="showOriginal" class="mt-5">
                <h3>{{ $t("text.fakta") }}</h3>
                <table class="table facts">
                  <tbody>
                    <!-- <tr>
                      <th>{{ $t("form.artnr") }}</th>
                      <td>{{ form.artnr }}</td>
                    </tr> -->
                    <tr v-for="(fact, i) in form.facts" :key="`fact-${i}`">
                      <th>{{ $t(fact.title) }}</th>
                      <td>
                        <div v-if="factsOptions[fact.title] && factsOptions[fact.title].options">
                          <div v-if="Array.isArray(fact.value)">
                            <b-form-checkbox-group v-model="fact.value" :options="factsOptions[fact.title].options" :name="`fact-options-${i}`"></b-form-checkbox-group>
                          </div>
                          <div v-else><b-form-radio-group v-model="fact.value" :options="factsOptions[fact.title].options" :name="`fact-options-${i}`"></b-form-radio-group></div>
                        </div>
                        <!-- If fact.value is an array and has multiple values -->
                        <div v-if="Array.isArray(fact.value) && !factsOptions[fact.title].options" class="d-flex flex-wrap" style="gap: 10px">
                          <div v-for="(value, j) in fact.value" :key="`fact-value-${j}`">
                            <b-input-group>
                              <b-form-input v-model="fact.value[j]" type="number"></b-form-input>
                              <b-input-group-text>
                                {{ fact.suffix }}
                              </b-input-group-text>
                              <b-button variant="danger" size="sm" @click="fact.value.splice(j, 1)"><b-icon icon="dash"></b-icon></b-button>
                            </b-input-group>
                          </div>
                          <button type="button" :disabled="fact.value[fact.value.length - 1] == ''" class="btn btn-success" @click="fact.value.push('')"><b-icon icon="plus"></b-icon></button>
                        </div>
                        <!-- <b-form-input v-if="fact.value == 'JA' && fact.text != null" placeholder="fritext" v-model="fact.text"></b-form-input> -->

                        <!-- {{ factOptions[fact.title] }} -->
                        <!-- <b-form-group label="" v-slot="{ ariaDescribedby }">
                        <b-form-radio-group :id="`radio-group-${i}`" v-model="fact.value" :options="fact.options" :aria-describedby="ariaDescribedby" name="radio-options"></b-form-radio-group>
                      </b-form-group> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <label for="" class="mt-5">{{ $t("text.varianter") }}</label>

              <table class="table variants">
                <thead>
                  <tr>
                    <th v-if="showTranslation"></th>
                    <th>{{ $t("form.namn") }}</th>
                    <th>{{ $t("form.artnr") }}</th>
                    <th colspan="2" v-if="showOriginal">{{ $t("form.enr") }}</th>
                    <th colspan="2" v-if="showTranslation">{{ $t("form.nobnr") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(variant, i) in form.variants" :key="`variants-${i}`">
                    <td v-if="showTranslation" style="vertical-align: middle">{{ variant.title }}</td>
                    <td>
                      <b-input v-if="showOriginal" v-model="variant.title" id="`variant-title-${i}`" required></b-input>
                      <b-input-group v-if="showTranslation">
                        <b-input v-model="form.translations.no.variants[i].title" id="`no-variant-title-${i}`"></b-input>
                        <template #append>
                          <country-flag country="no" size="normal" class="ml-n5" />
                        </template>
                      </b-input-group>
                    </td>
                    <td>
                      <b-input v-if="showOriginal" v-model="variant.artnr" id="`variant-artnr-${i}`" required></b-input>
                      <b-input-group v-if="showTranslation">
                        <b-input v-model="form.translations.no.variants[i].artnr" id="`no-variant-artnr-${i}`"></b-input>
                        <template #append>
                          <country-flag country="no" size="normal" class="ml-n5" />
                        </template>
                      </b-input-group>
                    </td>
                    <td v-if="showOriginal">
                      <b-input v-model="variant.enr" id="`variant-enr-${i}`" required></b-input>
                    </td>
                    <td v-if="showTranslation">
                      <b-input-group>
                        <b-input v-model="variant.nobnr" id="`no-variant-nobnr-${i}`"></b-input>
                        <template #append>
                          <country-flag country="no" size="normal" class="ml-n5" />
                        </template>
                      </b-input-group>
                    </td>
                    <td width="5" v-if="showOriginal">
                      <button class="btn btn-danger" @click="removeVariant(variant.artnr, $event)"><b-icon icon="dash"></b-icon></button>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="3" align="right"><small>{{ $t('form.enr-help') }}</small></td>
                    <td align="right" v-if="showOriginal">
                      <button type="button" class="btn btn-success" @click="form.variants.push({ title: '', artnr: '' })">{{ $t("text.admin.lagg-till-variant") }} <b-icon icon="plus"></b-icon></button>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <div class="mt-5" v-if="showTranslation">
                <label>{{ $t("text.admin.sampletext-orig") }}</label>
                <b-card class="shadow-sm" no-body bg-variant="light">
                  <div class="d-flex" style="justify-content: space-between">
                    <h3 class="m-3">{{ form.name }}</h3>
                    <div class="my-3 ml-auto">{{ form.artnr }}</div>
                    <div class="mt-2">
                      <country-flag country="se" size="normal" class="mr-0 ml-0" />
                    </div>
                  </div>
                  <div class="px-3">
                    <dl v-for="(info, i) in form.info" v-bind:key="`product-info-${i}`">
                      <div v-if="info.body">
                        <dt>{{ info.title }}</dt>
                        <dd v-html="info.body" style="white-space: pre-wrap"></dd>
                      </div>
                    </dl>
                  </div>
                  <!-- Variants -->
                  <!-- <div class="p-3">
                    <table class="table table-sm variants" v-if="form.variants && form.variants.length">
                      <thead>
                        <tr>
                          <th>{{ $t("form.admin.varianter") }}</th>
                          <th>{{ $t("form.artnr") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(fact, i) in form.variants" v-bind:key="`product-facts-${i}`">
                          <td>{{ fact.title }}</td>
                          <td v-html="fact.artnr"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div> -->
                </b-card>
              </div>
            </b-col>
            <b-col cols="4">
              <b-alert :show="form.artnr == ''" variant="warning"><b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> {{ $t("text.admin.alert-no-artnr") }}</b-alert>
              <b-form-group :label="$t('text.produktbild')" v-if="form.artnr">
                <b-form-row>
                  <div class="shadow-sm w-100 border">
                    <ProductImages :artnr="form.artnr" :key="uselessNumber" @on-deleted-image="uselessNumber--"></ProductImages>
                  </div>
                </b-form-row>
                <b-form-row class="mt-3" v-if="form.artnr && showOriginal">
                  <b-col>
                    <upload-product-images multiple :for-product-art="form.artnr" @on-select-product-image="setProductImageData" @on-uploaded-image="uploadDone(...arguments)"></upload-product-images>
                  </b-col>
                </b-form-row>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-row v-if="form.artnr" class="border rounded p-3 mt-3 mb-4">
            <b-col>
              <b-form-group>
                <upload-product-docs :language="access_level == 2 ? 'no' : 'se'" v-if="form.artnr" multiple :for-product-art="form.artnr" @on-select-product-docs="setProductDocs"></upload-product-docs>
                <small>{{ $t("text.admin.ladda-upp-bilagor-max") }} {{ maxSize | fsize }}</small>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-row>
            <b-col>
              <b-button type="button" :to="{ name: 'adm-dashboard' }" variant="outline-primary"><b-icon icon="chevron-left"></b-icon> {{ $t("text.admin.ga-till-lista") }}</b-button>
            </b-col>
            <b-col align="right">
              <b-button type="submit" :disabled="preventSubmit" variant="primary">{{ $t("text.admin.spara") }}</b-button>
            </b-col>
          </b-row>
        </b-container>
        <div v-if="showOriginal && id">
          <hr class="mt-5 mb-0" />
          <b-container fluid class="bg-light p-md-5 px-sm-1 py-sm-3">
            <b-row>
              <b-col class="px-md-5 px-sm-0">
                <h2 class="mb-4">{{ $t("text.admin.kriterier-for") }} {{ form.name }}</h2>
                <AdminLhOptionsEdit :lhid="this.id" :lhnr="form.lockid" :lhebs="this.form.elslutbleck" @on-change-slug="updateChangedEbs(...arguments)" />
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-overlay>
    </b-form>
  </div>
</template>

<script>
  import ApiServiceAdmin from "@/services/ApiServiceAdmin";
  import AdminLhOptionsEdit from "@/components/admin/AdminLhOptionsEdit";
  import AdminEditMixin from "@/mixins/AdminEditMixin";
  import AdminAccessMixin from "@/mixins/AdminAccessMixin";
  import _ from "lodash";

  export default {
    name: "AdminLhEdit",
    mixins: [AdminEditMixin, AdminAccessMixin],
    components: {
      AdminLhOptionsEdit,
    },
    data() {
      return {
        preventSubmit: false,
        language: process.env.VUE_APP_I18N_LOCALE,
        spec: null,
        updatedEbs: [],
        factsOptions: {
          Dorndjup: {
            values: [{ text: "", suffix: "mm" }],
          },
          Låshustyp: {
            options: [
              { text: this.$t("Dubbelfall"), value: "Dubbelfall" },
              { text: this.$t("Enkelfall"), value: "Enkelfall" },
              { text: this.$t("Cylinderfall"), value: "Cylinderfall" },
              { text: this.$t("Tryckesfall"), value: "Tryckesfall" },
            ],
          },
          Vändbar: {
            options: [
              { text: this.$t("text.nej"), value: "NEJ" },
              { text: this.$t("text.ja"), value: "JA" },
            ],
          },
          Microbrytare: {
            options: [
              { text: this.$t("text.nej"), value: "NEJ" },
              { text: this.$t("text.ja-tillval"), value: "JA" },
            ],
          },
          Brandmotstånd: {
            options: [
              { text: this.$t("text.nej"), value: "NEJ" },
              { text: this.$t("text.ja"), value: "JA" },
            ],
          },
          "Certifierat enligt SS-EN 12209": {
            options: [
              { text: this.$t("text.nej"), value: "NEJ" },
              { text: this.$t("text.ja"), value: "JA" },
            ],
          },
          "Certifierat enligt SS-EN 179": {
            options: [
              { text: this.$t("text.nej"), value: "NEJ" },
              { text: this.$t("text.ja"), value: "JA" },
            ],
          },
          "Certifierat enligt SS-EN 1125": {
            options: [
              { text: this.$t("text.nej"), value: "NEJ" },
              { text: this.$t("text.ja"), value: "JA" },
            ],
          },
          "Låsklass enligt SSF 3522": {
            options: [
              { text: "Klass 1A", value: "Klass 1A" },
              { text: "Klass 2A", value: "Klass 2A" },
              { text: "Klass 2B", value: "Klass 2B" },
              { text: "Klass 3", value: "Klass 3" },
            ],
          },
        },
        old: {
          artnr: "",
        },
        form: {
          name: "",
          artnr: "",
          lockid: "",
          elslutbleck: [],
          variants: [],
          facts: [
            { title: "Dorndjup", value: [], suffix: "mm" },
            { title: "Låshustyp", value: [] },
            { title: "Vändbar", value: "" },
            { title: "Microbrytare", value: "" },
            { title: "Brandmotstånd", value: "" },
            { title: "Certifierat enligt SS-EN 12209", value: "" },
            { title: "Certifierat enligt SS-EN 179", value: "" },
            { title: "Certifierat enligt SS-EN 1125", value: "" },
            { title: "Låsklass enligt SSF 3522", value: "" },
          ],
          info: [
            {
              title: "Användningsområde",
              body: "",
            },
            {
              title: "Funktion",
              body: "",
            },
          ],
        },
        options: [],
        show: true,
      };
    },
    computed: {
      canPublishSE() {
        return this.form.name && this.form.artnr && this.form.info[0].body;
      },
      canPublishNO() {
        return this.form.translations && this.form.translations.no.name && this.form.translations.no.info[0].body;
      },
      title: {
        get() {
          return this.form.name ? this.form.name : this.$t("text.admin.add-lh");
        },
      },
      slugs() {
        return Object.keys(this.form.spec);
      },
    },

    methods: {
      removeVariant(artnr, event) {
        event.preventDefault();
        const indexOfArtnr = _.findIndex(this.form.variants, function (v) {
          return v.artnr == artnr;
        });
        this.form.variants.splice(indexOfArtnr, 1);
      },
      uploadDone(files) {
        console.log("Upload done", files);
        this.uselessNumber++;
      },
      addEbToLh(eb) {
        // Add EB to housing.elslutbleck
        this.form.elslutbleck.push(eb);
      },
      async updateRelatedEbs() {
        // Update all related ebs
        console.log("updateRelatedEbs");
        if (this.updatedEbs.length) {
          this.updatedEbs.forEach((eb) => {
            console.log("eb", eb);
            ApiServiceAdmin.updateEbById(eb._id, { name: eb.name, artnr: eb.artnr, spec: eb.spec, facts: eb.facts, info: eb.info, published: eb.published, translations: eb.translations });
          });
        }
      },
      async updateChangedEbs(ebsFromTable) {
        console.log("updateChangedEbs", ebsFromTable);
        this.form.elslutbleck = await _.map(ebsFromTable, (eb) => ({
          _id: eb._id,
        }));
        this.updatedEbs = await _.map(ebsFromTable, (eb) => eb);
      },
      async initFormData() {
        try {
          await ApiServiceAdmin.getLhDataById(this.id, []).then((response) => {
            let formobj = _.pick(response.data, ["name", "artnr", "lockid", "enr", "nobnr", "elslutbleck", "info", "facts", "variants", "published", "translations"]);
            // formobj = _.pickBy(formobj, (item) => item !== null);
            // if (formobj.published === null) {
            //   // Published should never be null
            //   formobj.published = false;
            // }
            this.form = formobj;
            // this.form.translations.no.variants = this.form.variants;
            this.form.translations.no.variants = _.map(this.form.variants, (v, i) => {
              // Need this for the page not to crash
              console.log("title", this.form.translations.no.variants[i]);
              if (this.form.translations.no.variants[i] === undefined) {
                return { title: "", artnr: "" };
              }
              return { title: this.form.translations.no.variants[i].title ? this.form.translations.no.variants[i].title : "", artnr: this.form.translations.no.variants[i].artnr ? this.form.translations.no.variants[i].artnr : "" };
            });
            this.old.artnr = this.form.artnr;
          });
        } catch (error) {
          console.error(error);
        }
      },
      onSubmit(event) {
        event.preventDefault();
        this.loading = true;
        console.log('DONE')
        if (!this.id) {
          ApiServiceAdmin.addLh(JSON.stringify(this.form))
            .then((response) => {
              console.log("response", response);
              this.$router.push(`/admin/lh/${response.data.result._id}`);
              this.id = response.data.result._id;
              this.handleSuccess(response);
            })
            .catch(this.handleError);
        } else {
          ApiServiceAdmin.updateLhById(this.id, JSON.stringify(this.form))
            .then((response) => {
              // Update folder name in S3
              if (this.form.artnr != this.old.artnr) {
                console.log("Update folder name in S3");
                ApiServiceAdmin.updateFolderByArt(this.form.artnr, this.old.artnr)
                  .then(() => {
                    this.old.artnr = this.form.artnr;
                    // this.makeToast({ variant: "info", title: this.$t("messages.post-update-artnr"), text: this.$t("messages.post-save") });
                  })
                  .catch(this.handleError);
              }
              // Update related ebs
              this.updateRelatedEbs();
              this.handleSuccess(response);
            })
            .catch(this.handleError);
        }
      },
      onChange() {
        let variants = this.form.variants
        if ( this.$i18n.locale == "no" ) {
          variants = this.form.translations.no.variants
        }
        variants.forEach(async (variant) => {
          const unique_enr = await this.isUnique('enr', variant.enr, this.id)
          const unique_nobnr = await this.isUnique('nobnr', variant.nobnr, this.id)
          if ( !unique_enr || !unique_nobnr ) {
            // Prevent saving if not unique
            this.handleError('E11000_DUPLICATE_KEY')
            this.preventSubmit = true;
            return false;
          }
          console.log('HELLO')
        });
        this.preventSubmit = false;
      },
    },
    // watch: {
    //   'form.variants': {
    //     handler: function (val, oldVal) {
    //       val.forEach(async (variant) => {
    //         const unique_enr = await this.isUnique('enr', variant.enr, this.id)
    //         const unique_nobnr = await this.isUnique('nobnr', variant.nobnr, this.id)
    //         if ( !unique_enr || !unique_nobnr ) {
    //           // Prevent saving if not unique
    //           this.handleError('E11000_DUPLICATE_KEY')
    //           return false;
    //         }
    //         console.log('HELLO')
    //       });
    //       console.log("form changed", val, oldVal);
    //       // this.$emit("on-form-change", val);
    //     },
    //     deep: true,
    //   },
    // },
  };
</script>

<style lang="scss" scoped>
  @import "../../../scss/_colors.scss";
  table {
    th,
    td {
      border-width: 2px;
      border-color: white;
    }
    th {
      background-color: $primary;
      color: $white;
    }
    td {
      background-color: $gray-100;
    }
  }
</style>
