<template>
    <div>
        <b-overlay :show="loading" opacity="0.95" bg-color="white" no-center>
            <template #overlay>
                <b-icon
                icon="stopwatch"
                variant="info"
                animation="cylon" 
                scale="2"
                shift-v="8"
                shift-h="8"
                class="position-absolute"
                style="top: 40vh; right: 50%"
                ></b-icon>
            </template>
            <table class="table m-0" v-for="o in data" v-bind:key="o._id">
                <thead class="thead-light position-sticky" style="top: 87px;">
                    <tr>
                    <th>{{ $t(o.name) }}</th>
                    <th width="10"></th>
                    <th width="10%">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr :class="{'bg-light text-muted border-left border-warning': s.total == 0}" v-for="s in o.slugData" v-bind:key="s.name">
                    <td class="align-middle">{{ $t(s.name) }}</td>
                    <td>
                        <div v-show="s.total > 0">
                            <b-dropdown size="sm" :id="`products-${s}`" :text="s.total.toString()" variant="link" lazy>
                                <b-dropdown-header :id="`products-${s}-header`">{{ $t('Produkter') }}</b-dropdown-header>
                                <b-dropdown-item :id="p._id" v-for="p in s.products" @click.prevent="$emit('on-select-product', p._id)" v-bind:key="p._id">
                                    <span class="ml-n3" :class="{'text-muted': isPublished(p) == false}"><b-icon :icon="isPublished(p) ? 'record-fill': 'record'" :variant="isPublished(p) ? 'success': 'warning'"></b-icon> {{ p.name }}</span>
                                    <b-tooltip v-if="isPublished(p) === false" :target="p._id" variant="warning" placement="left">{{ $t('Ej publicerad') }}</b-tooltip>
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </td>
                    <td>
                        <b-button v-show="s.total == 0 && showAddAndDelete" variant="danger" @click.prevent="$emit('on-delete-item',s.name, options)"><b-icon-trash-fill></b-icon-trash-fill></b-button>
                    </td>
                    </tr>
                </tbody>
            </table>
        </b-overlay>
    </div>
</template>

<script>
    import AdminAccessMixin from '../../mixins/AdminAccessMixin';
    export default {
        name: "AdminOptionsListTable",
        mixins: [AdminAccessMixin],
        props: {
            data: Array,
            options: Array,
            loading: Boolean
        },
        methods: {
            isPublished(p) {
                console.log('p', p)
                if ( this.showTranslation ) {
                    return p.translations && p.translations.no.published;
                } else {
                    return p.published;
                }
            }
        },
        data: function() {
            return {
                totalForSlug: {},
                productsForSlug: {}
            };
        },
        updated() {
            setTimeout(() => {
                this.$emit('on-updated-options-list-table');
            }, 500);
        },
    }
</script>