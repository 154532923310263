<template>
  <div class="card mb-10 product" :class="{ 'active sticky-top': active }">
    <b-link class="text-decoration-none text-dark" :id="_id" @click.prevent="$emit('on-product-select', _id)">
      <div class="row no-gutters">
        <div class="col">
          <div class="card-body py-3 pl-3 pr-0">
            <div class="align-items-center" style="overflow: hidden">
              <h4>
                {{ translatedName }}
              </h4>
              <p v-if="translatedInfo && translatedInfo[0]" :title="translatedInfo[0].body" class="product-short-text">{{ translatedInfo[0].body }}</p>
            </div>
          </div>
        </div>
        <div class="col-auto" v-if="artnr">
          <ProductImages single :artnr="artnr"></ProductImages>
        </div>
      </div>
    </b-link>
  </div>
</template>

<script>
  import ProductImages from "@/components/products/ProductImages";
  export default {
    name: "ProductsListItem",
    props: ["_id", "name", "info", "artnr", "active", "translations"],
    components: {
      ProductImages,
    },
    computed: {
      translatedName() {
        const language = this.$i18n.locale;
        if (language != "sv" && this.translations && this.translations[language].name != "") {
          return this.translations[this.$i18n.locale].name;
        }
        return this.name;
      },
      translatedInfo() {
        const language = this.$i18n.locale;
        if (language != "sv" && this.translations && this.translations[language] && this.translations[language].info[0].body != "") {
          return this.translations[language].info;
        }
        return this.info;
      },
    },
  };
</script>

<style lang="scss" scoped>
  h4 {
    font-weight: 600;
    line-height: 21px;
    margin-bottom: 13px;
  }
  p {
    margin: 0;
  }
</style>
