<template>
  <div class="py-5 px-5">
    <b-container fluid>
      <table class="table table-hover table-sm">
        <thead class="table-dark">
          <tr>
            <th>date</th>
            <th>User</th>
            <th></th>
            <th></th>
            <th>Status</th>
            <th>Method</th>
            <th>Type</th>
            <th>message</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(a, i) in activities" v-bind:key="`log-${i}`">
            <td>{{ a.date | sv_date }}</td>
            <td v-b-tooltip.hover :title="a.user.email">
              {{ a.user.name }}
            </td>
            <td><country-flag :country="a.lang == 'no' ? 'no' : 'se'" size="small" /></td>
            <td align="center">
              <span v-if="a.page">
                <b-link v-if="a.method == 'put'" variant="primary" size="sm" target="_blank" :href="a.page.href">{{ a.page.title }}</b-link>
                <span v-else>{{ a.page.title }}</span>
              </span>
            </td>
            <td>{{ a.status }}</td>
            <td>{{ a.method }}</td>
            <td>{{ a.type }}</td>
            <td>{{ a.message }}</td>
            <td>
              <div v-if="a.datastr">
                <b-link v-b-modal="`popover-target-${i}`"><b-icon-code></b-icon-code></b-link>
                <b-modal :id="`popover-target-${i}`" size="xl">
                  <div>
                    <code>
                      <pre>{{ JSON.parse(a.datastr) }}</pre>
                    </code>
                  </div>
                </b-modal>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </b-container>
  </div>
</template>

<script>
  import ApiServiceAdmin from "../../services/ApiServiceAdmin";
  import AdminListMixin from "../../mixins/AdminListMixin";
  export default {
    name: "log",
    mixins: [AdminListMixin],
    data() {
      return {
        activities: [],
      };
    },
    mounted() {
      ApiServiceAdmin.getActivies().then((response) => {
        this.activities = response.data.map((a) => {
          return a;
        });
      });
    },
  };
</script>

<style></style>
