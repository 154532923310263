<template>
  <div>
    <b-navbar
      id="assa-navbar"
      variant="light"
      type="light"
      toggleable="lg"
      class="py-2 py-lg-3 px-3 px-lg-5"
    >
      <b-navbar-toggle
        target="nav-collapse"
        variant="light"
        class="p-0 border-0"
        style="width: 30px"
      ></b-navbar-toggle>
      <b-navbar-brand :to="{ name: 'index' }" class="mx-auto">
        <img src="@/assets/assa-logo.svg" id="assa-logo" alt="" />
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" class="p-0 border-0 d-lg-none">
        <b-iconstack font-scale="2.5">
          <b-icon stacked icon="circle-fill" variant="secondary"></b-icon>
          <b-icon stacked icon="search" scale="0.4" variant="dark"></b-icon>
        </b-iconstack>
      </b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav class="px-2 px-lg-0">
        <b-navbar-nav class="mx-auto" wrap>
          <b-nav-item :to="{ name: 'index' }">{{ $t("Start") }}</b-nav-item>
          <span class="border-right border-dark mx-2 my-1"></span>
          <b-nav-item-dropdown
            id="guide-dropdown"
            :text="$t('text.nav.guider')"
            toggle-class="nav-link-custom"
          >
            <b-dropdown-item :to="{ name: 'guide-filter-em-start' }">{{
              $t("text.nav.products-em")
            }}</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'guide-filter-eb-start' }">{{
              $t("text.nav.products-eb")
            }}</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'guide-filter-ms-start' }">{{
              $t("text.nav.products-ms")
            }}</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'guide-filter-sb-start' }">{{
              $t("text.nav.products-sb")
            }}</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item :to="{ name: 'guide-filter-ug' }">{{
              $t("text.nav.guide-ug")
            }}</b-dropdown-item>
          </b-nav-item-dropdown>
          <!-- <b-nav-item :to="{ name: 'guide-filter-eb' }">{{ $t("text.nav.guide-eb") }}</b-nav-item>
          <b-nav-item :to="{ name: 'guide-filter-ms' }">{{ $t("text.nav.guide-ms") }}</b-nav-item>
          <b-nav-item :to="{ name: 'guide-filter-ug' }">{{ $t("text.nav.guide-ug") }}</b-nav-item>
          <b-nav-item :to="{ name: 'guide-filter-em' }">{{ $t("text.nav.guide-em") }}</b-nav-item>
          <b-nav-item :to="{ name: 'guide-filter-sb' }">{{ $t("text.nav.guide-sb") }}</b-nav-item> -->
          <!-- <b-nav-item :to="{ name: 'guide-filter-ug-eb' }">{{ $t("text.nav.guide[3]") }}</b-nav-item> -->
          <span class="border-right border-dark mx-2 my-1"></span>
          <b-nav-item-dropdown
            id="products-dropdown"
            :text="$t('text.nav.produkter')"
            toggle-class="nav-link-custom"
          >
            <b-dropdown-item :to="{ name: 'products-em' }">{{
              $t("text.nav.products-em")
            }}</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'products-eb' }">{{
              $t("text.nav.products-eb")
            }}</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'products-lh' }">{{
              $t("text.nav.products-lh")
            }}</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'products-ms' }">{{
              $t("text.nav.products-ms")
            }}</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'products-sb' }">{{
              $t("text.nav.products-sb")
            }}</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'products-nb' }">{{
              $t("text.nav.products-nb")
            }}</b-dropdown-item>
          </b-nav-item-dropdown>
          <span
            v-show="loggedIn"
            class="border-right border-dark mx-2 my-1"
          ></span>
          <b-nav-item-dropdown
            v-if="loggedIn"
            id="my-nav-dropdown"
            :text="$t('text.nav.dropdown')"
            toggle-class="nav-link-custom"
          >
            <b-dropdown-item
              :to="{ name: 'adm-dashboard' }"
              v-show="loggedIn"
              >{{ $t("text.nav.dashboard") }}</b-dropdown-item
            >
            <b-dropdown-item
              :to="{ name: 'adm-utbytesguiden' }"
              v-show="loggedIn"
              >{{ $t("text.nav.utbytesguiden") }}</b-dropdown-item
            >
            <b-dropdown-item
              :to="{ name: 'adm-oversattningar' }"
              v-show="loggedIn"
              >{{ $t("text.nav.oversattningar") }}</b-dropdown-item
            >
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item variant="danger" @click="logout()">{{
              $t("text.nav.logout")
            }}</b-dropdown-item>
          </b-nav-item-dropdown>
          <!-- <b-nav-item :to="{ name: 'adm-utbytesguiden-eb' }" v-show="loggedIn">{{ $t("text.nav.utbytesguiden-eb") }}</b-nav-item> -->
          <!-- <b-nav-item v-if="!loggedIn" id="login" :to="{ name: 'login' }"><b-icon icon="person-circle"/></b-nav-item> -->
        </b-navbar-nav>
        <b-navbar-nav align="center">
          <b-nav-form @submit.prevent>
            <b-form-input
              block
              size="lg"
              v-model="token"
              type="search"
              class="filter-input bg-secondary border-0 text-dark"
              :placeholder="$t('text.nav.search')"
              @input="search(token)"
            ></b-form-input>
            <b-icon-search style="color: #ccc"></b-icon-search>
          </b-nav-form>
        </b-navbar-nav>
        <!-- <b-form-group v-if="$store.state.user.access_level != 2 && $route.path != '/' && $route.path != '/utbytesguiden' && $route.path != '/utbytesguiden-eb'" class="float-right my-2" label="" v-slot="{ ariaDescribedby }">
          <b-form-radio-group button-variant="outline-primary" id="btn-radios-1" v-model="$i18n.locale" :options="$i18n.availableLocales" :aria-describedby="ariaDescribedby" name="radios-btn-default" buttons></b-form-radio-group>
        </b-form-group> -->
      </b-collapse>
      <ProductInfoModal
        id="selectedProductInfoSearchModal"
        v-bind="selectedProductData"
        :product-docs="selectedProductDocs"
        scope="search"
      ></ProductInfoModal>
    </b-navbar>
    <small>
        <v-idle @idle="logout" v-if="loggedIn" :wait="5" :duration="60 * 60" />
    </small>
    <!-- Search result container -->
    <b-collapse
      is-nav
      id="navbar-search-container"
      class="w-lg-auto navbar-collapse collapse show w-sm-100"
      ref="navbar-search-container"
      :visible="hasSearchResult"
    >
      <b-card no-body class="shadow-md-lg rounded px-3 py-2 p-md-0">
        <div v-if="hasEbs">
          <b-list-group flush>
            <b-list-group-item variant="secondary" class="py-2"
              ><small class="fw-600">{{ $t("text.admin.elslutbleck") }}</small>
              <b-badge
                variant="primary"
                v-text="getSearchResult.ebs.length"
              ></b-badge
            ></b-list-group-item>
          </b-list-group>
          <b-list-group flush>
            <b-list-group-item
              class="bg-light"
              v-for="(eb, i) in getSearchResult.ebs"
              :key="`eb-${i}`"
            >
              <b-link @click.prevent="showEbInModal(eb._id)">{{
                getTranslatedEbName(eb)
              }}</b-link>
              <small>{{ getTranslatedEbArt(eb) }}</small>
            </b-list-group-item>
          </b-list-group>
        </div>
        <div v-if="hasLhs">
          <b-list-group flush>
            <b-list-group-item variant="secondary" class="py-2"
              ><small class="fw-600">{{ $t("Låshus") }}</small>
              <b-badge
                variant="primary"
                v-text="getSearchResult.lhs.length"
              ></b-badge
            ></b-list-group-item>
          </b-list-group>
          <b-list-group flush>
            <b-list-group-item
              class="bg-light"
              v-for="(lh, i) in getSearchResult.lhs"
              :key="`lh-${i}`"
            >
              <b-link @click.prevent="showLhInModal(lh._id)">{{
                getTranslatedLhName(lh)
              }}</b-link>
              <small>{{ getTranslatedLhArt(lh) }}</small>
              <!-- <ProductsListItem v-bind="lh" class="w-25" :ref="`search-list-item-${lh._id}`" :active="false" @on-product-select="$emit('on-product-select', lh._id)"></ProductsListItem> -->
            </b-list-group-item>
          </b-list-group>
        </div>
        <div v-if="hasMs">
          <b-list-group flush>
            <b-list-group-item variant="secondary" class="py-2"
              ><small class="fw-600">{{ $t("Monteringsstolpe") }}</small>
              <b-badge
                variant="primary"
                v-text="getSearchResult.ms.length"
              ></b-badge
            ></b-list-group-item>
          </b-list-group>
          <b-list-group flush>
            <b-list-group-item
              class="bg-light"
              v-for="(ms, i) in getSearchResult.ms"
              :key="`ms-${i}`"
            >
              <b-link @click.prevent="showMsInModal(ms._id)">{{
                getTranslatedMsName(ms)
              }}</b-link>
              <small>{{ getTranslatedMsArt(ms) }}</small>
              <!-- <ProductsListItem v-bind="ms" class="w-25" :ref="`search-list-item-${ms._id}`" :active="false" @on-product-select="$emit('on-product-select', ms._id)"></ProductsListItem> -->
            </b-list-group-item>
          </b-list-group>
        </div>
        <div v-if="hasEm">
          <b-list-group flush>
            <b-list-group-item variant="secondary" class="py-2"
              ><small class="fw-600">{{ $t("Eltryckeslås & motorlås") }}</small>
              <b-badge
                variant="primary"
                v-text="getSearchResult.em.length"
              ></b-badge
            ></b-list-group-item>
          </b-list-group>
          <b-list-group flush>
            <b-list-group-item
              class="bg-light"
              v-for="(em, i) in getSearchResult.em"
              :key="`em-${i}`"
            >
              <b-link @click.prevent="showEmInModal(em._id)">{{
                getTranslatedEmName(em)
              }}</b-link>
              <small>{{ getTranslatedEmArt(em) }}</small>
              <!-- <ProductsListItem v-bind="ms" class="w-25" :ref="`search-list-item-${ms._id}`" :active="false" @on-product-select="$emit('on-product-select', ms._id)"></ProductsListItem> -->
            </b-list-group-item>
          </b-list-group>
        </div>
        <div v-if="hasSb">
          <b-list-group flush>
            <b-list-group-item variant="secondary" class="py-2"
              ><small class="fw-600">{{ $t("Slutbleck") }}</small>
              <b-badge
                variant="primary"
                v-text="getSearchResult.sb.length"
              ></b-badge
            ></b-list-group-item>
          </b-list-group>
          <b-list-group flush>
            <b-list-group-item
              class="bg-light"
              v-for="(sb, i) in getSearchResult.sb"
              :key="`sb-${i}`"
            >
              <b-link @click.prevent="showSbInModal(sb._id)">{{
                getTranslatedSbName(sb)
              }}</b-link>
              <small>{{ getTranslatedSbArt(sb) }}</small>
              <!-- <ProductsListItem v-bind="ms" class="w-25" :ref="`search-list-item-${ms._id}`" :active="false" @on-product-select="$emit('on-product-select', ms._id)"></ProductsListItem> -->
            </b-list-group-item>
          </b-list-group>
        </div>
        <div v-if="hasNb">
          <b-list-group flush>
            <b-list-group-item variant="secondary" class="py-2"
              ><small class="fw-600">{{ $t("Nödutrymningsbeslag") }}</small>
              <b-badge
                variant="primary"
                v-text="getSearchResult.nb.length"
              ></b-badge
            ></b-list-group-item>
          </b-list-group>
          <b-list-group flush>
            <b-list-group-item
              class="bg-light"
              v-for="(nb, i) in getSearchResult.nb"
              :key="`nb-${i}`"
            >
              <b-link @click.prevent="showNbInModal(nb._id)">{{
                getTranslatedNbName(nb)
              }}</b-link>
              <small>{{ getTranslatedNbArt(nb) }}</small>
              <!-- <ProductsListItem v-bind="ms" class="w-25" :ref="`search-list-item-${ms._id}`" :active="false" @on-product-select="$emit('on-product-select', ms._id)"></ProductsListItem> -->
            </b-list-group-item>
          </b-list-group>
        </div>
        <div v-if="hasUtb">
          <b-list-group flush>
            <b-list-group-item variant="secondary" class="py-2"
              ><small class="fw-600">{{ $t("Utbytesguiden") }}</small>
              <b-badge
                variant="primary"
                v-text="getSearchResult.utb.length"
              ></b-badge
            ></b-list-group-item>
          </b-list-group>
          <b-list-group flush>
            <b-list-group-item
              class="bg-light"
              v-for="(ub, index) in getSearchResult.utb"
              :key="`utb-${index}`"
            >
              <!-- <mark>{{ token }}</mark>
              <b-icon-arrow-right class="mx-2"></b-icon-arrow-right> -->
              <div class="d-flex" style="justify-content: space-between">
                <span>{{ ub.artnr }}</span>
                <em class="mx-2">{{ $t("text.guideUG.kan-ersattas-med") }}</em>
              </div>
              <div
                v-for="(ms, i) in ub.utbyte"
                :key="`msutb-${i}`"
                class="text-right"
              >
                <b-link
                  :id="`popover-target-${ms.artnr}`"
                  @click.prevent="showMsInModal(ms._id)"
                  >{{ getTranslatedMsName(ms) }}</b-link
                >
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ApiService from "@/services/ApiService";
import ProductInfoModal from "@/components/products/ProductInfoModal";
export default {
  name: "navbar",
  components: {
    ProductInfoModal,
  },
  data() {
    return {
      language: process.env.VUE_APP_I18N_LOCALE,
      token: "",
      selectedProductData: null,
      selectedProductDocs: [],
    };
  },
  computed: {
    ...mapGetters("user", ["loggedIn"]),
    ...mapGetters(["getSearchResult", "resetSearch"]),
    searchResult() {
      return this.getSearchResult;
    },
    hasSearchResult() {
      const hasAny =
        this.hasEbs || this.hasLhs || this.hasMs || this.hasEm || this.hasSb || this.hasUtb;
      return hasAny && this.token.length > 1;
    },
    hasEbs() {
      return (
        this.searchResult &&
        this.searchResult.ebs &&
        this.searchResult.ebs.length > 0
      );
    },
    hasLhs() {
      return (
        this.searchResult &&
        this.searchResult.lhs &&
        this.searchResult.lhs.length > 0
      );
    },
    hasMs() {
      return (
        this.searchResult &&
        this.searchResult.ms &&
        this.searchResult.ms.length > 0
      );
    },
    hasEm() {
      return (
        this.searchResult &&
        this.searchResult.em &&
        this.searchResult.em.length > 0
      );
    },
    hasSb() {
      return (
        this.searchResult &&
        this.searchResult.sb &&
        this.searchResult.sb.length > 0
      );
    },
    hasNb() {
      return (
        this.searchResult &&
        this.searchResult.nb &&
        this.searchResult.nb.length > 0
      );
    },
    hasUtb() {
      return (
        this.searchResult &&
        this.searchResult.utb &&
        this.searchResult.utb.length > 0
      );
    },
  },
  methods: {
    ...mapActions(["search"]),
    showEbInModal(id) {
      ApiService.getEbDataById(id)
        .then(async (response) => {
          const data = await response.data;
          this.selectedProductData = data;
          ApiService.getDocsForArt(data.artnr).then(async (response) => {
            const data = await response.data;
            this.selectedProductDocs = data;
          });
        })
        .finally(() => {
          this.$bvModal.show("selectedProductInfoSearchModal");
        });
    },
    showLhInModal(id) {
      ApiService.getLhDataById(id)
        .then(async (response) => {
          const data = await response.data;
          this.selectedProductData = data;
          ApiService.getDocsForArt(data.artnr).then(async (response) => {
            const data = await response.data;
            this.selectedProductDocs = data;
          });
        })
        .finally(() => {
          this.$bvModal.show("selectedProductInfoSearchModal");
        });
    },
    showMsInModal(id) {
      ApiService.getMsDataById(id)
        .then(async (response) => {
          const data = await response.data;
          this.selectedProductData = data;
          ApiService.getDocsForArt(data.artnr).then(async (response) => {
            const data = await response.data;
            this.selectedProductDocs = data;
          });
        })
        .finally(() => {
          this.$bvModal.show("selectedProductInfoSearchModal");
        });
    },
    showEmInModal(id) {
      ApiService.getEmDataById(id)
        .then(async (response) => {
          const data = await response.data;
          this.selectedProductData = data;
          ApiService.getDocsForArt(data.artnr).then(async (response) => {
            const data = await response.data;
            this.selectedProductDocs = data;
          });
        })
        .finally(() => {
          this.$bvModal.show("selectedProductInfoSearchModal");
        });
    },
    showSbInModal(id) {
      ApiService.getSbDataById(id)
        .then(async (response) => {
          const data = await response.data;
          this.selectedProductData = data;
          ApiService.getDocsForArt(data.artnr).then(async (response) => {
            const data = await response.data;
            this.selectedProductDocs = data;
          });
        })
        .finally(() => {
          this.$bvModal.show("selectedProductInfoSearchModal");
        });
    },
    showNbInModal(id) {
      ApiService.getNbDataById(id)
        .then(async (response) => {
          const data = await response.data;
          this.selectedProductData = data;
          ApiService.getDocsForArt(data.artnr).then(async (response) => {
            const data = await response.data;
            this.selectedProductDocs = data;
          });
        })
        .finally(() => {
          this.$bvModal.show("selectedProductInfoSearchModal");
        });
    },
    logout() {
      this.$store.dispatch("user/logout");
      this.$router.push({ name: "guide-filter-eb" });
    },
    getTranslatedEbName(eb) {
      const language = this.$i18n.locale;
      if (
        language != "sv" &&
        eb.translations &&
        eb.translations[language].name != ""
      ) {
        return eb.translations[this.$i18n.locale].name;
      }
      return eb.name;
    },
    getTranslatedEbArt(eb) {
      const language = this.$i18n.locale;
      if (
        language != "sv" &&
        eb.translations &&
        eb.translations[language].artnr != ""
      ) {
        return eb.translations[this.$i18n.locale].artnr;
      }
      return eb.artnr;
    },
    getTranslatedLhName(lh) {
      const language = this.$i18n.locale;
      if (
        language != "sv" &&
        lh.translations &&
        lh.translations[language].name != ""
      ) {
        return lh.translations[this.$i18n.locale].name;
      }
      return lh.name;
    },
    getTranslatedLhArt(lh) {
      const language = this.$i18n.locale;
      if (
        language != "sv" &&
        lh.translations &&
        lh.translations[language].artnr != ""
      ) {
        return lh.translations[this.$i18n.locale].artnr;
      }
      return lh.artnr;
    },
    getTranslatedMsName(ms) {
      const language = this.$i18n.locale;
      if (
        language != "sv" &&
        ms.translations &&
        ms.translations[language].name != ""
      ) {
        return ms.translations[this.$i18n.locale].name;
      }
      return ms.name;
    },
    getTranslatedMsArt(ms) {
      const language = this.$i18n.locale;
      if (
        language != "sv" &&
        ms.translations &&
        ms.translations[language].artnr != ""
      ) {
        return ms.translations[this.$i18n.locale].artnr;
      }
      return ms.artnr;
    },
    getTranslatedEmName(em) {
      const language = this.$i18n.locale;
      if (
        language != "sv" &&
        em.translations &&
        em.translations[language].name != ""
      ) {
        return em.translations[this.$i18n.locale].name;
      }
      return em.name;
    },
    getTranslatedEmArt(em) {
      const language = this.$i18n.locale;
      if (
        language != "sv" &&
        em.translations &&
        em.translations[language].artnr != ""
      ) {
        return em.translations[this.$i18n.locale].artnr;
      }
      return em.artnr;
    },
    getTranslatedSbName(sb) {
      const language = this.$i18n.locale;
      if (
        language != "sv" &&
        sb.translations &&
        sb.translations[language].name != ""
      ) {
        return sb.translations[this.$i18n.locale].name;
      }
      return sb.name;
    },
    getTranslatedSbArt(sb) {
      const language = this.$i18n.locale;
      if (
        language != "sv" &&
        sb.translations &&
        sb.translations[language].artnr != ""
      ) {
        return sb.translations[this.$i18n.locale].artnr;
      }
      return sb.artnr;
    },
    getTranslatedNbName(nb) {
      const language = this.$i18n.locale;
      if (
        language != "sv" &&
        nb.translations &&
        nb.translations[language].name != ""
      ) {
        return nb.translations[this.$i18n.locale].name;
      }
      return nb.name;
    },
    getTranslatedNbArt(nb) {
      const language = this.$i18n.locale;
      if (
        language != "sv" &&
        nb.translations &&
        nb.translations[language].artnr != ""
      ) {
        return nb.translations[this.$i18n.locale].artnr;
      }
      return nb.artnr;
    },
  },
  watch: {
    $route() {
      // This will close the collapse if any part of the route changes
      // including query, params, hash, name, or path
      this.$store.dispatch("resetSearch");
    },
    "$i18n.locale"() {
      this.$store.dispatch("productEB/resetProduct");
      this.$store.dispatch("optionsEB/resetSlugs");
      this.$store.dispatch("productLH/resetProduct");
      this.$store.dispatch("productMS/resetProduct");
      this.$store.dispatch("optionsMS/resetSlugs");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../scss/custom.scss";
@import "../../../node_modules/bootstrap/scss/mixins/_breakpoints.scss";

.v-idle {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  font-size: 12px;
}

.navbar {
  #assa-logo {
    width: auto;
    height: 45px;
  }
  border-radius: 6px;
  .filter-input {
    border-radius: 1.2em;
    font-size: 14px;
    font-family: Noto Sans;
    & ~ svg {
      margin-left: -32px;
    }
    &:hover,
    &:focus {
      & ~ svg {
        display: none;
      }
    }
  }
  .nav-item {
    white-space: nowrap;
  }
}
@include media-breakpoint-down(md) {
  .navbar {
    border-radius: 0;
  }
  .form-inline > .form-inline {
    width: 100%;
    padding-top: 1rem;
    margin-top: 0.5rem;
    border-top: $secondary solid;
  }
  .filter-input {
    width: 100% !important;
  }
  #navbar-search-container {
    right: 0 !important;
    .card {
      border-radius: 0 !important;
    }
  }
}
#navbar-search-container {
  position: absolute;
  right: 1rem;
  width: 375px;
  z-index: 1039;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  .card {
    background-color: $light;
    width: 100%;
    max-height: calc(100vh - 167px - 1rem);
    overflow: auto;
    margin: 0.5rem 0 0 auto;
    @include media-breakpoint-down(md) {
      margin: 0;
    }

    .card-header {
      padding: 0.25rem 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-color: #ccc;
      a {
        font-size: 80%;
        font-weight: 600;
      }
    }
    .card-header {
      font-weight: 600;
    }
  }
}
</style>
