<template>
  <header id="header-section" class="p-0">
    <Navbar></Navbar>
    <slot></slot>
  </header>
</template>

<script>
  import Navbar from "./HeaderNavbar.vue";
  export default {
    name: "header-section",
    components: {
      Navbar,
    },
  };
</script>

<style lang="scss" scoped>
  @import "scss/custom.scss";
  @import "node_modules/bootstrap/scss/mixins/_breakpoints.scss";
  #header-section {
    position: relative !important;

    @include media-breakpoint-down(sm) {




    }
  }
  h1 {
    margin: 180px auto 140px;
    font-size: 68px;
    font-weight: 600;
    > span {
      padding: 0 26px;
      background-color: #000;
      color: white;
    }
  }
  @include media-breakpoint-down(xl) {
    h1 {
      font-size: 48px;
    }
  }
  @include media-breakpoint-down(sm) {
    h1 {
      margin: 40px auto 30px;
      font-size: 24px;
    }
    .navbar {
      border-radius: 0;
    }
  }
</style>
