<template>
  <div>
    <RowContentWrapper blue fluid>
      <h2 class="text-white">{{ startsida_title }}</h2>
      <p>{{ startsida_text }}</p>
    </RowContentWrapper>
    <RowContentWrapper fluid class="bg-secondary">
      <b-row>
        <RowContentCTA
          cols="12"
          sm="6"
          lg="6"
          xl="3"
          :h3='$t("text.nav.products-em")'
          :bg="require('@/assets/content/AssaAbloy_guide_eltryckeslas.jpg')"
          :icon="require('@/assets/content/AssaAbloy_ikon_guide.png')"
          :readmore="{ name: 'guide-filter-em-start' }"
          :btn-text="$t('text.startsida.till-guiden')"
        ></RowContentCTA>
        <RowContentCTA
          cols="12"
          sm="6"
          lg="6"
          xl="3"
          :h3='$t("text.nav.products-eb")'
          :bg="require('@/assets/content/AssaAbloy_guide_elslutbleck.jpg')"
          :icon="require('@/assets/content/AssaAbloy_ikon_guide.png')"
          :readmore="{ name: 'guide-filter-eb-start' }"
          :btn-text="$t('text.startsida.till-guiden')"
        ></RowContentCTA>
        <RowContentCTA
          cols="12"
          sm="6"
          lg="6"
          xl="3"
          :h3='$t("text.nav.products-ms")'
          :bg="require('@/assets/content/AssaAbloy_guide_monteringsstolpe.jpg')"
          :icon="require('@/assets/content/AssaAbloy_ikon_guide.png')"
          :readmore="{ name: 'guide-filter-ms-start' }"
          :btn-text="$t('text.startsida.till-guiden')"
        ></RowContentCTA>
        <RowContentCTA
          cols="12"
          sm="6"
          lg="6"
          xl="3"
          :h3="$t('text.nav.guide-ug')"
          :bg="require('@/assets/content/AssaAbloy_guide_utbytesguide.jpg')"
          :icon="require('@/assets/content/AssaAbloy_ikon_utbytesguide.png')"
          :readmore="{ name: 'guide-filter-ug' }"
          :btn-text="$t('text.startsida.till-guiden')"
        ></RowContentCTA>
        <!-- <RowContentPuff cols="3" :h3="guide_elslutbleck_title" :body="guide_elslutbleck_text" :image="require('@/assets/content/AssaAbloy_guide_elslutbleck.jpg')" :icon="require('@/assets/content/AssaAbloy_ikon_guide.png')" :readmore="{ name: 'guide-filter-eb' }" />
          <RowContentPuff cols="3" :h3="guide_eltryckeslas_motorlas_title" :body="guide_eltryckeslas_motorlas_text" :image="require('@/assets/content/AssaAbloy_guide_eltryckeslas.jpg')" :icon="require('@/assets/content/AssaAbloy_ikon_guide.png')" :readmore="{ name: 'guide-filter-em' }" />
          <RowContentPuff :h3="guide_montagestolpe_title" :body="guide_montagestolpe_text" :image="require('@/assets/content/AssaAbloy_guide_monteringsstolpe.jpg')" :icon="require('@/assets/content/AssaAbloy_ikon_guide.png')" :readmore="{ name: 'guide-filter-ms' }" />
          <RowContentPuff :h3="utbytesguiden_title" :body="utbytesguiden_text" :image="require('@/assets/content/AssaAbloy_guide_utbytesguide.jpg')" :icon="require('@/assets/content/AssaAbloy_ikon_utbytesguide.png')" :readmore="{ name: 'guide-filter-ug' }" /> -->
      </b-row>
    </RowContentWrapper>
    <RowContentWrapper fluid>
      <h2 style="margin-bottom: 23px">{{ produktinfo_title }}</h2>
      <p>{{ produktinfo_text }}</p>
      <b-row>
        <RowContentPuff
          :h4="$t('text.startsida.products-eb.title')"
          :body="$t('text.startsida.products-eb.text')"
          :image="require('@/assets/content/Bleck_bild.jpg')"
          :readmore="{ name: 'products-eb' }"
        />
        <RowContentPuff
          :h4="$t('text.startsida.products-lh.title')"
          :body="$t('text.startsida.products-lh.text')"
          :image="require('@/assets/content/lock_bild.jpg')"
          :readmore="{ name: 'products-lh' }"
        />
        <RowContentPuff
          :h4="$t('text.startsida.products-ms.title')"
          :body="$t('text.startsida.products-ms.text')"
          :image="require('@/assets/content/Stolpar_bild.jpg')"
          :readmore="{ name: 'products-ms' }"
        />
      </b-row>
    </RowContentWrapper>
    <!-- <RowContentWrapper fluid class="bg-secondary">
      <b-row>
        <b-col class="p-0 m-0 d-flex align-items-center">
          <RowContentPuff
            :h3="utbytesguide_montagestolpe_title"
            :body="utbytesguide_montagestolpe_text"
            :readmore="{ name: 'guide-filter-ug' }"
          />
        </b-col>
        <b-col align="right" class="d-none d-md-block">
          <b-img
            :src="require('@/assets/content/Utbytesguide.png')"
            width="245"
          ></b-img>
        </b-col>
      </b-row>
    </RowContentWrapper>
    <RowContentWrapper fluid class="">
      <b-row>
        <b-col class="p-0 m-0 d-flex align-items-center">
          <RowContentPuff
            :h3="utbytesguide_elslutbleck_title"
            :body="utbytesguide_elslutbleck_text"
            :readmore="{ name: 'guide-filter-ug-eb' }"
          />
        </b-col>
        <b-col align="right" class="d-none d-md-block">
          <b-img
            :src="require('@/assets/content/Utbytesguide.png')"
            width="245"
          ></b-img>
        </b-col>
      </b-row>
    </RowContentWrapper> -->
  </div>
</template>

<script>
import RowContentWrapper from "@/components/content/RowContentWrapper";
import RowContentPuff from "@/components/content/RowContentPuff";
import RowContentCTA from "@/components/content/RowContentCTA";
import ContentMixin from "@/mixins/contentMixin";
export default {
  name: "IndexView",
  mixins: [ContentMixin],
  components: {
    RowContentWrapper,
    RowContentPuff,
    RowContentCTA,
  },
};
</script>

<style lang="scss" scoped>
h2 {
  margin-bottom: 28px;
  font-weight: 350;
  font-size: 30px;
}
</style>
