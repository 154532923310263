<template>
  <b-col :cols="cols" :md="md" :sm="sm" :lg="lg" :xl="xl">
    <div class="py-3 h-100 bg-image cta" :style="`background-image:url(${bg})`">
      <b-img v-if="icon" :src="icon" class="icon" />
      <h3 v-if="h3" v-text="h3" />
      <b-button
        v-if="readmore"
        variant="dark"
        size="sm"
        class="px-5 rounded stretched-link text-nowrap"
        :to="readmore"
        >{{ btnText }}</b-button
      >
    </div>
  </b-col>
</template>

<script>
export default {
  name: "RowContentCTA",
  props: {
    cols: { type: String },
    md: { type: String, default: "" },
    sm: { type: String, default: "" },
    lg: { type: String, default: "" },
    xl: { type: String, default: "" },
    h3: { type: String },
    bg: { type: String },
    icon: { type: String },
    btnText: { type: String },
    readmore: { type: Object },
  },
};
</script>

<style lang="scss" scoped>
.bg-image {
  background-size: cover;
}

.cta {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 350px !important;
  padding: 50px !important;
  margin-bottom: 20px;
  text-align: center;
  box-sizing: border-box;
}

.icon {
  width: 70px;
}

h3 {
  margin-bottom: 31px;
  font-weight: 350;
  font-size: 38px;
  color: #ffffff;
}
img {
  margin-bottom: 21px;
}
p {
  margin-bottom: 29px;
}
</style>
