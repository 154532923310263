<template>
  <div class="py-5">
    <b-container>
      <!-- <h2>
        Admin Dashboard
      </h2> -->
      <b-badge v-if="env !== 'production'" variant="warning" class="mb-3">{{ env }}</b-badge>
      <b-card no-body>
        <b-tabs v-model="activetab" pills content-class="m-0" card lazy>
          <b-tab no-body :title="$t('text.admin.elslutbleck')">
            <AdminEbDashboard></AdminEbDashboard>
          </b-tab>
          <b-tab no-body :title="$t('text.admin.lashus')">
            <AdminLhDashboard></AdminLhDashboard>
          </b-tab>
          <b-tab no-body :title="$t('text.admin.monteringsstolpar')">
            <AdminMsDashboard></AdminMsDashboard>
          </b-tab>
          <b-tab no-body :title="$t('text.admin.eltryckeslas_motorlas')">
            <AdminEmDashboard></AdminEmDashboard>
          </b-tab>
          <b-tab no-body :title="$t('text.admin.slutbleck')">
            <AdminSbDashboard></AdminSbDashboard>
          </b-tab>
          <b-tab no-body :title="$t('text.admin.nodutrymningsbeslag')">
            <AdminNbDashboard></AdminNbDashboard>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-container>
  </div>
</template>

<script>
  import ApiServiceAdmin from "@/services/ApiServiceAdmin";
  import AdminEbDashboard from "@/components/admin/AdminEbDashboard";
  import AdminMsDashboard from "@/components/admin/AdminMsDashboard";
  import AdminLhDashboard from "@/components/admin/AdminLhDashboard";
  import AdminEmDashboard from "@/components/admin/AdminEmDashboard";
  import AdminSbDashboard from "@/components/admin/AdminSbDashboard";
  import AdminNbDashboard from "@/components/admin/AdminNbDashboard";
  export default {
    name: "AdminDashboard",
    components: {
      AdminEbDashboard,
      AdminMsDashboard,
      AdminLhDashboard,
      AdminEmDashboard,
      AdminSbDashboard,
      AdminNbDashboard,
    },
    data() {
      return {
        env: process.env.VUE_APP_ENV,
        activetab: parseInt(localStorage.getItem('esb-activetab'))
      };
    },
    methods: {
      fetchLastActivity() {
        ApiServiceAdmin.getLastActivity().then(() => {
          // Do nothing... just for checking auth
        });
      },
    },
    mounted() {
      this.fetchLastActivity();
    },
    beforeUpdate() {
      // If logged in and has access_level = 2 (norway), we should set language to norwegian
      const access_level = this.$store.state.user.access_level;
      
      if (access_level == 2) {
        // console.log("user_access is 2 (norway)");
        this.$i18n.locale = "no";
        localStorage.setItem("langcode", "no");
      }
  },
  watch: {
    activetab() {
      this.fetchLastActivity();
      localStorage.setItem('esb-activetab', this.activetab)
    },
  },
  };
</script>

<style lang="scss" scoped></style>
