<template>
  <div>
    <b-container class="mt-2 d-print-none" id="guideInfoSection">
      <b-row no-gutters>
        <b-col cols="12" class="ingress">
          <slot name="ingress-prepend"></slot>
          <h2>{{ title }}<slot name="title-append"></slot></h2>
          <p class="mb-3">{{ text }}</p>
          <p>{{ text2 }}</p>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="p-0">
      <b-row no-gutters>
        <b-col>
          <slot></slot>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-show="!hideFilter">
      <b-row class="mt-0 mb-4 mt-md-0 mb-md-0" no-gutters v-if="step1">
        <b-col cols="12" md="auto" class="ml-md-0 d-flex align-items-center">
          <!-- <h3 class="text-primary m-0"><span class="numbered-circle" data-number="1"></span>{{ step1 }}</h3></b-col -->
          <h3 class="text-primary px-0">{{ step1 }}</h3>
        </b-col>
        <b-col cols="12" md="4" class="d-flex align-items-center mr-0 ml-auto mr-md-auto ml-md-0">
          <slot name="bottom"></slot>
        </b-col>
        <b-col cols="12" md="auto" align="right" class="mt-2 mt-md-0 align-items-center d-flex ml-auto mr-0">
          <b-button size="sm" class="mr-0 ml-auto w-100 w-md-auto rounded" variant="dark" @click="$emit('on-reset-slugs')">{{ $t("text.rensa-kriterier") }}</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  export default {
    name: "GuideInfoSecion",
    props: ["title", "text", "text2", "step1", "hideFilter"],
  };
</script>

<style lang="scss" scoped>
  h3 {
    margin-bottom: 0;
    font-weight: 600;
  }
  p {
    margin-bottom: 34px;
  }
</style>
