<template>
  <div>
    <b-container>
      <transition name="fade">
        <ProductsList scope="em" :total="getTotal" :selected-product-data="getProduct" :selected-product-docs="getDocs" :products="getProducts" @on-product-select="showProduct(...arguments)" @on-click-load-more="loadMore">
          <template #ingress>
            <h2 class="d-print-none">{{ products_eltryckeslas_motorlas_title }}</h2>
            <p class="d-print-none">{{ products_eltryckeslas_motorlas_text }}</p>
          </template>
        </ProductsList>
      </transition>
    </b-container>
  </div>
</template>

<script>
  import store from "@/store/store";
  import { mapGetters, mapActions } from "vuex";
  import productsListMixin from "@/mixins/productsListMixin";
  import contentMixin from "@/mixins/contentMixin";
  export default {
    name: "ProductsListViewEM",
    mixins: [productsListMixin, contentMixin],
    computed: {
      ...mapGetters("productsEM", ["getProducts", "getCount", "getTotal"]),
      ...mapGetters("productEM", ["getProduct", "getDocs"]),
    },
    methods: {
      ...mapActions("productsEM", ["fetchProducts"]),
      ...mapActions("productEM", ["fetchProduct", "fetchDocsForArt"]),
      loadMore() {
        this.$store.dispatch("productsEM/updateSkip");
      },
    },
    beforeRouteEnter(to, from, next) {
      // Reset pagination
      store.dispatch("productsEM/resetSkip");
      // Reset selected product
      store.dispatch("productEM/resetProduct");
      store
        .dispatch("productsEM/fetchProducts")
        .then(() => next())
        .catch((error) => {
          if (error.response && error.response.status == 404) {
            next({ name: "404", params: { resource: "event" } });
          } else {
            next({ name: "network-issue" });
          }
        });
    },
    beforeRouteUpdate(to, from, next) {
      store.dispatch("productsEM/fetchProducts").then(() => {
        next();
      });
    },
  };
</script>

<style lang="scss" scoped></style>
