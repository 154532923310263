<template>
    <div>
      <AdminOptionsListTable :data="optionsTableData" :loading="loading" :options="options" 
      @on-delete-item="deleteSlug(...arguments, 'sb')" 
      @on-select-product="$router.push(`/admin/sb/${arguments[0]}`)" 
      @on-updated-options-list-table="onUpdatedOptionsListTable"></AdminOptionsListTable>
    </div>
</template>

<script>
  import AdminListMixin from "@/mixins/AdminListMixin";
  import AdminAccessMixin from "@/mixins/AdminAccessMixin";
  import AdminOptionsListTable from "@/components/admin/AdminOptionsListTable";
  import ApiServiceAdmin from "@/services/ApiServiceAdmin";
  import _ from "lodash";
  export default {
    name: "AdminSbOptionsList",
    mixins: [AdminListMixin, AdminAccessMixin],
    components: {
      AdminOptionsListTable
    },
    data() {
      return {
        options: [],
        totalForSlug: {},
        productsForSlug: {},
        optionsTableData: [],
        loading: false
      };
    },
    methods: {
      getTotalForSlug(slug) {
        return ApiServiceAdmin.getSbTotal([slug]).then((response) => {
            return response.data
            // if ( response.data > 0 ) {
            //   console.log('Request data for ',slug)
            //   this.getProductsForSlug(slug);
            // }
        });
      },
      getProductsForSlug(slug) {
        return ApiServiceAdmin.getSb([slug]).then((response) => {
            return response.data
        });
      },
      async fetchSbOptions() {
        try {
          this.options = await ApiServiceAdmin.getSbOptions().then((response) => {
            return response.data;
          }); 
        } catch (error) {
          console.error(error);
        }
      },
      fetchOptions() {
        this.loading = true;
        this.fetchSbOptions()
        .then(() => {
          // Create new array with objects, containing useful data for the table
          this.optionsTableData = this.options.map((o) => {
            return {
              _id: o._id,
              name: o.name,
              slugData: o.slugs.map((s) => {
                return {
                  _id: s._id,
                  name: s,
                  total: -1,
                  products: [],
                };
              }),
            };
          });
          this.optionsTableData.forEach((o,i) => {
            o.slugData.forEach((s) => {
              this.getTotalForSlug(s.name)
              .then((count) => {
                s.total = count;
                if ( count > 0 ) {
                  this.getProductsForSlug(s.name)
                  .then((products) => {
                    s.products = products;
                  })
                }
              })
            });
          });
        });
      },
    },
    mounted() {
      this.fetchOptions()
    },
  };
</script>

<style lang="scss" scoped></style>
