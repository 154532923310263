<template>
  <div class="py-5">
    <b-form novalidate @submit="onSubmit" @change="onChange" @reset="onReset">
      <b-overlay
        :show="loading"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
        class="d-inline-block w-100"
        @hidden="onOverlayHidden"
      >
        <b-container>
          <h2>{{ title }}</h2>
          <b-row>
            <b-col>
              <b-form-row>
                <b-col>
                  <b-form-group :label="$t('form.namn')">
                    <b-form-input
                      v-if="showOriginal"
                      required
                      id="nb-name"
                      v-model="form.name"
                    ></b-form-input>
                    <b-input-group v-if="showTranslation">
                      <b-form-input
                        required
                        id="nb-name-no"
                        v-model="form.translations.no.name"
                      ></b-form-input>
                      <template #append>
                        <country-flag
                          country="no"
                          size="normal"
                          class="ml-n5"
                        />
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <!-- <b-col cols="2">
                  <input type="hidden" name="old_artnr" v-model="old.artnr" />
                  <b-form-group :label="$t('form.artnr')">
                    <b-form-input v-if="showOriginal" required id="nb-artnr" v-model="form.artnr"></b-form-input>
                    <b-input-group v-if="showTranslation">
                      <b-form-input required id="nb-artnr-no" v-model="form.translations.no.artnr"></b-form-input>
                      <template #append>
                        <country-flag country="no" size="normal" class="ml-n5" />
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col> -->
                <b-col cols="2">
                  <b-form-group :label="$t('form.artid')">
                    <b-form-input
                      id="nb-artnr"
                      v-model="form.artnr"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <!-- <b-col cols="3">
                  <b-form-group v-if="showOriginal" :label="$t('form.enr')">
                    <b-form-input required id="eb-enr" v-model="form.enr"></b-form-input>
                  </b-form-group>
                  <b-form-group v-if="showTranslation" :label="$t('form.nobnr')">
                    <b-input-group>
                      <b-form-input required id="eb-nobnr" v-model="form.nobnr"></b-form-input>
                      <template #append>
                        <country-flag country="no" size="normal" class="ml-n5" />
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col> -->
              </b-form-row>
              <b-form-row
                v-for="(info, i) in form.info"
                v-bind:key="`info-${i}`"
              >
                <b-col>
                  <b-form-group :label="$t(info.title)">
                    <b-form-textarea
                      v-if="showOriginal"
                      rows="5"
                      wrap="soft"
                      :formatter="removeHtml"
                      id="`info-${i}`"
                      v-model="info.body"
                    ></b-form-textarea>
                    <b-input-group v-if="showTranslation">
                      <b-form-textarea
                        rows="5"
                        wrap="soft"
                        :formatter="removeHtml"
                        id="`no-info-${i}`"
                        v-model="form.translations.no.info[i].body"
                      ></b-form-textarea>
                      <template #append>
                        <country-flag
                          country="no"
                          size="normal"
                          class="ml-n5"
                        />
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row v-if="showOriginal">
                <b-col>
                  <b-form-checkbox
                    :disabled="!canPublishSE"
                    v-model="form.published"
                    switch
                  >
                    {{ $t("text.admin.published") }}
                    <small>{{ $t("text.admin.published-help") }}</small>
                  </b-form-checkbox>
                </b-col>
              </b-form-row>

              <div v-if="showTranslation">
                <b-form-row>
                  <b-col>
                    <b-form-checkbox
                      :disabled="!canPublishNO"
                      v-model="form.translations.no.published"
                      switch
                    >
                      {{ $t("text.admin.published") }}
                      <small>{{ $t("text.admin.published-help") }}</small>
                    </b-form-checkbox>
                  </b-col>
                </b-form-row>
              </div>

              <label for="" class="mt-5">{{ $t("text.varianter") }}</label>
              <table class="table variants">
                <thead>
                  <tr>
                    <th v-if="showTranslation"></th>
                    <th>{{ $t("form.namn") }}</th>
                    <th>{{ $t("form.artnr") }}</th>
                    <th colspan="2" v-if="showOriginal">{{ $t("form.enr") }}</th>
                    <th colspan="2" v-if="showTranslation">{{ $t("form.nobnr") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(variant, i) in form.variants" :key="`variants-${i}`">
                    <td v-if="showTranslation" style="vertical-align: middle">{{ variant.title }}</td>
                    <td>
                      <b-input v-if="showOriginal" v-model="variant.title" id="`variant-title-${i}`" required></b-input>
                      <b-input-group v-if="showTranslation">
                        <b-input v-model="form.translations.no.variants[i].title" id="`no-variant-title-${i}`"></b-input>
                        <template #append>
                          <country-flag country="no" size="normal" class="ml-n5" />
                        </template>
                      </b-input-group>
                    </td>
                    <td>
                      <b-input v-if="showOriginal" v-model="variant.artnr" id="`variant-artnr-${i}`" required></b-input>
                      <b-input-group v-if="showTranslation">
                        <b-input v-model="form.translations.no.variants[i].artnr" id="`no-variant-artnr-${i}`"></b-input>
                        <template #append>
                          <country-flag country="no" size="normal" class="ml-n5" />
                        </template>
                      </b-input-group>
                    </td>
                    <td v-if="showOriginal">
                      <b-input v-model="variant.enr" id="`variant-enr-${i}`" required></b-input>
                    </td>
                    <td v-if="showTranslation">
                      <b-input-group>
                        <b-input v-model="variant.nobnr" id="`no-variant-nobnr-${i}`"></b-input>
                        <template #append>
                          <country-flag country="no" size="normal" class="ml-n5" />
                        </template>
                      </b-input-group>
                    </td>
                    <td width="5" v-if="showOriginal">
                      <button class="btn btn-danger" @click="removeVariant(variant.artnr, $event)"><b-icon icon="dash"></b-icon></button>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="3" align="right"><small>{{ $t('form.enr-help') }}</small></td>
                    <td align="right" v-if="showOriginal">
                      <button type="button" class="btn btn-success" @click="form.variants.push({ title: '', artnr: '' })">{{ $t("text.admin.lagg-till-variant") }} <b-icon icon="plus"></b-icon></button>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <div class="mt-5" v-if="showTranslation">
                <label>{{ $t("text.admin.sampletext-orig") }}</label>
                <b-card class="shadow-sm" no-body bg-variant="light">
                  <div class="d-flex" style="justify-content: space-between">
                    <h3 class="m-3">{{ form.name }}</h3>
                    <div class="my-3 ml-auto">{{ form.artnr }}</div>
                    <div class="mt-2">
                      <country-flag
                        country="se"
                        size="normal"
                        class="mr-0 ml-0"
                      />
                    </div>
                  </div>
                  <div class="px-3">
                    <dl
                      v-for="(info, i) in form.info"
                      v-bind:key="`product-info-${i}`"
                    >
                      <div v-if="info.body">
                        <dt>{{ info.title }}</dt>
                        <dd
                          v-html="info.body"
                          style="white-space: pre-wrap"
                        ></dd>
                      </div>
                    </dl>
                  </div>
                  <!-- Variants -->
                  <!-- <div class="p-3">
                    <table class="table table-sm variants" v-if="form.variants && form.variants.length">
                      <thead>
                        <tr>
                          <th>{{ $t("form.admin.varianter") }}</th>
                          <th>{{ $t("form.artnr") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(fact, i) in form.variants" v-bind:key="`product-facts-${i}`">
                          <td>{{ fact.title }}</td>
                          <td v-html="fact.artnr"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div> -->
                </b-card>
              </div>
            </b-col>
            <b-col cols="4">
              <b-alert :show="form.artnr == ''" variant="warning"
                ><b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill>
                {{ $t("text.admin.alert-no-artnr") }}</b-alert
              >
              <b-form-group :label="$t('text.produktbild')" v-if="form.artnr">
                <b-form-row>
                  <div class="shadow-sm w-100 border">
                    <ProductImages
                      :artnr="form.artnr"
                      :key="uselessNumber"
                      @on-deleted-image="uselessNumber--"
                    ></ProductImages>
                  </div>
                </b-form-row>
                <b-form-row class="mt-3" v-if="form.artnr && showOriginal">
                  <b-col>
                    <upload-product-images
                      multiple
                      :for-product-art="form.artnr"
                      @on-select-product-image="setProductImageData"
                      @on-uploaded-image="uploadDone(...arguments)"
                    ></upload-product-images>
                  </b-col>
                </b-form-row>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-row v-if="form.artnr" class="border rounded p-3 mt-3 mb-4">
            <b-col>
              <b-form-group>
                <upload-product-docs
                  :language="access_level == 2 ? 'no' : 'se'"
                  v-if="form.artnr"
                  multiple
                  :for-product-art="form.artnr"
                  @on-select-product-docs="setProductDocs"
                ></upload-product-docs>
                <small
                  >{{ $t("text.admin.ladda-upp-bilagor-max") }}
                  {{ maxSize | fsize }}</small
                >
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-row>
            <b-col>
              <b-button
                type="button"
                :to="{ name: 'adm-dashboard' }"
                variant="outline-primary"
                ><b-icon icon="chevron-left"></b-icon>
                {{ $t("text.admin.ga-till-lista") }}</b-button
              >
            </b-col>
            <b-col align="right">
              <b-button type="submit" :disabled="preventSubmit" variant="primary">{{
                $t("text.admin.spara")
              }}</b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-overlay>
    </b-form>
  </div>
</template>

<script>
import ApiServiceAdmin from "@/services/ApiServiceAdmin";
import AdminEditMixin from "@/mixins/AdminEditMixin";
import AdminAccessMixin from "@/mixins/AdminAccessMixin";
import _ from "lodash";

export default {
  name: "AdminNbEdit",
  mixins: [AdminEditMixin, AdminAccessMixin],
  data() {
    return {
      preventSubmit: false,
      language: process.env.VUE_APP_I18N_LOCALE,
      spec: null,
      updatedEbs: [],
      updatedSbs: [],
      updatedEms: [],
      factsOptions: null,
      old: {
        artnr: "",
      },
      form: {
        artnr: "",
        enr: "",
        nobnr: "",
        name: "",
        spec: {
          "em-oro-omstallbar": [],
          "em-oro-rattvand": [],
          "em-oro-omvand": [],
          "em-regelplacering-connect": [],
          "em-regelplacering-klassisk": [],
          "em-dd-29-0": [],
          "em-dd-35-0": [],
          "em-dd-50-0": [],
          "em-dd-70-0": [],
          "em-automatik-ja": [],
          "em-automatik-nej": [],
          "em-ej-brandkrav": [],
          "em-brandkrav-e30-el30": [],
          "em-brandkrav-e60-el60": [],
          "em-brandkrav-e90-el90": [],
          "em-brandkrav-e120-el120": [],
          "em-trycke-uts": [],
          "em-kortlasare-uts": [],
          "em-kodlasare-uts": [],
          "em-trycke-ins": [],
          "em-kortllasare-ins": [],
          "em-kodlasare-ins": [],
          "em-oppnaknapp-ins": [],
          "em-split-spindle-ins": [],
          "em-ss-en-179-ins-ja": [],
          "em-ss-en-1125-ins": [],
          "em-vred-ins": [],
          "em-nyckel-ins": [],
          "em-ej-aterinrymning": [],
          "em-aterinrymning-mekanisk": [],
          "em-aterinrymning-elektrisk-via-brandlarm": [],
          "em-aterinrymning-elektrisk-via-brandlarm-och-eller-oppnaknapp-ja": [],
          "em-hio-nej": [],
          "em-hio-ja": [],
          "em-indikering-trycke-ins": [],
          "em-indikering-dorrlage": [],
          "em-indikering-hakregel": [],
          "em-indikering-forregling": [],
          "em-indikering-forcerad-dorr": [],
          "em-lasklass-ssf-3522-1a": [],
          "em-lasklass-sff-3522-1b": [],
          "em-lasklass-sff-3522-2a": [],
          "em-lasklass-sff-3522-2b": [],
          "em-lasklass-sff-3522-3": [],
          "em-sse-split-spindel": [],
          "em-sse-enkel": [],
          "em-sse-omstallbar": [],
        },
        info: [
          { title: "Användningsområde", body: "" },
          { title: "Egenskaper", body: "" },
          { title: "Funktion", body: "" }
        ],
        variants: [],
        elslutbleck: [],
        slutbleck: [],
        ems: [],
        published: false,
        translations: {}
      },
      options: [],
      show: true,
      productsSB: [],
      productsEB: [],
      optionsEM: []
    };
  },
  computed: {
    canPublishSE() {
      return this.form.name && this.form.artnr && this.form.info[0].body;
    },
    canPublishNO() {
      return this.form.translations && this.form.translations.no.name && this.form.translations.no.info[0].body;
    },
    title: {
      get() {
        return this.form.name ? this.form.name : this.$t("text.admin.add-nb");
      },
    },
    slugs() {
      return Object.keys(this.form.spec);
    },
  },

  methods: {
    removeVariant(artnr, event) {
      event.preventDefault();
      const indexOfArtnr = _.findIndex(this.form.variants, function (v) {
        return v.artnr == artnr;
      });
      this.form.variants.splice(indexOfArtnr, 1);
    },
    uploadDone(files) {
      console.log("Upload done", files);
      this.uselessNumber++;
    },
    addEbToNb(eb) {
      // Add EB to nodutrymningsbeslag.elslutbleck
      this.form.elslutbleck.push(eb);
    },
    // async updateRelatedEms() {
    //   // Update all related ebs
    //   console.log('should run update')
    //   if (this.updatedEms.length) {
    //     console.log('started update')
    //     this.updatedEms.forEach((em) => {
    //       console.log("em", em);
    //       ApiServiceAdmin.updateEmById(em._id, {
    //         name: em.name,
    //         artnr: em.artnr,
    //         spec: em.spec,
    //         nodutrymningsbeslag: em.nodutrymningsbeslag,
    //         elslutbleck: em.elslutbleck,
    //         slutbleck: em.slutbleck,
    //         facts: em.facts,
    //         info: em.info,
    //         published: em.published,
    //         translations: em.translations
    //       })
    //         .then(this.handleSuccess)
    //         .catch(this.handleError);
    //     });
    //   }
    // },
    async updateChangedSbs(spec, sbsFromTable) {
      console.log("updateChangedSbs", sbsFromTable);
      this.form.slutbleck = await _.map(sbsFromTable, (sb) => ({
        _id: sb._id,
      }));
      this.updatedSbs = await _.map(sbsFromTable, (sb) => sb);
    },
    async updateChangedEbs(spec, ebsFromTable) {
      console.log("updateChangedEbs", ebsFromTable);
      this.form.elslutbleck = await _.map(ebsFromTable, (eb) => ({
        _id: eb._id,
      }));
      this.updatedEbs = await _.map(ebsFromTable, (eb) => eb);
    },
    async updateChangedEms(emsFromTable) {
      console.log("updateChangedEms", emsFromTable);
      this.form.ems = await _.map(emsFromTable, (em) => ({
        _id: em._id,
      }));
      this.updatedEms = await _.map(emsFromTable, (em) => em);
    },
    updateNbSpecObject(spec, products) {
      console.log('debug', spec, products)
      let merge = _.mergeWith(this.form.spec, spec, (objValue, srcValue) => {
        if (_.isArray(objValue)) {
          return objValue.concat(srcValue);
        }
      });

      this.form.spec = merge;
    },
    async updateRelatedEbs() {
      // Update all related ebs
      console.log("updateRelatedEbs");
      if (this.updatedEbs.length) {
        this.updatedEbs.forEach((eb) => {
          console.log("eb", eb);
          ApiServiceAdmin.updateEbById(eb._id, {
            name: eb.name,
            artnr: eb.artnr,
            spec: eb.spec,
            facts: eb.facts,
            info: eb.info,
            published: eb.published,
            translations: eb.translations
          });
        });
      }
    },
    async updateRelatedSbs() {
      // Update all related ebs
      console.log("updateRelatedSbs");
      if (this.updatedSbs.length) {
        this.updatedSbs.forEach((sb) => {
          console.log("sb", sb);
          ApiServiceAdmin.updateSbById(sb._id, {
            name: sb.name,
            artnr: sb.artnr,
            spec: sb.spec,
            facts: sb.facts,
            info: sb.info,
            published: sb.published,
            translations: sb.translations
          });
        });
      }
    },
    async initFormData() {
      if (!this.id) {
        return;
      }
      try {
        await ApiServiceAdmin.getNbDataById(this.id, []).then((response) => {
          let formobj = _.pick(response.data, ["name", "artnr", "enr", "nobnr", "spec", "elslutbleck", "slutbleck", "ems", "info", "variants", "published", "translations"]);
          this.form = formobj;

          if (this.form.spec === null) {
            this.form.spec = {};
          }

          this.form.translations.no.variants = _.map(this.form.variants, (v, i) => {
            // Need this for the page not to crash
            console.log("title", this.form.translations.no.variants[i]);
            if (this.form.translations.no.variants[i] === undefined) {
              return { title: "", artnr: "" };
            }
            return { title: this.form.translations.no.variants[i].title ? this.form.translations.no.variants[i].title : "", artnr: this.form.translations.no.variants[i].artnr ? this.form.translations.no.variants[i].artnr : "" };
          });
          this.old.artnr = this.form.artnr;
        })
      } catch (error) {
        console.log(error);
      }
    },
    onSubmit(event) {
      this.loading = true;
      event.preventDefault();
      if (!this.id) {
        ApiServiceAdmin.addNb(JSON.stringify(this.form))
          .then((response) => {
            console.log("response", response);
            this.$router.push(`/admin/nb/${response.data.result._id}`);
            this.id = response.data.result._id;
            this.handleSuccess(response);
          })
          .catch(this.handleError);
      } else {
        ApiServiceAdmin.updateNbById(this.id, JSON.stringify(this.form))
          .then((response) => {
            // Update folder name in S3
            if (this.form.artnr != this.old.artnr) {
              console.log("Update folder name in S3");
              ApiServiceAdmin.updateFolderByArt(this.form.artnr, this.old.artnr)
                .then(() => {
                  this.old.artnr = this.form.artnr;
                  // this.makeToast({ variant: "info", title: this.$t("messages.post-update-artnr"), text: this.$t("messages.post-save") });
                })
                .catch(this.handleError);
            }
            // Update related ems
            this.updateRelatedEbs();
            this.updateRelatedSbs();
            this.handleSuccess(response);
          })
          .catch(this.handleError);
      }
    },
    onChange() {
      let variants = this.form.variants
      if ( this.$i18n.locale == "no" ) {
        variants = this.form.translations.no.variants
      }
      variants.forEach(async (variant) => {
        const unique_enr = await this.isUnique('enr', variant.enr, this.id)
        const unique_nobnr = await this.isUnique('nobnr', variant.nobnr, this.id)
        if ( !unique_enr || !unique_nobnr ) {
          // Prevent saving if not unique
          this.handleError('E11000_DUPLICATE_KEY')
          this.preventSubmit = true;
          return false;
        }
        console.log('HELLO')
      });
      this.preventSubmit = false;
    },
    fetchSb() {
      ApiServiceAdmin.getSb()
        .then(async (response) => {
          this.slutbleck = await response.data;
          this.productsSB = this.slutbleck;
          this.handleSuccess(response);
        })
        .catch((err) => console.error(err));
    },
    fetchEb() {
      ApiServiceAdmin.getEb()
        .then(async (response) => {
          this.elslutbleck = await response.data;
          this.productsEB = this.elslutbleck;
          this.handleSuccess(response);
        })
        .catch((err) => console.error(err));
    },
    async fetchEmOptions() {
      const emOptions = await ApiServiceAdmin.getEmOptions().then((response) => response.data)
      this.optionsEM = _.flatten(_.valuesIn(_.map(emOptions, (o) => o.slugs)));
    },
  },

  mounted() {
    this.fetchSb();
    this.fetchEb();
    this.fetchEmOptions();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../scss/_colors.scss";
table {
  th,
  td {
    border-width: 2px;
    border-color: white;
  }
  th {
    background-color: $primary;
    color: $white;
  }
  td {
    background-color: $gray-100;
  }
}
</style>
