import ApiClient from '@/services/ApiService'
// import _ from 'lodash'
export const namespaced = true;

export const state = {
  products: [],
  total: 0,
  ui: {
    paginate: {
      limit: 20,
      skip: 0
    }
  }
}

export const actions = {
  filterFetchedProductsById({ commit, state }, ids = []) {
    console.log('filterFetchedProductsById', ids)
    if (ids.length == 0) return
    // Fetch again to make sure we have all products
    return ApiClient.getSb([], state.ui.paginate)
      .then(async (response) => {
        let products = await response.data

        let filtered = products.filter(p => ids.includes(p._id))
        commit('UPD_PRODUCTS', filtered)
        commit('SET_TOTAL', state.products.length)
        return response
      })
  },
  fetchProducts({ commit, dispatch, state }, params = []) {
    console.log('fetchProducts SB')
    dispatch('fetchTotal', params)
    dispatch('setLimit', state.ui.paginate.limit)
    return ApiClient.getSb(params, state.ui.paginate)
      .then(async (response) => {


        if (state.ui.paginate.skip > 0) {
          // Append to list
          commit('APPEND_PRODUCTS', await response.data)
        } else {
          commit('UPD_PRODUCTS', await response.data)
        }
        return response
      })
  },
  fetchTotal({ commit }, params = []) {
    ApiClient.getSbTotal(params)
      .then(async response => await commit('SET_TOTAL', response.data))
  },
  setLimit({ commit }, limit) {
    commit('UPD_LIMIT', limit)
  },
  updateSkip({ commit, state, dispatch, rootState }) {
    commit('UPD_SKIP', state.ui.paginate.skip + state.ui.paginate.limit)
    dispatch("fetchProducts", [...rootState.optionsSB.selection])
  },
  resetSkip({ commit }) {
    commit('UPD_SKIP', 0)
  },
  async fetchDocsForProduct({ commit }) {
    return await ApiClient.getDocsForArt()
      .then(async (response) => {
        await commit('UPD_PRODUCTS', response.data)
        return response.data
      })
  },
}
export const mutations = {
  UPD_PRODUCTS(state, products) {
    state.products = products
  },
  APPEND_PRODUCTS(state, products) {
    state.products = [...state.products, ...products]
  },
  UPD_SKIP(state, skip) {
    state.ui.paginate.skip = skip
  },
  UPD_LIMIT(state, limit) {
    state.ui.paginate.limit = limit
  },
  SET_TOTAL(state, total) {
    state.total = total
  }
}
export const getters = {
  getProducts: state => state.products,
  getCount: (state) => state.products.length,
  getTotal: (state) => state.total,
  getUi: (state) => state.ui,
}