<template>
  <div id="app">
    <div class="sticky-header d-print-none">
      <HeaderSection class="d-print-none"></HeaderSection>
      <Alert :alert="alert" v-if="alert.msg"></Alert>
    </div>
    <main class="pb-5" id="main">
      <transition name="fade">
        <router-view></router-view>
      </transition>
    </main>
    <FooterSection />
  </div>
</template>

<script>
  import HeaderSection from "./components/header/HeaderSection.vue";
  import Alert from "./components/notifications/Alert.vue";
  import FooterSection from "./components/footer/FooterSection.vue";
  import contentMixin from "./mixins/contentMixin";
  export default {
    name: "app",
    metaInfo() {
      return {
        title: `${process.env.VUE_APP_TITLE}`,
        meta: [
          { name: 'robots', content: 'noindex,nofollow' }
        ]
      }
    },
    mixins: [contentMixin],
    computed: {
      alert: {
        get() {
          return this.$store.state.alert;
        },
        set(msg) {
          this.$store.commit("ALERT", msg);
        },
      },
    },
    components: {
      HeaderSection,
      Alert,
      FooterSection,
    },
  };
</script>

<style lang="scss">
  @import "scss/custom.scss";
  @import "node_modules/bootstrap/scss/mixins/_breakpoints.scss";
  html,
  body {
    min-height: 100%;
  }
  .sticky-header {
    position: sticky;
    top: 0;
    z-index: 1038 ;
  }
  @include media-breakpoint-down(sm) {
    html,
    body {
      overflow-x: hidden;
    }
  }
</style>
