<template>
  <b-row>
    <b-col cols="12" md="5" class="d-print-none">
      <b-card no-body class="product active h-auto mb-2 mb-md-0" :class="{ 'h-auto': result.length > 0 }">
        <b-card-header class="p-0 d-flex bg-white border-0 h-100">
          <b-form-input type="search" v-model="token" size="lg" class="border-0 h-100 py-3 px-3 mx-md-4" style="max-height: 80px" :placeholder="$t('text.guideUG.input-placeholder')" @input="fetchResult(token)"></b-form-input>
          <!-- <b-button size="lg" variant="link" class="position-absolute" style="right: 10px;"><b-icon-search></b-icon-search></b-button> -->
        </b-card-header>
        <b-card-body class="p-0" v-if="result.length">
          <b-list-group flush>
            <b-list-group-item class="border-secondary" v-for="(item, index) in result" :key="index">
              <mark>{{ token }}</mark>
              <b-icon-arrow-right class="mx-2"></b-icon-arrow-right>
              <span>{{ item.artnr }}</span>
              <em class="mx-2">{{ $t("text.guideUG.kan-ersattas-med") }}</em>
              <div v-for="(ms, index) in item.utbyte" :key="index">
                <b-link :id="`popover-target-${ms.artnr}`" @click="showProduct(ms._id)">{{ getTranslatedName(ms) }}</b-link>
              </div>
            </b-list-group-item>
          </b-list-group>
          <ProductInfoModal id="selectedProductInfoModal" v-bind="getProduct" :product-docs="getDocs"></ProductInfoModal>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col cols="12" md="7" class="pl-md-0 mb-2 d-none d-lg-block bg-white product-info-container p-0 d-print-block" :class="{ 'border border-primary': !!getProduct }">
      <b-collapse visible id="productInfo" class="sticky-top" styles="width: 795px;">
        <b-button block v-show="!result.length" size="lg" variant="primary" class="py-3 text-left"><b-icon icon="chevron-down" class="mr-3 d-none d-md-inline"></b-icon> {{ $t("text.guideUG.info") }}</b-button>
        <transition name="fade">
          <ProductsListAside v-if="!!getProduct" :product-data="getProduct" :product-docs="getDocs"></ProductsListAside>
        </transition>
      </b-collapse>
    </b-col>
  </b-row>
</template>

<script>
  import ApiService from "@/services/ApiService";
  // import ProductsListItem from "@/components/products/ProductsListItem";
  import ProductsListAside from "@/components/products/ProductsListAside";
  import productsListMixin from "@/mixins/productsListMixin";
  import ProductInfoModal from "@/components/products/ProductInfoModal";
  import { mapGetters, mapActions } from "vuex";
  export default {
    name: "UtbytesguidenInputWidget",
    mixins: [productsListMixin],
    components: {
      // ProductsListItem,
      ProductsListAside,
      ProductInfoModal,
    },
    data() {
      return {
        token: "",
        result: [],
      };
    },
    computed: {
      ...mapGetters("productMS", ["getProduct", "getDocs"]),
    },
    methods: {
      ...mapActions("productMS", ["fetchProduct", "fetchDocsForArt"]),
      fetchResult(token) {
        this.$store.dispatch("productMS/resetProduct");
        ApiService.searchKonvertering(token).then(async (response) => {
          const data = await response.data;
          // Filter data based on language
          let newData = [];
          data.forEach((item) => {
            if (this.$i18n.locale == "sv" && item.utbyte.filter((utb) => utb.published).length) {
              item.utbyte = item.utbyte.filter((utb) => utb.published);
              newData.push(item);
            } else if (this.$i18n.locale == "no" && item.utbyte.filter((utb) => utb.translations.no.published).length) {
              item.utbyte = item.utbyte.filter((utb) => utb.translations.no.published);
              newData.push(item);
            }
          });
          console.log("newData", newData);
          this.result = token ? data : "";
        });
      },
      getTranslatedName(item) {
        if (item.translations && item.translations[this.$i18n.locale]) {
          const translatedName = item.translations[this.$i18n.locale].name;
          if (this.$i18n.locale != "sv" && translatedName != "") {
            return translatedName;
          }
        }
        return item.name;
      },
      getTranslatedPublished(item) {
        if (item.translations && item.translations[this.$i18n.locale]) {
          const translatedPublished = item.translations[this.$i18n.locale].published;
          return translatedPublished;
        }
        return item.published !== false;
      },
    },
    mounted() {
      this.$store.dispatch("productMS/resetProduct");
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../../scss/custom.scss";
  @import "../../../node_modules/bootstrap/scss/mixins/_breakpoints.scss";
  .popover-preview {
    > .popover-body {
      padding: 0;
    }
  }
  .card.product {
    &:after {
      top: 30px;
    }
  }

  @include media-breakpoint-down(md) {
    .card.product {
      &:after {
        top: 30px;
        display: none !important;
      }
      input[type="search"] {
        font-size: 4vw !important;
        border-bottom: $secondary solid 1px !important;
        &::-webkit-search-cancel-button {
          display: block !important;
        }
      }
    }
  }
</style>
