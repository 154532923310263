<template>
  <div class="py-5">
    <b-form novalidate id="admin-oversattningar" @submit="onSubmit" @reset="onReset">
      <b-overlay :show="loading" rounded opacity="0.8" spinner-small spinner-variant="primary" @hidden="onOverlayHidden">
        <b-container>
          <div>
            <b-card no-body v-if="content">
              <b-card-text>
                <table class="table">
                  <thead class="">
                    <tr>
                      <!-- <th scope="col"></th> -->
                      <th scope="col">{{ $t("text.admin.sv") }} <big></big></th>
                      <th scope="col">{{ $t("text.admin.no") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="row in content" :key="row.machineName">
                      <!-- <th scope="row">{{ row.machineName }}</th> -->
                      <td>
                        <b-form-textarea :readonly="false" rows="6" v-model="row.text" placeholder=""></b-form-textarea>
                      </td>
                      <td>
                        <b-form-textarea rows="6" v-model="row.translations.no.text" placeholder=""></b-form-textarea>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-card-text>
            </b-card>
          </div>
          <hr class="my-5" />
          <b-row class="mt-4">
            <b-col>
              <b-button size="lg" type="button" :to="{ name: 'adm-dashboard' }" variant="outline-primary"><b-icon icon="chevron-left"></b-icon> {{ $t("text.admin.ga-till-lista") }}</b-button>
            </b-col>
            <b-col align="right">
              <b-button size="lg" type="submit" variant="primary">{{ $t("text.admin.spara") }}</b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-overlay>
    </b-form>
  </div>
</template>

<script>
  import ApiServiceAdmin from "@/services/ApiServiceAdmin";
  import AdminEditMixin from "@/mixins/AdminEditMixin";
  import AdminMessagesMixin from "@/mixins/AdminMessagesMixin";
  import _ from "lodash";
  export default {
    name: "AdminOversattningar",
    mixins: [AdminEditMixin, AdminMessagesMixin],
    data() {
      return {
        content: [],
        form: {},
      };
    },
    methods: {
      onSubmit(event) {
        this.loading = true;
        event.preventDefault();
        console.log("this.content", this.content);
        this.content.forEach((row) => {
          ApiServiceAdmin.updateContentById(row._id, _.pick(row, ["machineName", "text", "translations"]))
            .then(this.handleSuccess)
            .catch(this.handleError);
        });
      },
      async initFormData() {
        try {
          await ApiServiceAdmin.getContentData(this.id, []).then((response) => {
            console.log("response.data", response.data);
            this.content = response.data;
            // this.content = _.map(response.data, (c) => _.pick(c, ["machineName", "text", "translations"]));
            console.log("this.content", this.content);
          });
        } catch (error) {
          console.log(error);
        }
      },
    },
    mounted() {
      this.initFormData();
    },
  };
</script>

<style lang="scss" scoped>
  .product.card {
    min-height: 34px;
    &.active {
      border-width: 1px;
      border-right-width: 10px;
      &:after {
        width: 120px;
        right: -130px;
      }
    }
  }
</style>
