import axios from 'axios'

if (process.env.VUE_APP_ENV != 'production') {
  console.log('DBHOST', process.env.VUE_APP_DBHOST)
}

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_DBHOST,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 10000
})

apiClient.interceptors.request.use(config => {
  // console.time('api')
  return config
})

apiClient.interceptors.response.use(config => {
  // console.timeLog("api");
  // console.timeEnd('api')
  return config
})

function toQueryString(paramsObject) {
  if (!paramsObject) {
    return "";
  }
  return '&' + Object
    .keys(paramsObject)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(paramsObject[key])}`)
    .join('&');
}

export default {
  getEb(params = [], paginate) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get('/api/elslutbleck?' + params.join('&') + toQueryString(paginate), {
      headers: { 'Accept-Language': langcode }
    })
  },
  getMs(params = [], paginate) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get('/api/montagestolpar?' + params.join('&') + toQueryString(paginate), {
      headers: { 'Accept-Language': langcode }
    })
  },
  getEm(params = [], paginate) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get('/api/eltryckeslas-motorlas?' + params.join('&') + toQueryString(paginate), {
      headers: { 'Accept-Language': langcode }
    })
  },
  getSb(params = [], paginate) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get('/api/slutbleck?' + params.join('&') + toQueryString(paginate), {
      headers: { 'Accept-Language': langcode }
    })
  },
  getNb(params = [], paginate) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get('/api/nodutrymningsbeslag?' + params.join('&') + toQueryString(paginate), {
      headers: { 'Accept-Language': langcode }
    })
  },
  getLh(paginate) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get('/api/housing?' + toQueryString(paginate), {
      headers: { 'Accept-Language': langcode }
    })
  },
  getContent() {
    return apiClient.get('/api/content?')
  },
  getEbTotal(params = []) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get('/api/elslutbleck/total?' + params.join('&'), {
      headers: { 'Accept-Language': langcode }
    })
  },
  getMsTotal(params = []) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get('/api/montagestolpar/total?' + params.join('&'), {
      headers: { 'Accept-Language': langcode }
    })
  },
  getEmTotal(params = []) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get('/api/eltryckeslas-motorlas/total?' + params.join('&'), {
      headers: { 'Accept-Language': langcode }
    })
  },
  getSbTotal(params = []) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get('/api/slutbleck/total?' + params.join('&'), {
      headers: { 'Accept-Language': langcode }
    })
  },
  getNbTotal(params = []) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get('/api/nodutrymningsbeslag/total?' + params.join('&'), {
      headers: { 'Accept-Language': langcode }
    })
  },
  getLhTotal() {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get('/api/housing/total?', {
      headers: { 'Accept-Language': langcode }
    })
  },
  getMontagestolpar(params = []) {
    return apiClient.get('/api/montagestolpar?' + params.join('&'))
  },
  getCount(params) {
    return apiClient.get('/api/elslutbleck?c&' + params.join('&'))
  },
  checkEB(params) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get('/api/elslutbleck/check?' + params.join('&'), {
      headers: { 'Accept-Language': langcode }
    })
  },
  checkMS(params) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get('/api/montagestolpar/check?' + params.join('&'), {
      headers: { 'Accept-Language': langcode }
    })
  },
  checkEM(params) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get('/api/eltryckeslas-motorlas/check?' + params.join('&'), {
      headers: { 'Accept-Language': langcode }
    })
  },
  checkSB(params) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get('/api/slutbleck/check?' + params.join('&'), {
      headers: { 'Accept-Language': langcode }
    })
  },
  getEBOptions() {
    return apiClient.get('/api/options/eb')
  },
  getMSOptions() {
    return apiClient.get('/api/options/ms')
  },
  getEMOptions() {
    return apiClient.get('/api/options/em')
  },
  getSBOptions() {
    return apiClient.get('/api/options/sb')
  },
  getEbDataById(_id, params = []) {
    return apiClient.get(`/api/elslutbleck/${_id}?${params.join('&')}`)
  },
  getMsDataById(_id, params = []) {
    return apiClient.get(`/api/montagestolpar/${_id}?${params.join('&')}`)
  },
  getEmDataById(_id, params = []) {
    return apiClient.get(`/api/eltryckeslas-motorlas/${_id}?${params.join('&')}`)
  },
  getSbDataById(_id, params = []) {
    return apiClient.get(`/api/slutbleck/${_id}?${params.join('&')}`)
  },
  getLhDataById(_id, params = []) {
    return apiClient.get(`/api/housing/${_id}?${params.join('&')}`)
  },
  getNbDataById(_id, params = []) {
    return apiClient.get(`/api/nodutrymningsbeslag/${_id}?${params.join('&')}`)
  },
  getLhDataByArt(artnr) {
    return apiClient.get(`/api/housing/artnr/${encodeURIComponent(artnr)}?`)
  },
  getNbDataByArt(artnr) {
    return apiClient.get(`/api/nodutrymningsbeslag/artnr/${encodeURIComponent(artnr)}?`)
  },
  getAllElslutbleck() {
    return apiClient.get('/api/elslutbleck?')
  },
  getAllLH() {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get('/api/housing?', {
      headers: { 'Accept-Language': langcode }
    })
  },
  getAllNB() {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get('/api/nodutrymningsbeslag?', {
      headers: { 'Accept-Language': langcode }
    })
  },
  getDocsForArt(artnr, lang = "sv") {
    console.log('artnr', artnr)
    console.log('lang', lang)
    return apiClient.get(`/api/files/${encodeURIComponent(artnr)}/docs/${lang}`)
  },
  getImagesForArt(artnr) {
    return apiClient.get(`/api/files/${encodeURIComponent(artnr)}/images`)
  },
  getImageForArt(artnr) {
    const url = `${process.env.VUE_APP_S3_BASE_URL}${encodeURIComponent(artnr)}/images/${encodeURIComponent(artnr)}`
    return apiClient.get(encodeURI(url), {
      responseType: 'arraybuffer',
    })
  },
  search(token) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get(`/api/search?q=${token}`, {
      headers: { 'Accept-Language': langcode }
    })
  },
  getMsKonv() {
    return apiClient.get('/api/konvertering')
  },
  searchKonvertering(token) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.post('/api/konvertering/hitta', { token: token }, {
      headers: { 'Accept-Language': langcode }
    })
  },
  searchKonverteringEb(token) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.post('/api/konvertering-eb/hitta', { token: token }, {
      headers: { 'Accept-Language': langcode }
    })
  }
}