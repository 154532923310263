
import Vue from 'vue'
import Vuex from 'vuex'

import * as user from './modules/user'
import * as optionsEB from './modules/options/eb'
import * as optionsMS from './modules/options/ms'
import * as optionsEM from './modules/options/em'
import * as optionsSB from './modules/options/sb'
import * as productsEB from './modules/products/eb'
import * as productEB from './modules/product/eb'
import * as productsMS from './modules/products/ms'
import * as productMS from './modules/product/ms'
import * as productsEM from './modules/products/em'
import * as productEM from './modules/product/em'
import * as productsSB from './modules/products/sb'
import * as productSB from './modules/product/sb'
import * as productsLH from './modules/products/lh'
import * as productLH from './modules/product/lh'
import * as productsNB from './modules/products/nb'
import * as productNB from './modules/product/nb'
import * as utbytesguiden from './modules/utbytesguiden'
import * as content from './modules/content'

import ApiService from "@/services/ApiService"

// Admin
// import * as adminLH from './modules/admin/lh'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    data: null,
    search: {
      ebs: [],
      lhs: [],
      ms: [],
      em: [],
      nb: [],
      sb: [],
      utb: []
    },
    alert: {
      msg: '',
      variant: ''
    },
    selectedEb: null,
    selectedMs: null,
    selectedEm: null,
    selectedSb: null
  },
  modules: {
    user,
    optionsEB,
    optionsMS,
    optionsEM,
    optionsSB,
    productsEB,
    productEB,
    productsMS,
    productMS,
    productsEM,
    productEM,
    productsNB,
    productNB,
    productsSB,
    productSB,
    productsLH,
    productLH,
    utbytesguiden,
    content
  },
  actions: {
    updateSearchResult({ commit }, payload) {
      commit('UPD_SEARCH_RESULT', payload)
    },
    setSelectedEb({ commit }, item) {
      commit('SET_SELECTED_EB', item)
    },
    resetSelectedEb({ commit, dispatch }) {
      commit('RESET_SELECTED_EB', null)
      dispatch('productEB/resetProduct', { root: true })
    },
    setSelectedMs({ commit }, item) {
      commit('SET_SELECTED_MS', item)
    },
    resetSelectedMs({ commit, dispatch }) {
      commit('RESET_SELECTED_MS', null)
      dispatch('productMS/resetProduct', { root: true })
    },
    setSelectedEm({ commit }, item) {
      commit('SET_SELECTED_EM', item)
    },
    resetSelectedEm({ commit, dispatch }) {
      commit('RESET_SELECTED_EM', null)
      dispatch('productEM/resetProduct', { root: true })
    },
    setSelectedSb({ commit }, item) {
      commit('SET_SELECTED_SB', item)
    },
    resetSelectedSb({ commit, dispatch }) {
      commit('RESET_SELECTED_SB', null)
      dispatch('productSB/resetProduct', { root: true })
    },
    fetchKonv({ commit, state }, token) {
      ApiService.searchKonvertering(token).then(async (response) => {
        const utb = await response.data;
        commit('UPD_SEARCH_RESULT', { ...state.search, utb })

      });
    },
    search({ dispatch }, token) {
      if (token.length < 2) return;
      ApiService.search(token)
        .then(async results => {
          const data = await results.data
          dispatch('updateSearchResult', data)
        })
        .then(() => {
          dispatch('fetchKonv', token)
        })
    },
    resetSearch({ dispatch }) {
      dispatch('updateSearchResult', null)
    },
    // New
    clearAllData({ dispatch }, options = {clearSelection: true, clearResult: true}) {
      console.log('clearAllData', options)
      // Reset selected EB
      dispatch('resetSelectedEb')
      // Reset selected MS
      dispatch('resetSelectedMs')
      // Reset selected EM
      dispatch('resetSelectedEm')
      // Reset selected SB
      dispatch('resetSelectedSb')

      if (options.clearSelection == true) {
        console.log('clearSelection')
        // Reset EB options
        dispatch('optionsEB/resetSelection', { root: true })
        dispatch('optionsEB/resetDisabled', { root: true })
        // Reset MS options
        dispatch('optionsMS/resetSelection', { root: true })
        dispatch('optionsMS/resetDisabled', { root: true })
        // Reset EM options
        dispatch('optionsEM/resetSelection', { root: true })
        dispatch('optionsEM/resetDisabled', { root: true })
        // Reset SB options
        dispatch('optionsSB/resetSelection', { root: true })
        dispatch('optionsSB/resetDisabled', { root: true })

        // Fetch products
        // dispatch('productsEB/fetchProducts', { root: true })
        // dispatch('productsMS/fetchProducts', { root: true })
        // dispatch('productsEM/fetchProducts', { root: true })
        // dispatch('productsSB/fetchProducts', { root: true })
      }
      // Reset EB skip values
      dispatch('productsEB/resetSkip', { root: true })
      // Reset MS skip values
      dispatch('productsMS/resetSkip', { root: true })
      // Reset EM skip values
      dispatch('productsEM/resetSkip', { root: true })
      // Reset SB skip values
      dispatch('productsSB/resetSkip', { root: true })
    }
  },
  mutations: {
    ALERT(state, alert) {
      state.alert = alert
    },
    SET_SELECTED_EB(state, item) {
      state.selectedEb = item
    },
    RESET_SELECTED_EB(state) {
      state.selectedEb = null
    },
    SET_SELECTED_MS(state, item) {
      state.selectedMs = item
    },
    RESET_SELECTED_MS(state) {
      state.selectedMs = null
    },
    SET_SELECTED_EM(state, item) {
      state.selectedEm = item
    },
    RESET_SELECTED_EM(state) {
      state.selectedEm = null
    },
    SET_SELECTED_SB(state, item) {
      state.selectedSb = item
    },
    RESET_SELECTED_SB(state) {
      state.selectedSb = null
    },
    UPD_SEARCH_RESULT(state, payload) {
      state.search = payload
    }
  },
  getters: {
    getSearchResult: state => state.search,
  }
})