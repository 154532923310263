<template>
  <div>
    <!-- <GuideInfoSection :title="utbytesguide_elslutbleck_title" :text="utbytesguide_elslutbleck_text"> -->
    <GuideInfoSection title="" :text="utbytesguide_elslutbleck_text" :text2="guide_friskrivning">
      <template #title-append>
        <!-- <b-button variant="outline-primary" size="lg" :to="{ name: 'guide-filter-ug' }" class="ug-switch-button">{{ utbytesguide_montagestolpe_title }}</b-button> -->
        <b-nav pills>
          <b-nav-item :to="{ name: 'guide-filter-ug' }" class="ug-switch-button"><b-icon-square style="margin-right: 10px"></b-icon-square> {{ utbytesguide_montagestolpe_title }}</b-nav-item>
          <b-nav-item active class="ug-switch-button"><b-icon-check-square style="margin-right: 10px"></b-icon-check-square> {{ utbytesguide_elslutbleck_title }}</b-nav-item>
        </b-nav>
      </template>
    </GuideInfoSection>
    <b-container>
      <b-row>
        <b-col>
          <UtbytesguidenEbInputWidget></UtbytesguidenEbInputWidget>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import GuideInfoSection from "@/components/guide/options/GuideInfoSection";
  import UtbytesguidenEbInputWidget from "@/components/utbytesguiden/UtbytesguidenEbInputWidget";
  import contentMixin from "@/mixins/contentMixin";
  export default {
    name: "UtbytesguidenEbFilterView",
    mixins: [contentMixin],
    components: {
      GuideInfoSection,
      UtbytesguidenEbInputWidget,
    },
  };
</script>

<style lang="scss" scoped>
  @import "/scss/custom.scss";
  @import "/node_modules/bootstrap/scss/mixins/_breakpoints.scss";
  .ug-switch-button {
    .nav-link {
      padding: 0.75rem 1rem;
    }
    font-size: 1.1rem;
    width: 100% !important;
    border: #00a2d5 solid 1px;
    @include media-breakpoint-up(md) {
      font-size: 1.2rem;
      width: 50% !important;
      .nav-link {
        padding: 0.75rem 2.6rem;
      }
    }
  }
</style>
