<template>
  <div class="py-5">
    <b-form novalidate id="edit" @submit="onSubmit" @reset="onReset">
      <b-overlay :show="loading" rounded opacity="0.8" spinner-small spinner-variant="primary" @hidden="onOverlayHidden">
        <b-container>
          <h2>{{ title }}</h2>
          <b-row>
            <b-col cols="8">
              <b-form-row>
                <b-col>
                  <b-form-group :label="$t('form.namn')">
                    <b-form-input v-if="showOriginal" required id="eb-name" v-model="form.name"></b-form-input>
                    <b-input-group v-if="showTranslation">
                      <b-form-input required id="eb-name-no" v-model="form.translations.no.name"></b-form-input>
                      <template #append>
                        <country-flag country="no" size="normal" class="ml-n5" />
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group :label="$t('form.artnr')">
                    <input type="hidden" name="old_artnr" v-model="old.artnr" />
                    <b-form-input v-if="showOriginal" required id="eb-artnr" v-model="form.artnr"></b-form-input>
                    <b-input-group v-if="showTranslation">
                      <b-form-input required id="eb-artnr-no" v-model="form.translations.no.artnr"></b-form-input>
                      <template #append>
                        <country-flag country="no" size="normal" class="ml-n5" />
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group v-if="showOriginal" :label="$t('form.enr')" :description="$t('form.enr-help')">
                    <b-form-input required id="eb-enr" v-model="form.enr"></b-form-input>
                  </b-form-group>
                  <b-form-group v-if="showTranslation" :label="$t('form.nobnr')" :description="$t('form.nobnr-help')">
                    <b-input-group>
                      <b-form-input required id="eb-nobnr" v-model="form.nobnr"></b-form-input>
                      <template #append>
                        <country-flag country="no" size="normal" class="ml-n5" />
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-form-row>
              

              <b-form-row v-for="(info, i) in form.info" v-bind:key="`info-${i}`">
                <b-col>
                  <b-form-group :label="$t(info.title)">
                    <b-form-textarea v-if="showOriginal" rows="5" wrap="soft" :formatter="removeHtml" id="`info-${i}`" v-model="info.body"></b-form-textarea>
                    <b-input-group v-if="showTranslation">
                      <b-form-textarea rows="5" wrap="soft" :formatter="removeHtml" id="`no-info-${i}`" v-model="form.translations.no.info[0].body"></b-form-textarea>
                      <template #append>
                        <country-flag country="no" size="normal" class="ml-n5" />
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row v-if="showOriginal">
                <b-col>
                  <b-form-checkbox :disabled="!canPublishSE" v-model="form.published" switch>
                    {{ $t("text.admin.published") }} <small>{{ $t("text.admin.published-help") }}</small>
                  </b-form-checkbox>
                </b-col>
              </b-form-row>

              <div v-if="showTranslation">
                <b-form-row>
                  <b-col>
                    <b-form-checkbox :disabled="!canPublishNO" v-model="form.translations.no.published" switch>
                      {{ $t("text.admin.published") }} <small>{{ $t("text.admin.published-help") }}</small>
                    </b-form-checkbox>
                  </b-col>
                </b-form-row>
                <div class="mt-5" v-if="showTranslation">
                  <label>{{ $t("text.admin.sampletext-orig") }}</label>
                  <b-card class="shadow-sm" no-body bg-variant="light">
                    <div class="d-flex" style="justify-content: space-between">
                      <h3 class="m-3">{{ form.name }}</h3>
                      <div class="my-3 ml-auto">{{ form.artnr }}</div>
                      <div class="mt-2">
                        <country-flag country="se" size="normal" class="mr-0 ml-0" />
                      </div>
                    </div>
                    <div class="px-3">
                      <dl v-for="(info, i) in form.info" v-bind:key="`product-info-${i}`">
                        <div v-if="info.body">
                          <dt>{{ info.title }}</dt>
                          <dd v-html="info.body" style="white-space: pre-wrap"></dd>
                        </div>
                      </dl>
                    </div>
                  </b-card>
                </div>
              </div>

              <!-- Edit facts -->
              <div v-if="showOriginal" class="mt-5">
                <h3>{{ $t("text.fakta") }}</h3>
                <table class="table facts">
                  <tbody v-if="form.facts">
                    <tr v-for="(fact, i) in form.facts.filter((fact) => fact.name != 'passar-stolpar')" :key="`fact-${i}`">
                      <th>{{ $t(fact.title) }}</th>
                      <td>
                        <div v-if="factsOptions[fact.title] && factsOptions[fact.title].options">
                          <div v-if="Array.isArray(fact.value)">
                            <b-form-checkbox-group v-model="fact.value" :options="factsOptions[fact.title].options" :name="`fact-options-${i}`"></b-form-checkbox-group>
                          </div>
                          <div v-else>
                            <b-form-radio-group v-model="fact.value" :options="factsOptions[fact.title].options" :name="`fact-options-${i}`"></b-form-radio-group>
                          </div>
                        </div>
                        <div v-else>
                          <b-input-group :append="fact.suffix">
                            <b-form-input v-model="fact.value"></b-form-input>
                          </b-input-group>
                        </div>
                        <b-form-input v-if="fact.value == 'JA'" placeholder="fritext" v-model="fact.text"></b-form-input>
                        <!-- {{ factOptions[fact.title] }} -->
                        <!-- <b-form-group label="" v-slot="{ ariaDescribedby }">
                          <b-form-radio-group :id="`radio-group-${i}`" v-model="fact.value" :options="fact.options" name="radio-options"></b-form-radio-group>
                        </b-form-group> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
            <b-col cols="4">
              <b-alert :show="form.artnr == ''" variant="warning"><b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> {{ $t("text.admin.alert-no-artnr") }}</b-alert>
              <b-form-group :label="$t('text.produktbild')" v-if="form.artnr">
                <b-form-row>
                  <b-col class="shadow-sm w-100 border">
                    <ProductImages :artnr="form.artnr" :key="uselessNumber" @on-deleted-image="uselessNumber--"></ProductImages>
                  </b-col>
                </b-form-row>
                <b-form-row class="mt-3" v-if="form.artnr && showOriginal">
                  <b-col>
                    <upload-product-images multiple :for-product-art="form.artnr" @on-select-product-image="setProductImageData" @on-uploaded-image="uploadDone(...arguments)"></upload-product-images>
                  </b-col>
                </b-form-row>
              </b-form-group>
              <div class="accordion" role="tablist" v-if="showOriginal">
                <b-card no-body border-variant="primary" header-bg-variant="primary" header-text-variant="white">
                  <b-card-header header-tag="header" class="p-0 bg-white" role="tab">
                    <b-button block v-b-toggle.montagestolpar variant="outline-primary">
                      {{ $t("text.admin.passar-till-monteringsstolpar") }}
                      <!-- <b-badge variant="primary" class="float-right mt-1">{{ selectedMs.length }}</b-badge> -->
                    </b-button>
                  </b-card-header>
                  <b-collapse id="montagestolpar" accordion="montagestolpar" role="tabpanel">
                    <b-card-body class="px-2 py-1">
                      <div v-for="(ms, i) in montagestolpar" :key="`ms-${i}`">
                        <b-form-checkbox v-model="selectedMs" :value="ms.name" unchecked-value="" @change="updateRelatedMsSpec(ms, selectedMs.indexOf(ms.name) > -1)">{{ ms.name }}</b-form-checkbox>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </b-col>
          </b-row>
          <hr class="my-5" />
          <b-form-row v-if="form.artnr" class="border rounded p-3 mt-3 mb-4">
            <b-col>
              <b-form-group>
                <upload-product-docs :language="access_level == 2 ? 'no' : 'se'" v-if="form.artnr" multiple :docs="form.docs" :key="uselessNumber" :for-product-art="form.artnr" @on-select-product-docs="setProductDocs" @on-deleted-image="uselessNumber--"></upload-product-docs>
                <small>{{ $t("text.admin.ladda-upp-bilagor-max") }} {{ maxSize | fsize }}</small>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-row class="mt-4">
            <b-col>
              <b-button size="lg" type="button" :to="{ name: 'adm-dashboard' }" variant="outline-primary"><b-icon icon="chevron-left"></b-icon> {{ $t("text.admin.ga-till-lista") }}</b-button>
            </b-col>
            <b-col align="right">
              <b-button size="lg" type="submit" variant="primary">{{ $t("text.admin.spara") }}</b-button>
            </b-col>
          </b-row>
        </b-container>
        <div v-if="showOriginal && id">
          <hr class="mt-5 mb-0" />
          <b-container fluid class="bg-light p-md-5 px-sm-1 py-sm-3">
            <b-row>
              <b-col class="px-xl-5 px-lg-2 px-sm-0">
                <h2 class="mb-4">{{ $t("text.admin.kriterier-for") }} {{ form.name }}</h2>
                <!-- <AdminOptionsEditTable
                  :products="productsEM"
                  :options="optionsEB"
                  :spec="this.form.spec"
                  scope="em"
                  @on-change-slug="updateEbSpecObj(...arguments)"
                ></AdminOptionsEditTable> -->
                <!-- <AdminOptionsEditTable hide-header
                  :products="productsLH"
                  :options="optionsEB"
                  :spec="this.form.spec"
                  scope="lh"
                  @on-change-slug="updateEbSpecObj(...arguments)"
                ></AdminOptionsEditTable> -->
                <!-- <AdminEbEMOptionsEdit :ebid="this.id" @on-change-slug="updateEbSpecObj(...arguments)" /> -->
                <AdminEbOptionsEdit :ebid="this.id" @on-change-slug="updateEbSpecObj(...arguments)" />
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-overlay>
    </b-form>
  </div>
</template>

<script>
  import ApiServiceAdmin from "@/services/ApiServiceAdmin";
  // import AdminOptionsEditTable from "@/components/admin/AdminOptionsEditTable";
  // import AdminEbEMOptionsEdit from "@/components/admin/AdminEbEMOptionsEdit";
  import AdminEbOptionsEdit from "@/components/admin/AdminEbOptionsEdit";
  import AdminEditMixin from "@/mixins/AdminEditMixin";
  import AdminAccessMixin from "@/mixins/AdminAccessMixin";
  import _ from "lodash";

  export default {
    name: "AdminEbEdit",
    mixins: [AdminEditMixin, AdminAccessMixin],
  components: {
      // AdminOptionsEditTable,
      // AdminEbEMOptionsEdit,
      AdminEbOptionsEdit,
    },
    data() {
      return {
        language: process.env.VUE_APP_I18N_LOCALE,
        selectedMs: [],
        saveMsSpec: null,
        changedMs: [],
        factsOptions: {
          Funktion: {
            options: [
              { text: this.$t("text.omstallbar"), value: "Omställbar" },
              { text: this.$t("text.rattvand"), value: "Rättvänd" },
              { text: this.$t("text.omvand"), value: "Omvänd" },
            ],
          },

          "CE-märkt branddörr": {
            options: [
              { text: this.$t("text.nej"), value: "NEJ" },
              { text: this.$t("text.ja"), value: "JA" },
            ],
          },

          Listtryckteknik: {
            options: [
              { text: this.$t("text.nej"), value: "NEJ" },
              { text: this.$t("text.ja"), value: "JA" },
            ],
          },

          Spänning: {
            value: "12-24V AC/DC +15% -10%",
          },

          "Strömförbrukning vid 24V": {
            suffix: "mA",
          },

          "Strömförbrukning vid 12V": {
            suffix: "mA",
          },

          Skyddsdiod: {
            options: [
              { text: this.$t("text.nej"), value: "NEJ" },
              { text: this.$t("text.ja"), value: "JA" },
            ],
          },

          Kolvkontakt: {
            options: [
              { text: this.$t("text.nej"), value: "NEJ" },
              { text: this.$t("text.ja"), value: "JA" },
            ],
          },

          Ankarkontakt: {
            options: [
              { text: this.$t("text.nej"), value: "NEJ" },
              { text: this.$t("text.ja"), value: "JA" },
            ],
          },

          "Hi-O": {
            options: [
              { text: this.$t("text.nej"), value: "NEJ" },
              { text: this.$t("text.ja"), value: "JA" },
            ],
          },

          Brythållfasthet: {
            suffix: "kN",
          },

          Slagsäkert: {
            options: [
              { text: this.$t("text.nej"), value: "NEJ" },
              { text: this.$t("text.ja"), value: "JA" },
            ],
          },

          "Fungerar mot låshustyp": {
            options: [
              { text: this.$t("Dubbelfall"), value: "Dubbelfall" },
              { text: this.$t("Enkelfall"), value: "Enkelfall" },
              { text: this.$t("Cylinderfall"), value: "Cylinderfall" },
              { text: this.$t("Tryckesfall"), value: "Tryckesfall" },
            ],
          },
          "Durability enligt SS-EN 14846": {
            options: [
              { text: this.$t("text.nej"), value: "NEJ" },
              { text: this.$t("text.ja"), value: "JA" },
            ],
            text: "",
          },

          "Corrosion enligt SS-EN 14846": {
            options: [
              { text: this.$t("text.nej"), value: "NEJ" },
              { text: this.$t("text.ja"), value: "JA" },
            ],
            text: "",
          },

          "Security enligt SS-EN 14846": {
            options: [
              { text: this.$t("text.nej"), value: "NEJ" },
              { text: this.$t("text.ja"), value: "JA" },
            ],
            text: "",
          },

          "Security electrical manipulation enligt SS-EN 14846": {
            options: [
              { text: this.$t("text.nej"), value: "NEJ" },
              { text: this.$t("text.ja"), value: "JA" },
            ],
            text: "",
          },
        },
        old: {
          name: "",
          artnr: "",
        },
        form: {
          name: "",
          artnr: "",
          published: false,
          spec: null,
          info: [
            {
              title: "Användningsområde",
              body: "",
            },
          ],
          facts: [
            {
              title: "Funktion",
              value: [],
            },
            {
              title: "CE-märkt branddörr",
              value: "",
            },
            {
              title: "Listtryckteknik",
              value: "",
            },
            {
              title: "Spänning",
              value: "",
            },
            {
              title: "Strömförbrukning vid 24V",
              value: "",
              suffix: "mA",
            },
            {
              title: "Strömförbrukning vid 12V",
              value: "",
              suffix: "mA",
            },
            {
              title: "Skyddsdiod",
              value: "",
            },
            {
              title: "Kolvkontakt",
              value: "",
            },
            {
              title: "Ankarkontakt",
              value: "",
            },
            {
              title: "Hi-O",
              value: "",
            },
            {
              title: "Brythållfasthet",
              value: "",
              suffix: "kN",
            },
            {
              title: "Slagsäkert",
              value: "",
            },
            {
              title: "Fungerar mot låshustyp",
              value: [],
            },
            {
              name: "passar-stolpar",
              title: "Passar monteringsstolpe",
              value: [],
            },
            {
              title: "Durability enligt SS-EN 14846",
              value: "",
              text: "",
            },
            {
              title: "Corrosion enligt SS-EN 14846",
              value: "",
              text: "",
            },
            {
              title: "Security enligt SS-EN 14846",
              value: "",
              text: "",
            },
            {
              title: "Security electrical manipulation enligt SS-EN 14846",
              value: "",
              text: "",
            },
          ],
        },
        montagestolpar: [],
        options: [],
        lhs: null,
        show: true,
        productsEM: [],
        productsLH: [],
        optionsEB: []
      };
    },
    computed: {
      canPublishSE() {
        return this.form.name && this.form.artnr && this.form.info[0].body;
      },
      canPublishNO() {
        return this.form.translations && this.form.translations.no.name && this.form.translations.no.artnr && this.form.translations.no.info[0].body;
      },
      allMs: {
        get() {
          return this.montagestolpar.map((ms) => ms.name);
        },
      },
      title: {
        get() {
          return this.form.name ? this.form.name : this.$t("text.admin.add-eb");
        },
      },
      lockids() {
        // Return array with unique IDs concatinated from spec arrays
        let concatArray = [];
        for (const name in this.form.spec) {
          concatArray = [...new Set([...concatArray, ...this.form.spec[name]])];
        }
        return concatArray;
      },
      slugs() {
        return Object.keys(this.form.spec);
      }
    },
    methods: {
      updateRelatedMsSpec(ms, checked) {
        console.log("checked", checked);
        console.log("spec", ms.spec);

        const ebname = this.form.name;
        console.log("ebname", ebname);

        if (ms.spec) {
          // Set or remove ebname from ms.spec
          if (checked && ms.spec[ebname] === undefined) {
            // Add ebname to ms.spec
            ms.spec[ebname] = [];
          } else {
            // Remove ebname from ms.spec
            if (ms.spec[ebname]) {
              delete ms.spec[ebname];
            }
          }
          console.log("new spec", ms.spec);
        } else {
          // ms.spec could be null because of an old error, but should NEVER be null now
          console.log(`[spec] is null for "${ms.name}""`);
          console.log("ACTIVITY shold be written here");
        }

        // Update facts value
        const passarStolpar = this.getFactByName("passar-stolpar")[0].value;
        if (checked && passarStolpar.indexOf(ms.name) === -1) {
          passarStolpar.push(ms.name);
        } else if (!checked && passarStolpar.indexOf(ms.name) !== -1) {
          passarStolpar.splice(passarStolpar.indexOf(ms.name), 1);
        }

        // Fetch monteringstolpar or update spec value in object
        // this.fetchMs();

        // Update ms in db
        console.log("ms", ms);

        // Add MS to list of items should be updated on save
        this.changedMs.push(ms);

        // ApiServiceAdmin.updateMsById(ms._id, _.pick(ms, ["artnr", "name", "spec", "published", "facts", "info", "translations"]))
        //   .then((response) => {
        //     this.fetchMs();
        //     this.updateEb();
        //     this.handleSuccess(response);
        //   })
        //   .catch(this.handleError);
      },
      uploadDone(files) {
        console.log("Upload done", files);
        this.uselessNumber++;
      },
      updateEbSpecObj(spec) {
        console.log('debug', spec)
        let merge = _.mergeWith(this.form.spec, spec, (objValue, srcValue) => {
          if (_.isArray(objValue)) {
            return objValue.concat(srcValue);
          }
        });

        this.form.spec = merge;
      },
      async initFormData() {
        try {
          await ApiServiceAdmin.getEbDataById(this.id, []).then((response) => {
            this.form = _.pick(response.data, ["name", "artnr", "enr", "nobnr", "spec", "info", "facts", "published", "translations"]);
            // if (this.form.spec === null) {
            //   // Remove this block?
            //   this.form.spec = {
            //     // Todo: This should probably be dynamic. Fetch these from options
            //     rattvand: [],
            //     omvand: [],
            //     "tryckes-fall": [],
            //     "cylinder-fall": [],
            //     "dubbel-fall": [],
            //     automatik: [],
            //     "ej-automatik": [],
            //     "ej-brandkrav": [],
            //     "brandkrav-e30-el30": [],
            //     "brandkrav-e60-el60": [],
            //     "brandkrav-e90-el90": [],
            //     "brandkrav-e120-el120": [],
            //     "trycke-uts": [],
            //     "draghandtag-uts": [],
            //     "ej-aterinrymning": [],
            //     "trycke-ins": [],
            //     "draghandtag-ins": [],
            //     "vred-ins": [],
            //     "nyckel-ins": [],
            //     "split-spindle": [],
            //     "ss-en-179": [],
            //     "ss-en-1125": [],
            //     "elektrisk-via-brandlarm-och-eller-oeppnaknapp": [],
            //     "elektrisk-via-brandlarm": [],
            //     mekanisk: [],
            //     "kopplingsplint-hi-o-ja": [],
            //     "kopplingsplint-hi-o-nej": [],
            //     "mikrobrytare-ja": [],
            //     "mikrobrytare-nej": [],
            //   };
            // }

            // Set old.artnr to keep track of changes
            this.old.artnr = this.form.artnr;
          });
          // Set old.name to keep track of changes
          this.old.name = this.form.name;
          // init selected ms
          const passarStolpar = this.getFactByName("passar-stolpar")[0];
          this.selectedMs = passarStolpar.value;
          // this.handleMessage('Data fetched for "' + this.form.name + '"');
        } catch (error) {
          console.log(error);
        }
      },
      saveNewEb() {
        if (this.form.spec === null) {
          console.log("Set default spec");
          this.form.spec = {
            // Todo: This should probably be dynamic. Fetch these from options
            rattvand: [],
            omvand: [],
            "tryckes-fall": [],
            "cylinder-fall": [],
            "dubbel-fall": [],
            automatik: [],
            "ej-automatik": [],
            "ej-brandkrav": [],
            "brandkrav-e30-el30": [],
            "brandkrav-e60-el60": [],
            "brandkrav-e90-el90": [],
            "brandkrav-e120-el120": [],
            "trycke-uts": [],
            "draghandtag-uts": [],
            "ej-aterinrymning": [],
            "trycke-ins": [],
            "draghandtag-ins": [],
            "vred-ins": [],
            "nyckel-ins": [],
            "split-spindle": [],
            "ss-en-179": [],
            "ss-en-1125": [],
            "elektrisk-via-brandlarm-och-eller-oeppnaknapp": [],
            "elektrisk-via-brandlarm": [],
            mekanisk: [],
            "kopplingsplint-hi-o-ja": [],
            "kopplingsplint-hi-o-nej": [],
            "mikrobrytare-ja": [],
            "mikrobrytare-nej": [],
          };
        }
        // const payload = JSON.stringify(this.form);
        const payload = _.pick(this.form, ["artnr", "enr", "nobnr", "name", "spec", "published", "facts", "info", "translations"]);

        ApiServiceAdmin.addEb(payload)
          .then((response) => {
            this.$router.push(`/admin/eb/${response.data.result._id}`);
            this.id = response.data.result._id;
            this.handleSuccess(response);
          })
          .catch(this.handleError);
      },
      updateEb() {

        

          ApiServiceAdmin.updateEbById(this.id, _.pick(this.form, ["artnr", "enr", "nobnr", "name", "spec", "published", "facts", "info", "translations"]), 1) // [id], [payload], [loglevel]
            .then((response) => {
              console.log("updateEbById", "then");
              // Update folder name in S3
              if (this.form.artnr != this.old.artnr) {
                console.log("Update folder name in S3");
                ApiServiceAdmin.updateFolderByArt(this.form.artnr, this.old.artnr)
                  .then(() => {
                    this.old.artnr = this.form.artnr;
                    // this.makeToast({ variant: "info", title: this.$t("messages.post-update-artnr"), text: this.$t("messages.post-save") });
                  })
                  .catch(this.handleError);
              }
              // Use list of updated related MS to update them
              console.log("list of changed ms", this.changedMs);
              this.changedMs.forEach((ms) => {
                ApiServiceAdmin.updateMsById(ms._id, _.pick(ms, ["artnr", "name", "spec", "published", "facts", "info", "translations"]))
                  .then((response) => {
                    this.changedMs = [];
                    this.handleSuccess(response);
                  })
                  .catch(this.handleError);
              });
              this.old.name = this.form.name;
              this.handleSuccess(response);
            })
            .catch(this.handleError);
      },
      async onSubmit(event) {
        event.preventDefault();
        
        const unique_enr = await this.isUnique('enr', this.form.enr, this.id)
        const unique_nobnr = await this.isUnique('nobnr', this.form.nobnr, this.id)
        if ( !unique_enr || !unique_nobnr ) {
          // Prevent saving if not unique
          this.handleError('E11000_DUPLICATE_KEY')
          return false;
        }
        this.loading = true;
        if (!this.id) {
          this.saveNewEb();
        } else {
          this.updateEb();
        }
      },
      initSelected() {
        console.log(this.monteringsstolpar);

        // this.selectedMs = ["MONTERINGSSTOLPE 930-17 ,5"];
      },
      getFactByName(name) {
        return this.form.facts ? this.form.facts.filter((fact) => fact.name == name) : false;
      },
      fetchMs() {
        ApiServiceAdmin.getMs()
          .then(async (response) => {
            console.log("fetchMs");
            this.montagestolpar = response.data;
            this.handleSuccess(response);
            // this.montagestolpar = await _.map(response.data, (ms) => {
            //   // if (fact != null && fact.value.indexOf(ms.name) != -1) {
            //   //   this.selectedMs.push(ms);
            //   // }
            //   console.log("return MS");
            //   return ms;
            // });
          })
          .catch((err) => console.error(err));
      },
      async fetchEm() {
        this.productsEM = await ApiServiceAdmin.getEm().then((response) => response.data)
      },
      async fetchLh() {
        this.productsLH = await ApiServiceAdmin.getLh().then((response) => response.data)
      },
      async fetchEbOptions() {
        const ebOptions = await ApiServiceAdmin.getEbOptions().then((response) => response.data)
        this.optionsEB = _.flatten(_.valuesIn(_.map(ebOptions, (o) => o.slugs)));
      },
    },
    mounted() {
      const fact = this.getFactByName("passar-stolpar");
      console.log("fact", fact);
      this.fetchMs();
      this.fetchEm();
      this.fetchLh();
      this.fetchEbOptions();
      // this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
      //   if (isJustShown) {
      //     // this.selectedMs = [];
      //   }
      // });
    },
    watch: {
      "form.name": function (newname, oldname) {
        console.log(newname, oldname);

        // this.selectedMs = val;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../../scss/_colors.scss";
  #montagestolpar {
    max-height: 400px;
    overflow: auto;
  }
  table {
    th,
    td {
      border-width: 2px;
      border-color: white;
    }
    th {
      background-color: $primary;
      color: $white;
    }
    td {
      background-color: $gray-100;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        white-space: nowrap;
      }
    }
  }
</style>
