
import ApiClient from '@/services/ApiService'

export const namespaced = true;

export const state = {
  product: null,
  passandeStolpar: [],
  docs: []
}

export const actions = {
  async fetchProduct({ commit, rootState }, id) {
    return await ApiClient.getEbDataById(id, rootState.optionsEB.selection)
      .then(async (response) => {
        commit('UPD_PRODUCT', await response.data)
        return response.data
      })
  },
  async fetchMsAndMatchProduct({ commit, state }, id) {
    return await ApiClient.getMs()
      .then(async (response) => {
        console.log(id, state.product)
        commit('UPD_PASSANDE_STOLPAR', await response.data)
        return response.data
      })
  },
  resetProduct({ commit }) {
    commit('UPD_PRODUCT', null)
  },
  async fetchDocsForArt({ commit }, payload) {
    return await ApiClient.getDocsForArt(payload.artnr, payload.lang)
      .then(async (response) => {
        commit('UPD_PRODUCT_DOCS', await response.data)
        return response.data
      })
  },
}
export const mutations = {
  UPD_PRODUCT(state, product) {
    state.product = product
  },
  UPD_PRODUCT_DOCS(state, docs) {
    state.docs = docs
  },
  UPD_PASSANDE_STOLPAR(state, stolpar) {
    state.passandeStolpar = stolpar
  }
}
export const getters = {
  getProduct: state => state.product,
  getPassandeStolpar: state => state.passandeStolpar,
  getDocs: state => state.docs,
}
