<template>
  <b-list-group-item button :active="slugSelected" :class="{ disabled: disabled }" class="slug">
    <!-- {{ disabled }} -->
    <b-form-checkbox :name="slug" v-model="slugSelected" :class="{ checked: slugSelected }" size="sm" class="w-100 slug-item">{{ displaySlug }}</b-form-checkbox>
  </b-list-group-item>
</template>

<script>
  export default {
    name: "slug",
    props: {
      slug: String,
      translation: String,
      selected: Boolean,
      disabled: Boolean,
    },
    computed: {
      displaySlug() {
        // If names is passande-elslutbleck, return the translated name
        if (this.translation) {
          return this.translation;
        }

        // Convert float values to a readable format
        if (this.slug.indexOf("p-") == 0) {
          let dslug = this.slug.replace("p-", "").replace("-", ",");
          return dslug;
        }
        if (this.slug.indexOf("b-") == 0) {
          let dslug = this.slug.replace("b-", "").replace("-", ",");
          return dslug;
        }
        if (this.slug.indexOf("h-") == 0) {
          let dslug = this.slug.replace("h-", "").replace("-", ",");
          return dslug;
        }
        if (this.slug.indexOf("vd-") == 0) {
          let dslug = this.slug.replace("vd-", "").replace("-", ",");
          return dslug;
        }

        return this.$t(this.slug);
      },
      slugSelected: {
        get() {
          return this.selected;
        },
        set(selected) {
          this.$emit("toggle-slug", { selected: selected, slug: this.slug });
        },
      },
    },
    data() {
      return {
        active: false,
      };
    },
    // mounted() {
    //   store.dispatch("guide/fetchDisabled", this.slug);
    // },
  };
</script>

<style lang="scss">
  button.slug.list-group-item {
    padding: 0 0.5em;
    min-height: 32px;
    display: flex;
    align-items: center;
    color: #393939;
    font-weight: normal;
    > .slug-item {
      cursor: hand !important;
      white-space: nowrap;
      margin-top: 4px;
      align-self: middle;
    }
    > .custom-control > .custom-control-label {
      width: 100% !important;
      white-space: initial;
    }
  }
</style>
