import ProductsList from "@/components/products/ProductsList";

export default {
  components: {
    ProductsList,
  },
  methods: {
    showProduct(id, scope) {
      console.log('showProduct', id, scope)

      // Show product with [id]
      this.fetchProduct(id)
        .then(async (response) => {
          // Set selected elslutbleck
          const data = await response;

          // this.$root.$emit('bv::show::modal', 'productsListAside', `#show-product-${id}`)

          if (scope == "eb") {
            await this.setSelectedEb(data); // TODO: Fix this. Should probably save in localStorage?
            // this.$store.dispatch("optionsMS/resetSlugs")
            // this.$store.dispatch("optionsSB/resetSlugs")
          }
          if (scope == "em") {
            await this.setSelectedEm(data); // TODO: Fix this. Should probably save in localStorage?
            // this.$store.dispatch("optionsEB/resetSlugs")
            // this.$store.dispatch("optionsSB/resetSlugs")
            // this.$store.dispatch("optionsSB/resetSlugs")
          }
          if (scope == "sb") {
            this.$store.dispatch("optionsEB/resetSlugs")
          }
          console.log('1 locale', this.$i18n.locale)
          this.fetchDocsForArt({
            artnr: data.artnr,
            lang: this.$i18n.locale
          })
          if (this.ltLg) {
            this.$bvModal.show('selectedProductInfoModal')
          } else {
            // var container = this.$el.querySelector("#productInfo");
            // container.scrollIntoView({
            //   behavior: "smooth"
            // });
          }
          this.$store.dispatch("productsNB/fetchProducts", [...this.$store.state.optionsEM.selection, ...[data.name]])
        })
    },
  },
  watch: {
    '$i18n.locale'() {
      // Change localStorage variable for langcode
      localStorage.setItem('langcode', this.$i18n.locale)
      // Fetch products again to update for language
      this.fetchProducts()
      // Fetch options
      this.$store.dispatch('optionsMS/fetchOptions')
      // If product is loaded in aside box
      if (this.getProduct) {
        // Fetch docs when language has changed
        this.fetchDocsForArt({
          artnr: this.getProduct.artnr,
          lang: this.$i18n.locale
        })
      }
    }
  }
}