<template>
  <div>
    <GuideInfoSection :title="guideTitle" :text="guide_eltryckeslas_motorlas_text" :text2="guide_friskrivning" :step1="$t('text.guideEM.step1')" @on-reset-slugs="resetSlugs" class="d-print-none">
      <template #bottom>
        <!-- <OptionsCategory :categories="getCategories" :disabled="getDisabled" :selection="getSelection" :title="$t('em-omstallbar-rattvand-omvand')" @toggle-slug="toggleSlug" /> -->
      </template>
    </GuideInfoSection>
    <b-overlay no-fade spinner-variant="primary" :show="loading" opacity="0.95" bg-color="white">
      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon" variant="primary"></b-icon>
          <p class="mt-2">
            <strong>{{ $t("text.guideEM.loading") }}</strong>
          </p>
        </div>
      </template>
      <OptionsList :options="getOptions" :disabled="getDisabled" :selection="getSelection" @toggle-slug="toggleSlug" @reset-slugs="resetSlugs" product-type="elslutbleck" class="d-print-none" />
    </b-overlay>
    <b-container>
      <transition name="fade">
        <ProductsList :total="getTotal" :selected-product-data="getProduct" :selected-product-docs="getDocs" :products="getProducts" @on-product-select="showProduct(...arguments, 'em')" @on-click-load-more="loadMore" scope="em">
          <template #ingress>
            <h3 class="d-print-none">{{ $t("text.guideEM.step2") }}</h3>
            <p class="d-print-none">{{ $t("text.guideEM.step2-text") }}</p>
          </template>
        </ProductsList>
      </transition>
    </b-container>
  </div>
</template>

<script>
  import store from "@/store/store";
  import GuideInfoSection from "@/components/guide/options/GuideInfoSection";
  // import OptionsCategory from "@/components/guide/options/OptionsCategory.vue";
  import OptionsList from "@/components/guide/options/OptionsList.vue";
  import { mapGetters, mapActions } from "vuex";
  import productsListMixin from "@/mixins/productsListMixin";
  import contentMixin from "@/mixins/contentMixin";
  export default {
    name: "ProductsListViewEM",
    mixins: [productsListMixin, contentMixin],
    components: {
      GuideInfoSection,
      // OptionsCategory,
      OptionsList,
    },
    computed: {
      ...mapGetters("optionsEM", ["getOptions", "getSelection", "getDisabled", "getCategories"]),
      ...mapGetters("productsEM", ["getProducts", "getCount", "getTotal", "getUi"]),
      ...mapGetters("productEM", ["getProduct", "getDocs"]),
      loading: {
        get() {
          return this.$store.state.optionsEM.loading;
        },
        set(loading) {
          this.$store.state.optionsEM.loading = loading;
        },
      },
      // ebInfo() {
      //   let eb = this.$store.state.productEB.product;

      //   if (this.$i18n.locale == "no" && eb && eb.translations) {
      //     eb = eb.translations.no;
      //   }
      //   return eb ? ` - passande ${eb.name}` : "";
      // },
      guideTitle() {
        return this.guide_eltryckeslas_motorlas_title;
      },
    },
    methods: {
      ...mapActions("optionsEM", ["fetchOptions", "fetchDisabled", "updDisabled", "resetSlugs", "addSlug", "rmSlug"]),
      ...mapActions("productsEM", ["fetchProducts"]),
      ...mapActions("productEM", ["fetchProduct", "fetchDocsForArt"]),
      ...mapActions(["setSelectedEm", "clearAllData"]),
      toggleSlug(payload) {
        this.loading = true;
        const { slug, selected } = payload[0];
        if (selected) {
          this.addSlug(slug);
        } else {
          this.rmSlug(slug);
        }
      },
      skip(o) {
        return o.filter((o) => {
          return o.name != "produktkategori" && o.name != "em-omstallbar-rattvand-omvand";
        });
      },
      loadMore() {
        this.$store.dispatch("productsEM/updateSkip");
      },
    },
    // beforeRouteUpdate(to, from, next) {
    //   this.$store.dispatch("productsEM/fetchProducts").then(() => {
    //     next();
    //   });
    // },
    // 
    beforeRouteEnter(to, from, next) {
      store.dispatch("optionsEM/fetchOptions", to.name)
      // let numProducts = store.getters["productsEM/getProducts"].length
      // if (numProducts == 0 || to.name == "guide-filter-em-start") {
      //   store.dispatch("productsEM/fetchProducts")
      // }
      
      switch (to.name) {
        case "guide-filter-em":
        case "guide-filter-em-start":
          store.dispatch("productsEM/fetchProducts", store.state.optionsEM.selection)
          break;
        default:
          break;
      }

      next();
    },
  };
</script>

<style lang="scss" scoped></style>
