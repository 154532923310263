import axios from 'axios'

export const namespaced = true;

export const state = {
  user: null,
  access_level: null,
}

export const actions = {
  login({ commit, dispatch }, payload) {
    return axios
      .post(`${process.env.VUE_APP_DBHOST}/api/auth`, payload)
      .then(({ data }) => {
        commit('SET_USER_DATA', data)
        dispatch('fetchAccessLevel', data)
      })
  },
  logout({ commit }) {
    commit('LOGOUT')
  },
  fetchAccessLevel({ commit }, payload) {
    return axios
      .get(`${process.env.VUE_APP_DBHOST}/api/users/${payload.email}`, {
        headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
      })
      .then(({ data }) => {
        commit('SET_ACCESS_LEVEL', data.access_level)
      })
  }
}

export const mutations = {
  SET_USER_DATA(state, userData) {
    state.user = userData
    localStorage.setItem('user', JSON.stringify(userData))
    axios.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`

  },
  SET_ACCESS_LEVEL(state, access_level) {
    state.access_level = access_level
    localStorage.setItem('access_level', access_level)
  },
  LOGOUT(state) {
    state.user = null
    state.access_level = null
    localStorage.removeItem('user')
    localStorage.removeItem('access_level')
    axios.defaults.headers.common['Authorization'] = null
  },
}

export const getters = {
  loggedIn: (state) => {
    return !!state.user
  },
  // username: (state) => {
  //   return state.user.user
  // }
}