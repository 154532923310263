import { mapActions } from "vuex";
import _ from "lodash";
export default {

  computed: {
    ...mapActions("content", ["fetchContent"]),
    slogan1() {
      return this.getContentByName("slogan1");
    },
    slogan2() {
      return this.getContentByName("slogan2");
    },
    startsida_title() {
      return this.getContentByName("startsida_title");
    },
    startsida_text() {
      return this.getContentByName("startsida_text");
    },
    guide_elslutbleck_title() {
      return this.getContentByName("guide_elslutbleck_title");
    },
    guide_elslutbleck_text() {
      return this.getContentByName("guide_elslutbleck_text");
    },
    guide_montagestolpe_title() {
      return this.getContentByName("guide_montagestolpe_title");
    },
    guide_montagestolpe_text() {
      return this.getContentByName("guide_montagestolpe_text");
    },
    guide_eltryckeslas_motorlas_title() {
      return this.getContentByName("guide_eltryckeslas_motorlas_title");
    },
    guide_eltryckeslas_motorlas_text() {
      return this.getContentByName("guide_eltryckeslas_motorlas_text");
    },
    guide_slutbleck_title() {
      return this.getContentByName("guide_slutbleck_title");
    },
    guide_slutbleck_text() {
      return this.getContentByName("guide_slutbleck_text");
    },
    products_elslutbleck_title() {
      return this.getContentByName("products_elslutbleck_title");
    },
    products_elslutbleck_text() {
      return this.getContentByName("products_elslutbleck_text");
    },
    products_lashus_title() {
      return this.getContentByName("products_lashus_title");
    },
    products_lashus_text() {
      return this.getContentByName("products_lashus_text");
    },
    products_montagestolpe_title() {
      return this.getContentByName("products_montagestolpe_title");
    },
    products_montagestolpe_text() {
      return this.getContentByName("products_montagestolpe_text");
    },
    products_eltryckeslas_motorlas_title() {
      return this.getContentByName("products_eltryckeslas_motorlas_title");
    },
    products_eltryckeslas_motorlas_text() {
      return this.getContentByName("products_eltryckeslas_motorlas_text");
    },
    products_slutbleck_title() {
      return this.getContentByName("products_slutbleck_title");
    },
    products_slutbleck_text() {
      return this.getContentByName("products_slutbleck_text");
    },
    products_nodutrymningsbeslag_title() {
      return this.getContentByName("products_nodutrymningsbeslag_title");
    },
    products_nodutrymningsbeslag_text() {
      return this.getContentByName("products_nodutrymningsbeslag_text");
    },
    sammanstallning_title() {
      return this.getContentByName("sammanstallning_title");
    },
    sammanstallning_text() {
      return this.getContentByName("sammanstallning_text");
    },
    produktinfo_title() {
      return this.getContentByName("produktinfo_title");
    },
    produktinfo_text() {
      return this.getContentByName("produktinfo_text");
    },
    utbytesguide_elslutbleck_title() {
      return this.getContentByName("utbytesguide_elslutbleck_title");
    },
    utbytesguide_elslutbleck_text() {
      return this.getContentByName("utbytesguide_elslutbleck_text");
    },
    utbytesguide_montagestolpe_title() {
      return this.getContentByName("utbytesguide_montagestolpe_title");
    },
    utbytesguide_montagestolpe_text() {
      return this.getContentByName("utbytesguide_montagestolpe_text");
    },
    guide_friskrivning() {
      return this.getContentByName("guide_friskrivning");
    },
  },
  methods: {
    getContentByName(mname) {
      let obj = _.filter(this.$store.state.content.data, (row) => row.machineName == mname)[0];

      if (this.$i18n.locale == "no" && obj && obj.translations.no.text) {
        return obj.translations.no.text;
      } else if (obj) {
        return obj.text;
      } else {
        return mname;
      }
    },
  },
  mounted() {
    // console.log('after fetchContent')
    if (typeof this.fetchContent == 'function') {
      this.fetchContent();
    }
  },
}