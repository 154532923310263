<template>
  <b-card no-body id="productsListAside" class="border-primary print-area">
    <template #header>
      {{ translatedName }}
      <div class="fixed-bottom">
        <img
          src="@/assets/assa-logo.svg"
          id="assa-logo-print"
          width="20%"
          alt=""
          class="d-none d-print-block"
        />
      </div>
    </template>
    <b-row no-gutters style="min-height: 490px">
      <b-col cols="12" md="12" class="image-col" v-if="productData.artnr">
        <ProductImages
          :artnr="String(productData.artnr)"
          :key="productData.artnr"
        ></ProductImages>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <!-- <small><pre>{{ productData.filter }}</pre></small> -->
        <!-- <pre>{{ filterSbs }}</pre> -->
        <!-- <pre>{{ filterEbs }}</pre> -->
        <div class="accordion" role="tablist">
          <b-card
            no-body
            class="m-3"
            v-for="(info, i) in translatedInfo"
            v-bind:key="`product-info-${i}`"
            style="border: none"
          >
            <b-card-header
              class="p-0 accordion-header"
              role="tab"
              header-bg-variant="light"
            >
              <b-button
                block
                v-b-toggle
                :href="`#accordion-${i}`"
                variant="light"
                @click.prevent
              >
                <span>{{ $t(info.title) }}</span>
                <b-icon-dash-lg class="when-open"></b-icon-dash-lg>
                <b-icon-plus-lg class="when-closed"></b-icon-plus-lg>
              </b-button>
            </b-card-header>
            <b-collapse
              :id="`accordion-${i}`"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body class="px-2">
                <b-card-text
                  v-html="info.body"
                  style="white-space: pre-wrap"
                ></b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="m-3" style="border: none">
            <b-card-header
              class="p-0 accordion-header"
              role="tab"
              header-bg-variant="light"
            >
              <b-button
                block
                v-b-toggle
                href="#accordion-facts"
                variant="light"
                @click.prevent
              >
                <span>{{ $t("Fakta") }}</span>
                <b-icon-dash-lg class="when-open"></b-icon-dash-lg>
                <b-icon-plus-lg class="when-closed"></b-icon-plus-lg>
              </b-button>
            </b-card-header>
            <b-collapse
              id="accordion-facts"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body class="px-0">
                <b-card-text>
                  <table class="table table-sm facts">
                    <tbody>
                      <tr v-if="scope != 'lh' && scope != 'nb'">
                        <th>{{ $t("form.artnr") }}</th>
                        <td>{{ translatedArtnr }}</td>
                      </tr>
                      <tr v-if="scope == 'nb'">
                        <th>{{ $t("form.artid") }}</th>
                        <td>{{ translatedArtnr }}</td>
                      </tr>
                      <tr v-if="productData.enr && $i18n.locale != 'no'">
                        <th>{{ $t("form.enr") }}</th>
                        <td>{{ productData.enr }}</td>
                      </tr>
                      <tr v-if="productData.nobnr && $i18n.locale == 'no'">
                        <th>{{ $t("form.nobnr") }}</th>
                        <td>{{ productData.nobnr }}</td>
                      </tr>
                      <tr v-if="productData.spec && passarElslutbleck.length">
                        <th>{{ $t("Passar elslutbleck") }}</th>
                        <td>
                          <b-link
                            variant="primary"
                            size="sm"
                            class="d-print-none"
                            @click="toggleCollapsed('passar-elslutbleck')"
                          >
                            <b-icon
                              icon="chevron-right"
                              scale="1"
                              variant="primary"
                              animation=""
                              class="collapse-icon"
                              :class="{
                                collapsed:
                                  expand.indexOf('passar-elslutbleck') != -1,
                              }"
                            ></b-icon>
                            <small class="ml-1 toggle">{{
                              expand.indexOf("passar-elslutbleck") != -1
                                ? $t("dölj")
                                : $t("visa")
                            }}</small>
                          </b-link>
                          <b-collapse
                            id="passar-elslutbleck"
                            class="mt-2 d-print-block"
                            :visible="
                              expand.indexOf('passar-elslutbleck') != -1
                            "
                          >
                            <ul class="list-unstyled">
                              <li
                                v-for="(eb, i) in passarElslutbleck"
                                :key="`peb-${i}`"
                                class="list-fact-item"
                              >
                                {{ eb }}
                              </li>
                            </ul>
                          </b-collapse>
                        </td>
                      </tr>
                      <tr
                        v-for="(fact, i) in productData.facts"
                        v-bind:key="`product-facts-${i}`"
                        :class="{ 'd-none': !fact.value }"
                        class=""
                      >
                        <th width="180">{{ $t(fact.title) }}</th>
                        <td v-if="Array.isArray(fact.value)">
                          <div v-if="fact.value.length < 3">
                            <span v-for="(eb, j) in fact.value" :key="`pe-${j}`"
                              >{{ j > 0 ? ", " : "" }}{{ $t(eb) }}
                              {{ fact.suffix }}</span
                            >
                          </div>
                          <div v-else>
                            <b-link
                              variant="primary"
                              size="sm"
                              class="d-print-none"
                              @click="toggleCollapsed(fact.name)"
                            >
                              <b-icon
                                icon="chevron-right"
                                scale="1"
                                variant="primary"
                                animation=""
                                class="collapse-icon"
                                :class="{
                                  collapsed: expand.indexOf(fact.name) != -1,
                                }"
                              ></b-icon>
                              <small class="ml-1 toggle">{{
                                expand.indexOf(fact.name) != -1
                                  ? $t("dölj")
                                  : $t("visa")
                              }}</small>
                            </b-link>
                            <b-collapse
                              :id="`facts-toggle-${i}`"
                              class="mt-2 d-print-block"
                              :visible="expand.indexOf(fact.name) != -1"
                            >
                              <ul class="list-unstyled">
                                <li
                                  v-for="(eb, j) in fact.value"
                                  :key="`pe-${j}`"
                                  class=""
                                >
                                  {{ $t(eb) }} {{ fact.suffix }}
                                </li>
                              </ul>
                            </b-collapse>
                          </div>
                        </td>
                        <td v-else>
                          {{ $t(fact.value) }} {{ fact.text }} {{ fact.suffix }}
                        </td>
                      </tr>
                      <tr v-if="matchedKonvs.length">
                        <th>{{ $t("text.utbytesprodukt") }}</th>
                        <td>
                          <b-link
                            variant="primary"
                            size="sm"
                            class="d-print-none"
                            @click="toggleCollapsed('konvs')"
                          >
                            <b-icon
                              icon="chevron-right"
                              scale="1"
                              variant="primary"
                              animation=""
                              class="collapse-icon"
                              :class="{
                                collapsed: expand.indexOf('konvs') != -1,
                              }"
                            ></b-icon>
                            <small class="ml-1 toggle">{{
                              expand.indexOf("konvs") != -1
                                ? $t("dölj")
                                : $t("visa")
                            }}</small>
                          </b-link>
                          <b-collapse
                            id="konvs"
                            class="mt-2 d-print-block"
                            :visible="expand.indexOf('konvs') != -1"
                          >
                            <ul class="list-unstyled">
                              <li
                                v-for="(konv, i) in matchedKonvs"
                                :key="`konv-${i}`"
                              >
                                {{ konv }}
                              </li>
                            </ul>
                          </b-collapse>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <b-row no-gutters v-if="productDocs.length" class="d-print-none">
      <b-col>
        <b-card-body>
          <ProductsListAsideDocs
            :product-docs="productDocs"
          ></ProductsListAsideDocs>
        </b-card-body>
      </b-col>
    </b-row>
    <b-row v-if="translatedVariants && translatedVariants.length">
      <b-col>
        <b-card-body>
          <!-- Variants -->
          <table
            class="table table-sm variants"
            v-if="translatedVariants && translatedVariants.length"
          >
            <thead>
              <tr>
                <th>{{ $t("text.varianter") }}</th>
                <th>{{ $t("form.artnr") }}</th>
                <th v-if="$i18n.locale != 'no' && hasAnyEnr">{{ $t("form.enr") }}</th>
                <th v-if="$i18n.locale == 'no' && hasAnyNobnr">{{ $t("form.nobnr") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(fact, i) in translatedVariants" v-bind:key="`product-facts-${i}`">
                <td>{{ fact.title }}</td>
                <td v-html="fact.artnr"></td>
                <td v-if="$i18n.locale != 'no' && hasAnyEnr" v-html="fact.enr"></td>
                <td v-if="$i18n.locale == 'no' && hasAnyNobnr" v-html="fact.nobnr"></td>
              </tr>
            </tbody>
          </table>
        </b-card-body>
      </b-col>
    </b-row>
    <b-row v-if="selectedEm" no-gutters class="d-print-none">
      <b-col
        cols="12"
        md="3"
        class="p-3 border-right border-primary text-center"
        v-if="selectedEm && scope != 'em'"
      >
        <div>
          <strong>{{ $t("Valt eltryckeslås eller motorlås") }}</strong>
        </div>
        <ProductImages
          single
          :artnr="selectedEm.artnr"
          :key="selectedEm.artnr"
        ></ProductImages>
        <b-button
          block
          v-b-modal.selectedEmModal
          variant="link"
          class="stretched-link"
          >{{ selectedEm.name }}</b-button
        >
        <ProductInfoModal
          id="selectedEmModal"
          v-bind="selectedEm"
          :product-docs="[]"
          scope="em"
          modal-name="selectedProduct"
        ></ProductInfoModal>
      </b-col>
      <b-col cols="12" md="" class="p-3" v-if="matchedNbs && matchedNbs.length">
        <ProductsGridNB
          :products="matchedNbs"
          :title="$t('Kräver nödutrymningsbeslag')"
          noRulers
        ></ProductsGridNB>
      </b-col>
    </b-row>
    <b-row
      v-if="selectedEb && scope != 'em'"
      no-gutters
      class="border-primary d-print-none"
      :class="{ 'border-top border-bottom': matchedHousings.length }"
    >
      <b-col
        cols="12"
        md="3"
        class="p-3 border-right border-primary text-center"
        v-if="scope == 'ms' && selectedEb"
      >
        <div>
          <strong>{{ $t("Valt elslutbleck") }}</strong>
        </div>
        <ProductImages
          single
          :artnr="selectedEb.artnr"
          :key="selectedEb.artnr"
        ></ProductImages>
        <b-button
          block
          v-b-modal.selectedEbModal
          variant="link"
          class="stretched-link"
          >{{ selectedEb.name }}</b-button
        >
        <ProductInfoModal
          id="selectedEbModal"
          v-bind="selectedEb"
          :product-docs="[]"
          scope="eb"
          modal-name="selectedProduct"
        ></ProductInfoModal>
      </b-col>
      <b-col
        cols="12"
        md=""
        class="p-3"
        v-if="matchedHousings.length && scope != 'lh' && selectedEm === null"
      >
        <ProductsGrid
          :products="matchedHousings"
          :title="$t('Passar låshus, utefter valda kriterier:')"
        ></ProductsGrid>
      </b-col>
    </b-row>
    <b-row
      no-gutters
      v-if="selectedEb && scope == 'eb'"
      class="d-print-none py-5"
    >
      <b-col>
        <b-card-body>
          <b-button
            v-if="scope == 'eb'"
            block
            variant="primary"
            size="lg"
            class="mx-auto guide-next w-75"
            :to="{ name: 'guide-filter-ms' }"
            >{{ $t("text.guideEB.valj-passande-stolpe") }}
            <b-icon icon="chevron-right"></b-icon
          ></b-button>
        </b-card-body>
      </b-col>
    </b-row>
    <b-row v-if="scope == 'ms' || scope == 'sb'" class="d-print-none my-5">
      <b-col>
        <b-card-body>
          <b-button
            block
            variant="primary"
            size="lg"
            class="mx-auto guide-next w-75"
            :to="{ name: 'print-order' }"
            >{{ $t("Visa sammanställning av dina val") }}
            <b-icon icon="chevron-right"></b-icon
          ></b-button>
        </b-card-body>
      </b-col>
    </b-row>
    <b-row v-if="scope == 'em'" class="d-print-none py-5">
      <b-col>
        <b-card-body>
          <b-button
            v-if="showSbNextButton"
            block
            variant="primary"
            size="lg"
            class="mx-auto guide-next w-50"
            :to="{ name: 'guide-filter-sb-with-em' }"
            v-show="totalSb > 0"
            >{{ $t("text.guideSB.valj-passande-slutbleck") }}
            <b-badge variant="primary">{{ totalSb }}</b-badge>
            <b-icon icon="chevron-right"></b-icon
          ></b-button>
          <p class="text-center my-3" v-if="totalSb > 0 && totalEb > 0">
            {{ $t("eller") }}
          </p>

          <b-button
            v-if="showEbNextButton"
            block
            variant="primary"
            size="lg"
            class="mx-auto guide-next w-50"
            :to="{ name: 'guide-filter-eb-with-em' }"
            v-show="totalEb > 0"
            >{{ $t("text.guideSB.valj-passande-elslutbleck") }}
            <b-badge variant="primary">{{ totalEb }}</b-badge>
            <b-icon icon="chevron-right"></b-icon
          ></b-button>
        </b-card-body>
      </b-col>
    </b-row>
    <!-- <div>
      <b-button
        variant="link"
        class="fixed-bottom-aside-arrow py-3 d-print-none"
        @click="scrollToBottom()"
        v-show="showScrollToBottom"
        ><b-icon-chevron-down
          animation="cylon-vertical"
          scale="2"
          class=""
        ></b-icon-chevron-down
      ></b-button>
    </div> -->
  </b-card>
</template>

<script>
import ProductImages from "@/components/products/ProductImages";
import ProductsListAsideDocs from "@/components/products/ProductsListAsideDocs";
import ProductsGrid from "@/components/products/ProductsGrid";
import ProductsGridNB from "@/components/products/ProductsGridNB";
import ProductInfoModal from "./ProductInfoModal";
import ProductTranslationsMixin from "@/mixins/productTranslationsMixin";
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";

export default {
  name: "ProductsListAside",
  props: ["productData", "productDocs", "scope"],
  mixins: [ProductTranslationsMixin],
  components: {
    ProductImages,
    ProductsListAsideDocs,
    ProductsGrid,
    ProductsGridNB,
    ProductInfoModal,
  },
  data() {
    return {
      API_HOST: process.env.VUE_APP_DBHOST,
      expand: [],
      showScrollToBottom: false,
      nbs: [],
      showSbNextButton: true,
      showEbNextButton: true,
    };
  },
  computed: {
    ...mapGetters("utbytesguiden", ["getMsKonvs"]),
    ...mapGetters("productsNB", ["getProducts"]),
    ...mapGetters("optionsEM", ["allRequiredOptionsSelectedToShowNB", "getSelection"]),
    ...mapGetters(["productsSB/getTotal", "productsEB/getTotal"]),
    hasAnyEnr() {
      return this.translatedVariants.filter((v) => v.enr && v.enr != "").length > 0;
    },
    hasAnyNobnr() {
      return this.translatedVariants.filter((v) => v.nobnr && v.nobnr != "").length > 0;
    },
    totalEb() {
      return this.filterEbs.length;
    },
    totalSb() {
      return this.filterSbs.length;
    },
    selectedEb() {
      if (this.scope) {
        return this.$store.state.productEB.product;
      }
      return null;
    },
    selectedEm() {
      if (this.scope) {
        return this.$store.state.productEM.product;
      }
      return null;
    },
    matchedHousings() {
      const spec = this.selectedEb.spec;
      const intersection = _.intersection(..._.valuesIn(spec));
      return intersection;
    },
    matchedNbs() {
      // If NOT all required options are selected, we should never show any NBS
      if (
        !this.$store.getters["optionsEM/allRequiredOptionsSelectedToShowNB"]
      ) {
        return [];
      }

      // Get NBS from store
      const nbsFromMatchedFilters = this.nbsFromMatchedFilters;
      const matched = this.nbs.filter((nb) => {
        return nbsFromMatchedFilters.indexOf(nb._id) > -1;
      });

      return _.map(matched, (nb) => nb.artnr);
    },
    infoWithValue() {
      return _.map(this.productData.info);
    },
    passarElslutbleck() {
      let justEbs = Object.entries(this.productData.spec).filter((item) => {
        return item[0].indexOf("ELSLUTBLECK") == 0;
      });
      justEbs = Object.fromEntries(justEbs);
      return _.keys(justEbs);
    },
    matchedKonvs() {
      const allKonvs = this.$store.getters["utbytesguiden/getMsKonvs"];
      let matched = [];
      allKonvs.forEach((konv) => {
        const nkonvs = konv.utbyte.filter(
          (utb) => utb.name == this.productData.name
        ).length;
        if (nkonvs > 0) {
          matched.push(konv.artnr);
        }
      });
      return matched;
    },
    matchedFilters() {
      let filters = [];
      if (this.productData.filter) {
        filters = this.productData.filter;
      } else if (this.selectedEm) {
        filters = this.selectedEm.filter;
      }
      let matched = [];
      const selection = Array.from(this.$store.state.optionsEM.selection);

      matched = filters.filter((f) => {
        let sel = Array.from(selection);
        let slugs = Array.from(f.slugs);
        let diff = _.difference(sel, slugs);
        return diff.length == 0;
      });
      return matched;
    },
    nbsFromMatchedFilters() {
      let nbs = this.matchedFilters
        .map((f) => {
          return Array.from(f.nodutrymningsbeslag);
        })
        .flat();

      nbs = _.uniq(nbs);

      return nbs;
    },
    filterSbs() {
      let sbs = []
      if (!this.productData.filter) {
        return sbs
      }
      this.productData.filter.forEach((f) => {
        if (f.slutbleck && f.slutbleck.length > 0) {
          let selection = this.$store.getters["optionsEM/getSelection"]
          let intersect = _.intersection(selection, f.slugs)

          if (intersect.length == selection.length) {
            sbs = [...sbs, ...f.slutbleck]
          }
        }
      })
      sbs = _.uniq(sbs)
      return sbs
    },
    filterEbs() {
      let ebs = []
      if (!this.productData.filter) {
        return ebs
      }
      this.productData.filter.forEach((f) => {
        if (f.elslutbleck && f.elslutbleck.length > 0) {
          let selection = this.$store.getters["optionsEM/getSelection"]
          let intersect = _.intersection(selection, f.slugs)

          if (intersect.length == selection.length) {
            ebs = [...ebs, ...f.elslutbleck]
          }
        }
      })
      ebs = _.uniq(ebs)
      return ebs
    }
  },
  methods: {
    ...mapActions("utbytesguiden", ["fetchMsKonv"]),
    ...mapActions("productsNB", ["fetchProducts"]),
    ...mapActions("productsSB", ["fetchTotal"]),
    ...mapActions("productsEB", ["fetchTotal"]),
    productImage(artnr) {
      const key = `${artnr}/images/${artnr}`;
      return `${process.env.VUE_APP_S3_BASE_URL}${key}`;
    },
    toggleCollapsed(name) {
      if (this.expand.indexOf(name) == -1) {
        this.expand.push(name);
      } else {
        this.expand.splice(this.expand.indexOf(name), 1);
      }
    },
    handleScroll() {
      this.showScrollToBottom = true;
      let d = document.documentElement;
      let offset = d.scrollTop + window.innerHeight;
      let height = d.offsetHeight;

      if (offset + 180 >= height) {
        this.showScrollToBottom = false;
      }
    },
    scrollToBottom() {
      // window.scrollTo(0, document.body.scrollHeight);
      let scrollarea = document.getElementById("productsListAside");
      scrollarea.scroll({
        top: scrollarea.scrollHeight,
        behavior: "smooth",
      });
    },
  },
  updated() {
    this.$store.dispatch("productsSB/fetchTotal", [
      this.productData.name,
      ...this.$store.state.optionsEM.selection,
    ]);
    this.$store.dispatch("productsEB/fetchTotal", [
      this.productData.name,
      ...this.$store.state.optionsEM.selection,
    ]);
    
    if ( this.scope == "em" && !this.isMobileWidth ) {

      // We have a diff in length, so we need to filter products based on the filter
      this.$store.dispatch("productsSB/filterFetchedProductsById", this.filterSbs);
  
      // We have a diff in length, so we need to filter products based on the filter
      // [productsEB/filterFetchedProductsById] will fetch products to make sure we have all products before filtering
      this.$store.dispatch("productsEB/filterFetchedProductsById", this.filterEbs);
    }
  },
  mounted() {
    // store.dispatch("productEB/fetchMsAndMatchProduct", this.productData._id);
    this.$store.dispatch("utbytesguiden/fetchMsKonv");
    // Fetch NBS
    this.$store.dispatch("productsNB/fetchProducts").then((response) => {
      this.nbs = response.data;
    });
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
@import "../../../scss/_colors.scss";

.accordion-header {
  > .btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 800;
    height: 46px;
    padding: 0 14px;
  }
  > .btn.collapsed {
    background-color: $secondary;
  }
  > .btn.not-collapsed {
    background-color: $primary;
    color: $white;
  }
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.fixed-bottom-aside-arrow {
  position: fixed;
  bottom: 0;
  border: none;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.9) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  display: block;
  width: 745px;
}

.card {
  border-width: 2px;
  overflow-y: scroll;
  overflow-x: hidden;
  // max-height: calc(100vh - 264px);
  max-height: calc(100vh - 87px - 20px);
  .row.border-top,
  .row.border-bottom,
  .row > .border-right {
    border-width: 2px !important;
  }
  .card-header:not(.accordion-header) {
    background-color: $primary;
    min-height: 80px;
    display: flex;
    align-items: center;
    color: $white;
    font-size: 30px;
    margin: 0;
    font-weight: 400;
    position: sticky;
    top: 0;
    z-index: 100;
  }
  .collapse-icon {
    transition: all 100ms ease-in-out;
    &.collapsed {
      transform: rotate(90deg);
      transition: all 100ms ease-in-out;
    }
  }
  small.toggle {
    vertical-align: middle;
  }
  li.list-fact-item {
    white-space: nowrap;
  }

  table {
    font-size: 14px;
    width: 100%;
    border-collapse: separate;
    page-break-inside: auto;
    tr {
      page-break-inside: avoid;
      page-break-before: auto;
      th,
      td {
        // border-width: 2px;
        border-color: white;
        padding: 0.2em 0.6em;
        white-space: nowrap;
      }
      th {
        background-color: $primary;
        color: $white;
        overflow: hidden;
        width: auto;
      }
      td {
        background-color: $gray-100;
      }
      th ~ td {
        width: 100%;
      }
      td ~ td {
        width: 50%;
      }
    }
  }
  table.variants {
    td:first-child {
      background-color: $gray-200;
    }
  }
  /* width */
  &::-webkit-scrollbar {
        width: 8px;
    }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $primary; 
  }
  
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $gray-900; 
  }
}
</style>
