<template>
  <div v-if="publishedProducts.length" class="border-primary" :class="{ 'border-top border-bottom py-3': publishedProducts.length && !noRulers }">
    <b-row no-gutters>
      <b-col>
        <slot name="title">
          <!-- <strong>{{ title }}</strong> -->
          <b-alert show variant="warning">{{title}}</b-alert>
        </slot>
      </b-col>
    </b-row>
    <b-row no-gutters class="products-grid text-center">
      <b-col cols="auto" v-for="nb in publishedProducts" v-bind:key="nb">
        <div>
          <ProductImages single :size="size" :artnr="nb" :key="nb" img-width="160px"></ProductImages>
        </div>
        <b-link v-b-modal="`productInfoModalNB${random}`" @click="fetchModalData(nb)" block variant="link" :id="`productModalLink${nb}`" :data-artnr="nb" class="stretched-link" :title="nb">{{ nb }}</b-link>
      </b-col>
    </b-row>
    <ProductInfoModal ref="productModalNB" :id="`productInfoModalNB${random}`" v-bind="modalData" :product-docs="[]" scope="nb" modal-name="nbInfo"></ProductInfoModal>
  </div>
</template>

<script>
  import ProductImages from "./ProductImages";
  import ApiService from "@/services/ApiService";
  import { mapGetters } from "vuex";
  export default {
    name: "ProductsGridNB",
    props: {
      products: {
        type: Array,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      size: {
        type: String,
        required: false,
      },
      noRulers: {
        type: Boolean,
        default: false,
      }
    },
    computed: {
      ...mapGetters("productNB", ["getProduct", "getDocs"]),
      housings() {
        return this.lhs;
      },
    },
    components: {
      ProductImages,
      ProductInfoModal: () => import("./ProductInfoModal"),
    },
    data() {
      return {
        API_HOST: process.env.VUE_APP_DBHOST,
        nbs: [],
        modalData: null,
        publishedProducts: [],
        random: Date.now(),
      };
    },
    methods: {
      getLhData(artnr) {
        return this.lhs.filter((lh) => lh.artnr == artnr);
      },
      filterProductsPublished() {
        let pubNbs = this.nbs.filter((nb) => nb.published);
        this.publishedProducts = this.products.filter((artnr) => {
          return pubNbs.filter((pnb) => pnb.artnr == artnr).length > 0;
        });
      },
      getNbs() {
        ApiService.getAllNB()
          .then(async (response) => {
            this.nbs = await response.data;
            this.filterProductsPublished();
          })
          .catch((err) => console.error(err));
      },
      showProductModal(artnr) {
        this.$refs["productModalNB"].show();
        this.fetchModalData(artnr);
      },
      fetchModalData(artnr) {
        ApiService.getNbDataByArt(artnr).then(async (response) => {
          this.modalData = await response.data;
        });
      },
    },
    mounted() {
      this.getNbs();
    },
  };
</script>

<style scoped lang="scss">
  @import "../../../scss/custom.scss";
  @import "../../../node_modules/bootstrap/scss/mixins/_breakpoints.scss";

  @include media-breakpoint-down(md) {
    .products-grid {
      justify-content: center;
    }
  }
  h4 {
    margin-top: 10px;
  }
</style>
