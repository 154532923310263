<template>
  <div>
    <b-overlay :show="show">
      <form @change="$emit('on-change-slug', filter)" v-if="filter">
        <!-- <b-textarea
          v-model="fillMultiRowHelperValues"
          @change="fillMultiRowHelper()"
        ></b-textarea> -->
        <table class="table-header-rotated table-hover">
          <thead class="bg-light" v-if="options">
            <tr>
              <th v-if="development"></th>
              <th scope="col" class="rotate p-0" v-for="(o, index) in options" :key="index">
                <div>
                  <span>{{ $t(o) }}</span>
                </div>
              </th>
              <th scope="col" class="rotate p-0">
                <div>
                  <span>{{ $t("Nödutrymningsbeslag") }}</span>
                </div>
              </th>
              <th scope="col" class="rotate p-0">
                <div>
                  <span>{{ $t("Slutbleck") }}</span>
                </div>
              </th>
              <th scope="col" class="rotate p-0">
                <div>
                  <span>{{ $t("Elslutbleck") }}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody v-if="filter">
            <tr v-for="(f, row) in filter" :key="`row-${row}`" :class="{ 'bg-warning': row.unsynced }">
              <th scope="row" class="text-right th-sticky-left" width="200" v-if="development">
                {{ row }}
                <b-form-input :ref="`rowHelper-${row}`" v-model="fillRowHelperValues[row]" @change="fillRowHelper(row)"></b-form-input>
              </th>
              <td v-for="(o, col) in options" :key="`col-${col}`" align="center">
                <b-form-checkbox :disabled="access_level > 1" :name="o" :value="o" :unchecked-value="[]" v-model="f.slugs" @change="$emit('on-update-row', f)"></b-form-checkbox>
              </td>
              <td class="py-0">
                <b-form-select
                  :class="{
                    'bg-success text-light': f.nodutrymningsbeslag && f.nodutrymningsbeslag.length > 0,
                  }"
                  size="sm"
                  v-model="f.nodutrymningsbeslag"
                  :options="nbsSelectOptions"
                  @change="$emit('on-update-row', f)"></b-form-select>
              </td>
              <td class="py-0">
                <b-form-select
                  :disabled="f.elslutbleck && f.elslutbleck.length > 0"
                  :class="{
                    'bg-success text-light': f.slutbleck && f.slutbleck.length > 0,
                  }"
                  size="sm"
                  v-model="f.slutbleck"
                  :options="sbsSelectOptions"
                  @change="$emit('on-update-row', f)"></b-form-select>
              </td>
              <td class="py-0">
                <b-form-select
                  :disabled="f.slutbleck && f.slutbleck.length > 0"
                  :class="{
                    'bg-success text-light': f.elslutbleck && f.elslutbleck.length > 0,
                  }"
                  size="sm"
                  v-model="f.elslutbleck"
                  :options="ebsSelectOptions"
                  @change="$emit('on-update-row', f)"></b-form-select>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td :colspan="`${options.length + 3}`">
                <b-button variant="primary" @click="$emit('on-add-row', filter)">Add row</b-button>
              </td>
            </tr>
          </tfoot>
        </table>
      </form>
    </b-overlay>
    <div v-show="debug" style="position:fixed; bottom:0; left: 0; background: #fff; padding: 10px;z-index: 1000; max-height: 100vh; overflow: scroll;text-align: left;">
      <b-button variant="primary" @click="$emit('on-update-selected-products', {UISelectedEb,UISelectedSb})">updateSelectedProducts</b-button>
      <hr>
      <pre>UISelectedEb = {{ UISelectedEb }}</pre>
      <hr>
      <pre>UISelectedSb = {{ UISelectedSb }}</pre>
      <hr>
      <pre>selectedSb = {{ selectedSb }}</pre>
      <hr>
      <pre>selectedEb = {{ selectedEb }}</pre>
      <hr>
      <pre>factsSb = {{ factsSb }}</pre>
      <hr>
      <pre>factsEb = {{ factsEb }}</pre>
      <hr>
    </div>
  </div>
</template>

<script>
  import ApiServiceAdmin from "../../services/ApiServiceAdmin";
  import _ from "lodash";
  export default {
    name: "AdminFilterEditTable",
    props: {
      filter: {
        type: Array,
        required: true,
      },
      options: {
        type: Array,
        required: true,
      },
      selectedSb: {
        type: Array,
        required: true,
      },
      selectedEb: {
        type: Array,
        required: true,
      },
      factsSb: {
        type: Array,
        required: true,
      },
      factsEb: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        debug: false,
        show: false,
        fillMultiRowHelperValues: "",
        fillRowHelperValues: [],
        nbs: [],
        sbs: [],
        ebs: [],
        nbsSelectOptions: [],
        sbsSelectOptions: [],
        ebsSelectOptions: [],
      };
    },
    computed: {
      UISelectedEb() {
        const hasEb = _.filter(this.filter, (o) => {
          return o.elslutbleck && o.elslutbleck.length > 0;
        });
        const flatten = _.flatten(_.map(hasEb, "elslutbleck"));
        const uniq = _.uniq(flatten);
        return uniq;
      },
      UISelectedSb() {
        const hasSb = _.filter(this.filter, (o) => {
          return o.slutbleck && o.slutbleck.length > 0;
        });
        const flatten = _.flatten(_.map(hasSb, "slutbleck"));
        const uniq = _.uniq(flatten);
        return uniq;
      },
      access_level() {
        return this.$store.state.user.access_level;
      },
      development() {
        // return process.env.VUE_APP_ENV == 'development'
        return false;
      },
    },
    methods: {
      fillRowHelper(index) {
        if (this.filter[index] === undefined) {
          this.filter.push({ slugs: [], nodutrymningsbeslag: [] });
        }

        const x = this.fillRowHelperValues[index].split("\t");
        x.forEach((o, i) => {
          let slug = this.options[i];
          if (o == "X") {
            this.filter[index].slugs.push(slug);
            // } else if (this.filter[index].slugs && this.filter[index].slugs.indexOf(slug) > -1) {
            // this.filter[index].slugs.splice(this.filter[index].slugs.indexOf(slug), 1)
          }
        });
        this.$emit("on-change-slug", this.filter);
      },
      fillMultiRowHelper() {
        const rowsValues = this.fillMultiRowHelperValues.split("\n");

        rowsValues.forEach((row, index) => {
          if (this.filter[index] === undefined) {
            this.$emit("on-add-row", this.filter);
          }
          this.fillRowHelperValues.push(row);
          this.fillRowHelper(index);
        });
      },
    },
    afterUpdate() {
      this.show = false;
    },
    mounted() {
      ApiServiceAdmin.getNb().then((response) => {
        this.nbs = response.data;
        this.nbsSelectOptions = [
          { value: [], text: this.$t("Välj ett nödutrymningsbeslag") },
          ...this.nbs.map((o) => {
            return { value: [o._id], text: o.name };
          }),
        ];
      });
      ApiServiceAdmin.getSb().then((response) => {
        this.sbs = response.data;
        this.sbsSelectOptions = [
          { value: [], text: this.$t("Välj ett slutbleck") },
          ...this.sbs.map((o) => {
            return { value: [o._id], text: o.name };
          }),
        ];
        this.filter.forEach((f) => {
          if (f.slutbleck === undefined) {
            f.slutbleck = [];
          }
          if (f.elslutbleck === undefined) {
            f.elslutbleck = [];
          }
          // We may need a way to "sync" the slugs with the related products
          this.$emit("on-update-row", f);

        });
        // Update facts for EM
        this.$emit("on-update-selected-products", { UISelectedEb: this.UISelectedEb, UISelectedSb: this.UISelectedSb });
      });
      ApiServiceAdmin.getEb().then((response) => {
        this.ebs = response.data;
        this.ebsSelectOptions = [
          { value: [], text: this.$t("Välj ett elslutbleck") },
          ...this.ebs.map((o) => {
            return { value: [o._id], text: o.name };
          }),
        ];
        this.filter.forEach((f) => {
          if (f.slutbleck === undefined) {
            f.slutbleck = [];
          }
          if (f.elslutbleck === undefined) {
            f.elslutbleck = [];
          }
          // We may need a way to "sync" the slugs with the related products
          this.$emit("on-update-row", f);
        });
      });
    },
  };
</script>

<style lang="scss" scoped>

  thead {
    position: sticky;
    top: 0px;
    z-index: 999;
  }
  thead > tr > th {
    font-size: 14px;
    padding: 0.25em 1em;
  }

  tbody > tr > td > select {
    width: auto !important;
  }
  .th-sticky-left {
    position: sticky;
    left: 0;
    box-shadow: inset -1px 0 1px rgb(0 0 0 / 10%);
    background-color: #f8f9fa;
    font-size: 14px;
    min-width: 300px;
    z-index: 1000;
  }
  .td-sticky-right {
    position: sticky;
    right: 0;
    box-shadow: inset -1px 0 1px rgb(0 0 0 / 10%);
    background-color: #f8f9fa;
    font-size: 14px;
    min-width: 300px;
    z-index: 1000;
  }
  tbody .th-sticky-left {
    z-index: 999;
  }
  .table-header-rotated {
    border-collapse: collapse;
  }
  .table-header-rotated td {
    width: 30px;
  }
  .table-header-rotated th {
    padding: 5px;
  }
  .table-header-rotated td {
    text-align: center;
    padding: 10px 5px;
    border: 1px solid #ccc;
  }
  .table-header-rotated th.rotate {
    height: 200px;
    white-space: nowrap;
  }
  .table-header-rotated th.rotate > div {
    -webkit-transform: translate(0.35vw, 78px) rotate(315deg);
    -ms-transform: translate(0.35vw, 78px) rotate(315deg);
    transform: translate(0.35vw, 78px) rotate(315deg);
    width: 30px;
  }
  .table-header-rotated th.rotate > div > span {
    // border-bottom: 1px solid #ccc;
    padding: 0;
  }
  .table-header-rotated th.row-header {
    padding: 0 10px;
    border-bottom: 1px solid #ccc;
  }
</style>
