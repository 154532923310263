export default {
  computed: {
    isMobileDevice() {
      return this.$isMobile()
    },
    isMobileWidth() {
      return this.$screen.width < this.$screen.config.md;
    },
    ltLg() {
      return this.$screen.width < this.$screen.config.lg;
    }
  }
}