<template>
  <div>
    <b-overlay
      :show="loading"
      spinner-small
      bg-color="#ffffff"
      v-if="images[0]"
      style="min-height: 154px; min-width: 40px"
    >
      <transition name="fade">
        <div
          v-if="single && images.length > 0 && !size"
          class="product-list-image-container"
        >
          <b-img class="product-list-image" :src="images[0].src"></b-img>
        </div>
        <div
          v-else-if="images.length > 0"
          class="product-images-container"
          :class="{ 'has-size': imgWidth }"
        >
          <b-carousel
            indicators
            id="product-images"
            class="pt-3 pb-5"
            :interval="8000"
          >
            <b-carousel-slide v-for="(img, index) in images" :key="index">
              <template #img>
                <b-img
                  class="d-block mx-auto product-aside-image"
                  :src="img.src"
                  @click="$refs['lb'].showImage(index)"
                />
              </template>
              <b-icon-zoom-in
                class="zoom-image"
                @click="$refs['lb'].showImage(index)"
              ></b-icon-zoom-in>
              <b-button v-if="$store.state.user.user && access_level < 2" variant="link" class="text-danger position-absolute p-3 d-print-none" style="right: 0" @click="deleteImage(decodeURIComponent(img.src.split('/').pop().substr(0,img.src.split('/').pop().indexOf('?'))))"><b-icon-trash-fill /></b-button>
            </b-carousel-slide>
          </b-carousel>
          <!-- <b-img v-else src="https://fakeimg.pl/200x300/ffffff/f4f4f4/?retina=1&text=produktbild"></b-img> -->
        </div>
      </transition>
    </b-overlay>
    <LightBox
      :media="images"
      ref="lb"
      :showLightBox="false"
      :showThumbs="false"
    ></LightBox>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import ApiServiceAdmin from "@/services/ApiServiceAdmin";
import _ from "lodash";
import LightBox from "vue-it-bigger";
import("vue-it-bigger/dist/vue-it-bigger.min.css"); // when using webpack
import AdminMessagesMixin from "@/mixins/AdminMessagesMixin";
export default {
  name: "ProductImages",
  mixins: [AdminMessagesMixin],
  props: {
    artnr: { type: [String, Number], required: true },
    single: { type: Boolean, default: false },
    size: { type: String },
    imgWidth: { type: String, default: "" },
  },
  components: {
    LightBox,
  },
  data() {
    return {
      loading: true,
      images: [],
    };
  },
  computed: {
    access_level() {
      // return 2;
      return this.$store.state.user.access_level;
    },
    VUE_APP_S3_BASE_URL() {
      return process.env.VUE_APP_S3_BASE_URL;
    },
  },
  methods: {
    fixUrlEncodingIssue(url) {
      // return url.replace(/%20/g, "%2520");
      return encodeURI(url)
    },
    deleteImage(name) {
      this.loading = true;
      ApiServiceAdmin.deleteImageByArt(
        this.artnr,
        name,
        window.localStorage.langcode
      ).then(async (response) => {
        await this.$emit("on-deleted-image");
        this.handleSuccess(response);
      });
    },
    fetchImages(artnr) {
      this.images = [];
      ApiService.getImagesForArt(artnr)
        .then(async (response) => {
          const images = await response.data;
          this.images = _.map(images, (img) => ({
            type: "image",
            src:
            encodeURI(img.url) +
              "?" +
              new Date().getUTCMilliseconds(),
            thumb: img.url,
            width: 1071,
            height: 1660,
          }));
        })
        .finally(() => (this.loading = false))
        .catch((error) => console.log(error));
    },
  },
  mounted() {
    this.fetchImages(this.artnr);
  },
};
</script>

<style lang="scss" scoped>
@import "../../../scss/custom.scss";

.product-list-image {
  height: auto;
  width: auto;
  max-width: 134px;
  max-height: 134px;
  margin-right: 10px;
}

.product-aside-image {
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
  max-height: 700px;
  width: auto;
  max-width: 100%;
}

.product-list-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  height: 158px;
}

.product-images-container {
  width: 100%;
  &.has-size {
    @include media-breakpoint-up(lg) {
      width: 160px;
    }
  }
}
ol.carousel-indicators > li {
  background-color: red !important;
}
.carousel-control-prev,
.carousel-control-next {
  color: red;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

.zoom-image {
  position: absolute;
  right: 0;
  bottom: 0;
  color: $dark;
  cursor: pointer;
  font-size: 30px;
}
</style>
