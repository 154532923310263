<template>
  <div>
    <GuideInfoSection
      :hide-filter="hideFilterComponents"
      :title="guideTitle"
      :text="guide_elslutbleck_text"
      :text2="guide_friskrivning"
      :step1="$t('text.guideEB.step1')"
      @on-reset-slugs="resetSlugs"
      class="d-print-none"
      :class="{ 'd-none': hideoptions }"
    >
      <FilterSelection
        :selection="this['optionsEM/getSelection']"
        :options="this['optionsEM/getOptions']"
        :text="$t('text.guideEM.criterion')"
        :show="this['optionsEM/getSelection'].length > 0"
      ></FilterSelection>
      <template #bottom>
        <OptionsCategory
          :categories="getCategories"
          :disabled="getDisabled"
          :selection="getSelection"
          :title="$t('rattvand-omvand')"
          @toggle-slug="toggleSlug"
        />
      </template>
    </GuideInfoSection>
    <b-overlay
      no-fade
      spinner-variant="primary"
      :show="false"
      opacity="0.95"
      bg-color="white"
      v-show="!hideFilterComponents"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon
            icon="stopwatch"
            font-scale="3"
            animation="cylon"
            variant="primary"
          ></b-icon>
          <p class="mt-2">
            <strong>{{ $t("text.guideEB.loading") }}</strong>
          </p>
        </div>
      </template>
      <OptionsList
        :options="skip([getEms, ...getOptions])"
        :disabled="getDisabled"
        :selection="getSelection"
        scope="eb"
        @toggle-slug="toggleSlug"
        @reset-slugs="resetSlugs"
        product-type="elslutbleck"
        class="d-print-none"
      />
    </b-overlay>
    <b-container>
      <transition name="fade">
        <ProductsList
          :total="getTotal"
          :selected-product-data="getProduct"
          :selected-product-docs="getDocs"
          :products="getProducts"
          @on-product-select="showProduct(...arguments, 'eb')"
          @on-click-load-more="loadMore"
          scope="eb"
        >
          <template #ingress>
            <h3 class="d-print-none">{{ $t("text.guideEB.step2") }}</h3>
            <p class="step2 mb-4 d-print-none">
              {{ $t("text.guideEB.step2-text") }}
            </p>
          </template>
        </ProductsList>
      </transition>
    </b-container>
  </div>
</template>

<script>
import store from "@/store/store";
import GuideInfoSection from "@/components/guide/options/GuideInfoSection";
import FilterSelection from "@/components/guide/options/FilterSelection";
import OptionsCategory from "@/components/guide/options/OptionsCategory.vue";
import OptionsList from "@/components/guide/options/OptionsList.vue";
import { mapGetters, mapActions } from "vuex";
import productsListMixin from "@/mixins/productsListMixin";
import contentMixin from "@/mixins/contentMixin";
export default {
  name: "ProductsListViewEB",
  mixins: [productsListMixin, contentMixin],
  components: {
    GuideInfoSection,
    FilterSelection,
    OptionsCategory,
    OptionsList,
  },
  data() {
    return {
      hideoptions: false,
    };
  },
  computed: {
    ...mapGetters("optionsEB", [
      "getOptions",
      "getSelection",
      "getDisabled",
      "getCategories",
      "getEms",
    ]),
    ...mapGetters("productsEB", ["getProducts", "getCount", "getTotal"]),
    ...mapGetters("productEB", ["getProduct", "getDocs"]),
    ...mapGetters(["optionsEM/getSelection", "optionsEM/getOptions"]),
    loading: {
      get() {
        return this.$store.state.optionsEB.loading;
      },
      set(loading) {
        this.$store.state.optionsEB.loading = loading;
      },
    },
    hideFilterComponents() {
      // return this.$route.name == "guide-filter-eb-with-em";
      return (this.$route.meta && this.$route.meta.hideFilter === true);
    },
    emInfo() {
      let em = this.$store.state.productEM.product;

      if (this.$i18n.locale == "no" && em && em.translations) {
        em = em.translations.no;
      }
      return em ? ` - passande ${em.name}` : "";
    },
    guideTitle() {
      return this.guide_elslutbleck_title + this.emInfo;
    },
  },
  methods: {
    ...mapActions("optionsEB", [
      "fetchOptions",
      "fetchDisabled",
      "updDisabled",
      "resetSlugs",
      "addSlug",
      "rmSlug",
    ]),
    ...mapActions("productsEB", ["fetchProducts"]),
    ...mapActions("productEB", ["fetchProduct", "fetchDocsForArt"]),
    ...mapActions(["setSelectedEb", "clearAllData"]),
    toggleSlug(payload) {
      this.loading = true;
      const { slug, selected } = payload[0];
      console.log("payload", payload);
      if (selected) {
        this.addSlug(slug);
      } else {
        this.rmSlug(slug);
      }
    },
    skip(o) {
      return o.filter((o) => {
        return (
          o.name != "produktkategori" &&
          o.name != "rattvand-omvand" &&
          o.name != "passande-eltryckeslas-motorlas"
        );
      });
    },
    loadMore() {
      this.$store.dispatch("productsEB/updateSkip");
    },
  },
  // beforeRouteEnter(to, from, next) {
  //   // Reset selected product
  //   // store.dispatch("productEB/resetProduct");
  //   //

  //   // if (to.name == "guide-filter-eb-start") {
  //   //   store.dispatch("resetSelectedEm");
  //   //   store.dispatch("optionsEB/resetSlugs");
  //   //   store.dispatch("productsEB/resetSkip");
  //   // }

  //   if (to.name == "guide-filter-eb-with-em") {
  //     console.log("should hide options");
  //   }

  //   if (to.name != "guide-filter-eb-edit") {
  //     store.dispatch("optionsEB/resetSlugs");
  //     store.dispatch("productsEB/resetSkip");
  //     store
  //       .dispatch("productsEB/fetchProducts")
  //       .then(() => next())
  //       .catch((error) => {
  //         if (error.response && error.response.status == 404) {
  //           next({ name: "404", params: { resource: "event" } });
  //         } else {
  //           next({ name: "network-issue" });
  //         }
  //       });
  //   } else next();
  // },
  // beforeRouteUpdate(to, from, next) {
  //   store.dispatch("productsEB/fetchProducts").then(() => {
  //     next();
  //   });
  // },
  beforeRouteEnter(to, from, next) {
    store.dispatch("optionsEB/fetchOptions");
    // If page is reloaded, fetch products
    // guide-filter-eb-start = via menu
    // guide-filter-eb-with-em = via EM
    // guide-filter-eb-edit = via edit button
    // guide-filter-eb = direct access
    if ( to.name == "guide-filter-eb-start" || to.name == "guide-filter-eb" ) {
      store.dispatch("productsEB/fetchProducts");
    }

    // let numProducts = store.getters["productsEB/getProducts"].length;
    // if (numProducts == 0 && store.state.selectedEm ) {
    //   store.dispatch("productsEB/fetchProducts", [
    //     store.state.selectedEm.name,
    //     ...store.state.optionsEM.selection,
    //   ]);
    // } else { 
    //   // Fetch all
    //   store.dispatch("productsEB/fetchProducts")
    // }
    next();
  },
};
</script>

<style lang="scss" scoped></style>
