import ApiClient from '@/services/ApiService'
const _ = require('lodash');

export const namespaced = true;

export const state = {
  options: [],
  selection: [],
  disabled: [],
  categories: [],
  ems: [],
  loading: false
}

export const actions = {
  fetchOptions({ commit, state, dispatch }) { // Fetch elslutbleck options
    state.loading = true
    ApiClient.getEm()
      .then((response) => {
        let namePath = 'name'
        var translationPath = 'name'
        if (window.localStorage.langcode == 'no') {
          translationPath = 'translations.no.name'
        }
        commit("UPDATE_EMS", { name: "passande-eltryckeslas-motorlas", slugs: _.map(response.data, namePath), translations: _.map(response.data, translationPath) })
      })
    return ApiClient.getEBOptions()
      .then(async (response) => {
        const options = await response.data;
        commit('UPD_OPTIONS', options)
        dispatch('updCategories')
        // We should disable slugs not having any result.
        dispatch('updDisabled')
        state.loading = false
        return options
      })
  },
  async check({ state }, slug) {
    return await ApiClient.checkEB([...state.selection, slug])
      .then((response) => {
        return response.data.check
      }).catch(err => {
        return new Promise(err)
      })
  },
  updCategories({ state, commit }) {
    const indexOfCat = _.findIndex(state.options, (c) => c.name == "rattvand-omvand");
    commit('UPD_CATEGORIES', state.options[indexOfCat].slugs)

  },
  async updDisabled({ dispatch, state }) {
    try {
      state.options.forEach(o => {
        o.slugs.forEach(s => {
          dispatch('check', s).then(async check => {
            if (!check) {
              await dispatch('addDisabled', s)
            } else {
              await dispatch('rmDisabled', s)
            }
          });
        });
      });
    } catch (error) {
      console.error(error)
    }
  },
  addDisabled({ commit }, slug) {
    commit('ADD_DISABLED', slug)
  },
  rmDisabled({ commit }, slug) {
    commit('RM_DISABLED', slug)
  },
  resetSlugs({ commit, dispatch }) {
    dispatch('productEB/resetProduct', null, { root: true })
    dispatch('productMS/resetProduct', null, { root: true })
    dispatch("setSelectedEb", null, { root: true });
    commit('optionsMS/RESET_SLUGS', null, { root: true })
    commit('RESET_SLUGS')
    commit('UPD_RESULTS', [])
    commit('UPD_TOTAL', 0)
    commit('RESET_DISABLED')
  },
  addSlug({ commit, state, dispatch }, slug) {
    state.loading = true
    dispatch("productsEB/resetSkip", null, { root: true });
    dispatch('productEB/resetProduct', null, { root: true })
    commit('ADD_SLUG', slug)
    dispatch("updDisabled");
    dispatch("productsEB/fetchProducts", [...state.selection], { root: true })
      .then(response => {
        commit('UPD_TOTAL', response.data.length)
      })
      .finally(() => {
        state.loading = false
      })
  },
  rmSlug({ commit, state, dispatch }, slug) {
    state.loading = true
    dispatch("productsEB/resetSkip", null, { root: true });
    dispatch('productEB/resetProduct', null, { root: true })
    commit('RM_SLUG', slug)
    dispatch("updDisabled");
    dispatch("productsEB/fetchProducts", [...state.selection], { root: true })
      .then(response => {
        commit('UPD_TOTAL', response.data.length)
      })
      .finally(() => {
        state.loading = false
      })
  },
  // New
  resetSelection({ commit }) {
    commit('RESET_SLUGS')
  },
  resetDisabled({ commit }) {
    commit('RESET_DISABLED')
  }
}
export const mutations = {
  ADD_SLUG(state, slug) {
    state.selection.push(slug)
  },
  RM_SLUG(state, slug) {
    state.selection = state.selection.filter(s => s != slug)
  },
  RESET_SLUGS(state) {
    state.selection = []
  },
  RESET_DISABLED(state) {
    state.disabled = []
  },
  UPD_TOTAL(state, total) {
    state.totalCount = total
  },
  UPD_CATEGORIES(state, categories) {
    state.categories = categories
  },
  UPD_OPTIONS(state, options) {
    state.options = options
  },
  UPD_RESULTS(state, results) {
    state.results = results
  },
  SET_ALL_DISABLED(state) {
    const mapped = state.options.map(o => o.slugs)
    const flattened = _.flatten(mapped)
    state.disabled = flattened
  },
  ADD_DISABLED(state, slug) {
    state.disabled = [...state.disabled, slug]
  },
  RM_DISABLED(state, slug) {
    state.disabled = state.disabled.filter(s => s != slug)
  },
  UPDATE_EMS(state, ems) {
    state.ems = ems
  }
}

export const getters = {
  getSelection: state => state.selection,
  getOptions: state => state.options,
  getEms: state => state.ems,
  getDisabled: state => state.disabled,
  getCategories: state => state.categories,
  inSelection: (state, getters) => getters.getSelection.filter(s => s === state.slug).length > 0,
  isDisabled: (state, getters) => (slug) => {
    console.log('slug', slug)
    return getters.getDisabled.includes(slug)
  },
}
