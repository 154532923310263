<template>
  <div class="py-5">
    <b-form novalidate id="admin-utbytesguiden" @submit="onSubmit" @reset="onReset">
      <b-overlay :show="loading" rounded opacity="0.8" spinner-small spinner-variant="primary" @hidden="onOverlayHidden">
        <b-container>
          <b-button variant="link" size="lg" :to="{ name: 'adm-utbytesguiden' }" class="mr-4 mb-5">{{ $t("text.admin.ug-title") }}</b-button>
          <b-button variant="outline-primary" size="lg" :to="{ name: 'adm-utbytesguiden-eb' }" class="mb-5">{{ $t("text.admin.ugeb-title") }}</b-button>
          <b-row>
            <b-col class="d-flex align-items-center justify-content-between mb-3">
              <h5 class="font-weight-bold">{{ $t("text.konkurentprodukt") }}</h5>
              <!-- <b-button variant="outline-primary">Spara</b-button> -->
            </b-col>

            <b-col
              ><h5 class="mb-3 font-weight-bold">{{ $t("text.elslutbleck-fran-assa") }}</h5></b-col
            >
          </b-row>
          <b-row>
            <b-col id="konkurentprodukter">
              <div class="d-flex justify-content-between w-100">
                <!-- Add new -->
                <div class="card product h-auto w-100 mr-4 border-primary">
                  <b-row no-gutters class="h-100">
                    <b-col cols="">
                      <div class="card-body p-0 d-flex h-100">
                        <b-form-input block size="md" class="h-100 border-0 p-0 px-2" v-model="form.artnr" placeholder="Skriv in produktens artnr"></b-form-input>
                        <b-button size="md" variant="primary" class="w-auto p-0" @click="addKonv">{{ $t("text.admin.lagg-till") }}</b-button>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div v-for="(konv, index) in konverteringar" :key="index" class="d-flex justify-content-between">
                <div class="card product h-auto w-100 mr-4 border-top-0 border-primary" :class="{ active: activeKonv.artnr == konv.artnr }">
                  <b-link class="text-decoration-none text-dark">
                    <div class="row no-gutters">
                      <div class="col">
                        <div class="card-body px-2 py-1">
                          <div class="d-flex justify-content-between align-items-center" style="overflow: hidden">
                            <b-form-input block size="md" @focus="selectKonv(konv)" @change="updateKonv(konv, $event)" class="h-100 p-0 border-0" :value="konv.artnr"></b-form-input>
                            <b-link size="sm" variant="link" @click="revertKonv(konv)" class="p-0 mr-2 text-nowrap" v-if="konv._oldArtnr">
                              <small class="mr-1">{{ konv._oldArtnr }}</small>
                              <b-icon-arrow-counterclockwise class="align-middle" font-scale="1"></b-icon-arrow-counterclockwise>
                            </b-link>
                            <b-link size="sm" variant="link" @click="deleteKonv(konv)" class="text-danger p-0">
                              <b-icon-trash-fill font-scale="1"></b-icon-trash-fill>
                            </b-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-link>
                </div>
                <!-- <b-iconstack font-scale="3.6" variant="white" :class="{ invisible: activeKonv.artnr != konv.artnr }">
                  <b-icon stacked icon="square-fill" variant="primary"></b-icon>
                  <b-icon stacked icon="arrow-left-right" scale="0.5"></b-icon>
                </b-iconstack> -->
              </div>
            </b-col>
            <b-col id="elslutbleck" class="border-top border-primary p-0">
              <div class="card product h-auto border-2 border-top-0 border-primary" v-for="(eb, index) in elslutbleck" :key="index">
                <div class="text-decoration-none text-dark">
                  <div class="row no-gutters">
                    <div class="col">
                      <div class="card-body py-1 px-2">
                        <div class="align-items-center d-flex" style="overflow: hidden">
                          <b-form-checkbox :value="getUbById(eb._id)" :checked="getUbById(eb._id)" @change="toggleEb(eb._id, getUbIndex(eb._id))" class="fw-600">{{ eb.name }}</b-form-checkbox>
                          <small class="ml-auto mr-0 text-muted">{{ eb.artnr }}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-overlay>
    </b-form>
  </div>
</template>

<script>
  import ApiServiceAdmin from "@/services/ApiServiceAdmin";
  import AdminEditMixin from "@/mixins/AdminEditMixin";
  import AdminMessagesMixin from "@/mixins/AdminMessagesMixin";
  import _ from "lodash";
  export default {
    name: "AdminUtbytesguidenEb.vue",
    mixins: [AdminEditMixin, AdminMessagesMixin],
    data() {
      return {
        elslutbleck: [],
        konverteringar: [],
        selectedKonv: {
          artnr: null,
          utbyte: [],
        },
        form: {
          artnr: null,
          utbyte: [],
        },
      };
    },

    computed: {
      activeKonv() {
        return this.selectedKonv;
      },
    },
    methods: {
      getUbIndex(_id) {
        let ubIndex = this.activeKonv.utbyte.findIndex((ub) => ub._id === _id);

        return ubIndex;
      },
      getUbById(_id) {
        return _.valuesIn(this.activeKonv.utbyte.filter((ub) => ub._id == _id))[0];
      },
      sortKonvs(konverteringar) {
        return _.sortBy(konverteringar, ["artnr"]);
      },
      sortEb(elslutbleck) {
        return _.sortBy(elslutbleck, (eb) => this.getUbById(eb._id));
      },
      fetchEb() {
        ApiServiceAdmin.getEb()
          .then(async (response) => {
            this.elslutbleck = await response.data;
          })
          .catch(this.handleError);
      },
      fetchKonv() {
        return ApiServiceAdmin.getKonvEb()
          .then(async (response) => {
            this.konverteringar = await response.data;
          })
          .finally(() => {
            this.selectKonv(this.konverteringar[0]);
            this.elslutbleck = this.sortEb(this.elslutbleck);
            this.konverteringar = this.sortKonvs(this.konverteringar);
          })
          .catch(this.handleError);
      },
      toggleEb(id, ubIndex) {
        const checked = event.target.checked;
        if (ubIndex == -1 && checked) {
          // Push new UB
          this.activeKonv.utbyte.push({ _id: id });
        } else {
          // Exists already
          this.activeKonv.utbyte.splice(ubIndex, 1);
        }
        this.updateKonv(this.selectedKonv);
      },
      selectKonv(konv) {
        this.selectedKonv = konv;
        // this.fetchEb();
      },
      addKonv() {
        ApiServiceAdmin.addKonvEb(JSON.stringify(this.form))
          .then(async (response) => {
            const newKonv = await response.data.konvertering;
            this.fetchKonv().then(async () => {
              await this.selectKonv(newKonv);
              this.form.artnr = "";
              this.handleSuccess(response);
            });
          })
          .catch(this.handleError);
      },
      updateKonv(konv, newArtnr = konv.artnr) {
        if (konv.artnr != newArtnr) {
          konv._oldArtnr = konv.artnr;
        }
        konv.artnr = newArtnr;
        ApiServiceAdmin.updateKonvEbById(konv._id, _.pick(konv, ["artnr", "utbyte"]))
          .then((response) => {
            this.handleSuccess(response);
          })
          .catch(this.handleError);
      },
      revertKonv(konv) {
        konv.artnr = konv._oldArtnr;
        delete konv._oldArtnr;
        ApiServiceAdmin.updateKonvEbById(konv._id, _.pick(konv, ["artnr", "utbyte"]))
          .then((response) => {
            this.handleSuccess(response);
          })
          .catch(this.handleError);
      },
      deleteKonv(konv) {
        if (confirm(`Vill du verkligen ta bort "${konv.artnr}" och dess kopplingar till elslutbleck?`)) {
          ApiServiceAdmin.deleteKonvEbById(konv._id)
            .then(async (response) => {
              this.fetchKonv().then(() => {
                this.handleSuccess(response);
              });
            })
            .catch(this.handleError);
        }
      },
      onSubmit() {
        // ApiServiceAdmin.updateKonvById(this.form.konv).catch(this.handleError);
      },
    },
    mounted() {
      this.fetchEb();
      this.fetchKonv();
    },
  };
</script>

<style lang="scss" scoped>
  .product.card {
    min-height: 34px;
    &.active {
      border-width: 1px;
      border-right-width: 10px;
      &:after {
        width: 120px;
        right: -130px;
      }
    }
  }
</style>
