import axios from 'axios'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_DBHOST,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 10000
})

export default {
  getAssetsByArt(artnr, type) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get(`/api/files/${artnr}/${type}/${langcode}`, {
      headers: { 
        'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token, 
        'Accept-Language': langcode,
        'admin': true
      }
    })
  },
  getEb(params = []) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get('/api/elslutbleck?' + params.join('&'), {
      headers: { 
        'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token, 
        'Accept-Language': langcode,
        'admin': true
      }
    })
  },
  getEbTotal(params = []) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get('/api/elslutbleck/total?' + params.join('&'), {
      headers: { 
        'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token, 
        'Accept-Language': langcode,
        'admin': true
      }
    })
  },
  getEbOptions() {
    return apiClient.get('/api/options/eb')
  },
  exportToCsv(id, scope) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get(`/api/download/${scope}/${id}`, {
      headers: { 
        'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token, 
        'Accept-Language': langcode,
        'admin': true
      },
      responseType: 'arraybuffer'
    })
    .then(response => {
      let blob = new Blob([response.data], { type: 'application/zip' }),
        url = window.URL.createObjectURL(blob)

      window.location.assign(url) // Mostly the same, I was just experimenting with different approaches, tried link.click, iframe and other solutions
      return response
    })
  },
  exportListToCsv(scope) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get(`/api/download/${scope}/`, {
      headers: { 
        'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token, 
        'Accept-Language': langcode,
        'admin': true
      },
      responseType: 'arraybuffer'
    })
    .then(response => {
      let blob = new Blob([response.data], { type: 'text/csv' }),
        url = window.URL.createObjectURL(blob)

      window.location.assign(url) // Mostly the same, I was just experimenting with different approaches, tried link.click, iframe and other solutions
      return response
    })
  },
  getSbOptions() {
    return apiClient.get('/api/options/sb')
  },
  getEmOptions() {
    return apiClient.get('/api/options/em')
  },
  addEb(payload) {
    return apiClient.post('/api/elslutbleck', payload, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
    })
  },
  getEm(params = []) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get('/api/eltryckeslas-motorlas?' + params.join('&'), {
      headers: { 
        'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token, 
        'Accept-Language': langcode,
        'admin': true
      }
    })
  },
  getEmTotal(params = []) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get('/api/eltryckeslas-motorlas/total?' + params.join('&'), {
      headers: { 
        'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token, 
        'Accept-Language': langcode,
        'admin': true
      }
    })
  },
  addEm(payload) {
    return apiClient.post('/api/eltryckeslas-motorlas', payload, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token, headers: { loglevel: 1 } }
    })
  },

  getSb(params = []) {
    return apiClient.get('/api/slutbleck?' + params.join('&'), {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token, 'admin': true }
    })
  },
  getSbTotal(params = []) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get('/api/slutbleck/total?' + params.join('&'), {
      headers: { 'Accept-Language': langcode }
    })
  },
  getNb(params = []) {
    return apiClient.get('/api/nodutrymningsbeslag?' + params.join('&'), {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token, 'admin': true }
    })
  },
  addLh(payload) {
    return apiClient.post('/api/housing', payload, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
    })
  },
  addMs(payload) {
    return apiClient.post('/api/montagestolpar', payload, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
    })
  },
  addSb(payload) {
    return apiClient.post('/api/slutbleck', payload, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
    })
  },
  addNb(payload) {
    return apiClient.post('/api/nodutrymningsbeslag', payload, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
    })
  },
  getMsOptions() {
    return apiClient.get('/api/options/ms')
  },
  updateEbOptionsById(_id, payload) {
    return apiClient.put(`/api/options/eb/${_id}`, payload, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
    })
  },
  updateMsOptionsById(_id, payload) {
    return apiClient.put(`/api/options/ms/${_id}`, payload, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
    })
  },
  updateEmOptionsById(_id, payload) {
    return apiClient.put(`/api/options/em/${_id}`, payload, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
    })
  },
  updateSbOptionsById(_id, payload) {
    return apiClient.put(`/api/options/sb/${_id}`, payload, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
    })
  },
  getLh(params = []) {
    return apiClient.get('/api/housing?' + params.join('&'), {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
    })
  },
  getLhOptions() {
    return apiClient.get('/api/options/lh')
  },
  getLhsForEb(params = []) {
    return apiClient.get('/api/housing?' + params.join('&'))
  },
  getMs(params = []) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get('/api/montagestolpar?' + params.join('&'), {
      headers: { 
        'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token, 
        'Accept-Language': langcode,
        'admin': true
      }
    })
  },
  getMsTotal(params = []) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get('/api/montagestolpar/total?' + params.join('&'), {
      headers: { 
        'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token, 
        'Accept-Language': langcode,
        'admin': true
      }
    })
  },
  getKonv(params = []) {
    return apiClient.get('/api/konvertering?' + params.join('&'))
  },
  getKonvEb(params = []) {
    return apiClient.get('/api/konvertering-eb?' + params.join('&'))
  },
  getEbDataById(_id, params = []) {
    return apiClient.get(`/api/elslutbleck/${_id}?${params.join('&')}`)
  },
  getLhDataById(_id, params = []) {
    return apiClient.get(`/api/housing/${_id}?${params.join('&')}`)
  },
  getMsDataById(_id, params = []) {
    return apiClient.get(`/api/montagestolpar/${_id}?${params.join('&')}`)
  },
  getEmDataById(_id, params = []) {
    return apiClient.get(`/api/eltryckeslas-motorlas/${_id}?${params.join('&')}`)
  },
  getSbDataById(_id, params = []) {
    return apiClient.get(`/api/slutbleck/${_id}?${params.join('&')}`)
  },
  getNbDataById(_id, params = []) {
    return apiClient.get(`/api/nodutrymningsbeslag/${_id}?${params.join('&')}`)
  },
  getUserByEmail(email) {
    return apiClient.get(`/api/users/${email}`, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
    })
  },
  getContentData() {
    return apiClient.get('/api/content')
  },
  updateEbById(_id, payload, loglevel = 0) { // loglevel 0 = no log, 1 = log, 2 = ... (prepared for future use)
    return apiClient.put(`/api/elslutbleck/${_id}`, payload, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token, 'loglevel': loglevel }
    })
  },
  updateEmById(_id, payload, loglevel = 0) { // loglevel 0 = no log, 1 = log, 2 = ... (prepared for future use)
    return apiClient.put(`/api/eltryckeslas-motorlas/${_id}`, payload, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token, 'loglevel': loglevel }
    })
  },
  updateSbById(_id, payload, loglevel = 0) { // loglevel 0 = no log, 1 = log, 2 = ... (prepared for future use)
    return apiClient.put(`/api/slutbleck/${_id}`, payload, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token, 'loglevel': loglevel }
    })
  },
  updateNbById(_id, payload, loglevel = 0) { // loglevel 0 = no log, 1 = log, 2 = ... (prepared for future use)
    return apiClient.put(`/api/nodutrymningsbeslag/${_id}`, payload, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token, 'loglevel': loglevel }
    })
  },
  updateKonvById(_id, payload) {
    return apiClient.put(`/api/konvertering/${_id}`, payload, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
    })
  },
  updateKonvEbById(_id, payload) {
    return apiClient.put(`/api/konvertering-eb/${_id}`, payload, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
    })
  },
  updateContentById(_id, payload) {
    return apiClient.put(`/api/content/${_id}`, payload, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
    })
  },
  addKonv(payload) {
    return apiClient.post('/api/konvertering/', payload, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
    })
  },
  addKonvEb(payload) {
    return apiClient.post('/api/konvertering-eb/', payload, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
    })
  },
  updateLhById(_id, payload) {
    return apiClient.put(`/api/housing/${_id}`, payload, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
    })
  },
  updateMsById(_id, payload, loglevel = 0) { // loglevel 0 = no log, 1 = log, 2 = ... (prepared for future use)
    return apiClient.put(`/api/montagestolpar/${_id}`, payload, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token, 'loglevel': loglevel }
    })
  },
  getImageForId(_id, name) {
    return apiClient.get(`/api/files/image/${_id}/${name}`)
  },
  getDocsForArt(artnr, lang = "sv") {
    // Need to url encode the artnr
    return apiClient.get(`/api/files/${encodeURIComponent(artnr)}/docs/${lang}`)
    // return apiClient.get(`/api/files/${artnr}/docs/${lang}`)
  },
  // One method rule them all
  deleteById(_id, scope) {
    return apiClient.delete(`/api/${scope}/${_id}`, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
    })
  },
  deleteImageByArt(artnr, name, lang = "sv") {
    return apiClient.delete(`/api/files/${encodeURIComponent(artnr)}/images/${lang}/${encodeURIComponent(name)}`, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
    })
  },
  deleteDocByArt(artnr, name, lang = "sv") {
    return apiClient.delete(`/api/files/${encodeURIComponent(artnr)}/docs/${lang}/${encodeURIComponent(name)}`, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
    })
  },
  updateFolderByArt(newartnr, artnr) {
    console.log(newartnr, encodeURIComponent(newartnr))
    return apiClient.get(`/api/files/${artnr}/${encodeURIComponent(newartnr)}`, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
    })
  },
  deleteKonvById(_id) {
    return apiClient.delete(`/api/konvertering/${_id}`, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
    })
  },
  deleteKonvEbById(_id) {
    return apiClient.delete(`/api/konvertering-eb/${_id}`, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
    })
  },
  logActivity(payload) {
    return apiClient.post('/api/activity', payload, {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
    })
  },
  getActivies() {
    return apiClient.get('/api/activity', {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
    })
  },
  getLastActivity() {
    return apiClient.get('/api/activity/last', {
      headers: { 'x-Auth-Token': JSON.parse(localStorage.getItem('user')).token }
    })
  },
  isUniqueFor(prop, val, id) {
    let langcode = localStorage.getItem('langcode') ? localStorage.getItem('langcode') : 'sv'
    return apiClient.get(`/api/utils/unique/${prop}/${val}${id === null ? '' : '/'+id}`, { 'Accept-Language': langcode })
  }
}