// import ApiServiceAdmin from "@/services/ApiServiceAdmin";
// import ApiServiceAdmin from "@/services/ApiServiceAdmin";
import AdminMessagesMixin from "@/mixins/AdminMessagesMixin";
import futils from "@/mixins/futils.js";
import productAssets from "@/mixins/productAssets.js";
// import _ from "lodash";
import { MAX_SIZE } from "@/config";
import ApiServiceAdmin from "../services/ApiServiceAdmin";

export default {
  mixins: [AdminMessagesMixin, futils, productAssets],
  data() {
    return {
      id: null,
      loading: false,
      uselessNumber: 0,

    }
  },
  created() {
    if (this.$route.params.id && this.$route.params.id != "add") {
      this.id = this.$route.params.id;
    }
  },
  mounted() {
    if (this.initFormData) {
      this.initFormData();
    }
  },
  computed: {
    access_level() {
      // return 2;
      return this.$store.state.user.access_level;
    },
    showOriginal() {
      return this.access_level < 2;
    },
    showTranslation() {
      return this.access_level == 2;
    },
    maxSize() {
      return MAX_SIZE;
    },
  },
  methods: {
    isUnique(prop, val, id) {
      if ( val == "" ) {
        return true
      }
      console.log('Checking uniqueness of', prop, val, id)
      return ApiServiceAdmin.isUniqueFor(prop, val, id).then((response) => {
        const resp = response
        return resp.data.unique
      })
    },
    removeHtml(value) {
      return String(value).replace(/(<([^>]+)>)/ig, "").replace(/<|>/ig, "");
    },
    onOverlayHidden() {
      console.log("overlay hidden");
    },
    onReset(event) {
      event.preventDefault();
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    getDefaultSpecObjFor(scope, spec) {
      // TODO: Initialize spec object for a given scope(eb, sb, ms, lh etc.)
      console.log('getDefaultSpecObjFor', scope, spec)
      let specObj = {}
      switch (scope) {
        case 'sb':
          specObj = {
            "sb-l-20": [],
            "sb-b-20": [],
            "sb-p-9-0": [],
            "sb-p-10-5": [],
            "sb-p-10-0": [],
            "sb-p-11-5": [],
            "sb-p-12-5": [],
            "sb-p-12-0": [],
            "sb-p-13-5": [],
            "sb-p-13-0": [],
            "sb-p-14-5": [],
            "sb-p-15-5": [],
            "sb-p-15-0": [],
            "sb-p-16-3": [],
            "sb-p-16-5": [],
            "sb-p-16-0": [],
            "sb-p-17-5": [],
            "sb-p-17-0": [],
            "sb-p-18-0": [],
            "sb-p-19-0": [],
            "sb-p-20-0": [],
            "sb-p-21-3": [],
            "sb-p-21-5": [],
            "sb-p-21-0": [],
            "sb-p-22-3": [],
            "sb-p-24-5": [],
            "sb-p-24-0": [],
            "sb-p-25-5": [],
            "sb-p-26-5": [],
            "sb-p-26-9": [],
            "sb-p-26-0": [],
            "sb-p-27-9": [],
            "sb-p-28-0": [],
            "sb-p-30-5": [],
            "sb-p-31-5": [],
            "sb-p-36-5": [],
            "sb-p-36-0": [],
            "sb-p-37-5": [],
            "sb-p-4-5": [],
            "sb-p-5-0": [],
            "sb-p-9-5": [],
            "sb-plan": [],
            "sb-vinklad": [],
          }
          break;
        case 'em':
          specObj = {
            "em-oro-omstallbar": [],
            "em-oro-rattvand": [],
            "em-oro-omvand": [],
            "em-regelplacering-connect": [],
            "em-regelplacering-klassisk": [],
            "em-dd-29-0": [],
            "em-dd-35-0": [],
            "em-dd-50-0": [],
            "em-dd-70-0": [],
            "em-automatik-ja": [],
            "em-automatik-nej": [],
            "em-ej-brandkrav": [],
            "em-brandkrav-e30-el30": [],
            "em-brandkrav-e60-el60": [],
            "em-brandkrav-e90-el90": [],
            "em-brandkrav-e120-el120": [],
            "em-trycke-uts": [],
            "em-kortlasare-uts": [],
            "em-kodlasare-uts": [],
            "em-trycke-ins": [],
            "em-kortllasare-ins": [],
            "em-kodlasare-ins": [],
            "em-oppnaknapp-ins": [],
            "em-split-spindle-ins": [],
            "em-ss-en-179-ins-ja": [],
            "em-ss-en-1125-ins": [],
            "em-vred-ins": [],
            "em-nyckel-ins": [],
            "em-ej-aterinrymning": [],
            "em-aterinrymning-mekanisk": [],
            "em-aterinrymning-elektrisk-via-brandlarm": [],
            "em-aterinrymning-elektrisk-via-brandlarm-och-eller-oppnaknapp-ja":
              [],
            "em-hio-nej": [],
            "em-hio-ja": [],
            "em-indikering-trycke-ins": [],
            "em-indikering-dorrlage": [],
            "em-indikering-hakregel": [],
            "em-indikering-forregling": [],
            "em-indikering-forcerad-dorr": [],
            "em-lasklass-ssf-3522-1a": [],
            "em-lasklass-sff-3522-1b": [],
            "em-lasklass-sff-3522-2a": [],
            "em-lasklass-sff-3522-2b": [],
            "em-lasklass-sff-3522-3": [],
            "em-sse-split-spindel": [],
            "em-sse-enkel": [],
            "em-sse-omstallbar": [],
          }
          break;
        default:
          break;
      }
      return specObj
    }
  },
  beforeRouteLeave(to, from, next) {
    if ( from.path.indexOf('/add') > -1 ) {
      next();
      return;
    }
    const answer = window.confirm(this.$t("text.admin.leave-warning"));
    if (answer) {
      next();
    } else {
      next(false);
    }
  },
}