<template>
  <div class="container">
    <div class="d-flex w-50 h-100 p-3 mx-auto flex-column p-5 my-5 bg-light rounded shadow-sm">
      <h1>Uh-Oh! 😱</h1>
      <h3>Det är problem med nätverket. <router-link :to="{ name: 'guide' }">Ladda om sidan</router-link> eller försök igen om en stund.</h3>
    </div>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped></style>
