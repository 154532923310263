<template>
  <div>
    <b-row no-gutters>
      <b-col>
        <slot name="title">
          <strong>{{ title }}</strong>
        </slot>
      </b-col>
    </b-row>
    <b-row no-gutters class="products-grid">
      <b-col cols="auto" v-for="lh in publishedProducts" v-bind:key="lh" class="text-center">
        <div>
          <ProductImages single :size="size" :artnr="lh" :key="lh" img-width="160px"></ProductImages>
        </div>
        <b-link v-b-modal.productInfoModal @click="fetchModalData(lh)" block variant="link" :id="`productModalLink${lh}`" :data-artnr="lh" class="stretched-link" :title="lh">{{ lh }}</b-link>
      </b-col>
    </b-row>
    <ProductInfoModal ref="productModal" id="productInfoModal" v-bind="modalData" :product-docs="[]" scope="lh"></ProductInfoModal>
  </div>
</template>

<script>
  import ProductImages from "./ProductImages";
  import ApiService from "@/services/ApiService";
  import { mapGetters } from "vuex";
  export default {
    name: "ProductsGrid",
    props: ["products", "title", "size"],
    computed: {
      ...mapGetters("productEB", ["getProduct", "getDocs"]),
      housings() {
        return this.lhs;
      },
    },
    components: {
      ProductImages,
      ProductInfoModal: () => import("./ProductInfoModal"),
    },
    data() {
      return {
        API_HOST: process.env.VUE_APP_DBHOST,
        lhs: [],
        modalData: null,
        publishedProducts: [],
      };
    },
    methods: {
      
      getLhData(artnr) {
        return this.lhs.filter((lh) => lh.artnr == artnr);
      },
      filterProductsPublished() {
        let pubLhs = this.lhs.filter((lh) => lh.published);
        this.publishedProducts = this.products.filter((artnr) => {
          return pubLhs.filter((plh) => plh.artnr == artnr).length > 0;
        });
      },
      getLhs() {
        ApiService.getAllLH()
          .then(async (response) => {
            this.lhs = await response.data;
            this.filterProductsPublished();
          })
          .catch((err) => console.error(err));
      },
      showProductModal(artnr) {
        this.$refs["productModal"].show();
        this.fetchModalData(artnr);
      },
      fetchModalData(artnr) {
        ApiService.getLhDataByArt(artnr).then(async (response) => {
          this.modalData = await response.data;
        });
      },
    },
    mounted() {
      this.getLhs();
    },
  };
</script>

<style scoped lang="scss">
  @import "../../../scss/custom.scss";
  @import "../../../node_modules/bootstrap/scss/mixins/_breakpoints.scss";

  @include media-breakpoint-down(md) {
    .products-grid {
      justify-content: space-between;
    }
  }
  h4 {
    margin-top: 10px;
  }
</style>
