<template>
  <div>
    <form @change="$emit('on-change-slug', spec)">
      <table class="table table-sm table-hover table-bordered table-responsive shadow rounded">
        <thead class="thead-dark" v-if="options">
          <tr>
            <th scope="col" class="text-nowrap th-sticky-left">{{ $t("text.admin.lashus") }}</th>
            <th scope="col" class="text-nowrap" v-for="(o, index) in options" :key="index">{{ $t(o) }}{{ columnExtraInfo(o) }}</th>
          </tr>
        </thead>
        <tbody v-if="lhs">
          <tr v-for="(lh, index) in lhs" :key="index">
            <th scope="row" class="text-center th-sticky-left">
              {{ lh }}
            </th>
            <td v-for="(o, index) in options" :key="index" align="center">
              <b-form-checkbox :disabled="access_level > 1" :name="o" :value="lh" v-if="checked[o]" v-model="checked[o]"></b-form-checkbox>
            </td>
          </tr>
        </tbody>
      </table>
      <details v-if="access_level == 0">
        <table class="" border="1">
          <thead class="" v-if="options">
            <tr>
              <th scope="col" class="">{{ $t("text.admin.lashus") }}</th>
              <th scope="col" class="text-nowrap" v-for="(o, index) in options" :key="index">{{ $t(o) }}{{ columnExtraInfo(o) }}</th>
            </tr>
          </thead>
          <tbody v-if="lhs">
            <tr v-for="(lh, index) in lhs" :key="`debug-${index}`">
              <th scope="row" class="text-center th-sticky-left">
                {{ lh }}
              </th>
              <td v-for="(o, index) in options" :key="`debug${index}`" align="center">
                <span v-if="checked[o].filter((c) => c == lh).length">X</span>
              </td>
            </tr>
          </tbody>
        </table>
      </details>
    </form>

    <!-- <code>options: {{ options }}</code>
    <br />
    <code>ebid: {{ ebid }}</code>
    <br />
    <code>EB spec: {{ spec }}</code>
    <br />
    <code>lhs: {{ lhs }}</code>
    <br /> -->
  </div>
</template>

<script>
  import ApiServiceAdmin from "@/services/ApiServiceAdmin";
  const _ = require("lodash");
  export default {
    name: "AdminEbOptionsEdit",
    props: ["ebid"],
    data() {
      return {
        spec: {
          // Todo: This should probably be dynamic. Fetch these from options
          rattvand: [],
          omvand: [],
          "tryckes-fall": [],
          "cylinder-fall": [],
          "dubbel-fall": [],
          automatik: [],
          "ej-automatik": [],
          "ej-brandkrav": [],
          "brandkrav-e30-el30": [],
          "brandkrav-e60-el60": [],
          "brandkrav-e90-el90": [],
          "brandkrav-e120-el120": [],
          "trycke-uts": [],
          "draghandtag-uts": [],
          "ej-aterinrymning": [],
          "trycke-ins": [],
          "draghandtag-ins": [],
          "vred-ins": [],
          "nyckel-ins": [],
          "split-spindle": [],
          "ss-en-179": [],
          "ss-en-1125": [],
          "elektrisk-via-brandlarm-och-eller-oeppnaknapp": [],
          "elektrisk-via-brandlarm": [],
          mekanisk: [],
          "kopplingsplint-hi-o-ja": [],
          "kopplingsplint-hi-o-nej": [],
          "mikrobrytare-ja": [],
          "mikrobrytare-nej": [],
        },
        lhs: [],
        options: [],
      };
    },
    computed: {
      access_level() {
        return this.$store.state.user.access_level;
      },
      checked() {
        return this.spec;
      },
    },
    mounted() {
      this.fetchEb();
    },
    methods: {
      columnExtraInfo(slug) {
        if (slug.indexOf("draghandtag-ins") != -1) {
          return " (ins.)";
        }
        if (slug.indexOf("draghandtag-uts") != -1) {
          return " (uts.)";
        }
        if (slug.indexOf("trycke-ins") != -1) {
          return " (ins.)";
        }
        if (slug.indexOf("trycke-uts") != -1) {
          return " (uts.)";
        }
        return "";
      },
      async fetchEb() {
        try {
          // this.lhs = _.sortBy(_.uniq(_.flatten(_.valuesIn(spec))));
          this.lhs = await ApiServiceAdmin.getLh().then((response) => {
            return _.map(response.data, (lh) => lh.artnr);
          });
          this.options = await ApiServiceAdmin.getEbOptions().then((response) => {
            return _.flatten(_.valuesIn(_.map(response.data, (o) => o.slugs)));
          });

          if (this.ebid !== null) {
            // New EB
            const eb = await ApiServiceAdmin.getEbDataById(this.ebid);

            if (eb.data.spec !== null) {
              // If no value in DB, use default object
              this.spec = eb.data.spec;
            }
          } else {
            this.spec = {};
            this.options.forEach((o) => (this.spec[o] = []));
          }
        } catch (error) {
          console.error(error);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  thead > tr > th {
    font-size: 14px;
    padding: 0.25em 1em;
  }
  .th-sticky-left {
    position: sticky;
    left: 0;
    box-shadow: inset -1px 0 1px rgb(0 0 0 / 10%);
  }
  tbody .th-sticky-left {
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 999;
  }
</style>
