import ApiClient from '@/services/ApiService'
const _ = require('lodash');

export const namespaced = true;

export const state = {
  options: [],
  selection: [],
  disabled: [],
  categories: [],
  esbs: [],
  loading: false,
  didFirstTimeCleanup: false
}

export const actions = {
  fetchOptions({ commit, dispatch, getters, rootState }, routename) {
    state.loading = true
    ApiClient.getEb()
      .then((response) => {
        let namePath = 'name'
        var translationPath = 'name'
        if (window.localStorage.langcode == 'no') {
          translationPath = 'translations.no.name'
        }
        commit("UPDATE_ESBS", { name: "passande-elslutbleck", slugs: _.map(response.data, namePath), translations: _.map(response.data, translationPath) })
      })
      // console.log('routename', routename)
      // if (routename == "guide-filter-ms") {
        return ApiClient.getMSOptions()
          .then(async (response) => {
            let options = await response.data
            // if ( getters.getDidFirstTimeCleanup == false ) {
            //   // First time loading the options, those old values that have not matches should not be visible
            //   let outerpromise = new Promise((outerresolve) => {
            //     options.forEach((opt, colindx) => { // Remove options not generate any result
            //       let promise = new Promise((resolve) => {
            //         let origSlugs = opt.slugs
            //         opt.slugs = []
            //         let checkedSlugs = origSlugs
            //         let filteredSlugs = [];
            //         let slugsObj = [];
            //         for (let i = 0; i < origSlugs.length; i++) {
            //           let slug = origSlugs[i];
            //           let slugIndexInArr = origSlugs.indexOf(slug) // Get original index of slug
            //           dispatch('check', slug).then(check => {
            //             let slugIndexInCheckedSlugsArr = checkedSlugs.indexOf(slug) // Get index of slug in checkedSlugs array
            //             if (check) {
            //               slugsObj.push({ slug: slug, _weight: slugIndexInArr })
            //               filteredSlugs.push(slug)
            //             }
            //             checkedSlugs.splice(slugIndexInCheckedSlugsArr, 1)
            //             // setTimeout(() => { // Seems lite this 1ms delay is needed to make sure all promises are resolved correctly
            //             if (checkedSlugs.length == 0) {
            //               // checkedSlugs is null, so we can resolve the promise
            //               resolve(slugsObj);
            //             }
            //             // }, 1000);
            //           });
            //         }
            //       })

            //       promise.then((slugsObj) => { // When filter is done, we can update the option
            //         let slugObjSorted = _.sortBy(slugsObj, ['_weight'])
            //         let slugObjSortedFlat = _.map(slugObjSorted, (s) => s.slug)
            //         opt.slugs = slugObjSortedFlat
            //         if (colindx == options.length - 1) {
            //           setTimeout(() => {
            //             outerresolve(options)
            //           }, 200);
            //         }
            //       })
            //     });
            //   })
            //   outerpromise.then((options) => {
            //     commit('UPD_OPTIONS', options)
            //     dispatch('updCategories')
            //     if (rootState.productEB.product) {
            //       commit("optionsMS/ADD_SLUG", rootState.productEB.product.name, { root: true });
            //     }
            //     dispatch('setDidFirstTimeCleanup', true)
            //     state.loading = false
            //   })
            // } else {
              commit('UPD_OPTIONS', options)
                dispatch('updCategories')
                dispatch('updDisabled')
                if (rootState.productEB.product) {
                  commit("optionsMS/ADD_SLUG", rootState.productEB.product.name, { root: true });
                }
                state.loading = false
            // }
            return response
          }).catch(err => {
            return new Promise(err)
          })
          
      // } else {
      //   return ApiClient.getMSOptions()
      //     .then(async (response) => {
      //       let options = await response.data
      //       commit('UPD_OPTIONS', options)
      //       dispatch('updCategories')
      //       // We should disable slugs not having any result.
      //       dispatch('updDisabled')
      //       state.loading = false
      //       return options
      //     })
      // }
  },
  async check({ state }, slug) {
    return await ApiClient.checkMS([...state.selection, slug])
      .then((response) => {
        return response.data.check
      })
  },
  updCategories({ state, commit }) {
    const indexOfCat = _.findIndex(state.options, (c) => c.name == "produktkategori");
    commit('UPD_CATEGORIES', state.options[indexOfCat].slugs)

  },
  async updDisabled({ dispatch, state }) {
    try {
      state.options.forEach(o => {
        o.slugs.forEach(s => {
          dispatch('check', s).then(async check => {
            if (!check) {
              dispatch('addDisabled', s)
            } else {
              dispatch('rmDisabled', s)
            }
          });
        });
      });
    } catch (error) {
      console.error(error)
    }
  },
  addDisabled({ commit }, slug) {
    commit('ADD_DISABLED', slug)
  },
  rmDisabled({ commit }, slug) {
    commit('RM_DISABLED', slug)
  },
  addSlug({ commit, state, dispatch }, slug) {
    console.time("ms-slug-select");
    state.loading = true
    dispatch("productsMS/resetSkip", null, { root: true });
    dispatch('productMS/resetProduct', null, { root: true })
    commit('ADD_SLUG', slug)
    dispatch("updDisabled");
    dispatch('productsMS/resetSkip', null, { root: true })
    dispatch("productsMS/fetchProducts", [...state.selection], { root: true })
      .then(response => {
        commit('UPD_TOTAL', response.data.length)
      })
      .finally(() => {
        state.loading = false
        console.timeEnd("ms-slug-select");
      })
  },
  rmSlug({ commit, state, dispatch }, slug) {
    state.loading = true
    dispatch("productsMS/resetSkip", null, { root: true });
    dispatch('productMS/resetProduct', null, { root: true })
    commit('RM_SLUG', slug)
    dispatch("updDisabled");
    dispatch('productsMS/resetSkip', null, { root: true })
    dispatch("productsMS/fetchProducts", [...state.selection], { root: true })
      .then(response => {
        commit('UPD_TOTAL', response.data.length)
      })
      .finally(() => {
        state.loading = false
      })
  },
  resetSlugs({ commit, dispatch }) {
    dispatch('resetSelectedMs', null, { root: true })
    dispatch('resetSelectedEb', null, { root: true })
    commit('RESET_SLUGS')
    commit('UPD_RESULTS', [])
    commit('UPD_TOTAL', 0)
    commit('RESET_DISABLED')
  },
  // New
  resetSelection({ commit }) {
    commit('RESET_SLUGS')
  },
  resetDisabled({ commit }) {
    commit('RESET_DISABLED')
  },
  setDidFirstTimeCleanup({ commit }, bool) {
    commit('SET_DID_FIRST_TIME_CLEANUP', bool)
  }
}
export const mutations = {

  ADD_SLUG(state, slug) {
    state.selection.push(slug)
  },
  RM_SLUG(state, slug) {
    state.selection = state.selection.filter(s => s != slug)
  },
  RESET_SLUGS(state) {
    state.selection = []
  },
  RESET_DISABLED(state) {
    state.disabled = []
  },
  UPD_TOTAL(state, total) {
    state.totalCount = total
  },
  UPD_CATEGORIES(state, categories) {
    state.categories = categories
  },
  UPD_OPTIONS(state, options) {
    state.options = options
  },
  UPD_RESULTS(state, results) {
    state.results = results
  },
  SET_ALL_DISABLED(state) {
    const mapped = state.options.map(o => o.slugs)
    const flattened = _.flatten(mapped)
    state.disabled = flattened
  },
  ADD_DISABLED(state, slug) {
    state.disabled = [...state.disabled, slug]
  },
  RM_DISABLED(state, slug) {
    state.disabled = state.disabled.filter(s => s != slug)
  },
  UPDATE_ESBS(state, esbs) {
    state.esbs = esbs
  },
  SET_DID_FIRST_TIME_CLEANUP(state, bool) {
    state.didFirstTimeCleanup = bool
  }
}

export const getters = {
  getSelection: state => state.selection,
  getOptions: state => state.options,
  getEsbs: state => state.esbs,
  getDisabled: state => state.disabled,
  getCategories: state => state.categories,
  inSelection: (state, getters) => getters.getSelection.filter(s => s === state.slug).length > 0,
  isDisabled: (state, getters) => (slug) => {
    return getters.getDisabled.includes(slug)
  },
  getDidFirstTimeCleanup: state => state.didFirstTimeCleanup
}
