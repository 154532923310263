<template>
    <div>
      <AdminOptionsListTable :data="optionsTableData" :loading="loading" :options="options" 
      @on-delete-item="deleteSlug(...arguments, 'em')" 
      @on-select-product="$router.push(`/admin/em/${arguments[0]}`)" 
      @on-updated-options-list-table="onUpdatedOptionsListTable"></AdminOptionsListTable>
    </div>
</template>

<script>
  import AdminListMixin from "@/mixins/AdminListMixin";
  import AdminAccessMixin from "@/mixins/AdminAccessMixin";
  import ApiServiceAdmin from "@/services/ApiServiceAdmin";
  import AdminOptionsListTable from "@/components/admin/AdminOptionsListTable";
  export default {
    name: "AdminEmOptionsList",
    mixins: [AdminListMixin, AdminAccessMixin],
    components: {
      AdminOptionsListTable
    },
    data() {
      return {
        options: [],
        totalForSlug: {},
        productsForSlug: {},
        optionsTableData: [],
        loading: false
      };
    },
    methods: {
      getTotalForSlug(slug) {
        return ApiServiceAdmin.getEmTotal([slug]).then((response) => {
            return response.data
            // if ( response.data > 0 ) {
            //   console.log('Request data for ',slug)
            //   this.getProductsForSlug(slug);
            // }
        });
      },
      getProductsForSlug(slug) {
        return ApiServiceAdmin.getEm([slug]).then((response) => {
            return response.data
        });
      },
      initOptionsListData() {
        this.options.forEach((o) => {
          o.slugs.forEach((s) => {
            console.log('s',s)
            this.getTotalForSlug(s)
            .then((count) => {
              console.log('this.totalForSlug',this.totalForSlug)
              this.totalForSlug[s] = count;
              if ( count > 0 ) {
                this.getProductsForSlug(s);
              }
            })
          });
        });
      },
      async fetchEmOptions() {
        try {
          this.options = await ApiServiceAdmin.getEmOptions().then((response) => {
            return response.data;
          }); 
        } catch (error) {
          console.error(error);
        }
      },
      fetchOptions() {
        this.loading = true;
        this.fetchEmOptions()
        .then(() => {
          // Create new array with objects, containing useful data for the table
          this.optionsTableData = this.options.map((o) => {
            return {
              _id: o._id,
              name: o.name,
              slugData: o.slugs.map((s) => {
                return {
                  name: s,
                  total: -1,
                  products: [],
                };
              }),
            };
          });
          this.optionsTableData.forEach((o) => {
            o.slugData.forEach((s) => {
              this.getTotalForSlug(s.name)
              .then((count) => {
                s.total = count;
                if ( count > 0 ) {
                  this.getProductsForSlug(s.name)
                  .then((products) => {
                    s.products = products;
                  })
                }
              })
            });
          });
        });
      }
    },
    mounted() {
      this.fetchOptions();
    },
  };
</script>

<style lang="scss" scoped></style>
