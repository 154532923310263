import ApiClient from '../../services/ApiService'

export const namespaced = true;

export const state = {
  data: []
}

export const actions = {
  async fetchContent({ commit }) {
    return await ApiClient.getContent()
      .then((response) => {
        commit('UPD_CONTENT', response.data)
        return response.data
      })
  }
}

export const mutations = {
  UPD_CONTENT(state, content) {
    state.data = content
  }
}

export const getters = {
  getContent: state => state.content,
}