import ApiClient from '@/services/ApiService'
const _ = require('lodash');

export const namespaced = true;

export const state = {
  options: [],
  selection: [],
  disabled: [],
  categories: [],
  esbs: [],
  sbs: [],
  loading: false
}

export const actions = {
  fetchOptions({ commit, state, dispatch }) {
    state.loading = true
    return ApiClient.getEMOptions()
      .then(async (response) => {
        let options = await response.data
        commit('UPD_OPTIONS', options)
        dispatch('updCategories')
        // We should disable slugs not having any result.
        dispatch('updDisabled')
        state.loading = false
        return options
      })
  },
  async check({ state }, slug) {
    return await ApiClient.checkEM([...state.selection, slug])
      .then((response) => {
        return response.data.check
      })
  },
  updCategories({ state, commit }) {
    const indexOfCat = _.findIndex(state.options, (c) => c.name == "em-omstallbar-rattvand-omvand");
    if (indexOfCat > -1) {
      commit('UPD_CATEGORIES', state.options[indexOfCat].slugs)
    }

  },
  updDisabled({ dispatch, state }) {
    state.options.forEach(o => {
      o.slugs.forEach(s => {
        dispatch('check', s).then(check => {
          if (!check) {
            dispatch('addDisabled', s)
          } else {
            dispatch('rmDisabled', s)
          }
        });
      });
    });
  },
  addDisabled({ commit }, slug) {
    commit('ADD_DISABLED', slug)
  },
  rmDisabled({ commit }, slug) {
    commit('RM_DISABLED', slug)
  },
  resetSlugs({ commit, dispatch }) {
    dispatch('productEM/resetProduct', null, { root: true })
    dispatch('productSB/resetProduct', null, { root: true })
    dispatch("setSelectedEm", null, { root: true });
    commit('optionsSB/RESET_SLUGS', null, { root: true })
    commit('RESET_SLUGS')
    commit('UPD_RESULTS', [])
    commit('UPD_TOTAL', 0)
    commit('RESET_DISABLED')
  },
  addSlug({ commit, state, dispatch }, slug) {
    state.loading = true
    dispatch("productsEM/resetSkip", null, { root: true });
    dispatch('productEM/resetProduct', null, { root: true })
    commit('ADD_SLUG', slug)
    dispatch("updDisabled");
    dispatch('productsEM/resetSkip', null, { root: true })
    dispatch("productsEM/fetchProducts", [...state.selection], { root: true })
      .then(response => {
        commit('UPD_TOTAL', response.data.length)
      })
      .finally(() => {
        state.loading = false
        console.timeEnd("em-slug-select");
      })
  },
  rmSlug({ commit, state, dispatch }, slug) {
    state.loading = true
    dispatch("productsEM/resetSkip", null, { root: true });
    dispatch('productEM/resetProduct', null, { root: true })
    commit('RM_SLUG', slug)
    dispatch("updDisabled");
    dispatch('productsEM/resetSkip', null, { root: true })
    dispatch("productsEM/fetchProducts", [...state.selection], { root: true })
      .then(response => {
        commit('UPD_TOTAL', response.data.length)
      })
      .finally(() => {
        state.loading = false
      })
  },
  // New
  resetSelection({ commit }) {
    commit('RESET_SLUGS')
  },
  resetDisabled({ commit }) {
    commit('RESET_DISABLED')
  }
}
export const mutations = {

  ADD_SLUG(state, slug) {
    state.selection.push(slug)
  },
  RM_SLUG(state, slug) {
    state.selection = state.selection.filter(s => s != slug)
  },
  RESET_SLUGS(state) {
    state.selection = []
  },
  RESET_DISABLED(state) {
    state.disabled = []
  },
  UPD_TOTAL(state, total) {
    state.totalCount = total
  },
  UPD_CATEGORIES(state, categories) {
    state.categories = categories
  },
  UPD_OPTIONS(state, options) {
    state.options = options
  },
  UPD_RESULTS(state, results) {
    state.results = results
  },
  SET_ALL_DISABLED(state) {
    const mapped = state.options.map(o => o.slugs)
    const flattened = _.flatten(mapped)
    state.disabled = flattened
  },
  ADD_DISABLED(state, slug) {
    state.disabled = [...state.disabled, slug]
  },
  RM_DISABLED(state, slug) {
    state.disabled = state.disabled.filter(s => s != slug)
  }
}

export const getters = {
  getSelection: state => state.selection,
  getOptions: state => state.options,
  getDisabled: state => state.disabled,
  getCategories: state => state.categories,
  inSelection: (state, getters) => getters.getSelection.filter(s => s === state.slug).length > 0,
  isDisabled: (state, getters) => (slug) => {
    return getters.getDisabled.includes(slug)
  },
  allRequiredOptionsSelectedToShowNB: (state) => {
    return state.selection.includes('em-ss-en-179-ins-ja')
  }
}
