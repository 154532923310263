import ApiClient from '@/services/ApiService'

export const namespaced = true;

export let state = {
  mskonvs: []
}

export const actions = {
  fetchMsKonv({ commit }) {
    ApiClient.getMsKonv()
      .then(async (response) => {
        const mskonvs = await response.data;
        commit('UPD_MSKONV', mskonvs)
      })
  }
}

export const mutations = {
  UPD_MSKONV(state, mskonvs) {
    state.mskonvs = mskonvs
  }
}

export const getters = {
  getMsKonvs: state => state.mskonvs,
}