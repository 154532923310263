<template>
  <b-container fluid class="position-absolute px-0" style="position: fixed; top: 107px;z-index: 2000;">
    <b-alert show fade :variant="alert.variant" class="m-0 rounded-0" style="" dismissible>
      {{ alert.msg }}
    </b-alert>
  </b-container>
</template>

<script>
  export default {
    name: "alert",
    props: {
      alert: {
        type: Object,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped></style>
