<template>
  <div>
    <table class="table table-hover">
      <thead class="table-secondary">
        <tr>
          <th>getEbOptions</th>
          <th>Namn</th>
          <th>Slugs</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="o in options" v-bind:key="o._id">
          <td>{{ o._id }}</td>
          <td>{{ o.name }}</td>
          <td>{{ o.slugs }}</td>
          <td>
            <div class="btn-group">
              <button class="btn btn-sm btn-primary py-0">Edit</button>
              <button class="btn btn-sm btn-danger py-0">Delete</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import ApiService from "../../services/ApiServiceAdmin";
  export default {
    name: "options",
    data() {
      return {
        options: [],
      };
    },
    mounted() {
      ApiService.getEbOptions().then((response) => {
        this.options = response.data.map((o) => {
          return o;
        });
      });
    },
  };
</script>

<style lang="scss" scoped></style>
