import axios from 'axios'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_DBHOST,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  uploadFilesToArt(artnr, type, lang = "sv", formData) {
    return apiClient.post(`/api/upload/${artnr}/${type}/${lang}`, formData, {
      headers: {
        'X-Auth-Token': JSON.parse(localStorage.getItem('user')).token,
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}