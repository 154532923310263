<template>
  <b-container
    :fluid="fluid"
    :style="`background-color: ${bgcolor}; color: ${textcolor}`"
  >
    <b-row no-gutters>
      <b-col cols="12" md="12" class="mx-auto px-2 content-container">
        <slot></slot>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "RowContentWrapper",
  props: {
    fluid: { type: Boolean, default: false },
    blue: { type: Boolean, default: false },
  },
  computed: {
    bgcolor() {
      return this.blue ? "#00a0d0" : "none";
    },
    textcolor() {
      return this.blue ? "#ffffff" : "#393939";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "scss/custom.scss";
@import "node_modules/bootstrap/scss/mixins/_breakpoints.scss";

.content-container {
  max-width: 2000px;
  padding: 50px 0 60px;

  @include media-breakpoint-down(sm) {
    padding: 30px 0 20px;
  }
}
</style>
