import "core-js/stable";
import "regenerator-runtime/runtime";
import 'mutationobserver-shim'
import Vue from 'vue'
import store from './store/store'
import App from './App.vue'
import router from './router'
import 'nprogress/nprogress.css'
import axios from 'axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import '../scss/custom.scss'
import i18n from "./i18n";
import VueMobileDetection from 'vue-mobile-detection'
import VueScreen from 'vue-screen';
import ScreenUtilsMixin from "@/mixins/ScreenUtilsMixin";
import CountryFlag from 'vue-country-flag'
import VueResizeText from 'vue-resize-text';
import VueMeta from 'vue-meta';
import Vidle from 'v-idle'
// Detect if mobile
Vue.use(VueMobileDetection)
// Screen
Vue.use(VueScreen, 'bootstrap');
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// Resize text
Vue.use(VueResizeText)

// Vue Meta
Vue.use(VueMeta)

// Detect idle users
Vue.use(Vidle)

Vue.config.productionTip = false

// Utils for screen size and device detection
Vue.mixin(ScreenUtilsMixin)
Vue.component('country-flag', CountryFlag)
var vm = new Vue({
  router,
  store,
  i18n,
  created() {
    const userString = localStorage.getItem('user')
    if (userString) {
      const userData = JSON.parse(userString)
      this.$store.commit('user/SET_USER_DATA', userData)
    }
    // Set access level from local storage
    const access_level = localStorage.getItem('access_level')
    if (access_level) {
      this.$store.commit('user/SET_ACCESS_LEVEL', access_level)
    }
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          this.$store.dispatch('user/logout')
        }
        return Promise.reject(error)
      }
    )
    if (this.$route.params.language) {
      // Set language from url
      this.$i18n.locale = this.$route.params.language;
      localStorage.setItem("langcode", this.$route.params.language);
    } else {
      // Set localStorage for langcode
      localStorage.setItem('langcode', this.$i18n.locale)
    }
  },
  render: h => h(App),
}).$mount('#app')
global.vm = vm; //Define your app variable globally