export default {
  data() {
    return {
      toastCount: 0
    }
  },
  methods: {
    makeToast(options) {
      this.toastCount++
      this.$bvToast.toast(options.text, {
        ...options,
        autoHideDelay: 5000,
        appendToast: true
      })
    }
  }
}

