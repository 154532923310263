import ApiClient from '@/services/ApiService'

export const namespaced = true;

export const state = {
  products: [],
  total: 0,
  ui: {
    paginate: {
      limit: 20,
      skip: 0
    }
  }
}

export const actions = {
  fetchProducts({ commit, dispatch, state }, params = []) {
    params = []
    dispatch('fetchTotal', [])
    // dispatch('setLimit', state.ui.paginate.limit)
    return ApiClient.getNb(params, state.ui.paginate)
      .then(async (response) => {

        if (state.ui.paginate.skip > 0) {
          // Append to list
          commit('APPEND_PRODUCTS', await response.data)
        } else {
          commit('UPD_PRODUCTS', await response.data)
        }
        return response
      })
  },
  fetchTotal({ commit }, params = []) {
    ApiClient.getNbTotal(params)
      .then(async response => await commit('SET_TOTAL', response.data))
  },
  setLimit({ commit }, limit) {
    commit('UPD_LIMIT', limit)
  },
  updateSkip({ commit, state, dispatch }) {
    commit('UPD_SKIP', state.ui.paginate.skip + state.ui.paginate.limit)
    dispatch("fetchProducts", [])
  },
  resetSkip({ commit }) {
    commit('UPD_SKIP', 0)
  },
  async fetchDocsForProduct({ commit }) {
    return await ApiClient.getDocsForArt()
      .then(async (response) => {
        await commit('UPD_PRODUCTS', response.data)
        return response.data
      })
  },
}
export const mutations = {
  UPD_PRODUCTS(state, products) {
    state.products = products
  },
  APPEND_PRODUCTS(state, products) {
    state.products = [...state.products, ...products]
  },
  UPD_SKIP(state, skip) {
    state.ui.paginate.skip = skip
  },
  UPD_LIMIT(state, limit) {
    state.ui.paginate.limit = limit
  },
  SET_TOTAL(state, total) {
    state.total = total
  }
}
export const getters = {
  getProducts: state => state.products,
  getCount: (state) => state.products.length,
  getTotal: (state) => state.total,
  getUi: (state) => state.ui,
}