<template>
    <div>
        <b-tabs v-model="activetab" pills content-class="m-0" card>
            <b-tab :title="$t('Produkter')" no-body lazy>
                <AdminEbList :data="products" @on-deleted-item="fetchEb"></AdminEbList>
            </b-tab>
            <b-tab :title="$t('Kriterier')" no-body lazy>
                <AdminEbOptionsList></AdminEbOptionsList>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
    import AdminEbList from "@/components/admin/AdminEbList";
    import AdminEbOptionsList from "@/components/admin/AdminEbOptionsList";
    import ApiServiceAdmin from "@/services/ApiServiceAdmin";
    export default {
        components: {
            AdminEbList,
            AdminEbOptionsList,
            ApiServiceAdmin
        }, 
        data() {
            return {
                products: [],
                totalForSlug: {},
                activetab: 0
            }
        },
        methods: {
            fetchEb() {
                ApiServiceAdmin.getEb().then((response) => {
                    this.products = response.data;
                });
            },
        },
        mounted() {
            this.fetchEb();
        },
    }

</script>