<template>
  <div>
    <div class="user-login-wrapper bg-light">
      <b-form novalidate class="user-login shadow-sm p-4 rounded bg-white needs-validation" @submit.prevent="login" method="post">
        <b-form-group label="e-postadress" label-for="email">
          <b-form-input size="lg" v-model="form.email.value" id="email" :invalid-feedback="form.email.error" name="email" type="text" class="form-control" @change="form.email.error = ''" required autofocus />
        </b-form-group>
        <b-form-group label="lösenord" label-for="password">
          <b-form-input size="lg" type="password" v-model="form.password.value" id="password" :invalid-feedback="form.password.error" name="email" @change="form.password.error = ''" required />
        </b-form-group>
        <b-button type="submit" size="lg" variant="primary">Logga in</b-button>
        <em class="text-danger" v-if="form.error">{{ form.error }}</em>
        <!-- <router-link to="/register">
          Don't have an account? Register.
        </router-link> -->
      </b-form>
    </div>
  </div>
</template>

<script>
  export default {
    name: "login",
    data() {
      return {
        form: {
          validated: false,
          error: "",
          email: {
            value: "",
            error: "",
          },
          password: {
            value: "",
            error: "",
          },
        },
      };
    },
    methods: {
      login() {
        this.$store
          .dispatch("user/login", { email: this.form.email.value, password: this.form.password.value })
          .then((response) => {
            console.log("response", response);
            this.$router.push({ name: "adm-dashboard" });
          })
          .catch((error) => {
            let data = error.response.data;
            if (data[0]) {
              data = data[0];
            }
            this.form.error = data.message;
            this.form.validated = true;
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .user-login-wrapper {
    display: flex;
    align-items: center;
    height: 100vh;
    .user-login {
      display: grid;
      grid-template-rows: auto;
      gap: 1em;
      width: 100%;
      max-width: 480px;
      margin: 0 auto 480px;
    }
  }
</style>
